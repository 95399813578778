
export const HEADER_DEPARTMENTS = [{
  label: 'COMMON.name',
  textLeft: true
}, {
  label: 'DEPARTMENTS.code'
}, {
  label: 'REGIONS.singleTitle'
}]

export const BODY_DEPARTMENTS = [{
  key: 'name',
  title: 'name'
}, {
  key: 'code',
  title: 'code'
}, {
  key: 'region',
  title: 'region'
}]
