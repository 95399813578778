import { isEqual } from 'lodash'
import { useRef, useEffect } from 'react'

export const useTabTitle = (title: string, prevailOnUnmount = false): void => {
  const defaultTitle = useRef(document.title)

  useEffect(() => {
    document.title = title
  }, [title])

  useEffect(() => () => {
    if (isEqual(prevailOnUnmount, false)) {
      document.title = defaultTitle.current
    }
  }, [])
}
