import { reduce, isEmpty, get, map, includes, isEqual, has, filter, isUndefined, find } from 'lodash'

import { RelationShipData, IncludeData } from './jsonapi.types'

export interface SearchParam {
  [name: string]: string
}

export const buildQueryParams = (search: SearchParam): object =>
  reduce(search, (acc, value, key) => {
    if (isEmpty(value) || isUndefined(value)) {
      if (isEqual(key, 'adType')) {
        return {
          ...acc,
          [key]: ['sale', 'rental', 'sublet']
        }
      }

      if (isEqual(typeof value, 'boolean')) {
        return {
          ...acc,
          [key]: value
        }
      }

      return acc
    }

    if (has(value, 'label')) {
      return {
        ...acc,
        [key]: get(value, 'value')
      }
    }

    return {
      ...acc,
      [key]: value
    }
  }, {})

export const getFieldById = (data: IncludeData[], fieldId: number, fieldType: string, fieldKey: string): string =>
  get(find(data, ({ id, type }) => isEqual(type, fieldType) && isEqual(fieldId, id)), fieldKey, 'N/A')

export const getObjectById = (data: IncludeData[], fieldId: number, fieldType: string, fieldKeys: object): object => {
  const values = find(data, ({ id, type }) => isEqual(type, fieldType) && isEqual(fieldId, id))

  return reduce(fieldKeys, (acc, value, key) => {
    return {
      ...acc,
      [key]: get(values, `attributes.${value as string}`, '')
    }
  }, {})
}

export const getFieldByIds = (data: IncludeData[], fieldIds: number[], fieldType: string, fieldKey: string): string[] =>
  map(filter(data, ({ id, type }) => isEqual(type, fieldType) && includes(fieldIds, id)), (value) =>
    get(value, fieldKey, 'N/A'))

export const getObjectByIds = (data: IncludeData[], fieldIds: number[], fieldType: string, fieldKeys: object): object[] => {
  const values = filter(data, ({ id, type }) => isEqual(type, fieldType) && includes(fieldIds, id))

  return map(values, value => reduce(fieldKeys, (acc, key, field) => {
    if (isEqual(key, 'id')) {
      return {
        ...acc,
        [field]: get(value, 'id')
      }
    }

    return {
      ...acc,
      [field]: get(value, `attributes.${key as string}`, '')
    }
  }, {}))
}

export const getRelationships = <T extends unknown>(relationships: {}, fields: string[], arrayFields: string[]): T => {
  const ships = reduce(relationships, (acc, value, key) => {
    if (includes(arrayFields, key)) {
      return {
        ...acc,
        [key]: map(get(value, 'data'), ({ id }: RelationShipData) => id)
      }
    }

    if (includes(fields, key)) {
      return {
        ...acc,
        [key]: get(value, 'data.id')
      }
    }

    return acc
  }, {})

  return ships as T
}
