import { get } from 'lodash'
import { generateMedia } from 'styled-media-query'

export const breakpoints = {
  sm: '577px',
  md: '769px',
  lg: '993px',
  xl: '1201px',
  xxl: '1401px',
  xxxl: '1601px'
}

export const getMinMediaQuery = (size) => window.matchMedia('(min-width: ' + get(breakpoints, size))
export const getMaxMediaQuery = (size) => window.matchMedia('(max-width: ' + get(breakpoints, size))

const media = generateMedia(breakpoints)

export default media
