import styled from 'styled-components'
import { spacings, media } from '@folhomee/front-library'
import { get, isEqual, isUndefined } from 'lodash'

import { CardProps } from './Card.types'

const Card = styled.div<CardProps>`
  gap: ${({ gap }) => isUndefined(gap) ? '32' : gap}px;
  width: ${({ width100 }) => isEqual(width100, true) ? '100%' : ''};
  display: flex;
  padding: ${({ padding }) => isUndefined(padding) ? '40px 32px' : padding};
  box-shadow: 0 3px 6px 0 rgba(51, 102, 204, 0.15);
  align-items: ${({ alignStart }) => isEqual(alignStart, true) ? 'start' : 'center'};
  border-radius: 20px;
  flex-direction: ${({ row }) => isEqual(row, true) ? 'row' : 'column'};
  justify-content: ${({ justifyStart }) => isEqual(justifyStart, true) ? 'start' : 'center'};
  background-color: ${({ theme }) => get(theme, 'white')};

  ${media.lessThan('md')`
    padding: ${get(spacings, 'l')}px ${get(spacings, 's')}px ${get(spacings, 'm')}px;
  `}
`

export default Card
