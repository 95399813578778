import { AxiosError } from 'axios'
import { get, map, omit, size, range, first, toNumber } from 'lodash'
import { useMutation, UseQueryResult, UseMutationResult, useQuery, InfiniteData, useInfiniteQuery, UseInfiniteQueryResult, useQueryClient } from 'react-query'

import useClient from '../../hooks/useClient'
import { getIds } from '../utils/alertFormat'
import { Client } from '../Clients/Clients.types'
import { Alert, PageAlert, PageAlerts } from './Alerts.types'
import { buildQueryParams, getFieldByIds, getObjectById, getRelationships } from '../utils'

export const buildAlert = (id: number, attributes: any, included: any, relationships: any): Alert => {
  const ships = getRelationships(relationships, ['client'], ['cities', 'taxations', 'sources', 'departments', 'agglomerations', 'alert_cities'])

  return {
    id,
    mute: get(attributes, 'mute', false),
    name: get(attributes, 'name'),
    client: getObjectById(included, get(ships, 'client', 0), 'client', { email: 'email', firstname: 'first_name', lastname: 'last_name', phone: 'phone_number', pipedrive: 'id_pipedrive' }) as Client,
    cities: getIds(included, 'city', get(ships, 'cities', [])),
    status: get(attributes, 'status'),
    garden: get(attributes, 'garden'),
    sources: getIds(included, 'source', get(ships, 'sources', [])),
    parking: get(attributes, 'parking'),
    balcony: get(attributes, 'balcony'),
    lotType: get(attributes, 'lot_type'),
    terrace: get(attributes, 'terrace'),
    distance: toNumber(first(getFieldByIds(included, get(ships, 'alert_cities', [-1]), 'alert_city', 'attributes.distance'))),
    minFloor: get(attributes, 'min_floor'),
    maxFloor: get(attributes, 'max_floor'),
    minPrice: get(attributes, 'min_price'),
    maxPrice: get(attributes, 'max_price'),
    minRooms: get(attributes, 'min_rooms'),
    maxRooms: get(attributes, 'max_rooms'),
    taxations: getIds(included, 'taxation', get(ships, 'taxations', [])),
    createdAt: get(attributes, 'created_at'),
    minSurface: get(attributes, 'min_surface'),
    maxSurface: get(attributes, 'max_surface'),
    departments: getIds(included, 'department', get(ships, 'departments', [])),
    deliveryYear: get(attributes, 'delivery_year'),
    agglomerations: getIds(included, 'agglomeration', get(ships, 'agglomerations', [])),
    deliveryQuarter: get(attributes, 'delivery_quarter'),
    deliveryYearMinimum: get(attributes, 'delivery_year_minimum'),
    deliveryQuarterMinimum: get(attributes, 'delivery_quarter_minimum')
  }
}

const transformPage = ({ data }: any): PageAlerts => ({
  meta: get(data, 'meta'),
  data: map(get(data, 'data'), ({ id, attributes, relationships }) => buildAlert(id, attributes, get(data, 'included'), relationships))
})

export const useUpdateAlert = (id: number): UseMutationResult => {
  const client = useClient()
  const queryClient = useQueryClient()

  return useMutation(async alert => await client.patch(`/api/alerts/${id}`, {
    alert
  }), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['fetchAlert', id])
    }
  })
}

export const useFetchAlertsQuery = (params: any): UseInfiniteQueryResult<PageAlerts, AxiosError> => {
  const client = useClient()

  return useInfiniteQuery(['fetchAllAlerts'], async () => {
    return await client.get('/api/alerts', { params })
  }, {
    select: ({ pages }): InfiniteData<PageAlerts> => {
      return {
        pages: map(pages, transformPage),
        pageParams: range(size(pages) + 1)
      }
    },
    refetchOnWindowFocus: false
  })
}

export const useFetchPaginatedAlertsQuery = (page: number): UseQueryResult<PageAlerts, AxiosError> => {
  const client = useClient()

  return useQuery(['fetchAlerts', page], async () => {
    return await client.get('/api/alerts', {
      params: {
        'page[number]': page,
        'page[size]': 9
      }

    })
  }, {
    select: ({ data }): PageAlerts => {
      const included = get(data, 'included')

      return {
        meta: get(data, 'meta'),
        data: map(get(data, 'data'), ({ id, attributes, relationships }) =>
          buildAlert(id, attributes, included, relationships)
        )
      }
    },
    staleTime: 60 * 1000,
    keepPreviousData: true,
    refetchOnWindowFocus: false
  })
}

export const useSearchAlertsQuery = (page: number, search: any): UseQueryResult<PageAlerts, AxiosError> => {
  const client = useClient()

  return useQuery(['searchAlerts', page, search], async () => {
    return await client.get('/api/alerts/search', {
      params: {
        'page[number]': page,
        'page[size]': 9,
        ...buildQueryParams(omit(search, ['page']))
      }
    })
  }, {
    select: ({ data }): PageAlerts => {
      const included = get(data, 'included')

      return {
        meta: get(data, 'meta'),
        data: map(get(data, 'data'), ({ id, attributes, relationships }) =>
          buildAlert(id, attributes, included, relationships)
        )
      }
    },
    staleTime: 60 * 1000,
    keepPreviousData: true,
    refetchOnWindowFocus: false
  })
}

export const useFetchAlertQuery = (id: string, enabled: boolean | undefined): UseQueryResult<PageAlert, AxiosError> => {
  const client = useClient()

  return useQuery(['fetchAlert', id], async () => {
    return await client.get(`/api/alerts/${id}`)
  }, {
    enabled,
    select: ({ data }): PageAlert => {
      const included = get(data, 'included')
      const { id, attributes, relationships } = get(data, 'data')

      return {
        meta: get(data, 'meta'),
        data: buildAlert(id, attributes, included, relationships)
      }
    },
    refetchOnWindowFocus: false
  })
}
