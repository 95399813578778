import styled from 'styled-components'
import { Text } from '@folhomee/front-library'
import { useTranslation } from 'react-i18next'
import { useCallback, useState } from 'react'
import { get, isUndefined, reduce, map } from 'lodash'

import Card from '../../atoms/Card'
import Modal from '../../organisms/Modal'
import CommentCard from '../../molecules/CommentCard'
import CommentForm from '../../molecules/CommentForm'
import CommentButton from '../../atoms/CommentButton'
import { CommentsProps } from './Comments.types'
import { useFetchCommentsQuery } from '../../../queries/Comments'
import { Comment, PageComments } from '../../../queries/Comments/Comments.types'

const Container = styled(Card)`
  gap: 0;
  width: 100%;
  margin: auto;
  padding: 24px;
`

const Header = styled.div`
  gap: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  justify-content: space-between;
`

const Content = styled.div`
  gap: 8px;
  width: 100%;
  display: flex;
  max-height: 400px;
  overflow-y: scroll;
  flex-direction: column;
`

const StyledText = styled(Text)`
  font-size: 24px;
`

const Comments = ({ id, route, programSources, userId }: CommentsProps): JSX.Element => {
  const { t } = useTranslation()
  const [open, updateOpen] = useState(false)

  const { data } = useFetchCommentsQuery(id ?? 0, userId, route, programSources)

  const handleModalDisplay = useCallback((open: boolean) => {
    updateOpen(open)
  }, [updateOpen])

  const comments = reduce(get(data, 'pages', []), (acc: Comment[], page: PageComments): Comment[] => [...acc, ...get(page, 'data', [])], [])

  return (
    <Container>
      <Header>
        <StyledText
          type='quote'
          strong={true}
          variant='secondary'>
          {t<string>('COMMENTS.title')}
        </StyledText>
        <CommentButton
          onClick={() => handleModalDisplay(true)} />
      </Header>
      {!isUndefined(id) && !isUndefined(comments) && <Content>
        {map(comments, (comment: Comment) => (
          <CommentCard comment={comment} id={id} route={route} />
        ))}
      </Content>}
      <Modal
        gap={24}
        isOpen={open}
        backgroundBlur
        onRequestClose={() => handleModalDisplay(false)}>
        <CommentForm
          id={id}
          route={route}
          updateOpen={updateOpen} />
      </Modal>
    </Container>
  )
}

export default Comments
