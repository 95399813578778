import { AxiosError } from 'axios'
// @ts-expect-error
import { deserialize } from 'deserialize-json-api'
import { get, omit, map, range, size, find, isEqual, isUndefined } from 'lodash'
import {
  InfiniteData, useInfiniteQuery, UseInfiniteQueryResult, useQuery,
  UseQueryResult, UseMutationResult, useQueryClient, useMutation
} from 'react-query'

import useClient from '../../hooks/useClient'
import { Client } from '../Clients/Clients.types'
import { buildAlert } from '../Alerts'
import { PageAlerts } from '../Alerts/Alerts.types'
import { PageUsers, User } from './Users.types'
import { buildQueryParams } from '../utils'

export const useFetchMeQuery = (): UseQueryResult<User, AxiosError> => {
  const client = useClient()

  return useQuery(['fetchMe'], async () => {
    return await client.get('/api/users/me')
  }, {
    select: ({ data }): User => {
      return {
        id: get(data, 'data.id'),
        role: get(data, 'data.attributes.role'),
        name: get(data, 'data.attributes.name'),
        email: get(data, 'data.attributes.email'),
        photo: get(data, 'data.attributes.photo'),
        phone: get(data, 'data.attributes.phone_number'),
        region: get(data, 'data.attributes.region_id'),
        devise: get(data, 'data.attributes.devise'),
        active: get(data, 'data.attributes.active'),
        lastName: get(data, 'data.attributes.last_name'),
        firstName: get(data, 'data.attributes.first_name'),
        slackId: get(data, 'data.attributes.slack_id'),
        pipedriveId: get(data, 'data.attributes.id_pipedrive'),
        createdAt: get(data, 'data.attributes.created_at')
      }
    },
    staleTime: 60 * 1000,
    keepPreviousData: true,
    refetchOnWindowFocus: false
  })
}

const transformPage = ({ data }: any): PageUsers => ({
  meta: get(data, 'meta'),
  data: map(get(data, 'data'), ({ id, attributes, relationships }) => ({
    id,
    role: get(attributes, 'role'),
    name: get(attributes, 'name'),
    email: get(attributes, 'email'),
    active: get(attributes, 'active'),
    photo: get(attributes, 'photo'),
    devise: get(attributes, 'devise'),
    region: get(attributes, 'region_id'),
    alertsTotal: size(get(relationships, 'alerts.data'))
  }))
})

export const useFetchUserAlertQuery = (page: number, id: string | number): UseQueryResult<PageAlerts, AxiosError> => {
  const client = useClient()

  return useQuery(['fetchUserAlert', page, id], async () => {
    const url: string = `/api/users/${id}/alerts`
    const params = {
      'page[number]': page,
      'page[size]': 9
    }
    return await client.get(url, {
      params: params
    })
  }, {
    select: ({ data }): PageAlerts => {
      const alerts = get(data, 'data')
      return {
        meta: get(data, 'meta'),
        data: map(get(data, 'data'), ({ id, attributes, relationships }) =>
          buildAlert(id, attributes, alerts, relationships)
        )
      }
    },
    staleTime: 60 * 1000,
    refetchOnWindowFocus: false
  })
}

export const useFetchUserRequestsQuery = (programId: number): UseQueryResult<any, AxiosError> => {
  const client = useClient()
  return useQuery(['fetchUserRequests'], async () => await client.get(`/api/users/${programId}/requests`), {
    select: ({ data }): any => get(data, 'requests', []),
    staleTime: 60 * 1000,
    refetchOnWindowFocus: false
  })
}

export const useFetchUserAdsQuery = (programId: number): UseQueryResult<any, AxiosError> => {
  const client = useClient()
  return useQuery(['fetchUserAds'], async () => await client.get(`/api/users/${programId}/annonces`), {
    select: ({ data }): any => {
      const page = deserialize(omit(get(data, 'data'), ['meta']))

      return {
        data: omit(page, ['data']),
        total: get(data, 'meta.total')
      }
    },
    staleTime: 60 * 1000,
    refetchOnWindowFocus: false
  })
}

export const useFetchUserClientQuery = (programId?: number): UseQueryResult<Client[], AxiosError> => {
  const client = useClient()

  return useQuery(['fetchMeClient'], async () => await client.get('/api/users/me/clients'), {
    select: ({ data }): Client[] => map(data, (client) => ({
      id: get(client, 'id'),
      email: get(client, 'email'),
      phone: get(client, 'phone_number'),
      lastName: get(client, 'last_name'),
      firstName: get(client, 'first_name'),
      isFavorite: find(get(client, 'program_clients'), programClient => isEqual(get(programClient, 'program_id'), programId)),
      idPipedrive: get(client, 'id_pipedrive')
    })),
    staleTime: 60 * 1000,
    refetchOnWindowFocus: false
  })
}

export const useMutateUserQuery = (userId: number | string): UseMutationResult => {
  const client = useClient()
  const queryClient = useQueryClient()

  return useMutation(async (user) => await client.patch(`/api/users/${userId}`, user), {
    onSuccess: async () => {
      await queryClient.refetchQueries(['fetchUser'])
      await queryClient.refetchQueries(['fetchUsers'])
      await queryClient.refetchQueries(['fetchMe'])
    }
  })
}

export const useFetchUserQuery = (userId: string | number): UseQueryResult<User, AxiosError> => {
  const client = useClient()

  return useQuery(['fetchUser', userId], async () => {
    return await client.get(`/api/users/${userId}`)
  }, {
    select: ({ data }): User => {
      return {
        id: get(data, 'data.id'),
        role: get(data, 'data.attributes.role'),
        name: get(data, 'data.attributes.name'),
        photo: get(data, 'data.attributes.photo'),
        phone: get(data, 'data.attributes.phone_number'),
        email: get(data, 'data.attributes.email'),
        region: get(data, 'data.attributes.region_id'),
        active: get(data, 'data.attributes.active'),
        devise: get(data, 'data.attributes.devise'),
        maxAds: get(data, 'data.attributes.max_ads'),
        budget: get(data, 'data.attributes.budget'),
        slackId: get(data, 'data.attributes.slack_id'),
        payment: get(data, 'data.attributes.payment_id'),
        lastName: get(data, 'data.attributes.last_name'),
        firstName: get(data, 'data.attributes.first_name'),
        createdAt: get(data, 'data.attributes.created_at')
      }
    },
    staleTime: 60 * 1000,
    refetchOnWindowFocus: false
  })
}

export const useFetchInfiniteUsersQuery = (search: any): UseInfiniteQueryResult<PageUsers, AxiosError> => {
  const client = useClient()

  return useInfiniteQuery(['fetchUsers', search], async ({ pageParam = 1 }) => {
    return await client.get('/api/users', {
      params: {
        'page[number]': pageParam,
        'page[size]': 22,
        ...buildQueryParams(omit(search, ['page'])),
        ...isUndefined(get(search, 'role')) ? { role: 0 } : {}
      }
    })
  }, {
    select: ({ pages }): InfiniteData<PageUsers> => ({
      pages: map(pages, transformPage),
      pageParams: range(size(pages) + 1)
    }),
    staleTime: 60 * 1000,
    getNextPageParam: (_, allPages) => size(allPages) + 1,
    refetchOnWindowFocus: false
  })
}
