import qs from 'qs'
import styled from 'styled-components'
import { Variant } from '@folhomee/front-library'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useState, useCallback, useEffect } from 'react'
import { get, map, reduce, omit, identity, size, isEqual, isUndefined } from 'lodash'

import Text from '../../atoms/Text'
import Table from '../../organisms/Table'
import FlexBox from '../../atoms/FlexBox'
import Container from '../../atoms/Container'
import ClientRow from '../../molecules/ClientRow'
import SearchForm from '../../molecules/SearchForm'
import TableBottom from '../../molecules/TableBottom'
import SearchInput from '../../molecules/SearchInput'
import DataContainer from '../../atoms/DataContainer'
import RightContainer from '../../atoms/RightContainer'
import { getMinMediaQuery } from '../../../utils/media'
import { SearchFieldsProps } from '../../molecules/SearchForm/SearchForm.types'
import { useFetchInfiniteClientQuery } from '../../../queries/Clients'
import { PageUsers, User } from '../../../queries/Users/Users.types'
import { useFetchMeQuery, useFetchInfiniteUsersQuery } from '../../../queries/Users'

const HEADER = [{
  label: 'COMMON.name',
  textLeft: true
}, {
  label: 'COMMON.firstName',
  textLeft: true
}, {
  label: 'COMMON.email',
  textLeft: true
}, {
  label: 'COMMON.phone',
  textLeft: true
}]

const BODY = [{
  key: 'lastName',
  title: 'lastName'
}, {
  key: 'firstName',
  title: 'firstName'
}, {
  key: 'email',
  title: 'email'
}, {
  key: 'phone',
  title: 'phone'
}]

const SOURCES_SEARCH_FIELDS = ['name', 'email']

const SEARCH_FIELDS = [{
  icon: 'astronaut' as Variant,
  title: 'COMMON.name',
  fields: [{
    key: 'name',
    placeholder: 'COMMON.name'
  }]
}, {
  icon: 'archive' as Variant,
  title: 'COMMON.email',
  fields: [{
    key: 'email',
    placeholder: 'COMMON.email'
  }]
}]

const DivInput = styled.div`
  width: 100%;
  max-width: 250px;
`

const Clients = (): JSX.Element => {
  const { t } = useTranslation()
  const [searchFields] = useState<SearchFieldsProps[]>(SEARCH_FIELDS)
  const [search, updateSearch] = useState<any>({})
  const [userIdToFilter, updateUserIdToFilter] = useState<number>(-1)
  const [bigScreen, updatebigScreen] = useState<boolean>(getMinMediaQuery('xl').matches)
  const [searchBuild, updateSearchBuild] = useState(true)
  const [searchParams, updateSearchParams] = useSearchParams()
  const { data: users } = useFetchInfiniteUsersQuery({})
  const { data: userData } = useFetchMeQuery()
  const { data, isFetching, fetchNextPage } = useFetchInfiniteClientQuery(search, userIdToFilter)

  const options = {
    bodyColor: 'extraLightBlue',
    headerColor: 'transparent',
    columnAlignment: '1fr 1fr 1fr 1fr'
  }

  const userOptions = reduce(get(users, 'pages', []), (acc: User[], page: PageUsers): User[] => [...acc, ...get(page, 'data', [])], [])

  useEffect(() => {
    const mql = getMinMediaQuery('xl')
    const resize = (event: MediaQueryListEvent): void => updatebigScreen(event.matches)

    mql.addEventListener('change', resize)
    return () => {
      mql.removeEventListener('change', resize)
    }
  }, [])

  const onSubmit = useCallback(async (values) => {
    updateUserIdToFilter(get(values, 'users.value', -1))
  }, [])

  const { handleChange, handleBlur, values, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      filteredUserId: ''
    },
    onSubmit: onSubmit
  })

  const setFieldValueHandler = useCallback(async (field: string, value: any, add?: boolean): Promise<void> => {
    if (!isUndefined(add) && isEqual(add, false)) {
      await setFieldValue(field, '')
      return
    }
    await setFieldValue(field, value)
  }, [])

  useEffect(() => {
    handleSubmit()
  }, [values])

  useEffect(() => {
    const querySearch = qs.parse(searchParams.toString())

    updateSearch(reduce(omit(querySearch, ['page']), (acc, value, key) => ({
      ...acc,
      [key]: value
    }), {}))
    updateSearchBuild(false)
  }, [searchParams])

  const clients = reduce(get(data, 'pages', []), (acc: any[], page: any): any[] => [...acc, ...get(page, 'data', [])], [])

  return (
    <Container>
      <SearchForm
        build={searchBuild}
        search={search}
        fields={searchFields}
        sources={clients}
        visible={true}
        searchFields={SOURCES_SEARCH_FIELDS}
        formatSearch={identity}
        updateSearch={updateSearchParams}
        updateShowSearch={() => { }}
        updateSearchBuild={updateSearchBuild} />
      <RightContainer>
        <DataContainer>
          <FlexBox row justifyContent='space-between'>
            <Text textType='title' strong>
              {t('CLIENTS.clientsTitle', { count: get(data, 'pages.0.meta.total', 0) })}
            </Text>
            {isEqual(get(userData, 'role', ''), 'admin') &&
              <DivInput>
                <SearchInput
                  key='filteredUserId'
                  name='users'
                  type='select'
                  values={values}
                  options={[...[{ value: -1, label: 'Tous' }], ...map(userOptions, ({ id, name }: User) => ({
                    value: id, label: name
                  }))]}
                  minWidth={220}
                  multiple={false}
                  handleBlur={handleBlur}
                  placeholder='CLIENTS.all'
                  handleChange={handleChange}
                  setFieldValue={setFieldValueHandler} />
              </DivInput>
            }
          </FlexBox>
          <Table
            row={ClientRow}
            data={clients}
            body={BODY}
            header={HEADER}
            options={options}
            bigScreen={bigScreen} />
          {size(clients) > 20 && <TableBottom
            loading={isFetching}
            fetchNextPage={fetchNextPage} />}
        </DataContainer>
      </RightContainer>
    </Container>
  )
}

export default Clients
