import { useTranslation } from 'react-i18next'

const NotFound = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <h1>{t('NOT_FOUND.title')}</h1>
  )
}

export default NotFound
