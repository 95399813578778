import List from '../../templates/List'
import AgglomerationRow from '../../molecules/AgglomerationRow'
import { useFetchInfiniteAgglomerationsQuery } from '../../../queries/Agglomerations'

const HEADER = [{
  label: 'COMMON.name',
  textLeft: true
}, {
  label: 'AGGLOMERATIONS.cities',
  textLeft: true
}]

const BODY = [{
  key: 'name',
  title: 'name'
}, {
  key: 'cities',
  title: 'cities'
}]

const Agglomerations = (): JSX.Element => {
  const options = {
    bodyColor: 'extraLightBlue',
    headerColor: 'transparent',
    columnAlignment: '1fr 1fr'
  }

  return (
    <List
      row={AgglomerationRow}
      body={BODY}
      title='agglomerations'
      header={HEADER}
      options={options}
      useFetch={useFetchInfiniteAgglomerationsQuery}
    />
  )
}

export default Agglomerations
