import styled from 'styled-components'
import { offColor } from 'off-color'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useState, useCallback, useEffect } from 'react'
import { get, map, isUndefined, isEqual, reduce, filter, toString } from 'lodash'

import Card from '../../atoms/Card'
import Icon from '../../atoms/Icon'
import Page from '../../atoms/Page'
import Text from '../../atoms/Text'
import Modal from '../../organisms/Modal'
import Loader from '../../molecules/LoaderRotating'
import FlexBox from '../../atoms/FlexBox'
import MaxContent from '../../atoms/MaxContent'
import SearchInput from '../../molecules/SearchInput'
import DocumentRow from '../../atoms/DocumentRow'
import DocumentForm from '../../molecules/DocumentForm'
import { useFetchDocumentsQuery } from '../../../queries/Documents'
import { PageUsers, User } from '../../../queries/Users/Users.types'
import { useFetchMeQuery, useFetchInfiniteUsersQuery } from '../../../queries/Users'

const StyledFlexBox = styled(FlexBox)`
  border-left: 1px solid ${({ theme }) => offColor(get(theme, 'grey')).rgba(0.5)};
  padding-left: 56px;
  min-height: 276px;
`

const DivInput = styled.div`
  margin-left: auto;
`

const filterPrivateDocuments = (privateDocs: any[], userIdToFilter: number, userData: any): any[] => {
  if (isEqual(get(userData, 'role', ''), 'admin') && !isEqual(userIdToFilter, -1)) {
    return filter(privateDocs, (doc) => isEqual(toString(get(doc, 'userId', -1)), toString(userIdToFilter)))
  }
  return privateDocs
}

const Documents = (): JSX.Element => {
  const { t } = useTranslation()
  const [open, updateOpen] = useState<boolean>(false)
  const [userIdToFilter, updateUserIdToFilter] = useState<number>(-1)
  const { data: users } = useFetchInfiniteUsersQuery({})
  const { data: userData } = useFetchMeQuery()
  const { data, isFetching } = useFetchDocumentsQuery('public', {})
  const { data: privateData, isFetching: isFetchingPrivate } = useFetchDocumentsQuery('private', { private: true, filter: userIdToFilter })

  const userOptions = reduce(get(users, 'pages', []), (acc: User[], page: PageUsers): User[] => [...acc, ...get(page, 'data', [])], [])

  const onSubmit = useCallback(async (values) => {
    updateUserIdToFilter(get(values, 'users.value', -1))
  }, [])

  const { handleChange, handleBlur, values, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      filteredUserId: ''
    },
    onSubmit: onSubmit
  })
  const setFieldValueHandler = useCallback(async (field: string, value: any, add?: boolean): Promise<void> => {
    if (!isUndefined(add) && isEqual(add, false)) {
      await setFieldValue(field, '')
      return
    }
    await setFieldValue(field, value)
  }, [])
  useEffect(() => {
    handleSubmit()
  }, [values])
  const handleModalDisplay = useCallback((open: boolean) => {
    updateOpen(open)
  }, [updateOpen])
  if (isUndefined(data) && isUndefined(privateData)) {
    return (
      <Page>
        <MaxContent>
          <Text textType='subtitle'><span>{t('DOCUMENTS.title')}</span></Text>
          <Loader loading={isEqual(isFetching, true) && isEqual(isFetchingPrivate, true)} />
        </MaxContent>
      </Page>
    )
  }

  return (
    <Page>
      <MaxContent>
        <FlexBox row justifyContent='start' gap={8} >
          <Text textType='title' strong>{t('DOCUMENTS.title')}</Text>
          {isEqual(get(userData, 'role'), 'admin') && <Icon cursor stroke variant='plus-circle' transform='translateY(2px)' onClick={() => handleModalDisplay(true)} />}
          {isEqual(get(userData, 'role', ''), 'admin') &&
            <DivInput>
              <SearchInput
                key='filteredUserId'
                name='users'
                type='select'
                values={values}
                options={[...[{ value: -1, label: 'Tous' }], ...map(userOptions, ({ id, name }: User) => ({
                  value: id, label: name
                }))]}
                minWidth={220}
                multiple={false}
                handleBlur={handleBlur}
                placeholder='DOCUMENTS.all'
                handleChange={handleChange}
                setFieldValue={setFieldValueHandler} />
            </DivInput>}
        </FlexBox>
        <Loader loading={isEqual(isFetching, true) && isEqual(isFetchingPrivate, true)} />
        <Card row alignStart gap={56}>
          <FlexBox alignStart gap={16}>
            <Text textType='subtitle' strong >
              {t('DOCUMENTS.commonDocuments')}
            </Text>
            {map(get(data, 'data', []), doc => (
              <DocumentRow document={doc} user={userData} key={get(doc, 'value')} />
            ))}
          </FlexBox>
          <StyledFlexBox alignStart gap={16} justifyContent='start'>
            <Text textType='subtitle' strong >
              {t('DOCUMENTS.privateDocuments')}
            </Text>
            {map(filterPrivateDocuments(get(privateData, 'data', []), userIdToFilter, userData), doc => {
              return (
                <DocumentRow document={doc} user={userData} key={get(doc, 'value')} />
              )
            })}
          </StyledFlexBox>
        </Card>
        <Modal
          isOpen={open}
          onRequestClose={() => handleModalDisplay(false)}
          backgroundBlur >
          <DocumentForm updateOpen={updateOpen} />
        </Modal>
      </MaxContent>
    </Page>
  )
}

export default Documents
