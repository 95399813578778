import styled from 'styled-components'
import { get, map, isEqual, isEmpty, startsWith } from 'lodash'

import Data from '../Data'
import Icon from '../../atoms/Icon'
import Text from '../../atoms/Text'
import Field from '../../atoms/Field'
import RowLink from '../../atoms/RowLink'
import ContainerRow from '../../atoms/ContainerRow'
import { SourceRowProps } from './SourceRow.types'
import { CrawlerNameText } from '../CrawlerRow/CrawlerRow.types'

const StyledLink = styled.a`
  align-self: center;
  text-align: center;
  text-decoration: none;
`

const StyledText = styled(Text) <CrawlerNameText>`
  ${({ broken, theme }) => isEqual(broken, true) ? `color: ${get(theme, 'orange', '#FFF') as string};` : ''}
  ${({ broken }) => isEqual(broken, true) ? 'transform: translateY(-3px)' : ''};
  ${({ broken }) => isEqual(broken, true) ? 'height: 23px' : ''};


  vertical-align: baseline;
`

const Warning = ({ broken }: any): JSX.Element | null => {
  if (isEqual(broken, true)) {
    return (
      <Icon variant='warning' stroke color='danger' transform='translateY(4px)' />
    )
  }

  return null
}

const SourceRow = ({ data, fields, options }: SourceRowProps): JSX.Element => (
  <ContainerRow options={options}>
    <RowLink options={options} to={`/promoters/${get(data, 'id', '') as string}`}>
      {map(fields, ({ key }) => {
        if (isEqual(key, 'name')) {
          return (
            <Data key={key} left={true}>
              <StyledText color='primary' strong broken={get(data, 'broken')}>
                <Warning broken={get(data, 'broken', false)} /> {get(data, key)}
              </StyledText>
            </Data>
          )
        }

        if (isEqual(key, 'slug')) {
          return (
            <Data key={key} left={true}>
              <Text >
                {get(data, key)}
              </Text>
            </Data>
          )
        }

        if (isEqual(key, 'urlPrescriber')) {
          const url = get(data, key, '')
          if (isEmpty(url)) {
            return (
              <Data key={key}>
                <Text >-</Text>
              </Data>
            )
          }

          return (
            <StyledLink key={key} target='_blank' href={url} rel="noreferrer">
              <Icon
                variant='extern-link'
                transform='scale(0.9)'
                stroke
                cursor />
            </StyledLink>
          )
        }

        if (startsWith(key, 'crawlers')) {
          if (isEqual(0, get(data, key))) {
            return (
              <Data key={key}>
                <Text >-</Text>
              </Data>
            )
          }

          return (
            <Data key={key}>
              <StyledLink key={key}>
                <Icon
                  variant='valid'
                  color='secondary'
                  transform='scale(0.9)'
                  stroke />
              </StyledLink>
            </Data>
          )
        }

        return (
          <Data key={key}>
            <Field
              key={key}
              name={key}
              value={get(data, key)} />
          </Data>
        )
      })}
    </RowLink>
  </ContainerRow>
)

export default SourceRow
