import styled from 'styled-components'
import { media } from '@folhomee/front-library'
import { isUndefined } from 'lodash'
import { ContainerProps } from './Container.types'

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${({ column }) => isUndefined(column) ? 'row' : 'column'};  
  ${media.lessThan('sm')`
    display: block;
  `}
`

export default Container
