import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { get, isEqual, toString } from 'lodash'

import Card from '../../atoms/Card'
import Tabs from '../../atoms/Tabs'
import Text from '../../atoms/Text'
import FlexBox from '../../atoms/FlexBox'
import LoadingDots from '../../atoms/LoadingDots'
import { DetailListProps } from './DetailListProps.types'

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
`

const Header = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
`

const Content = styled(Card)`
  margin: auto;
`

const Logo = styled.img`
  height: 100px;
  display: none;
`

const DetailList = <T extends unknown>({ tabs, type, actions: Actions, useFetch }: DetailListProps<T>): JSX.Element => {
  const { t } = useTranslation()
  const params = useParams()
  const { data, isError, isFetching } = useFetch(get(params, 'id', ''))

  if (isEqual(isError, true)) {
    return (
      <Container>
        {t(`ERRORS.${type}Error`)}
      </Container>
    )
  }

  if (isEqual(isFetching, true)) {
    return (
      <Container>
        <LoadingDots />
      </Container>
    )
  }

  const currentData = get(data, 'data')

  return (
    <Container>
      <Header>
        <FlexBox row gap={8}>
          <Text textType='title' strong>
            {get(currentData, 'name')}
          </Text>
          <Logo src={toString(get(currentData, 'logo'))} />
          <Actions {...get(currentData, type)} />
        </FlexBox>
      </Header>
      <Content>
        <Tabs data={currentData} tabs={tabs} />
      </Content>
    </Container>
  )
}

export default DetailList
