import { useTranslation } from 'react-i18next'

import Icon from '../Icon'
import ButtonRound from '../ButtonRound'
import TooltipContent from '../TooltipContent'
import { MuteButtonProps } from './MuteButton.types'

const MuteButton = ({ onClick }: MuteButtonProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      <ButtonRound
        color='lightSecondary'
        onClick={onClick}
        buttonSize='regular'
        data-tooltip-id="AlertTooltip">
        <Icon
          color='secondary'
          variant='alert'
          stroke />
      </ButtonRound>
      <TooltipContent
        id="AlertTooltip"
        color='lightSecondary'>
        {t('PROGRAM.mute')}
      </TooltipContent>
    </>
  )
}

export default MuteButton
