import { useTranslation } from 'react-i18next'

import FlexBox from '../../atoms/FlexBox'
import ButtonContainer from '../../atoms/ButtonContainer'
import { ConfirmationHandlerProps } from './ConfirmationHandler.types'

const ConfirmationHandler = ({ children, onConfirm, onCancel, type = 'submit' }: ConfirmationHandlerProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      { children }
      <FlexBox row gap={8}>
        <ButtonContainer
          type='submit'
          buttonSize='large'
          onClick={onCancel}
          color='mediumSecondary'
          label={t<string>('COMMON.cancel')}/>
        <ButtonContainer
          type='submit'
          buttonSize='large'
          onClick={onConfirm}
          color='primary'
          label={t<string>('COMMON.confirm')}/>
      </FlexBox>
    </>
  )
}

export default ConfirmationHandler
