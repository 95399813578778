import styled from 'styled-components'
import { get } from 'lodash'
import { media } from '@folhomee/front-library'

import Link from '../Link'
import { OptionsProps } from '../../organisms/Table/Table.types'

const RowLink = styled(Link) <OptionsProps>`
  gap: 8px;
  display: grid;
  align-items: center;
  grid-template-columns: ${({ options }) => get(options, 'columnAlignment')};

  ${media.lessThan('lg')`
    grid-template-columns: repeat(3, 1fr);
  `}
`
export default RowLink
