import { isEqual } from 'lodash'

export const getQuarterByMonth = (month: number): string => {
  if (isEqual(month, 0)) {
    return 'T1'
  }

  if (isEqual(month, 3)) {
    return 'T2'
  }

  if (isEqual(month, 6)) {
    return 'T3'
  }

  return 'T4'
}

const months = [1, 4, 7, 10]

export const getMonthByQuarter = (quarter: number): number => months[quarter]
export const getQuarterByIndex = (quarter: number): string => `T${quarter + 1}`

const monthNames = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre']

export const formatCommentDate = (date: Date): string => `${date.getHours()}h${date.getMinutes().toString().padStart(2, '0')} • ${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`
