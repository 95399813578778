import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useState, useRef, ChangeEvent, useCallback } from 'react'
import { get, size, reduce, map, isEqual, isUndefined } from 'lodash'

import Text from '../../atoms/Text'
import Icon from '../../atoms/Icon'
import ButtonContainer from '../../atoms/ButtonContainer'
import Input from '../../atoms/Input'
import Checkbox from '../../atoms/Checkbox'
import LoadingDots from '../../atoms/LoadingDots'
import TableBottom from '../TableBottom'
import { useLoadDocument } from '../../../queries/Documents'
import { DocumentFormProps } from './DocumentForm.types'
import { useFetchInfiniteUsersQuery } from '../../../queries/Users'
import { PageUsers, User } from '../../../queries/Users/Users.types'

const Container = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
`

const Choice = styled(Checkbox)`
  border-radius: 50%;
`

const Inline = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
`

const InlineAlign = styled(Inline)`
  align-items: center;
`

const UploadIcon = styled(Icon)`
  cursor: pointer;
`

const UserList = styled.div`
  gap: 8px;
  display: flex;
  padding: 4px 8px;
  max-height: 220px;
  overflow-y: scroll;
  flex-direction: column;
`

const Dots = styled(LoadingDots)`
  min-height: 24px;
`

const StyledInput = styled(Input)`
  width: auto;
`

const DocumentForm = ({ updateOpen }: DocumentFormProps): JSX.Element => {
  const { t } = useTranslation()
  const [file, updateFile] = useState<File>()
  const [type, updateType] = useState<string>('public')
  const [filename, updateFilename] = useState<string>('')
  const [loading, updateLoading] = useState<boolean>(false)
  const [selectUser, updateSelectUser] = useState<Number>()
  const inputRef = useRef<HTMLInputElement | null>(null)

  const { data, isFetching, hasNextPage, fetchNextPage } = useFetchInfiniteUsersQuery({
    active: true
  })

  const { mutateAsync } = useLoadDocument()

  const users = reduce(get(data, 'pages', []), (acc: User[], page: PageUsers): User[] => [...acc, ...get(page, 'data', [])], [])

  const handleUploadClick = (): void => {
    inputRef.current?.click()
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files == null) {
      return
    }

    updateFile(e.target.files[0])
    updateFilename(get(e.target.files[0], 'name', ''))
  }

  const handleDocumentSend = useCallback(async () => {
    if (isUndefined(file)) {
      return
    }

    updateLoading(true)

    const formData = new FormData()
    formData.append('document[file_content]', file, get(file, 'name'))
    formData.append('document[file_type]', type)
    formData.append('document[file_name]', filename)

    if (!isUndefined(selectUser)) {
      formData.append('document[user_id]', selectUser.toString())
    }

    await mutateAsync(formData)
    updateLoading(false)
    updateOpen(false)
  }, [file, filename, type, selectUser])

  return (
    <Container>
      <Inline>
        <Text textType='subtitle' strong>
          {t<string>('DOCUMENTS.type')}
        </Text>
        <Inline>
          <Inline>
            <Choice
              label=''
              bordered={!isEqual(type, 'public')}
              importance='secondary'
              checkboxSize='small'
              onClick={() => updateType('public')} />
            <Text>{t('COMMON.publicVisibility')}</Text>
          </Inline>
          <Inline>
            <Choice
              label=''
              bordered={!isEqual(type, 'private')}
              importance='secondary'
              checkboxSize='small'
              onClick={() => updateType('private')} />
            <Text>{t('COMMON.private')}</Text>
          </Inline>
        </Inline>
      </Inline>
      {isEqual(type, 'private') && <>
        <Text strong>
          {t<string>('DOCUMENTS.userSelection')}
        </Text>
        <UserList>
          {map(users, user => (
            <Inline key={get(user, 'id')}>
              <Checkbox
                label=''
                bordered={!isEqual(selectUser, get(user, 'id'))}
                importance='secondary'
                checkboxSize='small'
                onClick={() => updateSelectUser(get(user, 'id'))} />
              <Text >{get(user, 'name')}</Text>
            </Inline>
          ))}
          {size(users) > 20 && isEqual(hasNextPage, true) && <TableBottom
            loading={isFetching}
            fetchNextPage={fetchNextPage} />}
        </UserList>
      </>}
      <InlineAlign>
        <input
          type='file'
          ref={inputRef}
          onChange={handleFileChange}
          style={{ display: 'none' }} />
        <Text color='primary' strong>
          {t<string>('DOCUMENTS.load')}
        </Text>
        <UploadIcon variant='download' onClick={handleUploadClick} stroke />
        {!isUndefined(file) && <StyledInput
          name='filename'
          value={filename}
          squared={true}
          bordered={true}
          onChange={(e) => updateFilename(e.currentTarget.value)}
          inputSize='small'
          importance='secondary'
          placeholder={t('DOCUMENTS.filename')} />}
      </InlineAlign>
      <ButtonContainer
        color={(isEqual(type, 'private') && isUndefined(selectUser)) || isUndefined(file) ? 'secondary' : 'primary'}
        buttonSize='regular'
        onClick={handleDocumentSend}>
        <>
          {isEqual(loading, false) && t('COMMON.save')}
          {isEqual(loading, true) && <Dots color='white' />}
        </>
      </ButtonContainer>
    </Container>
  )
}

export default DocumentForm
