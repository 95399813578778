import { useTranslation } from 'react-i18next'

import Icon from '../Icon'
import ButtonRound from '../ButtonRound'
import TooltipContent from '../TooltipContent'
import { DoubleButtonProps } from './DoubleButton.types'

const DoubleButton = ({ onClick }: DoubleButtonProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      <ButtonRound
        color='lightSecondary'
        onClick={onClick}
        buttonSize='regular'
        data-tooltip-id='DoubleTooltip'>
        <Icon
          cursor
          variant='trash'
          color='secondary'
          stroke />
      </ButtonRound>
      <TooltipContent
        id="DoubleTooltip"
        color='lightSecondary'>
        {t('PROGRAM.mergeDouble')}
      </TooltipContent>
    </>
  )
}

export default DoubleButton
