/* eslint-disable @typescript-eslint/restrict-template-expressions */
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useCallback, useState } from 'react'
import { Button, Icon, spacings, Text, media } from '@folhomee/front-library'
import { first, reduce, find, get, isEqual, isUndefined, join, lowerCase, map, nth, startCase, upperCase, isEmpty } from 'lodash'

import Modal from '../../organisms/Modal'
import Loader from '../LoaderDot'
import Comments from '../../organisms/Comments'
import ContactForm from '../ContactForm'
import UpdateContact from '../../organisms/UpdateContact'
import ConfirmationHandler from '../ConfirmationHandler'
import { Region } from '../../../queries/Regions/Regions.types'
import { Contact } from '../../../queries/Contacts/Contacts.types'
import { useTabTitle } from '../../../hooks/useTabTitle'
import { useFetchMeQuery } from '../../../queries/Users'
import { useDecorateParams } from '../../../hooks/useDecorateParams'
import { useFetchInfiniteRegionsQuery } from '../../../queries/Regions'
import { ContactsProps, SourceDetailsProps } from './SourceDetails.types'
import { useDeleteContactQuery, useFetchContactsQuery } from '../../../queries/Contacts'

const Container = styled.div`
  gap: 24px;
  width: 100%;
  display: flex;

  ${media.lessThan('md')`
    flex-direction: column;
  `}
`

const LeftColumn = styled.div`
  max-width: 700px;

  ${media.lessThan('xl')`
    max-width: 500px;
  `}

  ${media.lessThan('lg')`
    max-width: 400px;
  `}
`

const RightColumn = styled.div`
  width: 330px;
`

const ContactRow = styled.div`
  overflow-y: scroll;
  margin-bottom: ${get(spacings, 'm')}px;
  flex-direction: column;
`

const Line = styled.div`
  width: 95%;
  border: 1px solid;
  margin-top: ${get(spacings, 's')}px;
  border-color: ${({ theme }) => get(theme, 'blue')};
  margin-right: ${get(spacings, 'm')}px;
  margin-bottom: ${get(spacings, 's')}px;
`

const Subtitle = styled(Text)`
  font-size: 19px;
  margin-bottom: ${get(spacings, 'xxs')}px;
`

const Inline = styled.div`
  width: 100%;
  align-items: center;
  margin-bottom: 8px;
`

const StyledLink = styled.a`
  text-decoration: none;
`

const LastLink = styled.div`
  margin-bottom: 32px;
`

const StyledContactLink = styled.a`
  text-decoration: none;
`

const StyledContactText = styled(Text)`
  margin-top: ${get(spacings, 'xxs')}px;
  margin-bottom: ${get(spacings, 'xxs')}px;
`

const SubtitleContainer = styled(Text)`
  width: auto;
`

const AddContactButton = styled(Button)`
  padding: 9px 24px;
`

const IconContainer = styled.div`
  position: relative;
`

const IconPosition = styled.div`
  gap: ${get(spacings, 'xxs')}px;
  right: ${get(spacings, 'xs')}px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: flex-end;

  & > svg {
    cursor: pointer;
  }
`

const StyledText = styled(Text)`
  width: 790px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${media.lessThan('xl')`
    width: 600px;
  `}

  ${media.lessThan('lg')`
    width: 450px;
  `}
`

const CommentsBlock = styled.div`
  max-width: 100%;
`

export const Contacts = ({ contacts, sourceId, isAdmin }: ContactsProps): JSX.Element => {
  const { t } = useTranslation()
  const [contact, updateContact] = useState<number>(0)
  const [confirmDelete, updateConfirmDelete] = useState<boolean>(false)
  const [isEditOpen, updateIsEditOpen] = useState<boolean>(false)
  const { mutateAsync, status } = useDeleteContactQuery(sourceId)
  const { data: regionsPage } = useFetchInfiniteRegionsQuery({})

  const regions = reduce(get(regionsPage, 'pages', []), (acc: Region[], page) => [...acc, ...get(page, 'data', [])], [])

  const getContact = (path: string): any => get(nth(contacts, contact), path)
  const getFullName = (firstName: string, lastName: string): string => join([startCase(lowerCase(firstName)), upperCase(lastName)], ' ')
  const getRegion = (regionId: number): string | undefined => get(find(regions, region => get(region, 'id') - regionId === 0), 'name')
  const getJobRegion = (job: string, regionId: number | undefined): string => {
    const info = []
    const region = getRegion(regionId as number)

    if (!isUndefined(job) && !isEmpty(job)) {
      info.push(job)
    }
    if (!isUndefined(region)) {
      info.push(region)
    }

    return join(info, ' - ')
  }

  const onClickRemove = (index: number): void => {
    updateContact(index)
    updateConfirmDelete(true)
  }

  const onClickEdit = (index: number): void => {
    updateContact(index)
    updateIsEditOpen(true)
  }

  const onCancelDelete = (): void => updateConfirmDelete(false)
  const onConfirmDelete = async (contact: number): Promise<void> => {
    await mutateAsync(contact)
    if (!isEqual(status, 'error')) {
      updateConfirmDelete(false)
    }
  }

  const onCancelEdit = (): void => updateIsEditOpen(false)

  return (
    <div>
      <Modal isOpen={confirmDelete} onRequestClose={onCancelDelete}>
        <ConfirmationHandler onCancel={onCancelDelete} onConfirm={async () => await onConfirmDelete(getContact('id'))}>
          <Text type='subtitle'>
            {t<string>('SOURCES.removeContact', { contact: getFullName(getContact('firstName'), getContact('lastName')) })}
          </Text>
        </ConfirmationHandler>
      </Modal>
      <Modal isOpen={isEditOpen} onRequestClose={onCancelEdit}>
        <UpdateContact id={sourceId} contact={nth(contacts, contact) as Contact} closeModal={() => updateIsEditOpen(false)} />
      </Modal>
      <ContactRow>
        {map(contacts, ({ id, firstName, lastName, email, phone, job, regionId }, index) => (
            <div key={id}>
              <div>
                {!isEqual(index, 0) && <Line />}
              </div>
              <IconContainer>
                <IconPosition>
                  {isEqual(isAdmin, true) && <Icon variant='edit' onClick={() => onClickEdit(index)}/>}
                  <Icon variant='close-circle' onClick={() => onClickRemove(index)}/>
                </IconPosition>
              </IconContainer>
              <StyledContactText type='regular' variant='primary' strong={true}>
                {getFullName(firstName, lastName)}
              </StyledContactText>
              <StyledContactLink href={`mailto:${email}`}>
                <StyledContactText type='regular' variant='primary' strong={false}>
                  {email}
                </StyledContactText>
              </StyledContactLink>
              <StyledContactLink href={`tel:${phone}`}>
                <StyledContactText type='regular' variant='primary' strong={false}>
                  {phone}
                </StyledContactText>
              </StyledContactLink>
              <StyledContactText type='regular' variant='primary' strong={false}>
                {getJobRegion(job, regionId)}
              </StyledContactText>
            </div>
        ))}
      </ContactRow>
    </div>
  )
}

const Links = ({ variant, url, index }: any): JSX.Element => {
  const { t } = useTranslation()
  const subtitle = isEqual(variant, 'prescriber') ? t<string>('SOURCES.prescriber') : t<string>('SOURCES.public')

  return (
    <Inline>
      <SubtitleContainer type='regular' strong={true} variant={'secondary'}>
        {isUndefined(index) ? `${subtitle}` : `${subtitle} ${index}`}
      </SubtitleContainer>
      <StyledLink key={'links'} target='_blank' href={url} rel="noreferrer">
        <StyledText type='regular' variant='primary'>
          {url}
        </StyledText>
      </StyledLink>
    </Inline>
  )
}

const SourceDetails = ({ data: sourceData }: SourceDetailsProps): JSX.Element => {
  const { t } = useTranslation()
  const [open, updateOpen] = useState(false)

  const { data: userData, isFetching: isFetchingUser } = useFetchMeQuery()
  const { data: contactsData } = useFetchContactsQuery(get(sourceData, 'id', 0))

  const contacts = get(first(get(contactsData, 'pages')), 'data')
  const name = get(sourceData, 'name', '')

  useTabTitle(name)
  useDecorateParams(name)

  const handleModalDisplay = useCallback((open: boolean) => {
    updateOpen(open)
  }, [updateOpen])

  if (isEqual(isFetchingUser, true) || isUndefined(sourceData) || isUndefined(userData)) {
    return (
      <Loader loading={isFetchingUser} />
    )
  }

  return (
    <Container>
      <LeftColumn>
        <Subtitle type='subtitle' strong={true}>
          {upperCase(t<string>('SOURCES.links'))}
        </Subtitle>
        <Links variant='prescriber' url={get(sourceData, 'urlPrescriber', '')} />
        {map(get(sourceData, 'urls', []), (url, ind) => (
          <Links variant='prescriber' url={url} index={ind + 2} />
        ))}
        <LastLink><Links variant='public' url={get(sourceData, 'urlPublic', '')}/></LastLink>
        <CommentsBlock>
          <Comments
            id={get(sourceData, 'id', 0)}
            userId={get(userData, 'id')}
            route='sources' />
        </CommentsBlock>
      </LeftColumn>
      <RightColumn>
        <Subtitle type='subtitle' strong={true}>
          {upperCase(t<string>('SOURCES.contacts'))}
        </Subtitle>
        <Contacts contacts={contacts as Contact[]} sourceId={get(sourceData, 'id', 0)} isAdmin={isEqual(get(userData, 'role'), 'admin')} />
        <AddContactButton
          label={t('SOURCES.addContact')}
          variant='primary'
          importance='small'
          onClick={() => handleModalDisplay(true)} />
        <Modal
          isOpen={open}
          onRequestClose={() => handleModalDisplay(false)}>
          <ContactForm
            id={get(sourceData, 'id', 0)}
            updateOpen={updateOpen} />
        </Modal>
      </RightColumn>
    </Container>
  )
}

export default SourceDetails
