import styled from 'styled-components'
import randomQuote from 'kaamelott-quotes'
import { Text } from '@folhomee/front-library'
import { useCallback, useState, useEffect } from 'react'

const StyledText = styled(Text)`
  position: relative;
  text-indent: 24px;
  padding-right: 20px;
`

const Quote = styled.span`
  width: auto;
  position: absolute;
  font-size: 40px;
  font-weight: 600;
  text-indent: 0;s
`

const FirstQuote = styled(Quote)`
  top: -10px;
  left: 0;
`
const LastQuote = styled(Quote)`
  position: absolute;
  padding-left: 2px;
`

const AuthQuote = (): JSX.Element => {
  const [quote, updateQuote] = useState<string>('')

  const fetchQuote = useCallback(async () => {
    try {
      const fetchedQuote = await randomQuote()

      updateQuote(fetchedQuote)
    } catch (err) {
      console.log(err)
    }
  }, [])

  useEffect(() => {
    // eslint-disable-next-line
    fetchQuote()
  }, [])

  return (
    <StyledText type='regular'>
      <FirstQuote>“</FirstQuote>
      {quote}
      <LastQuote>”</LastQuote>
    </StyledText>
  )
}

export default AuthQuote
