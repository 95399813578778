import { useCallback } from 'react'
import { get, reduce } from 'lodash'

import Icon from '../../atoms/Icon'
import Page from '../../atoms/Page'
import Table from '../../organisms/Table'
import DetailList from '../../templates/DetailList'
import MaxContent from '../../atoms/MaxContent'
import ProgramRow from '../../molecules/ProgramRow'
import AlertClient from '../../molecules/AlertClient'
import AlertSearch from '../../molecules/AlertSearch'
import TableBottom from '../../molecules/TableBottom'
import { TabProps } from '../../atoms/Tabs/Tabs.types'
import { ProgramListProps } from './Alert.types'
import { useFetchAlertQuery } from '../../../queries/Alerts'
import { useFetchInfiniteProgramsQuery } from '../../../queries/Programs'
import { PagePrograms, ProgramPreview } from '../../../queries/Programs/Programs.types'
import { BODY_PROGRAMS, HEADER_PROGRAMS } from '../../../utils/tables/programs'

const AlertPrograms = ({ data }: ProgramListProps): JSX.Element => {
  const { data: programs, isFetching, fetchNextPage } = useFetchInfiniteProgramsQuery(data)

  const options = {
    bodyColor: 'extraLightBlue',
    headerColor: 'transparent',
    columnAlignment: '8em 9.5em 0.6fr 0.8fr 0.8fr 0.8fr 0.4fr 0.6fr 0.5fr 1fr'

  }

  return (
    <>
      <Table
        row={ProgramRow}
        data={reduce(get(programs, 'pages', []), (acc: ProgramPreview[], page: PagePrograms): ProgramPreview[] => [...acc, ...get(page, 'data', [])], [])}
        body={BODY_PROGRAMS}
        header={HEADER_PROGRAMS}
        options={options} />
      <TableBottom
        loading={isFetching}
        fetchNextPage={fetchNextPage} />
    </>
  )
}

const TABS = [{
  key: 'alert',
  label: 'ALERTS.search',
  component: AlertSearch
}, {
  key: 'client',
  label: 'ALERTS.client',
  component: AlertClient
}, {
  key: 'programs',
  label: 'COMMON.programs',
  component: AlertPrograms
}] as TabProps[]

const Actions = (): JSX.Element => {
  const handleDelete = useCallback(() => {
  }, [])

  return (
    <Icon
      color='danger'
      variant='trash'
      onClick={handleDelete}
      cursor
      stroke />
  )
}

const Alert = (): JSX.Element => (
  <Page>
    <MaxContent>
      <DetailList
        tabs={TABS}
        type='source'
        actions={Actions}
        useFetch={useFetchAlertQuery} />
    </MaxContent>
  </Page>
)

export default Alert
