import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useState, useCallback } from 'react'
import { get, map, first, find, isEqual, isUndefined, filter } from 'lodash'

import Text from '../Text'
import FlexBox from '../FlexBox'
import { useFetchMeQuery } from '../../../queries/Users'
import { TabsProps, TabContentProps, TabTitleProps } from './Tabs.types'

const StyledFlexBox = styled(FlexBox)`
  cursor: pointer;
  border-radius: 25px;
  background-color: ${({ theme }) => get(theme, 'lightBlue', '#fff')};
`

const Tab = styled.div<TabTitleProps>`
  width: auto;
  padding: 8px 56px;
  border-radius: 25px;
  background-color: ${({ select, theme }) => get(theme, isEqual(select, true) ? 'blue' : 'lightBlue')};
`

const TabTitle = styled(Text) <TabTitleProps>`
  color: ${({ select, theme }) => get(theme, isEqual(select, true) ? 'white' : 'blue')};
`

const DetailContent = <T extends unknown>({ name, tabs, data }: TabContentProps<T>): JSX.Element => {
  const Elt = get(find(tabs, tab => isEqual(get(tab, 'key'), name)), 'component')

  if (!isUndefined(Elt)) {
    return <Elt data={data} />
  }
  return <></>
}

const Tabs = <T extends unknown>({ data, tabs }: TabsProps<T>): JSX.Element => {
  const { t } = useTranslation()
  const [select, updateSelect] = useState<string>(get(first(tabs), 'key', '') ?? '')
  const { data: user, isFetching } = useFetchMeQuery()
  const handleTab = useCallback((tab: string) => {
    updateSelect(tab)
  }, [updateSelect])
  if (isFetching) {
    return (
      <></>
    )
  }
  return (
    <>
      <StyledFlexBox
        gap={16}
        row
        padding='2px'
        width100
        justifyContent='start'>{map(filter(tabs, tab => !isEqual(get(tab, 'admin'), true) || isEqual(get(user, 'role'), 'admin')), ({ key, label, count }) => (
          <Tab select={isEqual(select, key)} onClick={() => handleTab(key)}>
            <TabTitle
              select={isEqual(select, key)}
              strong >
              {isEqual(count, true)
                ? `${t(label)} (${get(data, key, '?') as string})`
                : t(label)}
            </TabTitle>
          </Tab>
        ))}
      </StyledFlexBox>
      <DetailContent
        name={select}
        data={data}
        tabs={tabs} />
    </>
  )
}

export default Tabs
