import styled from 'styled-components'
import { get } from 'lodash'
import { spacings } from '@folhomee/front-library'

const Page = styled.div`
  display: flex;
  min-height: 100vh;
  min-width: 100vw;
  background: linear-gradient(#fcfdff 9%, ${({ theme }) => get(theme, 'lightBlue')} 99%);
  padding-top: ${get(spacings, 'xl')}px;
  flex-direction: column;
  padding-bottom: ${get(spacings, 's')}px;
`

export default Page
