import { useCallback } from 'react'
import { get, map, isEqual } from 'lodash'

import Data from '../Data'
import Icon from '../../atoms/Icon'
import Text from '../../atoms/Text'
import Field from '../../atoms/Field'
import RowLink from '../../atoms/RowLink'
import ContainerRow from '../../atoms/ContainerRow'
import { UserRowProps } from './UserRow.types'
import { useMutateUserQuery } from '../../../queries/Users'

const UserRow = ({ data, fields, options }: UserRowProps): JSX.Element => {
  const { mutateAsync } = useMutateUserQuery(get(data, 'id'))

  const handleActiveToggle = useCallback(async (evt, active) => {
    evt.preventDefault()
    await mutateAsync({ active })
  }, [])

  return (
    <ContainerRow options={options}>
      <RowLink options={options} to={`/users/${get(data, 'id', '')}`}>
        {map(fields, ({ key }) => {
          if (isEqual(key, 'name')) {
            return (
              <Data key={key} left={true}>
                <Text
                  color='primary'
                  strong>
                  {get(data, key)}
                </Text>
              </Data>
            )
          }

          if (isEqual(key, 'email')) {
            return (
              <Data key={key} left={true}>
                <Text capitalize='none'>
                  {get(data, key)}
                </Text>
              </Data>
            )
          }

          if (isEqual(key, 'active')) {
            const isActive = get(data, 'active')

            return (
              <Data key={key}>
                <Icon
                  fill
                  cursor
                  variant={isEqual(isActive, true) ? 'pause-solid' : 'play-solid'}
                  onClick={async (e) => await handleActiveToggle(e, isEqual(isActive, false))} />
              </Data>
            )
          }

          return (
            <Data key={key}>
              <Field
                key={key}
                name={key}
                value={get(data, key)} />
            </Data>
          )
        })}
      </RowLink>
    </ContainerRow>
  )
}

export default UserRow
