import {
  get, range, reduce, isEqual, split, includes, isEmpty, isUndefined,
  map, has, first, find, findIndex, isArray, size, last, snakeCase
} from 'lodash'

export const getMin = (array) => reduce(array, (acc, elt) => elt < acc ? elt : acc, Infinity)
export const getMax = (array, min) => reduce(array, (acc, elt) => elt > acc ? elt : acc, min)

export const buildSimpleQuery = (search) => reduce(search, (acc, value, key) => {
  if (isEqual(key, 'price')) {
    if (isEmpty(value)) {
      return acc
    }

    return {
      ...acc,
      ...isUndefined(value[0]) ? {} : { minPrice: value[0] },
      ...isUndefined(value[1]) ? {} : { maxPrice: value[1] }
    }
  }

  if (isEqual(key, 'surface')) {
    if (isEmpty(value)) {
      return acc
    }

    return {
      ...acc,
      ...isUndefined(value[0]) ? {} : { minSurface: value[0] },
      ...isUndefined(value[1]) ? {} : { maxSurface: value[1] }
    }
  }

  if (isEqual(key, 'rooms')) {
    if (isEmpty(value)) {
      return acc
    }

    const min = getMin(value)
    const max = getMax(value, min)

    return {
      ...acc,
      minRooms: min,
      ...isEqual(min, max) ? {} : { maxRooms: max }
    }
  }

  if (isEqual(key, 'floor')) {
    if (isEmpty(value)) {
      return acc
    }

    const min = getMin(value)
    const max = getMax(value, min)

    return {
      ...acc,
      minFloor: min,
      ...isEqual(min, max) ? {} : { maxFloor: max }
    }
  }

  if (isEqual(key, 'extras')) {
    return {
      ...acc,
      ...!includes(value, 'parking') ? {} : { parking: true },
      ...!includes(value, 'terrace') ? {} : { terrace: true },
      ...!includes(value, 'garden') ? {} : { garden: true },
      ...!includes(value, 'balcony') ? {} : { balcony: true }
    }
  }

  if (isEqual(key, 'deliveryQuarter')) {
    const deliveryQuarter = get(search, 'deliveryQuarter.value', -1)
    const deliveryYear = get(search, 'deliveryYear.value', -1)

    if (deliveryQuarter < 0 || deliveryYear < 0) {
      return acc
    }

    return {
      ...acc,
      deliveryYear,
      deliveryQuarter
    }
  }

  if (isEqual(key, 'deliveryYear')) {
    const deliveryQuarter = get(search, 'deliveryQuarter.value', -1)
    const deliveryYear = get(search, 'deliveryYear.value', -1)

    if (deliveryQuarter < 0 || deliveryYear < 0) {
      return acc
    }

    return {
      ...acc,
      deliveryYear,
      deliveryQuarter
    }
  }

  if (isEqual(key, 'deliveryQuarterMinimum')) {
    const deliveryQuarterMinimum = get(search, 'deliveryQuarterMinimum.value', -1)
    const deliveryYearMinimum = get(search, 'deliveryYearMinimum.value', -1)

    if (deliveryQuarterMinimum < 0 || deliveryYearMinimum < 0) {
      return acc
    }

    return {
      ...acc,
      deliveryYearMinimum,
      deliveryQuarterMinimum
    }
  }

  if (isEqual(key, 'deliveryYearMinimum')) {
    const deliveryQuarterMinimum = get(search, 'deliveryQuarterMinimum.value', -1)
    const deliveryYearMinimum = get(search, 'deliveryYearMinimum.value', -1)

    if (deliveryQuarterMinimum < 0 || deliveryYearMinimum < 0) {
      return acc
    }

    return {
      ...acc,
      deliveryYearMinimum,
      deliveryQuarterMinimum
    }
  }

  if (includes(['taxations', 'sources'], key)) {
    return {
      ...acc,
      [key]: map(value, ({ value: id }) => id)
    }
  }

  if (isEqual(key, 'location')) {
    const distinctLocations = reduce(value, (acc, { type, value: id }) => {
      return {
        ...acc,
        [type]: [...get(acc, type, []), id]
      }
    }, {})

    return {
      ...acc,
      ...distinctLocations
    }
  }

  if (isEqual(key, 'distance')) {
    if (size(search.location) > 1) {
      return acc
    }
    if (isArray(value)) {
      return {
        ...acc,
        [key]: last(value) ?? 0
      }
    }
  }

  return {
    ...acc,
    [key]: value
  }
}, {})

export const buildForm = async (searchFields, client, search, setFieldValue, fields) => {
  await Promise.all([map(searchFields, async (key) => {
    if (isEqual('floor', key)) {
      return await setFieldValue('floor', reduce([get(search, 'minFloor'), get(search, 'maxFloor')], (acc, floor) => {
        if (isUndefined(floor) || isEmpty(floor)) {
          return acc
        }

        return [...acc, parseInt(floor)]
      }, []))
    }

    if (isEqual('rooms', key)) {
      return await setFieldValue('rooms', reduce([get(search, 'minRooms'), get(search, 'maxRooms')], (acc, room) => {
        if (isUndefined(room) || isEmpty(room)) {
          return acc
        }

        return [...acc, parseInt(room)]
      }, []))
    }

    if (isEqual('location', key)) {
      const cities = get(search, 'cities', [])
      const departments = get(search, 'departments', [])
      const agglomerations = get(search, 'agglomerations', [])
      const regions = get(search, 'regions', [])

      if (isEmpty(cities) && isEmpty(departments) && isEmpty(agglomerations) && isEmpty(regions)) {
        return await setFieldValue('location', [])
      }

      const locations = await Promise.all(map(['cities', 'departments', 'agglomerations', 'regions'], async key => {
        if (isEmpty(get(search, key, []))) {
          return []
        }

        const { data } = await client.get(`/api/${key}`, {
          params: {
            id: split(get(search, key, []), ',')
          }
        })

        return map(get(data, 'data'), ({ attributes }) => ({
          type: key,
          label: get(attributes, isEqual(key, 'cities') ? 'full_name' : 'name'),
          value: get(attributes, 'id')
        }))
      }))

      return await setFieldValue('location', reduce(locations, (acc, location) => {
        return [...acc, ...location]
      }, []))
    }

    const searchValue = get(search, key)

    if (includes(['taxations', 'sources'], key)) {
      if (isUndefined(searchValue) || isEmpty(searchValue)) {
        return await setFieldValue(key, [])
      }

      return await setFieldValue(key, map(searchValue, id => {
        return find(get(first(get(find(fields, field =>
          isEqual(get(field, 'valueType', ''), key)), 'fields', [])), 'options'), item => isEqual(get(item, 'value'), parseInt(id, 10)))
      }))
    }

    if ((isEqual(key, 'deliveryYear') || isEqual(key, 'deliveryYearMinimum')) && !(isUndefined(searchValue) || isEmpty(searchValue))) {
      return await setFieldValue(key, {
        label: searchValue,
        value: searchValue
      })
    }

    if ((isEqual(key, 'deliveryQuarter') || isEqual(key, 'deliveryQuarterMinimum')) && !(isUndefined(searchValue) || isEmpty(searchValue))) {
      return await setFieldValue(key, {
        label: `T${parseInt(searchValue) + 1}`,
        value: searchValue
      })
    }

    if (isUndefined(searchValue) || isEmpty(searchValue)) {
      return await setFieldValue(key, '')
    }

    await setFieldValue(key, searchValue)
  })])
}

const getAsyncFilter = async (client, key, filters) => {
  if (has(filters, key)) {
    const { data } = await client.get(`/api/${key}`, {
      params: {
        id: split(get(filters, key, []), ',')
      }
    })

    return map(get(data, 'data'), ({ attributes }) => ({
      type: key,
      label: get(attributes, isEqual(key, 'cities') ? 'full_name' : 'name'),
      value: get(attributes, 'id')
    }))
  }

  return []
}

export const buildFilters = async (querySearch, client, fields) => {
  const updated = reduce(querySearch, (acc, value, key) => {
    if (includes(['taxations', 'sources'], key)) {
      return {
        ...acc,
        [key]: reduce(get(querySearch, key), (accu, id) => {
          const found = find(get(first(get(find(fields, field =>
            isEqual(get(field, 'valueType', ''), key)), 'fields', [])), 'options'), item => isEqual(get(item, 'value'), parseInt(id, 10)))

          if (isUndefined(found)) {
            return accu
          }

          const index = findIndex(acc, ({ value }) => isEqual(value, get(found, 'value')))

          if (isEqual(index, -1)) {
            return [...accu, found]
          }

          return accu
        }, [])
      }
    }

    return {
      ...acc,
      [key]: value
    }
  }, {})

  const cities = await getAsyncFilter(client, 'cities', updated)
  const departments = await getAsyncFilter(client, 'departments', updated)
  const agglomerations = await getAsyncFilter(client, 'agglomerations', updated)
  const regions = await getAsyncFilter(client, 'regions', updated)

  return {
    ...updated,
    cities,
    departments,
    agglomerations,
    regions
  }
}

export const fetchOptions = async (formFields, taxations, sources) => {
  const today = new Date()
  const currentYear = today.getFullYear()
  const yearOptions = map(range(currentYear, currentYear + 6), year => ({ value: year.toString(), label: year.toString() }))

  return map(formFields, elt => {
    return {
      ...elt,
      fields: map(get(elt, 'fields'), (field) => {
        if (isEqual(get(field, 'key'), 'taxations')) {
          return {
            ...field,
            options: isUndefined(taxations) ? [] : taxations
          }
        }
        if (isEqual(get(field, 'key'), 'sources')) {
          return {
            ...field,
            options: isUndefined(sources) ? [] : sources
          }
        }
        if (isEqual(get(field, 'key'), 'deliveryYear') || isEqual(get(field, 'key'), 'deliveryYearMinimum')) {
          return {
            ...field,
            options: yearOptions
          }
        }

        return field
      })
    }
  })
}

export const snakeCaseSearch = (search) => reduce(search, (acc, value, key) => ({
  ...acc,
  [snakeCase(key)]: value
}), {})
