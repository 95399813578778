import styled from 'styled-components'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useCallback, useMemo } from 'react'
import { UpdateUserModalProps } from './UpdateUserModal.types'
import { useMutation, useQueryClient } from 'react-query'
import { spacings, Icon, Text, Button } from '@folhomee/front-library'
import {
  find, get, isEmpty, isEqual, slice,
  isObject, isUndefined, map, reduce, snakeCase, toString
} from 'lodash'

import useClient from '../../../hooks/useClient'
import SearchInput from '../../molecules/SearchInput'
import { Region } from '../../../queries/Regions/Regions.types'
import { useMutateUserQuery } from '../../../queries/Users'
import { useFetchInfiniteRegionsQuery } from '../../../queries/Regions'

const ModalContainer = styled.div`
  margin: 0;
  display: flex;
  padding: 0;
  position: relative;
  max-height: 80vh;
  flex-direction: column;

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
`

const CloseModal = styled.div`
  top: 0;
  right: 10px;
  position: sticky;
  align-self: flex-end;
`

const CloseIcon = styled(Icon)`
  cursor: pointer;
  transform: scale(0.8);
`

const FormContainer = styled.div`
  display: flex;
  padding: 0 ${get(spacings, 'xs')}px;
  overflow-y: scroll;
  flex-direction: column;
`

const InputContainer = styled.div`
  display: flex;
  padding: ${get(spacings, 'xs')}px;
  flex-direction: column;
`

const Inline = styled.div`
  margin: ${get(spacings, 'xxs')}px 0;
  display: flex;
  align-items: center;
`

const StyledText = styled(Text)`
  color: ${({ theme }) => get(theme, 'darkGrey')};
  margin-right: ${get(spacings, 's')}px;
`

const ButtonContainer = styled.div`
  width: 100%;
  bottom: 0;
  display: flex;
  border-top: 1px solid ${({ theme }) => get(theme, 'darkGrey')};
  align-items: center;
  padding-top: ${get(spacings, 'xs')}px;
  justify-content: center;
  background-color: white;
`

const StyledButton = styled(Button)`
  display: flex;
  max-width: 50px;
  align-self: center;
  align-content: center;
  justify-content: center;
`

const USER_ROLES: string[] = ['USER.user', 'USER.manager', 'USER.admin', 'USER.client', 'USER.pro']
const USER_PAYMENT: string[] = ['Sélectionner un type de paiement', 'Boost', 'Classic', 'Premium']

const SlackIdField = ({ placeholder, title, user }: any): JSX.Element => {
  const { t } = useTranslation()
  const client = useClient()
  const queryClient = useQueryClient()

  const updateMutation = useMutation(async () => await client.post(`/api/users/${toString(get(user, 'id', ''))}/slack`, {}), {
    onSuccess: async () => {
      await queryClient.refetchQueries(['fetchUser'])
    }
  })

  const handleRefreshSlackId = useCallback(async () => {
    await updateMutation.mutateAsync(user)
  }, [user, updateMutation])

  if (isUndefined(placeholder) || isEmpty(placeholder)) {
    return (
      <InputContainer>
        <Text
          type='regular'
          strong={true}
          variant='secondary'>
          {t<string>(title)}
        </Text>
        <Inline>
          <StyledText type={'regular'}>
            {t('USER.noSlackId')}
          </StyledText>
          <StyledButton
            label={t('COMMON.search')}
            variant='primary'
            type='submit'
            onClick={handleRefreshSlackId}
            importance='small' />
        </Inline>
      </InputContainer>
    )
  }
  return (
    <InputContainer>
      <Text
        type='regular'
        strong={true}
        variant='secondary'>
        {t<string>(title)}
      </Text>
      <Inline>
        <StyledText type={'regular'}>
          {placeholder}
        </StyledText>
      </Inline>
    </InputContainer>
  )
}

const UpdateUserModal = ({ user, closeModal }: UpdateUserModalProps): JSX.Element => {
  const { t } = useTranslation()

  const { mutateAsync, status } = useMutateUserQuery(get(user, 'id'))
  const { data: regionsPage } = useFetchInfiniteRegionsQuery({})

  const regions = reduce(get(regionsPage, 'pages', []), (acc: Region[], page) => [...acc, ...get(page, 'data', [])], [])
  const userRegion = useMemo(() => {
    return get(find(regions, region => get(region, 'id') - get(user, 'region') === 0), 'name')
  }, [regions])

  const onSubmit = useCallback(async (values) => {
    const toSendValues = reduce(values, (acc: any, value: any, name: string): any => ({
      ...acc,
      [snakeCase(name)]: isObject(value) ? get(value, 'value') : value
    }), {})

    await mutateAsync(toSendValues)

    closeModal()
  }, [mutateAsync])

  const { handleChange, handleBlur, values, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      lastName: get(user, 'lastName'),
      firstName: get(user, 'firstName'),
      email: get(user, 'email'),
      role: get(user, 'role'),
      photo: get(user, 'photo'),
      maxAds: get(user, 'maxAds', 1),
      budget: get(user, 'budget'),
      devise: get(user, 'devise'),
      slackId: get(user, 'slackId'),
      regionId: get(user, 'region'),
      paymentId: get(user, 'payment')
    },
    onSubmit: onSubmit
  })

  const setFieldValueHandler = useCallback(async (field: string, value: any, add?: boolean): Promise<void> => {
    if (!isUndefined(add) && isEqual(add, false)) {
      await setFieldValue(field, '')
      return
    }

    await setFieldValue(field, value)
  }, [])

  const onClick = useCallback(() => {
    handleSubmit()
  }, [handleSubmit])

  const onSlackIdClick = useCallback(() => {
    handleSubmit()
  }, [handleSubmit])

  const fields = [{
    name: 'lastName',
    type: 'text',
    title: 'COMMON.name',
    options: [],
    placeholder: get(user, 'lastName')
  }, {
    name: 'firstName',
    type: 'text',
    title: 'COMMON.firstName',
    options: [],
    placeholder: get(user, 'firstName')
  }, {
    name: 'email',
    type: 'text',
    title: 'COMMON.email',
    options: [],
    placeholder: get(user, 'email')
  }, {
    name: 'role',
    type: 'select',
    title: 'USER.role',
    options: map(USER_ROLES, (label, value) => ({
      value, label: t(label)
    })),
    placeholder: t(`USER.${get(user, 'role')}`)
  }, {
    name: 'photo',
    type: 'text',
    title: 'USER.photo',
    options: [],
    placeholder: get(user, 'photo')
  }, {
    name: 'devise',
    type: 'area',
    title: 'USER.devise',
    options: [],
    placeholder: get(user, 'devise')
  }, {
    name: 'regionId',
    type: 'select',
    title: 'REGIONS.singleTitle',
    options: map(regions, (label) => ({
      value: get(label, 'id'), label: get(label, 'name')
    })),
    placeholder: userRegion
  }, {
    name: 'slackId',
    title: 'USER.slackId',
    options: [],
    placeholder: get(user, 'slackId')
  }]

  const FIELDS = isEqual(get(values, 'role'), 'pro') || isEqual(get(values, 'role.value'), 4)
    ? [...slice(fields, 0, 4), {
        name: 'maxAds',
        type: 'text',
        options: [],
        title: 'USER.maxAds',
        placeholder: get(user, 'maxAds', 1).toString()
      }, {
        name: 'paymentId',
        type: 'select',
        options: map([{
          id: 1,
          name: 'Boost'
        }, {
          id: 2,
          name: 'Classic'
        }, {
          id: 3,
          name: 'Premium'
        }], (label) => ({
          value: get(label, 'id'), label: get(label, 'name')
        })),
        title: 'USER.paymentType',
        placeholder: USER_PAYMENT[get(user, 'payment', 0)]
      }, {
        name: 'budget',
        type: 'text',
        options: [],
        title: 'USER.budget',
        placeholder: get(user, 'budget', 0).toString()
      }, ...slice(fields, 4)]
    : fields

  return (
    <ModalContainer>
      <CloseModal onClick={closeModal}>
        <CloseIcon variant='close' />
      </CloseModal>
      <FormContainer>
        {map(FIELDS, ({ name, title, type, options, placeholder }) => {
          if (isEqual(name, 'slackId')) {
            return (
              <SlackIdField
                placeholder={placeholder}
                title={title}
                onClick={onSlackIdClick}
                user={user} />
            )
          }
          return (
            <InputContainer>
              <Text
                type='regular'
                strong={true}
                variant='secondary'>
                {t<string>(title)}
              </Text>
              <SearchInput
                key={name}
                name={name}
                type={type}
                values={values}
                options={options}
                multiple={false}
                handleBlur={handleBlur}
                placeholder={placeholder}
                handleChange={handleChange}
                setFieldValue={setFieldValueHandler} />
            </InputContainer>
          )
        })}
      </FormContainer>
      <ButtonContainer>
        <StyledButton
          label={t('COMMON.validate')}
          variant='primary'
          type='submit'
          onClick={onClick}
          disabled={status === 'loading'}
          importance='small' />
      </ButtonContainer>
    </ModalContainer>
  )
}

export default UpdateUserModal
