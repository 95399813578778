import { AxiosError } from 'axios'
import { map, get, omit, range, size } from 'lodash'
import { useMutation, UseQueryResult, UseMutationResult, useQuery, InfiniteData, useInfiniteQuery, UseInfiniteQueryResult, useQueryClient } from 'react-query'

import useClient from '../../hooks/useClient'
import { Crawler, CrawlerType, PageCrawlers, PageCrawlerTypes } from './Crawlers.types'
import { buildQueryParams, getRelationships, getFieldById } from '../utils'

export const useCreateCrawlerQuery = (sources: any[]): UseMutationResult => {
  const client = useClient()
  const queryClient = useQueryClient()

  return useMutation(async crawler => await client.post('/api/crawlers', {
    crawler
  }), {
    onSuccess: async () => {
      await queryClient.refetchQueries(['fetchInfiniteCrawlers', { sources }])
    }
  })
}

export const useFetchCrawlerTypes = (): UseQueryResult<PageCrawlerTypes, AxiosError> => {
  const client = useClient()

  return useQuery(['fetchCrawlerTypes'], async () => await client.get('/api/crawlers/types'), {
    select: ({ data }): PageCrawlerTypes => {
      return {
        meta: get(data, 'meta'),
        data: map(get(data, 'data'), ({ id, attributes }): CrawlerType => {
          return {
            id,
            name: get(attributes, 'name')
          }
        })
      }
    }
  })
}

export const useFetchCrawlersQuery = (page: number, search: any): UseQueryResult<PageCrawlers, AxiosError> => {
  const client = useClient()

  return useQuery(['fetchCrawlers', page, search], async () => {
    return await client.get('/api/crawlers', {
      params: {
        'page[number]': page,
        'page[size]': 9,
        ...buildQueryParams(search)
      }
    })
  }, {
    select: ({ data }): PageCrawlers => {
      return {
        meta: get(data, 'meta'),
        data: map(get(data, 'data'), ({ id, attributes }): Crawler => {
          return {
            id,
            name: get(attributes, 'name'),
            url: `crawlers/${String(id)}`
          }
        })
      }
    },
    staleTime: 60 * 1000,
    keepPreviousData: true,
    refetchOnWindowFocus: false
  })
}

const transformPage = ({ data }: any): PageCrawlers => {
  const included = get(data, 'included')

  return {
    meta: get(data, 'meta'),
    data: map(get(data, 'data'), ({ id, attributes, relationships }) => {
      const ships = getRelationships(relationships, ['crawler_type'], ['document_types'])

      return {
        id,
        url: get(attributes, 'url'),
        name: get(attributes, 'name'),
        type: get(attributes, 'crawl_type'),
        status: get(attributes, 'status'),
        broken: get(attributes, 'broken', false),
        docTypes: get(ships, 'document_types', []),
        crawlType: getFieldById(included, get(ships, 'crawler_type', 0), 'crawler_type', 'attributes.name')
      }
    })
  }
}

export const useFetchInfiniteCrawlersQuery = (search: any): UseInfiniteQueryResult<PageCrawlers, AxiosError> => {
  const client = useClient()

  return useInfiniteQuery(['fetchInfiniteCrawlers', search], async ({ pageParam = 1 }) => {
    return await client.get('/api/crawlers', {
      params: {
        'page[number]': pageParam,
        'page[size]': 10,
        ...buildQueryParams(omit(search, ['page']))
      }
    })
  }, {
    select: ({ pages }): InfiniteData<PageCrawlers> => ({
      pages: map(pages, transformPage),
      pageParams: range(size(pages) + 1)
    }),
    getNextPageParam: (_, allPages) => size(allPages) + 1,
    staleTime: 1000 * 60,
    refetchOnWindowFocus: false
  })
}
