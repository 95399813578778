import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isEqual, get, filter, isUndefined, map } from 'lodash'

import Map from '../../molecules/Map'
import Page from '../../atoms/Page'
import Text from '../../atoms/Text'
import Block from '../../atoms/Block'
import Loader from '../../molecules/LoaderRotating'
import LotData from '../../organisms/LotData'
import Comments from '../../organisms/Comments'
import LotsTable from '../../molecules/LotsTable'
import ProgramDetails from '../../organisms/ProgramDetails'
import { useFetchMeQuery } from '../../../queries/Users'
import { useFetchLotQuery } from '../../../queries/Lots'
import { useFetchProgramQuery } from '../../../queries/Programs'

const Lot = (): JSX.Element => {
  const { t } = useTranslation()
  const params = useParams()

  const { data, isFetching, isError } = useFetchLotQuery(get(params, 'id', ''))
  const { data: userData, isFetching: isFetchingUser } = useFetchMeQuery()

  const lot = get(data, 'data')
  const [, updateIsOpenFavorite] = useState(false)

  const { data: dataProgram, isFetching: programFetch } = useFetchProgramQuery(`${get(lot, 'programId', '0') as string}`, {})
  const program = get(dataProgram, 'data')

  if (isEqual(isError, true)) {
    return (
      <Page>
        {t('ERRORS.programError')}
      </Page>
    )
  }

  if (isEqual(isFetching, true) || isEqual(isFetchingUser, true) || isEqual(programFetch, true) || isUndefined(program) || isUndefined(userData)) {
    return (
      <Page>
        <Loader loading={isFetching} />
      </Page>
    )
  }

  return (
    <Page>
      <ProgramDetails
        user={userData}
        lots={[get(lot, 'id') as number]}
        openFavorite={() => updateIsOpenFavorite(true)}
        program={program} />
      <Block>
        <Comments
          id={get(program, 'id')}
          userId={get(userData, 'id')}
          route='programs'
          programSources={map(get(program, 'sources', []), 'id')} />
      </Block>
      <Block>
        <LotData
          lot={lot}
          city={get(program, 'city')}
          brochure={get(program, 'brochureLink')} />
      </Block>
      <Block>
        <Text textType='title' strong>
          {get(program, 'address.text')}, {get(program, 'city.insee')} {get(program, 'city.name')}
        </Text>
        <Map position={{ lat: parseFloat(get(program, 'address.lat', 0).toString()), lng: parseFloat(get(program, 'address.lng', 0).toString()) }} />
      </Block>
      <Block>
        <Text textType='title' strong>
          {t<string>('PROGRAM.otherLots', { name: get(program, 'name') })}
        </Text>
        <LotsTable
          lots={filter(get(program, 'lots', []), ({ id }) => !isEqual(id, get(lot, 'id')))}
          alertId=''
          programId={get(program, 'id')}
          higlightedLots={[]} />
      </Block>
    </Page>
  )
}

export default Lot
