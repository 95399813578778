import styled from 'styled-components'
import { useThrottle } from '@react-hook/throttle'
import { useTranslation } from 'react-i18next'
import { Button, Input, spacings, Text } from '@folhomee/front-library'
import { useCallback, useEffect, useRef, useState } from 'react'
import { get, includes, isEqual, map, filter, size, reduce, toString } from 'lodash'

import Row from '../../atoms/Row'
import Table from '../../organisms/Table'
import Checkbox from '../../atoms/Checkbox'
import TableBottom from '../TableBottom'
import { DataSource, PageSources } from '../../../queries/Sources/Sources.types'
import { SourceMergeProps, SourceMergeRowProps } from './SourceMerge.types'
import { useDeleteSourceQuery, useFetchInfiniteSourceQuery } from '../../../queries/Sources'

const BODY_MERGE = [{
  key: 'action',
  title: 'common.select'
}, {
  key: 'name',
  title: 'COMMON.name'
}]

const HEADER_MERGE = [{
  label: 'COMMON.select'
}, {
  label: 'COMMON.name'
}]

const Container = styled.div`
  padding: 32px;
`

const Title = styled(Text)`
  margin-bottom: 32px;
`

const TableContainer = styled.div`
  overflow: scroll;
  max-height: 200px;
`

const ErrorMessage = styled(Text)`
  color: ${({ theme }) => get(theme, 'orange', '#FFF')};
  text-align: center;
`

const TextButton = styled.div`
  cursor: pointer;
  padding-top: 8px;
`

const DeleteText = styled(Text)`
  color: ${({ theme }) => get(theme, 'blue', '#fff')};
  font-size: 14px;
  text-align: center;
  text-decoration: underline;
`

const StyledCheckbox = styled(Checkbox)`
  justify-self: center;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 32px;
  align-items: center;
  justify-content: center;
`

const Field = styled.div`
  width: 100%;
  margin-bottom: ${get(spacings, 's')}px;
`

const SourceMergeRow = ({ data, meta, fields, actions, options }: SourceMergeRowProps): JSX.Element => (
  <Row options={options}>
    {map(fields, ({ key }) => {
      if (isEqual(key, 'action')) {
        return (
          <StyledCheckbox
            label=''
            onClick={() => actions(get(data, 'id'))}
            bordered={!includes(meta, get(data, 'id'))}
            importance='secondary'
            checkboxSize='small' />
        )
      }

      return (
        <Text
          type='regular'
          strong={false}>
          {get(data, key)}
        </Text>
      )
    })}
  </Row >
)

const SourceMerge = ({ id, update }: SourceMergeProps): JSX.Element => {
  const { t } = useTranslation()
  const [value, updateValue] = useState('')
  const [select, updateSelect] = useState<number>(-1)
  const [search, updateSearch] = useThrottle('')
  const [error, updateError] = useState<boolean>(false)

  const { data, isFetching, fetchNextPage } = useFetchInfiniteSourceQuery({ name: search })
  const { mutateAsync } = useDeleteSourceQuery(id, select)

  const options = useRef({
    bodyColor: 'extraLightBlue',
    headerColor: 'lightGrey',
    columnAlignment: '0.1fr 1fr'
  })

  const sources = reduce(get(data, 'pages', []), (acc: DataSource[], page: PageSources): DataSource[] => [...acc, ...get(page, 'data', [])], [])

  const handleSelection = useCallback((id: number) => {
    updateSelect(id)
  }, [select, updateSelect])

  const handleMerge = useCallback(async () => {
    if (isEqual(select, -1)) {
      updateError(true)
      return
    }
    await mutateAsync(select)
    update(false)
    window.location.href = '/promoters'
  }, [select])

  const handleDeleteWithoutMerge = useCallback(async () => {
    await mutateAsync(-1)
    update(false)
    window.location.href = '/promoters'
  }, [select])

  useEffect(() => {
  }, [select])

  useEffect(() => {
    updateSearch(value)
  }, [value])

  return (
    <Container>
      <Title
        type='quote'
        strong={true}>
        {t<string>('SOURCES.merge')}
      </Title>
      <Field key={'name'}>
        <Input
          type='text'
          name='search'
          value={value}
          squared={true}
          bordered={true}
          onChange={evt => updateValue(evt.currentTarget.value)}
          inputSize='small'
          importance='secondary'
          placeholder={t('COMMON.search')} />
      </Field>
      <TableContainer>
        <Table
          row={SourceMergeRow}
          data={filter(sources, (source) => !isEqual(toString(id), get(source, 'id', '')))}
          meta={select}
          body={BODY_MERGE}
          header={HEADER_MERGE}
          actions={handleSelection}
          options={options.current} />
        {size(sources) > 20 && <TableBottom
          loading={isFetching}
          fetchNextPage={fetchNextPage} />}
      </TableContainer>
     {isEqual(error, true) && <ErrorMessage
       type='regular'
       variant='secondary'>{t('ERRORS.sourceDelete')}
       </ErrorMessage>}
      <ButtonContainer>
        <Button
          label={t<string>('COMMON.validate')}
          outline={false}
          variant='primary'
          onClick={handleMerge}
          importance='small' />
      </ButtonContainer>
      <TextButton onClick={handleDeleteWithoutMerge}>
        <DeleteText
          type='regular'
          variant='secondary'>{t('SOURCES.deleteWithoutMerge')}
        </DeleteText>
      </TextButton>
    </Container>
  )
}

export default SourceMerge
