import { RefObject, useEffect } from 'react'
import { isNull, get, isEqual } from 'lodash'

export const useOnClickOutside = (ref: RefObject<any>, handler: (evt: Event) => void): void =>
  useEffect(() => {
    const listener = (event: Event): void => {
      if (isNull(get(ref, 'current', null)) || isEqual(ref.current.contains(event.target), true)) {
        return
      }

      return handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
