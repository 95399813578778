import { map, get } from 'lodash'

const TRANSLATIONS = {
  'Prêt à taux zéro': 'PTZ',
  'Résidence principale': 'RP',
  'Nue Propriété': 'Nue Pro'
}

export const formatTaxations = (taxations: string[]): string[] =>
  map(taxations, taxation => get(TRANSLATIONS, taxation, taxation))
