import styled from 'styled-components'
import { spacings } from '@folhomee/front-library'
import { get, isEmpty, reduce, filter } from 'lodash'

import Page from '../../atoms/Page'
import Table from '../../organisms/Table'
import CityData from '../../molecules/CityData'
import DetailList from '../../templates/DetailList'
import MaxContent from '../../atoms/MaxContent'
import ProgramRow from '../../molecules/ProgramRow'
import TableBottom from '../../molecules/TableBottom'
import { TabProps } from '../../atoms/Tabs/Tabs.types'
import { useFetchCityQuery } from '../../../queries/Cities'
import { useFetchInfiniteProgramsQuery } from '../../../queries/Programs'
import { PagePrograms, ProgramPreview } from '../../../queries/Programs/Programs.types'
import { BODY_PROGRAMS, HEADER_PROGRAMS } from '../../../utils/tables/programs'

const Container = styled.div`
  margin-top: ${get(spacings, 's')}px;
`

const ProgramCity = ({ data }: any): JSX.Element => {
  const { data: programs, isFetching, fetchNextPage } = useFetchInfiniteProgramsQuery({
    cities: [get(data, 'id')]
  })

  const options = {
    bodyColor: 'extraLightBlue',
    headerColor: 'transparent',
    columnAlignment: '8em 9.5em 0.6fr 0.8fr 0.8fr 0.8fr 0.4fr 0.6fr 0.5fr 1fr'
  }

  const items = filter(reduce(get(programs, 'pages', []), (acc: ProgramPreview[], page: PagePrograms): ProgramPreview[] => [...acc, ...get(page, 'data', [])], []),
    pgm => !isEmpty(pgm))

  if (isEmpty(items)) {
    return (
      <></>
    )
  }

  return (
    <Container>
      <Table
        row={ProgramRow}
        data={items}
        body={BODY_PROGRAMS}
        header={HEADER_PROGRAMS}
        options={options} />
      <TableBottom
        loading={isFetching}
        fetchNextPage={fetchNextPage} />
    </Container>
  )
}

const TABS = [{
  key: 'source',
  label: 'COMMON.data',
  component: CityData
}, {
  key: 'programs',
  label: 'COMMON.programs',
  component: ProgramCity
}] as TabProps[]

const Actions = (): JSX.Element => (
  <></>
)

const City = (): JSX.Element => (
  <Page>
    <MaxContent>
      <DetailList
        tabs={TABS}
        type='city'
        actions={Actions}
        useFetch={useFetchCityQuery} />
    </MaxContent>
  </Page>
)

export default City
