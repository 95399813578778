import { AxiosError } from 'axios'
// @ts-expect-error
import { deserialize } from 'deserialize-json-api'
import { get, isEqual, map, range, size } from 'lodash'
import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult, UseMutationResult, useQueryClient, useMutation } from 'react-query'

import useClient from '../../hooks/useClient'
import { PageComments } from './Comments.types'

const transformPage = (page: any, userId: number): PageComments => ({
  meta: get(page, 'data.meta'),
  data: map(get(deserialize(get(page, 'data')), 'data'), comment => ({
    id: get(comment, 'id'),
    public: get(comment, 'is_public', true),
    author: get(comment, 'user.name'),
    source: get(comment, 'source.name'),
    content: get(comment, 'content'),
    isAuthor: isEqual(userId, get(comment, 'user.id')),
    createdAt: new Date(get(comment, 'created_at'))
  }))
})

export const useCreateCommentQuery = (id: number, route: string): UseMutationResult => {
  const client = useClient()
  const queryClient = useQueryClient()

  return useMutation(async values => await client.post(`/api/${route}/${id}/comments`, {
    content: get(values, 'comment'),
    is_public: get(values, 'public'),
    program_id: isEqual(route, 'programs') ? id : null,
    source_id: isEqual(route, 'sources') ? id : null
  }), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['fetchComments', id])
    }
  })
}

export const useDeleteCommentQuery = (id: number, route: string, commentId: number): UseMutationResult => {
  const client = useClient()
  const queryClient = useQueryClient()

  return useMutation(async () => await client.delete(`/api/${route}/${id}/comments/${commentId}`), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['fetchComments', id])
    }
  })
}

export const useFetchCommentsQuery = (id: number, userId: number, route: string, programSources: number[] | undefined): UseInfiniteQueryResult<PageComments, AxiosError> => {
  const client = useClient()

  return useInfiniteQuery(['fetchComments', id], async ({ pageParam = 1 }) => {
    return await client.get(`/api/${route}/${id}/comments`, {
      params: {
        'page[number]': pageParam,
        'page[size]': 10,
        program_sources: programSources
      }
    })
  }, {
    select: ({ pages }): InfiniteData<PageComments> => ({
      pages: map(pages, page => transformPage(page, userId)),
      pageParams: range(size(pages) + 1)
    }),
    getNextPageParam: (_, allPages) => size(allPages) + 1,
    staleTime: 1000 * 60,
    refetchOnWindowFocus: false
  })
}
