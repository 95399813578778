import styled from 'styled-components'
import { offColor } from 'off-color'
import { useState } from 'react'
import { media, Icon, spacings } from '@folhomee/front-library'
import { get, isEmpty, size, map, isEqual } from 'lodash'

import DisplayImage from '../../molecules/DisplayImage'
import { CarouselProps } from './Carousel.types'

const Container = styled.div`
  width: 100%;
  height: 295px;
  border: 10px solid ${({ theme }) => get(theme, 'white')};
  overflow: hidden;
  position: relative;
  box-shadow: 0 3px 6px 0 rgba(51, 102, 204, 0.15);
  border-radius: 20px;

  ${media.lessThan('sm')`
    height: 200px;
  `}
`

const BlockArrow = styled.div`
  width: 10%;
  cursor: pointer;
  height: 275px;
  z-index: 20;
  display: inline-block;
  position: absolute;
  text-align: center;
  line-height: 275px;
  vertical-align: middle;

  ${media.lessThan('sm')`
    height: 200px;
    line-height: 200px;
  `}
`

const LeftArrow = styled(BlockArrow)`
  left: -5px;
`

const RightArrow = styled(BlockArrow)`
  right: -5px;
`

const IconArrow = styled(Icon)`
  transform: scale(2);
  
  & path {
    stroke: ${({ theme }) => get(theme, 'white')} !important;
    stroke-width: 3 !important;
  }
`

const Points = styled.div`
  gap: 8px;
  width: 100%;
  bottom: ${get(spacings, 'xxs')}px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
`

const Point = styled.div`
  width: 8px;
  height: 8px;
  background: ${({ theme }) => offColor(get(theme, 'white')).rgba(0.8)};
  border-radius: 20px;
`

const BigPoint = styled(Point)`
  width: 13px;
  height: 13px;
  background: ${({ theme }) => get(theme, 'white')};
`

const Carousel = ({ images }: CarouselProps): JSX.Element => {
  const [selection, updateSelection] = useState(0)
  const nImages = size(images)

  if (isEmpty(images)) {
    return (
      <Container>
        <DisplayImage data={''} isFetching={false} />
      </Container>
    )
  }

  return (
    <Container>
      <LeftArrow onClick={() => updateSelection(Math.abs(selection - 1) % nImages)}>
        <IconArrow variant='arrow-left'/>
      </LeftArrow>
      <RightArrow onClick={() => updateSelection(Math.abs(selection + 1) % nImages)}>
        <IconArrow variant='arrow-right'/>
      </RightArrow>
      <Points>
        {map(images, (_, idx) => {
          if (isEqual(idx, selection)) {
            return (
              <BigPoint key={idx} />
            )
          }
          return (
            <Point key={idx} />
          )
        })}
      </Points>
      <DisplayImage data={images[selection]} isFetching={false} />
    </Container>
  )
}

export default Carousel
