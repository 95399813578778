/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import styled from 'styled-components'
import { useFormik } from 'formik'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, spacings, Button } from '@folhomee/front-library'
import { map, isEqual, isUndefined, get, isObject, reduce, snakeCase } from 'lodash'

import SearchInput from '../../molecules/SearchInput'
import { CrawlerType } from '../../../queries/Crawlers/Crawlers.types'
import { CreateCrawlerModalProps } from './CreateCrawlerModal.types'
import { useFetchCrawlerTypes, useCreateCrawlerQuery } from '../../../queries/Crawlers'

const CRAWL_TYPES = ['otaree', 'prescriber', 'public']

const Container = styled.div`
  gap: ${get(spacings, 'xs')}px;
  display: flex;
  flex-direction: column;
`

const FieldContainer = styled.div``

const CreateCrawlerModal = ({ source, closeModal }: CreateCrawlerModalProps): JSX.Element => {
  const { t } = useTranslation()
  const { mutateAsync, isLoading } = useCreateCrawlerQuery([source])
  const { data: crawlerTypes, isFetching: isFetchingCrawlerTypes } = useFetchCrawlerTypes()

  const onSubmit = useCallback(async (values) => {
    const toSendValues = reduce(values, (acc: any, value: any, name: string): any => ({
      ...acc,
      [snakeCase(name)]: isObject(value) ? get(value, 'value') : value
    }), { source_id: source })

    await mutateAsync(toSendValues)

    closeModal()
  }, [mutateAsync])
  const { handleChange, handleBlur, values, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      name: '',
      url: '',
      lotUrl: '',
      crawlType: '',
      programUrl: '',
      crawlerTypeId: ''
    },
    onSubmit: onSubmit
  })
  const setFieldValueHandler = useCallback(async (field: string, value: any, add?: boolean): Promise<void> => {
    if (!isUndefined(add) && isEqual(add, false)) {
      await setFieldValue(field, '')
      return
    }

    await setFieldValue(field, value)
  }, [])
  const onClick = useCallback(() => {
    handleSubmit()
  }, [handleSubmit])

  if (isFetchingCrawlerTypes) {
    return (
      <></>
    )
  }

  const fields = [{
    name: 'name',
    type: 'text',
    title: 'COMMON.name',
    options: [],
    multiple: false,
    placeholder: 'COMMON.namePlaceholder'
  }, {
    name: 'url',
    type: 'text',
    title: 'CRAWLER.url',
    options: [],
    multiple: false,
    placeholder: 'CRAWLER.urlPlaceholder'
  }, {
    name: 'programUrl',
    type: 'text',
    title: 'CRAWLER.programUrl',
    options: [],
    multiple: false,
    placeholder: 'CRAWLER.programUrlPlaceholder'
  }, {
    name: 'lotUrl',
    type: 'text',
    title: 'CRAWLER.lotUrl',
    options: [],
    multiple: false,
    placeholder: 'CRAWLER.lotUrlPlaceholder'
  }, {
    name: 'crawlerTypeId',
    type: 'select',
    title: 'CRAWLER.crawlerTypeId',
    options: map(get(crawlerTypes, 'data', []), ({ id, name }: CrawlerType) => ({
      value: id, label: name
    })),
    multiple: false,
    placeholder: 'CRAWLER.crawlerTypeIdPlaceholder'
  }, {
    name: 'crawlType',
    type: 'select',
    title: 'CRAWLER.crawlType',
    options: map(CRAWL_TYPES, (name: string, id: number) => ({
      value: id, label: t(`COMMON.${name}`)
    })),
    placeholder: 'CRAWLER.crawlTypePlaceholder'
  }]

  return (
    <Container>
      <Text
        type='regular'
        strong={true}
        variant='primary'>
        <span>{t('CRAWLER.createTitle')}</span>
      </Text>
      {map(fields, ({ name, title, type, options, multiple, placeholder }) => (
        <FieldContainer>
          <Text
            type='regular'
            strong={true}
            variant='secondary'>
            {t<string>(title)}
          </Text>
          <SearchInput
            key={name}
            name={name}
            type={type}
            values={values}
            options={options}
            multiple={multiple}
            handleBlur={handleBlur}
            placeholder={placeholder}
            handleChange={handleChange}
            setFieldValue={setFieldValueHandler} />
        </FieldContainer>
      ))}
      <Button
        label={t('COMMON.validate')}
        variant='primary'
        onClick={onClick}
        disabled={isLoading}
        importance='small' />
    </Container>
  )
}

export default CreateCrawlerModal
