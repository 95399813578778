import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { useCallback, useRef, useState, useEffect } from 'react'
import { get, includes, isEqual, map, size, reduce, filter } from 'lodash'

import Row from '../../atoms/Row'
import Icon from '../../atoms/Icon'
import Text from '../../atoms/Text'
import Table from '../../organisms/Table'
import FlexBox from '../../atoms/FlexBox'
import Checkbox from '../../atoms/Checkbox'
import useClient from '../../../hooks/useClient'
import ButtonContainer from '../../atoms/ButtonContainer'
import TableBottom from '../../molecules/TableBottom'
import { StyledTableProps } from '../../organisms/Table/Table.types'
import { Alert, PageAlerts } from '../../../queries/Alerts/Alerts.types'
import { useFetchAlertsQuery } from '../../../queries/Alerts'
import { BODY_MUTE, HEADER_MUTE } from '../../../utils/tables/programs'
import { ProgramMuteMutationProps, ProgramMuteProps, ProgramMuteRowProps } from './ProgramMute.types'

const StyledTable = styled(Table) <StyledTableProps>`
  max-height: 330px;
  box-shadow: none;
`

const StyledCheckbox = styled(Checkbox)`
  justify-self: center;
`

const ProgramMuteRow = ({ data, meta, fields, actions, options }: ProgramMuteRowProps): JSX.Element => (
  <Row top={8} options={options} left={8}>
    {map(fields, ({ title, key }, idx) => {
      if (isEqual(key, 'action')) {
        return (
          <StyledCheckbox
            key={`${title ?? idx}-${key}`}
            label=''
            onClick={() => actions(get(data, 'id'))}
            bordered={!includes(meta, get(data, 'id'))}
            importance='secondary'
            checkboxSize='small' />
        )
      }
      return (
        <Text
          key={`${title ?? idx}-${key}`}
          left={8}>
          {get(data, key)}
        </Text>
      )
    })}
  </Row >
)

const ProgramMute = ({ id, mute, updateModal }: ProgramMuteProps): JSX.Element => {
  const { t } = useTranslation()
  const client = useClient()
  const queryClient = useQueryClient()
  const { data, isFetching, fetchNextPage } = useFetchAlertsQuery({ program: id, mute: true })
  const [muteAll, updateMuteAll] = useState<boolean>(mute)

  const [alerts, updateAlerts] = useState<Alert[]>([])
  const [muteAlerts, updateMuteAlerts] = useState<number[]>([])

  const options = useRef({
    bodyColor: 'extraLightBlue',
    headerColor: 'lightGrey',
    columnAlignment: '0.15fr 1fr'
  })

  const handleSelect = useCallback((id: number) => {
    const remove = filter(muteAlerts, elt => !isEqual(id, elt))

    updateMuteAlerts(isEqual(size(remove), size(muteAlerts)) ? [...muteAlerts, id] : remove)
  }, [muteAlerts, updateMuteAlerts])

  const updateMutation = useMutation(async (params: ProgramMuteMutationProps) => await client.post(`/api/programs/${id.toString()}/mute`, {
    alerts_to_mute: get(params, 'mute'),
    alerts_to_unmute: get(params, 'unmute'),
    mute_all: get(params, 'muteAll')
  }), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['fetchAllAlerts'])
      await queryClient.invalidateQueries(['fetchProgram', id])
      updateModal(false)
    }
  })

  const handleMerge = useCallback(async () => {
    const fetchedMutedAlerts: number[] = reduce(alerts, (acc: number[], alert: Alert) => {
      if (isEqual(get(alert, 'mute'), true)) {
        return [...acc, get(alert, 'id')]
      }

      return acc
    }, [])

    await updateMutation.mutateAsync({
      mute: filter(muteAlerts, id => isEqual(includes(fetchedMutedAlerts, id), false)),
      unmute: filter(fetchedMutedAlerts, id => isEqual(includes(muteAlerts, id), false)),
      muteAll
    })
  }, [mute, muteAll, muteAlerts, alerts, updateMutation])

  const handleSelectAll = useCallback(() => {
    updateMuteAll(!muteAll)
  }, [muteAll, updateMuteAll])

  useEffect(() => {
    if (!isFetching) {
      const allAlerts = reduce(get(data, 'pages', []), (acc: Alert[], page: PageAlerts): Alert[] => [...acc, ...get(page, 'data', [])], [])

      updateAlerts(allAlerts)
      updateMuteAlerts(reduce(allAlerts, (acc: number[], alert: Alert) => {
        if (isEqual(get(alert, 'mute'), true)) {
          return [...acc, get(alert, 'id')]
        }

        if (isEqual(get(alert, 'mute'), false)) {
          return filter(acc, elt => !isEqual(elt, get(alert, 'id')))
        }

        return acc
      }, []))
    }
  }, [data, isFetching])

  return (
    <>
      <FlexBox
        gap={4}
        justifyContent='start'
        row
        width100>
        <Icon fill variant='alert-solid' />
        <Text textType='bigger' strong
          color='primary'>
          {t('PROGRAM.muteListTitle')}
        </Text>
      </FlexBox>
      <FlexBox row bottom={16} gap={8} justifyContent='start'>
        <Checkbox
          label=''
          onClick={handleSelectAll}
          bordered={!muteAll}
          importance='secondary'
          checkboxSize='small' />
        <Text>{t('PROGRAM.muteUser')}</Text>
      </FlexBox>
      <Text>
        {t('PROGRAM.muteAlert')}
      </Text>
      <StyledTable
        row={ProgramMuteRow}
        data={alerts}
        meta={muteAlerts}
        body={BODY_MUTE}
        header={HEADER_MUTE}
        actions={handleSelect}
        options={options.current}
        outline={true} />
      <TableBottom
        loading={isFetching}
        fetchNextPage={fetchNextPage} />
      <ButtonContainer
        center
        label={t<string>('COMMON.validateCounter', { count: size(muteAlerts) })}
        color='success'
        onClick={handleMerge}
        buttonSize='large' />
    </>
  )
}

export default ProgramMute
