import styled from 'styled-components'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { get, isEqual } from 'lodash'

import Icon from '../Icon'
import Text from '../Text'
import Modal from '../../organisms/Modal'
import FlexBox from '../FlexBox'
import ButtonRound from '../ButtonRound'
import ButtonContainer from '../ButtonContainer'
import { DocumentRowProps } from './DocumentRow.types'
import { useDeleteDocumentQuery } from '../../../queries/Documents'

const DocumentLink = styled.a`
  gap: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
`

const DocumentRow = ({ document, user }: DocumentRowProps): JSX.Element => {
  const { t } = useTranslation()
  const [confirmDelete, updateConfirmDelete] = useState<boolean>(false)
  const { mutateAsync, status } = useDeleteDocumentQuery(get(document, 'id', 0))

  const onCancelDelete = (): void => updateConfirmDelete(false)
  const onConfirmDelete = async (): Promise<void> => {
    await mutateAsync({})
    if (!isEqual(status, 'error')) {
      updateConfirmDelete(false)
    }
  }

  return (
    <div>
      <Modal isOpen={confirmDelete} onRequestClose={onCancelDelete} gap={16}>
        <>
          <FlexBox top={16} gap={5} row >
            <Text textType='bigger' color='primary'>
              {t('DOCUMENTS.removeDocument')}
            </Text>
            <Text textType='bigger' color='primary' strong >
              {t<string>(get(document, 'label', ''))} ?
            </Text>
          </FlexBox>
          <FlexBox row gap={8}>
            <ButtonContainer
              type='submit'
              buttonSize='large'
              onClick={onCancelDelete}
              color='mediumSecondary'
              label={t<string>('COMMON.cancel')} />
            <ButtonContainer
              type='submit'
              buttonSize='large'
              onClick={async () => await onConfirmDelete()}
              color='danger'
              label={t<string>('COMMON.delete')} />
          </FlexBox>
        </>
      </Modal>
      <FlexBox row gap={8}>
        <DocumentLink href={get(document, 'value')} target='_blank'>
          <ButtonRound
            buttonSize='small'
            color='info'
            onClick={get(document, 'value')}>
            <Icon variant='download' stroke cursor />
          </ButtonRound>
          <Text
            color='primary'
            strong>
            {get(document, 'label', '')}
          </Text>
        </DocumentLink>
        {isEqual(get(user, 'role'), 'admin') && <Icon
          variant='close-circle'
          transform='scale(0.8)'
          color='mediumSecondary'
          stroke
          onClick={() => updateConfirmDelete(true)}
          cursor />}
      </FlexBox>
    </div>
  )
}

export default DocumentRow
