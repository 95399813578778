import { get, map, isEqual, size } from 'lodash'

import Data from '../Data'
import Text from '../../atoms/Text'
import Field from '../../atoms/Field'
import RowLink from '../../atoms/RowLink'
import ContainerRow from '../../atoms/ContainerRow'
import { AgglomerationRowProps } from './Agglomeration.types'

const AgglomerationRow = ({ data, fields, options }: AgglomerationRowProps): JSX.Element => (
  <ContainerRow options={options}>
    <RowLink options={options} to={`/agglomerations/${get(data, 'id', '')}`}>
      {map(fields, ({ key }) => {
        if (isEqual(key, 'name')) {
          return (
            <Data key={key} left={true}>
              <Text color='primary' strong>
                {get(data, key)}
              </Text>
            </Data>
          )
        }

        if (isEqual(key, 'cities')) {
          return (
            <Data key={key} left={true}>
              <Field
                key={key}
                name={key}
                value={size(get(data, key))} />
            </Data>
          )
        }

        return (
          <Data key={key}>
            <Field
              key={key}
              name={key}
              value={get(data, key)} />
          </Data>
        )
      })}
    </RowLink>
  </ContainerRow>
)

export default AgglomerationRow
