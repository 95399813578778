import { useCallback, useEffect, useState } from 'react'

const getPosition = async (options?: PositionOptions): Promise<GeolocationPosition> =>
  await new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject, options))

const useGeoPosition = (): null | GeolocationPosition => {
  const [position, updatePosition] = useState<null | GeolocationPosition>(null)

  const fetchPosition = useCallback(async () => {
    try {
      const pst = await getPosition()

      updatePosition(pst)
    } catch (err) {
      console.error(err)
    }
  }, [updatePosition])

  useEffect(() => {
    fetchPosition()
  }, [])

  return position
}

export default useGeoPosition
