import {
  isNull, isUndefined, keys, reduce, get, includes, isEmpty, startsWith, toLower, last, split, isEqual, capitalize, map, intersection
} from 'lodash'

import { Lot } from '../queries/Lots/lots.types'
import { Alert } from '../queries/Alerts/Alerts.types'
import { EXTERIORS_KEYS } from '../config'

const HANDLERS = {
  minPrice: 'price',
  maxPrice: 'price',
  minSurface: 'surface',
  maxSurface: 'surface'
}

const MINMAX_LIMIT = {
  floor: 4,
  rooms: 5
}

const handleMinOrMax = (lot: Lot, key: string, alert: Alert, isMin: boolean): boolean => {
  const sKey = toLower(last(split(key, isMin ? 'min' : 'max')))

  if (isEqual(parseInt(get(alert, key)), get(MINMAX_LIMIT, sKey))) {
    return get(lot, sKey) >= parseInt(get(alert, `min${capitalize(sKey)}`, ''), 10)
  }

  if (!isEmpty(get(alert, `${isMin ? 'max' : 'min'}${capitalize(sKey)}`))) {
    return get(lot, sKey) <= parseInt(get(alert, `max${capitalize(sKey)}`, ''), 10) && get(lot, sKey) >= parseInt(get(alert, `min${capitalize(sKey)}`, ''), 10)
  }

  return isEqual(get(lot, sKey), parseInt(get(alert, key), 10))
}

export const isHighlightedFromAlert = (lot: Lot, alert: Alert | undefined): boolean => {
  if (isUndefined(alert)) {
    return false
  }

  return reduce(keys(alert), (acc: boolean, key: string | undefined): boolean => {
    if (isUndefined(key) || isNull(get(alert, key)) || includes(['name', 'createdAt', 'status'], key)) {
      return acc
    }

    if (includes(EXTERIORS_KEYS, key)) {
      return acc && !isEmpty(get(lot, key, ''))
    }

    if (includes(['minPrice', 'minSurface'], key) && !isEmpty(get(alert, key))) {
      return acc && get(lot, get(HANDLERS, key)) >= parseInt(get(alert, key))
    }

    if (includes(['maxPrice', 'maxSurface'], key) && !isEmpty(get(alert, key))) {
      return acc && get(lot, get(HANDLERS, key)) <= parseInt(get(alert, key))
    }

    if (startsWith(key, 'min') && !isEmpty(get(alert, key))) {
      return acc && handleMinOrMax(lot, key, alert, true)
    }

    if (startsWith(key, 'max') && !isEmpty(get(alert, key))) {
      return acc && handleMinOrMax(lot, key, alert, false)
    }

    if (isEqual(key, 'lotType') && !isEmpty(get(alert, key))) {
      return acc &&
        ((startsWith(get(lot, 'type'), 'T') && isEqual(get(alert, key), 'apartment')) ||
          (startsWith(get(lot, 'type'), 'M') && isEqual(get(alert, key), 'house')))
    }

    if (isEqual(key, 'taxations')) {
      return acc && !isEmpty(intersection(map(get(lot, key), 'id'), get(alert, key)))
    }

    return acc
  }, true)
}
