import styled from 'styled-components'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { isEqual, map, get } from 'lodash'

import Text from '../../atoms/Text'
import FlexBox from '../../atoms/FlexBox'
import { SearchOptionsProps } from '../../molecules/SearchForm/SearchForm.types'
import { ButtonProps, TogglerProps } from './Toggler.types'

const CheckBox = styled.div<ButtonProps>`
  width: 100%;
  border: solid 1px ${({ theme }) => get(theme, 'darkGrey')};
  cursor: pointer;
  display: flex;
  min-height: 34px;
  align-items:center;
  border-radius: 5px;
  justify-content: center;
  background-color: ${({ isSelected, theme }) => isEqual(isSelected, true) ? get(theme, 'darkGrey') : get(theme, '')};

  & > p {
    color: ${({ isSelected, theme }) => !isEqual(isSelected, true) ? get(theme, 'darkGrey') : get(theme, 'white')} !important;
  }
`

const Toggler = ({ name, input, options, onChange }: TogglerProps): JSX.Element => {
  const { t } = useTranslation()
  const boxClicked = useCallback(async (value: any) => {
    if (isEqual(input, value)) {
      return await onChange(name, '')
    }

    return await onChange(name, value)
  }, [input, name])

  return (
    <FlexBox row width100 gap={8}>
      {map(options, ({ value, label }: SearchOptionsProps) => (
        <CheckBox
          key={label}
          onClick={async () => await boxClicked(value)}
          isSelected={isEqual(value, input)}>
          <Text>
            {t<string>(label)}
          </Text>
        </CheckBox>
      ))}
    </FlexBox>
  )
}

export default Toggler
