import styled from 'styled-components'
import { createPortal } from 'react-dom'
import { media, spacings } from '@folhomee/front-library'
import { isEqual, get } from 'lodash'
import { useRef, useState, useCallback, useEffect } from 'react'

import Icon from '../../atoms/Icon'
import Card from '../../atoms/Card'
import useIsMounted from '../../../hooks/useIsMounted'
import { ModalProps, ModalContentProps, ModalContainerProps } from './Modal.types'

const ESCAPE_KEY = 27

const Container = styled.div.attrs(({ backgroundBlur }: ModalContainerProps) => ({
  backgroundBlur
})) <ModalContainerProps>`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  width: auto;
  z-index: 1000;
  position: fixed;
  align-items: center;
  backdrop-filter: ${({ backgroundBlur }) => isEqual(backgroundBlur, true) ? 'blur(10px)' : 'none'};
  justify-content: center;
  background-color: rgba(0, 0, 0, .5);
`

const StyledCard = styled(Card)<ModalContentProps>`
  width: auto;
  position: relative;
  min-width: 40vw;
  min-height: 20vh;

  ${media.greaterThan('xl')`
    width: auto;
    min-width: 550px;
  `}

  ${media.lessThan('sm')`
    width: 90%;
    min-width: 90%;
  `}
`

const StyledIcon = styled(Icon)`
  top: ${get(spacings, 's')}px;
  right: ${get(spacings, 's')}px;
  position: absolute;
`

const Modal = ({ children, isOpen, onRequestClose, backgroundBlur = false, justifyStart, alignStart, gap }: ModalProps): JSX.Element | null => {
  const node = useRef<HTMLDivElement>(null)
  const isMounted = useIsMounted()
  const [isClicked, updateIsClicked] = useState(false)

  const handleClick = useCallback((evt) => {
    evt.stopPropagation()
    const { target } = evt
    if (!isClicked) {
      return updateIsClicked(true)
    }

    if (isEqual(node.current?.contains(target), true)) {
      return
    }

    if (isEqual(isOpen, true)) {
      updateIsClicked(false)
      return onRequestClose()
    }
  }, [isOpen, onRequestClose, node, isClicked, updateIsClicked])

  const handleKeyDown = useCallback(({ keyCode }) => {
    if (!isEqual(keyCode, ESCAPE_KEY)) {
      return
    }

    if (isEqual(isOpen, true)) {
      return onRequestClose()
    }
  }, [isOpen, onRequestClose, node])

  useEffect(() => {
    if (isEqual(isMounted, true) && isEqual(isOpen, true)) {
      document.addEventListener('click', handleClick)
      document.addEventListener('keydown', handleKeyDown)

      return () => {
        document.removeEventListener('click', handleClick)
        document.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [isMounted, isOpen])

  if (!isEqual(isOpen, true)) {
    return null
  }

  return createPortal(
    <Container backgroundBlur={backgroundBlur} onClick={handleClick}>
      <StyledCard ref={node} justifyStart={justifyStart} alignStart={alignStart} gap={gap}>
        <>
          <StyledIcon variant='close' stroke cursor onClick={() => onRequestClose()} />
          {children}
        </>
      </StyledCard>
    </Container>,
    document.getElementById('modal') as HTMLElement
  )
}

export default Modal
