import styled from 'styled-components'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'
import { spacings, media } from '@folhomee/front-library'

import { AuthTopProps } from './AuthTop.types'
import { ReactComponent as ImpulseLogo } from '../../../assets/svgs/logo-impulse.svg'

const LogoContainer = styled.div`
  width: 40%;
`

const Title = styled.h1`
  color: ${({ theme }) => get(theme, 'blue')};
  font-size: 19px;
  margin-top: ${get(spacings, 's')}px;
  font-weight: 600;

  ${media.greaterThan('md')`
    font-size: 30px;
  `}
`

const AuthTop = ({ title }: AuthTopProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      <LogoContainer>
        <ImpulseLogo />
      </LogoContainer>
      <Title>{t<string>(title)}</Title>
    </>
  )
}

export default AuthTop
