import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useSetRecoilState } from 'recoil'
import { Error } from '@folhomee/front-library'
import { useCallback, useState } from 'react'
import { get, isEmpty, isUndefined, split, last } from 'lodash'
import * as Yup from 'yup'

import AuthTop from '../../molecules/AuthTop'
import AuthCard from '../../atoms/AuthCard'
import AuthQuote from '../../atoms/AuthQuote'
import FormInput from '../../atoms/FormInput'
import LoginForm from '../../organisms/LoginForm'
import useClient from '../../../hooks/useClient'
import AuthContainer from '../../atoms/AuthContainer'
import ButtonContainer from '../../atoms/ButtonContainer'
import { getErrors } from '../../../services/errors'
import { userTokenState } from '../../../recoil/atoms'

const showError = (error: string, touched: boolean): boolean => !isEmpty(error) && touched

const Login = (): JSX.Element => {
  const { t } = useTranslation()
  const client = useClient()
  const setUserToken = useSetRecoilState(userTokenState)
  const [loading, updateLoading] = useState<boolean>(false)
  const [requestError, updateRequestError] = useState('')

  const loginSchema = Yup.object().shape({
    email: Yup.string().email(t('ERRORS.emailError')).required(t('ERRORS.emailRequired')),
    password: Yup.string().required(t('ERRORS.passwordRequired'))
  })

  const onSubmit = useCallback(async (values, { resetForm }) => {
    updateLoading(true)

    try {
      const { headers } = await client.post('/auth/login', {
        user: values
      })

      setUserToken(last(split(get(headers, 'authorization', ''), ' ')) ?? '')
    } catch (err) {
      const message = get(err, 'response.data.message')
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const loginError = isUndefined(message) ? getErrors(get(err, 'message', 'Network Error')) : getErrors(`LOGIN.${message}`)
      updateRequestError(loginError)
    } finally {
      updateLoading(false)
      resetForm()
    }
  }, [])

  const { handleSubmit, handleChange, handleBlur, touched, values, errors } = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: loginSchema,
    onSubmit
  })

  const emailError = showError(get(errors, 'email', ''), get(touched, 'email', false))
  const passwordError = showError(get(errors, 'password', ''), get(touched, 'password', false))

  return (
    <AuthContainer>
      <AuthCard>
        <AuthTop title='LOGIN.title' />
        <LoginForm loading={loading} withoutImage={false}>
          <Error
            show={!isEmpty(requestError)}
            label={t(requestError)} />
          <FormInput
            type='email'
            name='email'
            error={emailError}
            errors={errors}
            values={values}
            testId='input-email'
            handleBlur={handleBlur}
            placeholder={t('COMMON.email')}
            handleChange={handleChange} />
          <FormInput
            type='password'
            name='password'
            error={passwordError}
            errors={errors}
            values={values}
            testId='input-password'
            handleBlur={handleBlur}
            placeholder={t('COMMON.password')}
            handleChange={handleChange} />
          <ButtonContainer
            type='submit'
            label={t('LOGIN.button')}
            color='danger'
            buttonSize='large'
            onClick={(evt) => {
              evt.preventDefault()
              return handleSubmit()
            }} />
        </LoginForm>
        <AuthQuote />
      </AuthCard>
    </AuthContainer>
  )
}

export default Login
