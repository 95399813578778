import styled from 'styled-components'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useState } from 'react'
import { get, map, isEqual, toUpper, filter, includes, last } from 'lodash'

import Text from '../../atoms/Text'
import Input from '../../atoms/Input'
import FlexBox from '../../atoms/FlexBox'
import Dropdown from '../../atoms/Dropdown'
import ButtonContainer from '../../atoms/ButtonContainer'
import { FormProps, InputItemProps } from './Form.types'

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Select = styled.select`
  color: #707070;
  width: 166px;
  border: 1px solid #707070;
  height: 35px;
  margin-top: 4px;
  font-family: SourceSansPro;
  border-radius: 5px;

  &:focus {
    outline: none;
  }

  & > option {
    color: #70707s0;
    font-size: 15px;
    line-height: 1.5;
    font-family: SourceSansPro;
  }
`

const Inline = styled.div`
  display: flex;
  flex-direction: row;
`

const InlineWrap = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`

const LabelOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 6px;
  background-color: ${({ theme }) => get(theme, 'blue')};
  border-radius: 4px;
  color: white;
`
const Textarea = styled.textarea`
font-family: 'Source Sans Pro';
  padding: 12px 16px;
  font-size: 16px;
  background-clip: padding-box;
  margin-top: 4px;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  border-radius: 5px;
  background-color: ${({ theme }) => get(theme, 'white')};

  &::placeholder {
    width: 100%;
    height: 25px;
    opacity: 0.5;
    font-family: 'Source Sans Pro';
    font-size: 16px;
  }
`

export const InputItem = ({ handleChange, field, optionNull, values, setFieldTouched, setFieldValue, handleBlur }: InputItemProps): JSX.Element => {
  const [input, updateInput] = useState(get(values, get(field, 'key')))
  const [selection, updateSelection] = useState(get(values, get(field, 'key')))
  const { t } = useTranslation()

  const removeSelection = useCallback((value) => {
    const res = filter(selection, elt => !isEqual(value, elt))
    updateSelection(res)
    setFieldValue(get(field, 'key'), res)
  }, [selection, updateSelection])

  const addSelection = useCallback((event) => {
    if (!includes(selection, event.target.value)) {
      const res = [...selection, event.target.value]
      updateSelection(res)
      setFieldValue(get(field, 'key'), res)
    } else {
      const res = [...selection]
      updateSelection(res)
      setFieldValue(get(field, 'key'), res)
    }
  }, [field, selection, updateSelection])

  useEffect(() => {
    updateInput(get(values, get(field, 'key')))
  }, [values])

  if (isEqual(get(field, 'type'), 'options')) {
    return (
      <FlexBox alignStart gap={8}>
        <Text strong>
          <FlexBox alignStart gap={4}>
            {toUpper(t(get(field, 'placeholder', '')))}
            <Select
              name={get(field, 'key')}
              value={get(values, get(field, 'key'))}
              onBlur={handleBlur}
              onChange={handleChange}>
              {isEqual(optionNull, true) && <option value="" selected>
                Sélectionnez une ou plusieurs options
              </option>}
              {map(get(field, 'options', []), (option) => (
                <option value={get(option, 'value')} label={t<string>(get(option, 'label', ''))}>
                  {t<string>(get(option, 'label', ''))}
                </option>
              ))}
            </Select>
          </FlexBox>
        </Text>
      </FlexBox>
    )
  }

  if (isEqual(get(field, 'type'), 'multiselection')) {
    return (
      <FlexBox alignStart gap={8}>
        <Text strong>
          <FlexBox alignStart gap={4}>
            {toUpper(t(get(field, 'placeholder', '')))}
            <InlineWrap>{map(selection, selec => {
              return (
                <LabelOption>
                  <div>{selec}</div>
                  <div onClick={() => removeSelection(selec)}>X</div>
                </LabelOption>
              )
            })}</InlineWrap>
            <Select
              name={get(field, 'key')}
              value={last(get(values, get(field, 'key')))}
              onBlur={handleBlur}
              onChange={addSelection}>
              <option value="" disabled selected>
                Sélectionnez une ou plusieurs options
              </option>
              {map(get(field, 'options', []), (option) => (
                <option value={get(option, 'value')} label={t<string>(get(option, 'label', ''))}>
                  {t<string>(get(option, 'label', ''))}
                </option>
              ))}
            </Select>
          </FlexBox>
        </Text>
      </FlexBox>
    )
  }

  if (isEqual(get(field, 'type'), 'payments')) {
    return (
      <FlexBox alignStart gap={8}>
        <Text strong>
          <FlexBox alignStart gap={4}>
            {toUpper(t(get(field, 'placeholder', '')))}
            <Inline>
              <Input
                name='amount'
                value={get(values, 'amount', 0.00)}
                squared={true}
                bordered={true}
                onChange={handleChange}
                inputSize='regular'
                importance='secondary'
                placeholder={t(get(field, 'placeholder', ''))} />
              <Input
                name='subscription'
                value={get(values, 'subscription', '')}
                squared={true}
                bordered={true}
                onChange={handleChange}
                inputSize='regular'
                importance='secondary'
                placeholder='subscription id' />
              <Select
                name={get(field, 'key')}
                value={get(values, get(field, 'key'))}
                onBlur={handleBlur}
                onChange={(e) => setFieldValue('payments', e.target.value)}>
                <option value="" disabled selected>
                  Sélectionnez une option
                </option>
                {map(get(field, 'options', []), (option) => (
                  <option value={get(option, 'value')} label={t<string>(get(option, 'label', ''))}>
                    {t<string>(get(option, 'label', ''))}
                  </option>
                ))}
              </Select>
            </Inline>
          </FlexBox>
        </Text>
      </FlexBox>
    )
  }

  if (isEqual(get(field, 'type'), 'dropdown')) {
    return (
      <FlexBox alignStart gap={8}>
        <Text textType='subtitle' strong>
          <>
            {toUpper(t(get(field, 'placeholder', '')))}
            <Dropdown
              dropdownSize='large'
              name={get(field, 'key')}
              value={input}
              options={get(field, 'options', [])}
              multiple={false}
              onChange={setFieldValue}
              selection={input}
              placeholder={t(get(field, 'placeholder', ''))} />
          </>
        </Text>
      </FlexBox>
    )
  }

  if (isEqual(get(field, 'type'), 'checkbox')) {
    return (
      <FlexBox alignStart gap={8}>
        <Text textType='subtitle' strong>
          <FlexBox row gap={4}>
            <input
              type='checkbox'
              name={get(field, 'key')}
              checked={isEqual(get(values, get(field, 'key')), true)}
              onChange={() => {
                if (isEqual(get(values, get(field, 'key')), true)) {
                  setFieldValue(get(field, 'key'), false)
                } else {
                  setFieldValue(get(field, 'key'), true)
                }
                setFieldTouched(get(field, 'key'), true)
              }} />
            <Text>{t(get(field, 'placeholder', ''))}</Text>
          </FlexBox>
        </Text>
      </FlexBox>
    )
  }

  if (isEqual(get(field, 'type'), 'textarea')) {
    return (
      <FlexBox row={false} width100={true}>
        <Text textType='subtitle' strong>
          {toUpper(get(field, 'placeholder', ''))}
        </Text>
        <Textarea
          rows={10}
          name={get(field, 'key')}
          value={get(values, get(field, 'key'), '')}
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={get(field, 'placeholder', '')} />
      </FlexBox>
    )
  }

  return (
    <FlexBox alignStart gap={8}>
      <Text textType='subtitle' strong>
        {toUpper(t(get(field, 'placeholder', '')))}
      </Text>
      <Input
        name={get(field, 'key')}
        value={get(values, get(field, 'key'))}
        squared={true}
        bordered={true}
        onChange={handleChange}
        inputSize='regular'
        importance='secondary'
        placeholder={t(get(field, 'placeholder', ''))} />
    </FlexBox>
  )
}

const Form = ({ data, fields, submit }: FormProps): JSX.Element => {
  const { t } = useTranslation()

  const onSubmit = useCallback(async (values) => {
    await submit.mutateAsync(values)
  }, [])

  const { values, handleChange, handleSubmit, setFieldTouched, setFieldValue, handleBlur } = useFormik({
    initialValues: data,
    onSubmit
  })

  return (
    <FlexBox gap={16} width100 maxWidth={800} wrap >
      {map(fields, field => (
        <InputItem
          key={get(field, 'key')}
          field={field}
          values={values}
          handleBlur={handleBlur}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched} />
      ))}
      <ButtonContainer
        label={t<string>('COMMON.validate')}
        color='success'
        onClick={() => handleSubmit()}
        buttonSize='large'
        top={8} />
    </FlexBox>
  )
}

export default Form
