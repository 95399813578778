import { get, map, isEqual } from 'lodash'

import Data from '../Data'
import Link from '../../atoms/Link'
import Text from '../../atoms/Text'
import Field from '../../atoms/Field'
import RowLink from '../../atoms/RowLink'
import ContainerRow from '../../atoms/ContainerRow'
import { DepartmentRowProps } from './DepartmentRow.types'

const DepartmentRow = ({ data, fields, options }: DepartmentRowProps): JSX.Element => (
  <ContainerRow options={options}>
    <RowLink options={options} to={`/departments/${get(data, 'id').toString()}`}>
      {map(fields, ({ key }) => {
        if (isEqual(key, 'name')) {
          return (
            <Data key={key} left={true}>
              <Text color='primary' strong >
                {get(data, key)}
              </Text>
            </Data>
          )
        }

        if (isEqual(key, 'region')) {
          const regionId: number = get(data, 'region.id', 0)

          return (
            <Data key={key}>
              <Link to={`/regions/${regionId.toString()}`}>
                <Text color='primary'>
                  {get(data, 'region.code')}
                </Text>
              </Link>
            </Data>
          )
        }

        return (
          <Data key={key}>
            <Field
              key={key}
              name={key}
              value={get(data, key)} />
          </Data>
        )
      })}
    </RowLink>
  </ContainerRow>
)

export default DepartmentRow
