import React from 'react'
import ReactDOM from 'react-dom'
import { RecoilRoot } from 'recoil'
import { StyleProvider } from '@folhomee/front-library'

import App from './App'
import reportWebVitals from './reportWebVitals'
import './services/i18n'

import 'react-tooltip/dist/react-tooltip.css'

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <StyleProvider>
        <App />
      </StyleProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
