import styled from 'styled-components'
import { get, isEqual, isNull } from 'lodash'
import { Icon, spacings, Text } from '@folhomee/front-library'

import { DisplayFieldProps } from './DisplayField.types'

const TextContainer = styled.div`
  display: flex;
  padding: ${get(spacings, 'xs')}px;
  flex-direction: column;
`

const StyledImg = styled.img`
  padding: ${get(spacings, 'xs')}px;
  max-width: 100px;
  max-height: 150px;
`

const StyledIcon = styled(Icon)`
  width: 40px;
  height: 40px;
  transform: scale(2) translateY(50%) translateX(25%);
`

const Mapper = (type: string, value: string): JSX.Element => {
  if (isEqual(type, 'image')) {
    if (isNull(value)) {
      return (
        <StyledIcon variant='astronaut' />
      )
    }
    return (
      <StyledImg src={value} alt='Photo' />
    )
  }

  return (
    <Text type='regular'>{value}</Text>
  )
}

const DisplayField = ({ title, type, value }: DisplayFieldProps): JSX.Element => {
  return (
    <TextContainer>
      <Text type='regular' strong={true} variant='secondary'>{title} :</Text>
      {Mapper(type, value)}
    </TextContainer>
  )
}

export default DisplayField
