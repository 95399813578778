import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Button, Text } from '@folhomee/front-library'
import { useCallback, useState } from 'react'
import { get, reduce, isEqual, first, isEmpty } from 'lodash'

import AlertSearch from '../AlertSearch'
import LoadingDots from '../../atoms/LoadingDots'
import { ClientAlertProps } from './ClientAlert.types'
import { useFetchAlertsQuery } from '../../../queries/Alerts'
import { Alert, PageAlerts } from '../../../queries/Alerts/Alerts.types'
import { useCreatePipedriveAlert } from '../../../queries/Clients'

const Container = styled.div`
  margin-top: 32px;
`

const Dots = styled(LoadingDots)`
  min-height: 24px;
`

const ClientAlert = ({ data }: ClientAlertProps): JSX.Element => {
  const { t } = useTranslation()
  const [loading, updateLoading] = useState<boolean>(false)
  const [error, updateError] = useState<boolean>(false)

  const clientId = get(data, 'id', 0)
  const { data: fetchedAlert, isFetching } = useFetchAlertsQuery({ client: clientId })
  const alert = first(reduce(get(fetchedAlert, 'pages', []), (acc: Alert[], page: PageAlerts): Alert[] => [...acc, ...get(page, 'data', [])], []))

  const { mutateAsync } = useCreatePipedriveAlert(clientId)

  const handleCreateAlert = useCallback(async () => {
    try {
      updateLoading(true)
      await mutateAsync(clientId)
    } catch (err) {
      console.log(err)
      updateError(true)
    } finally {
      updateLoading(false)
    }
  }, [])

  if (isEqual(isFetching, true)) {
    return (
      <Container>
        <LoadingDots />
      </Container>
    )
  }

  if (isEqual(error, true)) {
    return (
      <Container>
        <Text type='regular' variant='secondary'>{t('ERRORS.pipedriveAlertCreationError')}</Text>
      </Container>
    )
  }

  if (isEmpty(alert)) {
    return (
      <Container>
        <Button variant='primary' importance='small' onClick={handleCreateAlert}>
          <>
            {isEqual(loading, false) && t<string>('CLIENTS.pipedriveAlert')}
            {isEqual(loading, true) && <Dots color='white' />}
          </>
        </Button>
      </Container>
    )
  }

  return (
    <AlertSearch data={{ data: alert }} />
  )
}

export default ClientAlert
