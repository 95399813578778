import styled from 'styled-components'
import { useFormik } from 'formik'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, spacings, Text } from '@folhomee/front-library'
import { get, map, reduce, snakeCase, isObject, chunk, join } from 'lodash'

import { Region } from '../../../queries/Regions/Regions.types'
import { InputItem } from '../../templates/Form'
import { ContactFormProps } from './ContactForm.types'
import { useCreateContactQuery } from '../../../queries/Contacts'
import { useFetchInfiniteRegionsQuery } from '../../../queries/Regions'

const Form = styled.div`
  padding: 8px;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Footer = styled.div`
  gap: 16px;
  display: flex;
  padding-top: ${get(spacings, 'xs')}px;
  flex-direction: column;
`

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  padding: ${get(spacings, 'xs')}px 0;
  max-height: 50vh;
  overflow-y: scroll;
  flex-direction: column;
`

const FieldsLine = styled.div`
  gap: 13px;
  width: 100%;
  display: flex;

  & > div {
    flex: 1
  }
`

const ContactForm = ({ id, updateOpen }: ContactFormProps): JSX.Element => {
  const { t } = useTranslation()
  const { mutateAsync } = useCreateContactQuery(id)
  const { data: regionsPage } = useFetchInfiniteRegionsQuery({})

  const onSubmit = useCallback(async (values) => {
    const toSendValues = reduce(values, (acc: any, value: any, name: string): any => ({
      ...acc,
      [snakeCase(name)]: isObject(value) ? get(value, 'value') : value
    }), [])
    await mutateAsync(toSendValues)
    updateOpen(false)
  }, [mutateAsync, updateOpen])

  const { values, handleChange, handleSubmit, setFieldTouched, setFieldValue, handleBlur } = useFormik({
    initialValues: {
      job: '',
      email: '',
      phone: '',
      sourceId: id,
      regionId: '',
      lastName: '',
      firstName: ''
    },
    onSubmit
  })

  const regions = reduce(get(regionsPage, 'pages', []), (acc: Region[], page) => [...acc, ...get(page, 'data', [])], [])

  const fields = [{
    key: 'firstName',
    type: 'text',
    options: [],
    placeholder: 'COMMON.firstName'
  }, {
    key: 'lastName',
    type: 'text',
    options: [],
    placeholder: 'COMMON.name'
  }, {
    key: 'email',
    type: 'text',
    options: [],
    placeholder: 'COMMON.email'
  }, {
    key: 'phone',
    type: 'text',
    options: [],
    placeholder: 'COMMON.phone'
  }, {
    key: 'job',
    type: 'text',
    options: [],
    placeholder: 'COMMON.job'
  }, {
    key: 'regionId',
    type: 'dropdown',
    options: map(regions, (label) => ({
      value: get(label, 'id').toString(), label: get(label, 'name')
    })),
    placeholder: 'REGIONS.singleTitle'
  }]

  return (
    <Form>
      <Header>
        <Text
          type='regular'
          strong={true}
          variant='primary'>
          {t('SOURCES.addContact')}
        </Text>
      </Header>
      <InputContainer>
        {map(chunk(fields, 2), (lineFields) => (
          <FieldsLine key={join(map(lineFields, 'key'), '-')}>
            {map(lineFields, ({ key, placeholder, type, options }, index) => (
              <InputItem
                key={index}
                field={{ key, placeholder, type, options }}
                values={values}
                handleBlur={handleBlur}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched} />
            ))}
          </FieldsLine>
        ))}
      </InputContainer>
      <Footer>
        <Button
          label={t('COMMON.save')}
          variant='primary'
          importance='small'
          onClick={() => handleSubmit()} />
        <Button
          label={t('COMMON.cancel')}
          variant='secondary'
          importance='small'
          onClick={() => updateOpen(false)} />
      </Footer>
    </Form>
  )
}

export default ContactForm
