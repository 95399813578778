import styled from 'styled-components'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { get, isEmpty, isEqual, isUndefined } from 'lodash'
import { useState, useRef, ChangeEvent, useCallback } from 'react'

import Icon from '../../atoms/Icon'
import Text from '../../atoms/Text'
import Input from '../../atoms/Input'
import FlexBox from '../../atoms/FlexBox'
import SearchInput from '../SearchInput'
import LoadingDots from '../../atoms/LoadingDots'
import ButtonContainer from '../../atoms/ButtonContainer'
import { useLoadDocument } from '../../../queries/Documents'
import { DocumentProgramFormProps } from './ProgramDocumentForm.types'

const StyledInput = styled(Input)`
  width: 60%;
`

const LinkUpload = styled.div`
  gap: 4px;
  display: flex;
  cursor: pointer;
`

const PGM_FILE_TYPES = [{
  value: 'brochure',
  label: 'Brochure'
}, {
  value: 'ground_plan',
  label: 'Plan de masse'
}, {
  value: 'notice',
  label: 'Notice descriptive'
}, {
  value: 'parking_plan',
  label: 'Plan de parking'
}, {
  value: 'contract',
  label: 'Contrat'
}, {
  value: 'other',
  label: 'Autre'
}]

const LOT_FILE_TYPES = [{
  value: 'plan',
  label: 'Plan'
}]

const ProgramDocumentForm = ({ programId, lotId, updateOpen, programName, lotNumber }: DocumentProgramFormProps): JSX.Element => {
  const { t } = useTranslation()
  const [file, updateFile] = useState<File>()
  const [error, updateError] = useState<boolean>(false)
  const [filename, updateFilename] = useState<string>('')
  const [loading, updateLoading] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const fileTypes = isUndefined(lotId) ? PGM_FILE_TYPES : LOT_FILE_TYPES

  const { mutateAsync } = useLoadDocument(programId)

  const handleUploadClick = (): void => {
    inputRef.current?.click()
  }

  const setFieldValueHandler = useCallback(async (field: string, value: any, add?: boolean): Promise<void> => {
    if (!isUndefined(add) && isEqual(add, false)) {
      await setFieldValue(field, '')
      return
    }

    await setFieldValue(field, value)
  }, [])

  const { values, handleBlur, handleChange, setFieldValue } = useFormik({
    initialValues: {
      fileType: fileTypes[0]
    },
    onSubmit: () => { }
  })

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files == null) {
      return
    }

    updateFile(e.target.files[0])
    updateFilename(get(e.target.files[0], 'name', ''))
  }

  const handleDocumentSend = useCallback(async () => {
    if (isUndefined(file)) {
      return
    }

    updateLoading(true)
    const formData = new FormData()
    formData.append('document[file_content]', file, get(file, 'name'))
    formData.append('document[file_type]', get(values, 'fileType.value', ''))
    formData.append('document[file_name]', filename)
    formData.append('document[program_id]', programId)
    if (!isUndefined(lotId)) {
      formData.append('document[lot_id]', lotId)
    }

    try {
      await mutateAsync(formData)
      updateOpen(false)
    } catch (error) {
      console.log(error)
      updateError(true)
    } finally {
      updateLoading(false)
    }
  }, [values, file, filename])

  const titleStr = (isUndefined(lotNumber) || isEmpty(lotNumber)) ? t('DOCUMENTS.addDocument', { program: programName }) : t('DOCUMENTS.addPlan', { lot: lotNumber, program: programName })

  return (
    <>
      <FlexBox width100 row justifyContent='start' gap={4}>
        <Icon variant='download-file-solid' fill />
        <Text
          color='primary'
          textType='bigger'
          strong>
          {titleStr}
        </Text>
      </FlexBox>
      <FlexBox gap={16} width100 alignStart>
        <FlexBox row gap={16} width100 justifyContent='start'>
          <Text strong>
            {t<string>('DOCUMENTS.type')}
          </Text>
          <SearchInput
            key='fileType'
            name='fileType'
            type='select'
            values={values}
            options={fileTypes}
            multiple={false}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValueHandler} />
        </FlexBox>
        <FlexBox row gap={16} width100 justifyContent='start'>
          <LinkUpload onClick={handleUploadClick} >
            <Icon variant='download' stroke cursor />
            <Text color='primary' strong>
              {t<string>('DOCUMENTS.load')}
            </Text>
          </LinkUpload>
          <input
            type='file'
            ref={inputRef}
            onChange={handleFileChange}
            style={{ display: 'none' }} />
          {!isUndefined(file) && <StyledInput
            name='filename'
            value={filename}
            squared={true}
            bordered={true}
            onChange={(e) => updateFilename(e.currentTarget.value)}
            inputSize='small'
            importance='secondary'
            placeholder={t('DOCUMENTS.filename')} />}
        </FlexBox>
      </FlexBox>
      <ButtonContainer top={16} color='danger' buttonSize='large' onClick={handleDocumentSend}>
        <>
          {isEqual(loading, false) && t('COMMON.save')}
          {isEqual(loading, true) && <LoadingDots color='white' />}
        </>
      </ButtonContainer>
      {isEqual(error, true) && <Text >Erreur lors de l&apos;upload: taille max. 1mo</Text>}
    </>
  )
}

export default ProgramDocumentForm
