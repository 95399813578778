export const HEADER_ANNONCES = [{
  label: 'ANNONCE.annonce'
}, {
  label: ''
}, {
  label: 'ANNONCE.user'
}, {
  label: 'ANNONCE.type'
}, {
  label: 'ANNONCE.price'
}, {
  label: 'ANNONCE.rooms'
}, {
  label: 'ANNONCE.payments'
}, {
  label: 'COMMON.action'
}]

export const BODY_ANNONCES = [{
  key: 'images',
  title: 'images'
}, {
  key: 'name',
  title: 'name'
}, {
  key: 'user',
  title: 'user'
}, {
  key: 'adType',
  title: 'type'
}, {
  key: 'price',
  title: 'price'
}, {
  key: 'max_rooms',
  title: 'rooms'
}, {
  key: 'payments',
  title: 'payments'
}, {
  key: 'action',
  title: 'action'
}]
