import { AxiosError } from 'axios'
import { get, omit, map, range, size } from 'lodash'
import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult, useQuery, UseQueryResult } from 'react-query'

import useClient from '../../hooks/useClient'
import { PageDepartments } from '../Departments/Departments.types'
import { Region, PageRegions, PageRegion } from './Regions.types'
import { buildQueryParams, getObjectById, getRelationships } from '../utils'

const transformPage = ({ data }: any): PageRegions => ({
  meta: get(data, 'meta'),
  data: map(get(data, 'data'), ({ id, attributes }) => ({
    id,
    name: get(attributes, 'name'),
    code: get(attributes, 'code')
  }))
})

const transformDepartmentPage = ({ data }: any): PageDepartments => ({
  meta: get(data, 'meta'),
  data: map(get(data, 'data'), ({ id, attributes, relationships }) => {
    const ships = getRelationships(relationships, ['region'], [])
    const included = get(data, 'included')

    return ({
      id,
      name: get(attributes, 'name'),
      code: get(attributes, 'code'),
      region: getObjectById(included, get(ships, 'region', 0), 'region', { id: 'id', name: 'name', code: 'code', region: 'region' }) as Region
    })
  })
})

export const useFetchInfiniteRegionsQuery = (search: any): UseInfiniteQueryResult<PageRegions, AxiosError> => {
  const client = useClient()

  return useInfiniteQuery(['fetchInfiniteRegions', search], async ({ pageParam = 1 }) => {
    return await client.get('/api/regions', {
      params: {
        'page[number]': pageParam,
        'page[size]': 21,
        ...buildQueryParams(omit(search, ['page']))
      }
    })
  }, {
    select: ({ pages }): InfiniteData<PageRegions> => ({
      pages: map(pages, transformPage),
      pageParams: range(size(pages) + 1)
    }),
    getNextPageParam: (_, allPages) => size(allPages) + 1,
    staleTime: 60 * 1000,
    refetchOnWindowFocus: false
  })
}

export const useFetchRegionQuery = (id: string): UseQueryResult<PageRegion, AxiosError> => {
  const client = useClient()

  return useQuery(['fetchRegion', id], async () => {
    return await client.get(`/api/regions/${id}`)
  }, {
    select: ({ data }): PageRegion => {
      const { id, attributes } = get(data, 'data')

      return {
        meta: 0,
        data: {
          id,
          code: get(attributes, 'code'),
          slug: get(attributes, 'slug'),
          name: get(attributes, 'name')
        }
      }
    },
    refetchOnWindowFocus: false
  })
}

export const useFetchRegionDepartmentsQuery = (id: string): UseInfiniteQueryResult<PageDepartments, AxiosError> => {
  const client = useClient()

  return useInfiniteQuery(['fetchInfiniteRegionDepartment', id], async ({ pageParam = 1 }) => {
    return await client.get(`/api/regions/${id}/departments`, {
      params: {
        'page[number]': pageParam,
        'page[size]': 9
      }
    })
  }, {
    select: ({ pages }): InfiniteData<PageDepartments> => ({
      pages: map(pages, transformDepartmentPage),
      pageParams: range(size(pages) + 1)
    }),
    getNextPageParam: (_, allPages) => size(allPages) + 1,
    staleTime: 60 * 1000,
    refetchOnWindowFocus: false
  })
}
