import styled from 'styled-components'
import { get, isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'

import TooltipTable from '../TooltipTable'
import { StatusProps, StatusColor } from './Status.types'

const Circle = styled.div<StatusColor>`
  width: 12px;
  height: 12px;
  background: ${({ theme, color }) => get(theme, color, '')};
  border-radius: 20px;
  position: relative;

  & > div {
   display: none;
  }

  &:hover > div {
   display: flex;
  }
`

const StyledTooltipTable = styled(TooltipTable)`
  z-index: 99999;
  left: -44px;
  top: 20px;

  &:before {
    top:-61%;
    left: 40%;
  }
`
const Status = ({ status, value, ...props }: StatusProps): JSX.Element => {
  const { t } = useTranslation()

  const color = isEqual(status, 'available') ? 'green' : 'orange'
  const textTooltip = isEqual(value, 'available') ? 'LOT.available' : 'LOT.unavailable'
  const colorTooltip = isEqual(value, 'available') ? 'lightSuccess' : 'lightDanger'

  return (
    <Circle {...props} color={color}>
      <StyledTooltipTable
        color={colorTooltip}>
        {t(textTooltip)}
      </StyledTooltipTable>
    </Circle>
  )
}

export default Status
