import styled from 'styled-components'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'

import Text from '../../atoms/Text'
import FlexBox from '../../atoms/FlexBox'

const StyledLink = styled.a`
  text-decoration: none;
`

const ClientData = ({ data }: any): JSX.Element => {
  const { t } = useTranslation()

  return (
    <FlexBox width100 alignStart gap={8}>
      <Text color='secondary' strong textType='subtitle'>
        {t('COMMON.contact')}
      </Text>
      <Text top={8} color='primary' textType='bigger' strong >
        {get(data, 'firstName')} {get(data, 'lastName')}
      </Text>
      <StyledLink href={`mailto:${get(data, 'email', '') as string}`}>
        <Text color='primary' textType='bigger'>
          {get(data, 'email')}
        </Text>
      </StyledLink>
      <StyledLink href={`tel:${get(data, 'phone', '') as string}`}>
        <Text color='primary' textType='bigger'>
          {get(data, 'phone')}
        </Text>
      </StyledLink>
    </FlexBox>
  )
}

export default ClientData
