import { filter, get, isEmpty, reduce } from 'lodash'

import Page from '../../atoms/Page'
import Table from '../../organisms/Table'
import FlexBox from '../../atoms/FlexBox'
import DetailList from '../../templates/DetailList'
import MaxContent from '../../atoms/MaxContent'
import ProgramRow from '../../molecules/ProgramRow'
import TableBottom from '../../molecules/TableBottom'
import DepartmentRow from '../../molecules/DepartmentRow'
import { RegionData } from '../../molecules/RegionData'
import { Department, PageDepartments } from '../../../queries/Departments/Departments.types'
import { useFetchInfiniteProgramsQuery } from '../../../queries/Programs'
import { BODY_PROGRAMS, HEADER_PROGRAMS } from '../../../utils/tables/programs'
import { BODY_DEPARTMENTS, HEADER_DEPARTMENTS } from '../../../utils/tables/departments'
import { useFetchRegionDepartmentsQuery, useFetchRegionQuery } from '../../../queries/Regions'

const DepartmentRegion = ({ data }: any): JSX.Element => {
  const { data: departments, isFetching, fetchNextPage } = useFetchRegionDepartmentsQuery(get(data, 'id'))

  const items = filter(reduce(get(departments, 'pages', []), (acc: Department[], page: PageDepartments): Department[] => [...acc, ...get(page, 'data', [])], []),
    department => !isEmpty(department))

  if (isEmpty(items)) {
    return (
      <></>
    )
  }

  const options = {
    bodyColor: 'extraLightBlue',
    headerColor: 'transparent',
    columnAlignment: '1fr 1fr 1fr'
  }

  return (
    <FlexBox width100>
      <Table
        row={DepartmentRow}
        data={items}
        body={BODY_DEPARTMENTS}
        header={HEADER_DEPARTMENTS}
        options={options} />
      <TableBottom
        loading={isFetching}
        fetchNextPage={fetchNextPage} />
    </FlexBox>
  )
}

const ProgramRegion = ({ data }: any): JSX.Element => {
  const { data: programs, isFetching, fetchNextPage } = useFetchInfiniteProgramsQuery({
    regions: [get(data, 'id')]
  })

  const options = {
    bodyColor: 'extraLightBlue',
    headerColor: 'transparent',
    columnAlignment: '8em 9.5em 0.6fr 0.8fr 0.8fr 0.8fr 0.4fr 0.6fr 0.5fr 1fr'
  }

  const displayData = reduce(get(programs, 'pages', []), (acc: any[], items: any[]): any[] =>
    !isEmpty(get(items, 'data')) ? [...acc, ...get(items, 'data', [])] : acc, [])

  if (isEmpty(displayData)) {
    return (
      <></>
    )
  }

  return (
    <FlexBox width100>
      <Table
        row={ProgramRow}
        data={displayData}
        body={BODY_PROGRAMS}
        header={HEADER_PROGRAMS}
        options={options}/>
      <TableBottom
        loading={isFetching}
        fetchNextPage={fetchNextPage}/>
    </FlexBox>
  )
}

const TABS = [{
  key: 'source',
  label: 'COMMON.data',
  component: RegionData
}, {
  key: 'departments',
  label: 'DEPARTMENTS.title',
  component: DepartmentRegion
}, {
  key: 'programs',
  label: 'COMMON.programs',
  component: ProgramRegion
}]

const Actions = (): JSX.Element => (
  <></>
)

const Region = (): JSX.Element => (
  <Page>
    <MaxContent>
      <DetailList
        tabs={TABS}
        type='region'
        actions={Actions}
        useFetch={useFetchRegionQuery}/>
    </MaxContent>
  </Page>
)

export default Region
