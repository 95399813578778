import qs from 'qs'
import { Variant } from '@folhomee/front-library'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { get, reduce, size, omit, identity } from 'lodash'

import Text from '../../atoms/Text'
import Table from '../../organisms/Table'
import Container from '../../atoms/Container'
import SourceRow from '../../molecules/SourceRow'
import SearchForm from '../../molecules/SearchForm'
import TableBottom from '../../molecules/TableBottom'
import DataContainer from '../../atoms/DataContainer'
import RightContainer from '../../atoms/RightContainer'
import { getMinMediaQuery } from '../../../utils/media'
import { SearchFieldsProps } from '../../molecules/SearchForm/SearchForm.types'
import { useFetchInfiniteSourceQuery } from '../../../queries/Sources'
import { PageSources, DataSource } from '../../../queries/Sources/Sources.types'

const SOURCES_SEARCH_FIELDS = ['name', 'broken']

const HEADER = [{
  label: 'COMMON.name',
  textLeft: true
}, {
  label: 'COMMON.slug',
  textLeft: true
}, {
  label: 'COMMON.urlPrescriber'
}, {
  label: 'CRAWLER.prescriberSite'
}, {
  label: 'CRAWLER.publicSite'
}, {
  label: 'COMMON.others'
}]

const BODY = [{
  key: 'name',
  title: 'name'
}, {
  key: 'slug',
  title: 'slug'
}, {
  key: 'urlPrescriber',
  title: 'urlPrescriber'
}, {
  key: 'crawlersPrescriber',
  title: 'crawlersPrescriber'
}, {
  key: 'crawlersPublic',
  title: 'crawlersPublic'
}, {
  key: 'crawlersOtaree',
  title: 'crawlersOtaree'
}]

const SEARCH_FIELDS = [{
  icon: 'program' as Variant,
  title: 'COMMON.name',
  moreSearch: true,
  fields: [{
    key: 'name',
    placeholder: 'COMMON.name'
  }]
}, {
  icon: 'build' as Variant,
  title: 'COMMON.status',
  moreSearch: true,
  fields: [{
    key: 'broken',
    type: 'checkbox',
    placeholder: 'CRAWLER.broken'
  }]
}]

const Sources = (): JSX.Element => {
  const { t } = useTranslation()
  const [searchFields] = useState<SearchFieldsProps[]>(SEARCH_FIELDS)
  const [search, updateSearch] = useState<any>({})
  const [bigScreen, updatebigScreen] = useState<boolean>(getMinMediaQuery('xl').matches)
  const [searchBuild, updateSearchBuild] = useState(true)
  const [searchParams, updateSearchParams] = useSearchParams()
  const { data, isFetching, fetchNextPage } = useFetchInfiniteSourceQuery(search)
  const options = {
    bodyColor: 'extraLightBlue',
    headerColor: 'transparent',
    columnAlignment: '1fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr'
  }

  useEffect(() => {
    const mql = getMinMediaQuery('xl')
    const resize = (event: MediaQueryListEvent): void => updatebigScreen(event.matches)

    mql.addEventListener('change', resize)
    return () => {
      mql.removeEventListener('change', resize)
    }
  }, [])

  useEffect(() => {
    const querySearch = qs.parse(searchParams.toString())

    updateSearch(reduce(omit(querySearch, ['page']), (acc, value, key) => ({
      ...acc,
      [key]: value
    }), {}))
    updateSearchBuild(false)
  }, [searchParams])

  const sources = reduce(get(data, 'pages', []), (acc: DataSource[], page: PageSources): DataSource[] => [...acc, ...get(page, 'data', [])], [])

  return (
    <Container>
      <SearchForm
        build={searchBuild}
        search={search}
        fields={searchFields}
        sources={sources}
        visible={true}
        searchFields={SOURCES_SEARCH_FIELDS}
        formatSearch={identity}
        updateSearch={updateSearchParams}
        updateShowSearch={() => { }}
        updateSearchBuild={updateSearchBuild} />
      <RightContainer>
        <DataContainer>
          <Text textType='title' strong>
            {t('SOURCES.sourcesTitle', { count: get(data, 'pages.0.meta.total', 0) })}
          </Text>
          <Table
            row={SourceRow}
            data={sources}
            body={BODY}
            header={HEADER}
            options={options}
            bigScreen={bigScreen} />
          {size(sources) > 20 && <TableBottom
            loading={isFetching}
            fetchNextPage={fetchNextPage} />}
        </DataContainer>
      </RightContainer>
    </Container>
  )
}

export default Sources
