import { get } from 'lodash'
import { useMutation, useQueryClient } from 'react-query'

import Form from '../../templates/Form'
import useClient from '../../../hooks/useClient'

const FIELDS = [{
  key: 'name',
  placeholder: 'COMMON.name'
}, {
  key: 'slug',
  placeholder: 'COMMON.slug'
}, {
  key: 'insee',
  placeholder: 'CITIES.insee'
}, {
  key: 'zipcode',
  placeholder: 'CITIES.zipcode'
}, {
  key: 'min_price',
  placeholder: 'COMMON.minPrice'
}, {
  key: 'image_type',
  type: 'options',
  options: [{
    label: 'COMMON.default',
    value: 'default'
  }, {
    label: 'COMMON.mountain',
    value: 'mountain'
  }],
  placeholder: 'CITIES.imageType'
}, {
  key: 'acquisition',
  type: 'checkbox',
  placeholder: 'CITIES.acquisition'
}]

const CityData = ({ data }: any): JSX.Element => {
  const client = useClient()
  const queryClient = useQueryClient()

  const updateMutation = useMutation(async (values) => await client.patch(`/api/cities/${get(data, 'id', '') as string}`, {
    city: {
      name: get(values, 'name'),
      slug: get(values, 'slug'),
      insee: get(values, 'insee'),
      zipcode: get(values, 'zipcode'),
      min_price: get(values, 'min_price'),
      image_type: get(values, 'image_type'),
      acquisition: get(values, 'acquisition')
    }
  }), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['fetchCity', get(data, 'id')])
      await queryClient.invalidateQueries(['fetchInfiniteCities'])
    }
  })

  return (
    <Form
      data={data}
      fields={FIELDS}
      submit={updateMutation} />
  )
}

export default CityData
