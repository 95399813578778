import styled from 'styled-components'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import { get, round, first, isEqual, isEmpty, map, isUndefined } from 'lodash'
import { media, Text, Button, Input, spacings, Picto, Error, Checkbox } from '@folhomee/front-library'

import Page from '../../atoms/Page'
import Card from '../../atoms/Card'
import Title from '../../atoms/Text'
import numeral from '../../../services/numeral'
import InlineTitle from '../../atoms/InlineTitle'
import { useFetchRates } from '../../../queries/Clients'
import { ButtonTabProps, CheckboxProps } from '../Calculs/Calculs.types'

const TABS = [{
  title: 'monthly',
  key: 'amountLoan'
}, {
  title: 'borrowingCapacity',
  key: 'desiredMonthly'
}]

const CALCUL_FORM = [{
  key: 'amountLoan',
  type: 'price'
}, {
  key: 'desiredMonthly',
  type: 'price'
}, {
  key: 'term',
  type: 'checkbox'
}, {
  key: 'interestRate',
  type: 'percent'
}, {
  key: 'insuranceRate',
  type: 'percent'
}]

const Container = styled.div`
  height: 100vh;
  padding: 48px 120px;
  object-fit: contain;

  ${media.lessThan('lg')`
    padding: 48px 5%;
  `} 
`

const ContentCard = styled.div`
  gap: ${get(spacings, 's')}px;
  display: flex;
  margin: ${get(spacings, 's')}px auto 0;

  ${media.lessThan('lg')`
    flex-direction: column;
    padding: 0 10%;
  `} 

  ${media.lessThan('md')`
    padding: 0 5%;
  `} 
`

const ButtonView = styled.div`
  display: flex;
  background: ${({ theme }) => get(theme, 'lightBlue', '#FFF')};
  border-radius: 25px;
  height: fit-content;
`

const ButtonTab = styled(Button) <ButtonTabProps>`
  display: flex;
  padding: 8px 16px;
  gap: 4px;
  border: none;
  background: ${({ theme, selected }) => isEqual(selected, true) ? `${get(theme, 'blue', '#FFF') as string}` : 'transparent'};
  transition: all 0.2s ease-out;
  & p {
    color: ${({ theme, selected }) => isEqual(selected, true) ? `${get(theme, 'white', '#FFF') as string}` : `${get(theme, 'blue', '#FFF') as string}`};
  }
`

const StyledCard = styled(Card)`
  width 50%;
  padding-bottom: ${get(spacings, 'm')}px;

  ${media.lessThan('lg')`
    width: auto;
  `} 
`

const CardMensuality = styled(StyledCard)`
  border: 4px solid ${({ theme }) => get(theme, 'lightOrange', '#FFF')};
  width: auto;
  flex-grow: 1;
`

const StyledInlineTitle = styled(InlineTitle)`
  display: flex;
  padding: 0;
  align-items: center;
  height: fit-content;

  & svg {
    transform: scale(0.7) translate(-22px, -22px);
    width: 50px;
    height: 40px;
    overflow: visible;
  }
`

const TitleBlock = styled(Title)`
  font-size: 20px;
  margin: 0;

  & svg {
    transform: scale(2);
  }
`

const ContentLine = styled.div`
  display: flex;
  width: 100%;
  gap: ${get(spacings, 'xxs')}px;
  align-items: center;
`

const TitleInput = styled(Text)`
  width: auto;
  max-width: 60%;
`

const Line = styled.hr`
  border: none;
  border-bottom: 1.5px dotted ${({ theme }) => get(theme, 'darkGrey', '#FFF')};
  flex-grow: 1;
`

const StyledInput = styled(Input)`
  width: 35%;
`

const InputChangeable = styled(StyledInput)`
  border-color: ${({ theme }) => get(theme, 'blue', '#FFF')};
  color: ${({ theme }) => get(theme, 'blue', '#FFF')};

  &::placeholder {
    color: ${({ theme }) => get(theme, 'blue', '#FFF')};
  }
`

const StyledCheckbox = styled(Checkbox) <CheckboxProps>`
  cursor: pointer;
  font-family: SourceSansPro;
  color: ${({ isClicked, theme }) => get(theme, isEqual(isClicked, true) ? 'white' : 'blue')};
  background: ${({ isClicked, theme }) => get(theme, isEqual(isClicked, true) ? 'blue' : 'transparent')};
  border-radius: 5px;
`

const MonthlyValue = styled(Text)`
  text-align: center;
  width: 100%;
  line-height: normal;

  & span:first-of-type{
      font-size: 60px;
      color: ${({ theme }) => get(theme, 'lightOrange', '#FFF')};
      margin-right: 4px;
    }

    & span:last-of-type{
      font-size: 32px;
      color: ${({ theme }) => get(theme, 'blue', '#FFF')};
    }
`

const TextMonthly = styled(Text)`
  & span:first-of-type{
      font-size: 24px;
      color: ${({ theme }) => get(theme, 'lightOrange', '#FFF')};
      margin-right: 4px;
  }

  & span:last-of-type{
      font-size: 18px;
      color: ${({ theme }) => get(theme, 'blue', '#FFF')};
  }           
`

const Calculs = (): JSX.Element => {
  const { t } = useTranslation()
  const [requestError] = useState('')
  const [selected, updateSelected] = useState<string>(get(first(TABS), 'key', 'amountLoan') ?? 'amountLoan')
  const { data } = useFetchRates()
  const [totalCredit, updateTotalCredit] = useState(0)
  const [totalInsurance, updateTotalInsurance] = useState(0)
  const [monthPayment, updateMonthPayment] = useState(0)
  const [monthInsurance, updateMonthInsurance] = useState(0)
  const [totalLoan, updateTotalLoan] = useState(0)

  const { values, handleChange, setFieldValue } = useFormik({
    initialValues: {
      amountLoan: 0,
      desiredMonthly: 0,
      term: 7,
      interestRate: first(data) ?? 3.4,
      insuranceRate: 0.34
    },
    onSubmit: () => { }
  })

  useEffect(() => {
    const annualInterest = get(values, 'interestRate', 0) / 100
    const annualInsurance = get(values, 'insuranceRate', 0) / 100
    const monthInterest = annualInterest / 12
    const monthInsurance = annualInsurance / 12
    const months = get(values, 'term') * 12

    if (isEqual(selected, 'amountLoan')) {
      const amountLoan = get(values, 'amountLoan')

      const monthLoan = amountLoan * monthInterest / (1 - Math.pow(1 + monthInterest, -months))
      const insurancePayment = amountLoan * monthInsurance
      const monthPayment = round((monthLoan + insurancePayment), 0)

      updateMonthPayment(monthPayment)
      updateMonthInsurance(round(insurancePayment, 0))
      updateTotalInsurance(round(insurancePayment * months, 0))
      updateTotalCredit(round(monthPayment * months - amountLoan, 0))
    } else {
      const desiredPayment = get(values, 'desiredMonthly')
      let amountLoan = (desiredPayment - (desiredPayment * monthInsurance)) * (1 - Math.pow(1 + monthInterest, -months)) / monthInterest

      let insurancePayment = amountLoan * monthInsurance
      const totalMonthlyPayment = desiredPayment
      let totalInsurance = insurancePayment * months
      let totalCredit = totalMonthlyPayment * months - amountLoan

      for (let i = 0; i < 10; i++) {
        amountLoan = (totalMonthlyPayment - totalInsurance / months) * (1 - Math.pow(1 + monthInterest, -months)) / monthInterest
        insurancePayment = amountLoan * monthInsurance
        totalInsurance = insurancePayment * months
        totalCredit = totalMonthlyPayment * months - amountLoan
      }

      updateTotalInsurance(round(totalInsurance, 0))
      updateTotalCredit(round(totalCredit, 0))
      updateTotalLoan(round(totalMonthlyPayment * months - totalCredit, 0))
    }
  }, [values])

  return (
    <Page>
      <Container>
        <div>
          <InlineTitle>
            <ButtonView>
              {map(TABS, ({ key, title }) => {
                return (
                  <ButtonTab
                    variant='primary'
                    selected={isEqual(key, selected)}
                    importance='small'
                    onClick={() => updateSelected(key)}>
                    <Text
                      type='regular'
                      strong={true}
                      variant='primary'>
                      {t<string>(`CALCULS.${title}`)}</Text>
                  </ButtonTab>
                )
              })}
            </ButtonView>
          </InlineTitle>
          <ContentCard>
            <StyledCard>
              <StyledInlineTitle>
                <Picto variant='new' />
                <TitleBlock
                  textType='title'
                  color='primary'
                  strong={true}>
                  <span>{t('CALCULS.project')}</span>
                </TitleBlock>
              </StyledInlineTitle>
              {map(CALCUL_FORM, ({ key, type }) => {
                if (isEqual(type, 'price') && !isEqual(selected, key)) {
                  return null
                }

                if (isEqual(key, 'term')) {
                  return (
                    <ContentLine>
                      <TitleInput type='subtitle'>
                        {t<string>(`CALCULS.${key}`)}
                      </TitleInput>
                      <Line></Line>
                      {map([7, 10, 15, 20, 25], (duration, idx) => {
                        return (
                          <StyledCheckbox
                            name={key}
                            value={duration.toString()}
                            label={duration.toString()}
                            bordered={true}
                            onChange={() => { }}
                            importance='primary'
                            checkboxSize='large'
                            onClick={async () => {
                              await setFieldValue('interestRate', isUndefined(data) ? 0 : data[idx])
                              await setFieldValue(key, duration)
                            }}
                            isClicked={isEqual(get(values, key), duration)} />
                        )
                      })}
                    </ContentLine>
                  )
                }

                return (
                  <ContentLine>
                    <TitleInput type='subtitle'>
                      {t<string>(`CALCULS.${key}`)}
                    </TitleInput>
                    <Line></Line>
                    <Error
                      show={!isEmpty(requestError)}
                      label={t(requestError)} />
                    <InputChangeable
                      min='0'
                      type='number'
                      value={get(values, key, '')}
                      name={key}
                      squared={true}
                      bordered={true}
                      onChange={handleChange}
                      inputSize='small'
                      importance={isEqual(type, 'percent') ? 'secondary' : 'primary'}
                      placeholder='Donnée à entrer' />
                  </ContentLine>
                )
              })}
            </StyledCard>
            <CardMensuality>
              <StyledInlineTitle>
                <Picto variant='purse' />
                <TitleBlock
                  color='primary'
                  textType='title'
                  strong={true}>
                  <span>{t(`CALCULS.${selected}Result`)}</span>
                </TitleBlock>
              </StyledInlineTitle>
              {isEqual(selected, 'amountLoan') && <ContentLine>
                <MonthlyValue type='subtitle'>
                  <span>{numeral(monthPayment).format('0,0')}</span>
                  <span>{t<string>('CALCULS.pricePerMonth')}</span>
                </MonthlyValue>
              </ContentLine>}
              {isEqual(selected, 'desiredMonthly') && <ContentLine>
                <MonthlyValue type='subtitle'>
                  <span>{numeral(totalLoan).format('0,0')}</span>
                  <span>{t<string>('COMMON.euroSign')}</span>
                </MonthlyValue>
              </ContentLine>}
              {isEqual(selected, 'amountLoan') &&
                <ContentLine>
                  <TitleInput type='subtitle'>
                    {t<string>('CALCULS.includingInsurance')}
                  </TitleInput>
                  <Line></Line>
                  <Error
                    show={!isEmpty(requestError)}
                    label={t(requestError)} />
                  <TextMonthly type='subtitle'>
                    <span>{numeral(monthInsurance).format('0,0')}</span>
                    <span>{t<string>('CALCULS.pricePerMonth')}</span>
                  </TextMonthly>
                </ContentLine>}
              <ContentLine>
                <TitleInput type='subtitle'>
                  {t<string>('CALCULS.priceTotal')}
                </TitleInput>
                <Line></Line>
                <Error
                  show={!isEmpty(requestError)}
                  label={t(requestError)} />
                <TextMonthly type='subtitle'>
                  <span>{numeral(totalCredit).format('0,0')}</span>
                  <span>{t<string>('COMMON.euroSign')}</span>
                </TextMonthly>
              </ContentLine>
              <ContentLine>
                <TitleInput type='subtitle'>
                  {t<string>('CALCULS.priceTotalInsurance')}
                </TitleInput>
                <Line></Line>
                <Error
                  show={!isEmpty(requestError)}
                  label={t(requestError)} />
                <TextMonthly type='subtitle'>
                  <span>{numeral(totalInsurance).format('0,0')}</span>
                  <span>{t<string>('COMMON.euroSign')}</span>
                </TextMonthly>
              </ContentLine>
            </CardMensuality>
          </ContentCard>
        </div>
      </Container>
    </Page>
  )
}

export default Calculs
