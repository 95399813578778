import styled from 'styled-components'
import { useRecoilValue } from 'recoil'
import { isEmpty, get, isEqual } from 'lodash'
import { useLocation, Navigate, useSearchParams } from 'react-router-dom'

import Navbar from '../../organisms/Navbar'
import { AuthPageProps } from './AuthPage.types'
import { userTokenState } from '../../../recoil/atoms'

const Container = styled.div`
  height: 100vh;
`

const AuthPage = ({ loginRequired, children }: AuthPageProps): JSX.Element => {
  const location = useLocation()
  const userToken = useRecoilValue(userTokenState)
  const [searchParams] = useSearchParams()

  if (loginRequired && isEmpty(userToken)) {
    const connexionURL = isEqual(get(location, 'pathname', '/'), '/') ? '/connexion' : `/connexion?previous=${get(location, 'pathname', '')}`

    return (
      <Navigate to={connexionURL} state={{ from: location }} />
    )
  }

  if (!loginRequired && !isEmpty(userToken)) {
    const previous = searchParams.get('previous')

    if (!isEmpty(previous)) {
      return (
        <Navigate to={previous as string} />
      )
    }

    return (
      <Navigate to='/' state={{ from: location }} />
    )
  }

  if (loginRequired) {
    return (
      <>
        <Navbar />
        <Container>
          {children}
        </Container>
      </>
    )
  }

  return (
    <>
      {children}
    </>
  )
}

export default AuthPage
