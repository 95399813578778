import styled from 'styled-components'
import { DateTime } from 'luxon'
import { spacings } from '@folhomee/front-library'
import { useTranslation } from 'react-i18next'
import { useCallback, useRef, useState } from 'react'
import { first, includes, isArray, isEmpty, isEqual, isUndefined, join, map, size, get, filter, uniqBy, last } from 'lodash'

import Data from '../../molecules/Data'
import Icon from '../Icon'
import Text from '../Text'
import Status from '../../atoms/Status'
import FlexBox from '../FlexBox'
import numeral from '../../../services/numeral'
import { useOnClickOutside } from '../../../hooks/useOnClickOutside'
import { StyledFieldProps, FieldProps, LinkFieldProps, LinksModalProps } from './Field.types'

export const Content = styled.div<StyledFieldProps>`
  display: flex;
  position: relative;
  padding-left: ${({ left }) => isEqual(left, true) ? '8px' : 0};
  margin-bottom: ${({ bottom }) => isUndefined(bottom) ? '' : bottom}px;
  justify-content: ${({ left }) => isEqual(left, true) ? 'start' : 'center'};
`

export const StyledVat = styled(FlexBox)`
  width: 52px;
  border: 1px solid ${({ theme }) => get(theme, 'blue', 'white')};
  border-radius: 3px;
`

const ModalContainer = styled.div`
  gap: ${get(spacings, 'xs')}px;
  top: -21px;
  right: 30px;
  display: flex;
  padding: ${get(spacings, 'xxs')}px;
  z-index: 9999;
  position: absolute;
  box-shadow: 0 1px 4px 0 rgba(51,102,204,0.30);
  font-family: SourceSansPro;
  font-weight: 600;
  border-radius: 10px;
  flex-direction: column;
  background-color: #e7eefd;

  & > a {
    width: max-content;
    text-align: left;
    font-family: 'SourceSansPro';
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => get(theme, 'orange', '#fff')};
    }
  }

  &::after {
    top: 32%;
    left: 100%;
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    border-top: 10px solid transparent;
    border-left: 10px solid #e7eefdd4;
    border-bottom: 10px solid transparent;
  }
`

const useFormatValue = (name: string, value: any): string => {
  const { t } = useTranslation()

  if (includes(['floor', 'maxFloor'], name)) {
    return t('LOT.floorLot', { count: value, context: `${value as string}` })
  }

  if ((isArray(value) && isEmpty(value)) || isEqual(value, 0)) {
    return t('COMMON.none')
  }

  if (includes(['minPrice', 'meterPrice'], name)) {
    const price = numeral(value).format('0,0$')

    if (isEqual(name, 'meterPrice')) {
      return t('LOT.meterPrice', { price })
    }

    return price
  }

  if (includes(['surface', 'surfaceExt'], name)) {
    return t('LOT.meterSurface', { value })
  }

  if (includes(['taxations', 'exposures'], name)) {
    return join(value, ', ')
  }

  if (isEqual('actability', name)) {
    return isEqual(value, true) ? t('COMMON.yes') : t('COMMON.none')
  }

  if (isEqual('createdAt', name)) {
    return DateTime.fromISO(value).setLocale('fr').toLocaleString()
  }

  return value
}

const LinksModal = ({ urls }: LinksModalProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <ModalContainer>
      {map(uniqBy(urls, 'url'), ({ url, crawlType, sourceName }) => (
        <a
          rel='noreferrer'
          href={url}
          target='_blank'>
          {t<string>(`COMMON.${crawlType}`, { source: sourceName })}
        </a>
      ))}
    </ModalContainer>
  )
}

const LinkField = ({ urls }: LinkFieldProps): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [showModal, updateShowModal] = useState<boolean>(false)

  const toggleModal = useCallback(() => updateShowModal(!showModal), [showModal, updateShowModal])
  const onClickHandler = useCallback(() => {
    toggleModal()
  }, [toggleModal])
  useOnClickOutside(containerRef, () => {
    if (showModal) {
      toggleModal()
    }
  })

  if (isEqual(size(urls), 1)) {
    return (
      <Content>
        <a target='_blank' href={get(first(urls), 'url')} rel='noreferrer'>
          <Icon variant='extern-link' stroke cursor />
        </a>
      </Content>
    )
  }

  return (
    <Content
      ref={containerRef}
      onClick={onClickHandler}>
      <Icon cursor stroke variant='extern-link' />
      {showModal && <LinksModal urls={urls} />}
    </Content>
  )
}

const Field = ({ name, value, title, left }: FieldProps): JSX.Element => {
  const { t } = useTranslation()
  const formatValue = useFormatValue(name, value)

  if (isEqual(name, 'url')) {
    return (
      <LinkField
        urls={filter(value, ({ url }) => !isEmpty(url))} />
    )
  }

  if (isEqual(name, 'meterPrice') && !isEqual(size(value), 1)) {
    return (
      <Data column={true} noPadding={true}>
        <Content bottom={4}>
          <Text capitalize='lowercase'>
            {t('LOT.meterPrice', { price: numeral(last(value)).format('0,0$') })}
          </Text>
        </Content>
        <Content left={isEqual(left, true)}>
          <Text capitalize='lowercase'>
            {t('LOT.meterPrice', { price: numeral(first(value)).format('0,0$') })}
          </Text>
        </Content>
      </Data>
    )
  }

  if ((isEqual(name, 'prices') || isEqual(name, 'priceRange.minPrice')) && isEqual(isUndefined(value), false)) {
    const price = numeral(value[0] ?? 0).format('0,0$')

    if (isEqual(size(value), 1)) {
      return (
        <Content left={isEqual(left, true)}>
          <Text
            color='primary'
            capitalize='lowercase'
            strong >
            {price}
          </Text>
        </Content>
      )
    }

    const fullPrice = numeral(value[1]).format('0,0$')
    return (
      <FlexBox gap={4}>
        <FlexBox row gap={4}>
          <StyledVat>
            <Text color='primary'
              textType='smallest'
              textAlign='center'
              center>
              {t('PROGRAM.vatFull')}
            </Text>
          </StyledVat>
          <Text
            color='primary'
            strong >
            {fullPrice}
          </Text>
        </FlexBox>
        <FlexBox row gap={4}>
          <StyledVat>
            <Text
              color='primary'
              textType='smallest'
              textAlign='center'
              center>
              {t('PROGRAM.vatReduced')}
            </Text>
          </StyledVat>
          <Text
            color='primary'
            strong>
            {price}
          </Text>
        </FlexBox>
      </FlexBox>
    )
  }

  if (isEqual(name, 'role')) {
    return (
      <Icon stroke transform='scale(0.9)' variant={isEqual(value, 'admin') ? 'valid' : 'close'} />
    )
  }

  if (isEqual(name, 'isPublic')) {
    const prescriberUrls = filter(value, ({ crawlType }) => isEqual(crawlType, 'prescriber') || isEqual(crawlType, 'otaree'))
    const publicUrls = filter(value, ({ crawlType }) => isEqual(crawlType, 'public'))

    const isPublic = !isEqual(size(publicUrls), 0) && isEqual(size(prescriberUrls), 0)

    return (
      <Content>
        {isPublic && <Icon fill variant='lock-solid' color='secondary' />}
      </Content>
    )
  }

  if (isEqual(name, 'typeLots')) {
    return (
      <Data>
        <Text center >
          {formatValue}
        </Text>
      </Data>
    )
  }

  if (isEqual(name, 'status')) {
    return (
      <Data noPadding={true} >
        <Status status={value} value={value} />
      </Data>
    )
  }

  return (
    <Content>
      {!isUndefined(title) && <Text strong>
        {t(title)}
      </Text>}
      <Text strong={includes(['minPrice'], name)}>
        {formatValue}
      </Text>
    </Content>
  )
}

export default Field
