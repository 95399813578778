import { get, map } from 'lodash'
import { AxiosError } from 'axios'
import { useQuery, UseQueryResult } from 'react-query'

import useClient from '../../hooks/useClient'
import { Data } from '../utils/jsonapi.types'

export const useFetchTaxationsQuery = (): UseQueryResult<Data[], AxiosError> => {
  const client = useClient()
  return useQuery(['fetchTaxations'], async () => await client.get('/api/taxations'), {
    select: ({ data }): Data[] => {
      return map(get(data, 'data'), ({ attributes }) => ({
        label: get(attributes, 'name'),
        value: get(attributes, 'id')
      }))
    },
    staleTime: 1000 * 60 * 60 * 24,
    refetchOnWindowFocus: false
  })
}
