import styled from 'styled-components'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useCallback, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { get, map, isEqual, isUndefined, includes, filter, toString, startsWith } from 'lodash'

import Row from '../../atoms/Row'
import Data from '../Data'
import Icon from '../../atoms/Icon'
import Text from '../../atoms/Text'
import Field from '../../atoms/Field'
import Modal from '../../organisms/Modal'
import Loader from '../LoaderDot'
import useClient from '../../../hooks/useClient'
import ButtonRound from '../../atoms/ButtonRound'
import ContainerRow from '../../atoms/ContainerRow'
import ButtonContainer from '../../atoms/ButtonContainer'
import DocumentTypeCheckbox from '../../molecules/DocumentTypeCheckbox'
import { useFetchDocumentTypesQuery } from '../../../queries/Documents'
import { CrawlerRowProps, CrawlerNameText } from './CrawlerRow.types'

const StyledText = styled(Text) <CrawlerNameText>`
  gap: 4px;
  display: flex;
  justify-content: center;
  ${({ broken, theme }) => isEqual(broken, true) ? `color: ${get(theme, 'orange', '#FFF') as string};` : ''}
`

const Link = styled.a`
  text-decoration: none;
`

const DocumentTypesContainer = styled.div`
  width: 440px;
  height: 220px;
  border: 1px solid ${({ theme }) => get(theme, 'blue')};
  resize: none;
  display: flex;
  overflow-y: scroll;
  border-radius: 10px;
  flex-direction: column;
`

const Warning = ({ broken }: any): JSX.Element | null => {
  if (isEqual(broken, true)) {
    return (
      <Icon variant='warning' stroke />
    )
  }

  return null
}

const CrawlerRow = ({ data, fields, options }: CrawlerRowProps): JSX.Element => {
  const { t } = useTranslation()
  const client = useClient()
  const queryClient = useQueryClient()
  const [handleEdit, updateHandleEdit] = useState<boolean>(false)
  const [selectedDocs, updateSelectedDocs] = useState<string[]>(get(data, 'docTypes', []))
  const { data: documentTypes, isFetching: isFetchingDocumentTypes } = useFetchDocumentTypesQuery()

  const updateMutation = useMutation(async (id: number) => await client.patch(`/api/crawlers/${id}}`, {
    status: isEqual(get(data, 'status'), false)
  }), {
    onSuccess: async () => {
      await queryClient.invalidateQueries('fetchInfiniteCrawlers')
    }
  })

  const handleStatus = useCallback(async () => {
    if (window.confirm(t(isEqual(get(data, 'status'), true) ? 'CRAWLER.confirmPause' : 'CRAWLER.confirmUnpause', { title: get(data, 'name') }))) {
      await updateMutation.mutateAsync(get(data, 'id'))
    }
  }, [data, updateMutation])

  const updateDocumentMutation = useMutation(async (id: number) => await client.put(`/api/crawlers/${id}/documents`, {
    documents: selectedDocs
  }), {
    onSuccess: async () => {
      await queryClient.invalidateQueries('fetchInfiniteCrawlers')
    }
  })

  const onSubmit = useCallback(async () => {
    await updateDocumentMutation.mutateAsync(get(data, 'id'))

    updateHandleEdit(false)
  }, [updateDocumentMutation])

  const { handleSubmit } = useFormik({
    initialValues: get(data, 'docTypes', []),
    onSubmit
  })

  const setFieldValueHandler = useCallback(async (docId: string) => {
    if (!includes(selectedDocs, docId)) {
      updateSelectedDocs([...selectedDocs, docId])
    } else {
      updateSelectedDocs(filter(selectedDocs, (doc) => doc !== docId))
    }
  }, [selectedDocs])

  const updateCrawlerDocs = useCallback(() => {
    handleSubmit()
  }, [handleSubmit])

  const onCancelEdit = (): void => updateHandleEdit(false)

  return (
    <ContainerRow options={options}>
      <Row options={options}>{map(fields, ({ key, title }) => {
        const componentKey = `${key}-${isUndefined(title) ? '' : title}`

        if (isEqual(key, 'name')) {
          return (
            <Data key={componentKey}>
              <Link target='_blank' href={get(data, 'url')}>
                <StyledText color='primary' strong broken={get(data, 'broken')}>
                  <Warning broken={get(data, 'broken', false)} /> {get(data, key)}
                </StyledText>
              </Link>
            </Data>
          )
        }

        if (startsWith(key, 'type')) {
          if (isEqual(title, get(data, key))) {
            return (
              <Data key={componentKey}>
                <Icon variant='valid' stroke color='secondary' />
              </Data>
            )
          }

          return (
            <Data key={componentKey}>
              <Text>-</Text>
            </Data>

          )
        }

        if (isEqual(key, 'actions')) {
          return (
            <>
              <Modal isOpen={handleEdit} onRequestClose={onCancelEdit}>
                <>
                  <StyledText capitalize='none' strong >{t<string>('CRAWLER.selectDocuments')}</StyledText>
                  <DocumentTypesContainer>
                    {map(documentTypes, (docType) => (
                      <DocumentTypeCheckbox
                        key={get(docType, 'value')}
                        isChecked={includes(get(data, 'docTypes'), toString(get(docType, 'value')))}
                        documentType={docType}
                        setFieldValue={setFieldValueHandler} />
                    ))}
                  </DocumentTypesContainer>
                  <ButtonContainer
                    buttonSize='large'
                    color='primary'
                    onClick={updateCrawlerDocs}>
                    {t<string>('COMMON.register')}
                  </ButtonContainer>
                  <Loader loading={isFetchingDocumentTypes} />
                </>
              </Modal>
              <Data key={componentKey}>
                <ButtonRound
                  color='transparent'
                  buttonSize='tiny'
                  onClick={handleStatus} >
                  <Icon
                    color='primary'
                    variant={isEqual(get(data, 'status'), true) ? 'pause-solid' : 'play-solid'}
                    transform='scale(1.4)'
                    fill />
                </ButtonRound>
                <ButtonRound
                  color='warning'
                  buttonSize='tiny'
                  onClick={() => updateHandleEdit(true)} >
                  <Icon
                    color='white'
                    variant='edit-solid'
                    transform='scale(0.9)'
                    fill />
                </ButtonRound>
              </Data >
            </>
          )
        }

        return (
          <Data key={componentKey}>
            <Field
              key={key}
              name={key}
              value={get(data, key)} />
          </Data>
        )
      })}
      </Row>
    </ContainerRow >
  )
}
export default CrawlerRow
