import styled from 'styled-components'
import { spacings } from '@folhomee/front-library'
import { useTranslation } from 'react-i18next'
import { get, map } from 'lodash'

import DisplayField from '../../molecules/DisplayField'
import { UserInfoProps } from './UserInfo.types'

const Container = styled.div`
  gap: ${get(spacings, 'xs')}px;
  display: flex;
  padding: ${get(spacings, 's')}px;
  flex-direction: column;
`

const UserInfo = ({ user }: UserInfoProps): JSX.Element => {
  const { t } = useTranslation()
  const fields = [{
    title: 'COMMON.photo',
    type: 'image',
    value: get(user, 'photo', '')
  }, {
    title: 'COMMON.email',
    type: 'text',
    value: get(user, 'email', '')
  }, {
    title: 'USER.slackId',
    type: 'text',
    value: get(user, 'slackId', '')
  }, {
    title: 'USER.pipedriveId',
    type: 'text',
    value: get(user, 'pipedriveId', '')
  }]

  return (
    <Container>
      {map(fields, ({ title, type, value }) => (
        <DisplayField
          title={t<string>(title)}
          type={type}
          value={value} />
      ))}
    </Container>
  )
}

export default UserInfo
