import useAsyncEffect from 'use-async-effect'
import { useRef } from 'react'
import { isEqual } from 'lodash'

import LoaderDot from '../LoaderDot'
import useIntersection from '../../../hooks/useIntersection'
import { TableBottomProps, LoaderProps } from './TableBottom.types'

const Loader = ({ loading }: LoaderProps): JSX.Element | null => {
  if (isEqual(loading, true)) {
    return (
      <LoaderDot loading={true}/>
    )
  }

  return null
}

const TableBottom = ({ loading, fetchNextPage, ...props }: TableBottomProps): JSX.Element => {
  const ref = useRef(null)

  const inViewport = useIntersection(ref, `${document.documentElement.offsetHeight / 4}px`)

  useAsyncEffect(async () => {
    if (inViewport) {
      await fetchNextPage()
    }
  }, [inViewport])

  return (
    <div ref={ref} {...props}>
      <Loader loading={loading} />
    </div>
  )
}

export default TableBottom
