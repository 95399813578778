import axios, { AxiosInstance } from 'axios'
import { useRef } from 'react'
import { isEmpty } from 'lodash'
import { useRecoilValue } from 'recoil'

import { BASE_URL } from '../config'
import { userTokenState } from '../recoil/atoms'

const useClient = (): AxiosInstance => {
  const userToken = useRecoilValue(userTokenState)

  const instance = useRef(axios.create({
    baseURL: BASE_URL,
    timeout: 30000,
    headers: {
      ...(isEmpty(userToken) ? {} : { Authorization: `Bearer ${userToken}` })
    }
  }))

  return instance.current
}

export default useClient
