import { map } from 'lodash'

import { TableBodyProps } from './TableBody.types'

const Body = <T extends unknown>({ row: Row, data, meta, actions, options, fields }: TableBodyProps<T>): JSX.Element => (
  <>
    {map(data, (elt, key) => (
      <Row
        key={key}
        data={elt}
        meta={meta}
        fields={fields}
        actions={actions}
        options={options} />
    ))}
  </>
)

export default Body
