import { map, get, isEqual, reduce, uniq, filter, isEmpty, last, first, capitalize } from 'lodash'

import { Lot } from '../Lots/lots.types'
import { getTypology } from './lotFormat'
import { getObjectByIds } from '.'
import { getQuarterByMonth } from './date'
import { ExternalReference } from './query.types'

export const getUrls = (included: any, ships: any): ExternalReference[] => {
  const urls = getObjectByIds(included, get(ships, 'external_references'), 'external_reference', { url: 'url', crawlType: 'crawl_type', sourceName: 'source.name' }) as ExternalReference[]

  const filtered = filter(urls, ref => isEmpty(get(ref, 'sourceName')))

  if (!isEmpty(filtered)) {
    return (map(urls, obj => {
      if (isEmpty(obj.sourceName)) {
        try {
          const hostname = new URL(obj.url).hostname.replace('www.', '').split(/[-.]/)[0]
          obj.sourceName = capitalize(hostname)
        } catch (err) {
          console.log(err)
        }
      }
      return obj
    }))
  }

  return urls
}

export const filterUrls = (programUrls: any[], sourceUrls: any[]): ExternalReference[] => {
  const prescUrls = filter(programUrls, ref => isEqual(get(ref, 'crawlType'), 'prescriber'))
  const urls = isEmpty(prescUrls) ? [...programUrls, ...sourceUrls] : programUrls

  if (isEqual(urls.length, 1)) {
    return urls
  }

  const filtered = filter(urls, ref => !isEqual(get(ref, 'crawlType'), 'otaree'))

  if (!isEmpty(filtered)) {
    return filtered
  }

  return urls
}

export const getDeliveryDate = (date: Date): string => {
  const year: string = date.getFullYear().toString()
  const quarter: string = getQuarterByMonth(date.getMonth())
  return `${quarter} ${year}`
}

export const getMaxPriceProgram = (lots: Lot[]): number[] => {
  const maxPrice = reduce(lots, (acc: any[], lot) => {
    const tmp = get(lot, 'prices')
    if ((last(tmp) ?? 0) > last(acc)) {
      return tmp
    }

    return acc
  }, [0])
  return maxPrice
}

export const getMinPriceProgram = (lots: Lot[]): number[] => {
  const minPrice = reduce(lots, (acc: any[], lot) => {
    const tmp = get(lot, 'prices')
    if ((first(tmp) ?? 0) < first(acc)) {
      return tmp
    }

    return acc
  }, [Infinity])
  return minPrice
}

export const getTypologies = (lots: Lot[]): string => reduce(uniq(map(lots, ({ rooms, type }) => getTypology(type, rooms))), (acc, str: string) => {
  if (isEqual(acc, '')) {
    return str
  }

  return `${acc}, ${str}`
}, '')

export const isActable = (actabilityDate: Date): boolean => Date.now() > actabilityDate.getTime()
