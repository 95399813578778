/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import styled from 'styled-components'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { get, toString } from 'lodash'
import { spacings } from '@folhomee/front-library'

import Text from '../../atoms/Text'
import { DocumentTypesCheckboxProps } from './DocumentTypeCheckbox'

const StyledCheckBox = styled.input`
  font: inherit;
  color: ${({ theme }) => get(theme, 'darkGrey')};
  width: 1.15em;
  border: 0.10em solid ${({ theme }) => get(theme, 'darkGrey')};
  height: 1.15em;
  margin: 0;
  display: grid;
  transform: translateY(-0.075em);
  appearance: none;
  border-radius: 0.10em;
  place-content: center;
  background-color: #fff;
  -webkit-appearance: none;

  &::before {
    width: 1.10em;
    height: 1.10em;
    content: '';
    transform: scale(0);
    box-shadow: inset 1em 1em ${({ theme }) => get(theme, 'darkGrey')};
    transition: 120ms transform ease-in-out;
    border-radius: 0.10em;
  }

  &:checked::before {
    transform: scale(1);
  }
`

const Container = styled.div`
  gap: ${get(spacings, 'xxs')}px;
  display: flex;
  font-size: 20px;
  align-items: center;
  padding-top: ${get(spacings, 'xs')}px;
  padding-left: ${get(spacings, 'xs')}px;
  justify-content: flex-start;s

  &:last-child {
    padding-bottom: ${get(spacings, 'xs')}px;
  }
`

const DocumentTypeCheckbox = ({ setFieldValue, isChecked: isCheckedDefault, documentType }: DocumentTypesCheckboxProps): JSX.Element => {
  const { t } = useTranslation()
  const [isChecked, updateIsChecked] = useState<boolean>(isCheckedDefault)

  const updateFieldValue = (): void => {
    setFieldValue(toString(get(documentType, 'value', 0)))
    updateIsChecked(!isChecked)
  }

  return (
    <Container>
      <StyledCheckBox
        onClick={updateFieldValue}
        type='checkbox'
        name='DocumentTypes'
        value={get(documentType, 'value')}
        defaultChecked= {isCheckedDefault} />
      <Text>{t(`DOCUMENTS.${get(documentType, 'label', '').replace('-', '_')}`)}</Text>
    </Container>
  )
}

export default DocumentTypeCheckbox
