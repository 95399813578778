/* eslint-disable @typescript-eslint/no-misused-promises */
import styled from 'styled-components'
import { get, isEqual } from 'lodash'

import Text from '../Text'
import { CheckboxProps, StyledBoxProps, StyledBoxLabelProps } from './Checkbox.types'

const SIZE = {
  small: 16,
  large: 40,
  regular: 30
}

const Box = styled.div<StyledBoxProps>`
  width: ${({ customProps }) => get(SIZE, get(customProps, 'size') ?? 'regular')}px;
  border: solid 1px ${({ theme, customProps }) => isEqual(get(customProps, 'importance'), 'primary') ? get(theme, 'blue') : get(theme, 'darkGrey')};
  cursor: pointer;
  height:${({ customProps }) => get(SIZE, get(customProps, 'size') ?? 'regular')}px;
  min-width: ${({ customProps }) => get(SIZE, get(customProps, 'size') ?? 'regular')}px;
  background: ${({ theme, customProps }) => isEqual(get(customProps, 'bordered'), true) ? get(theme, 'white', '#fff') : isEqual(get(customProps, 'importance'), 'primary') ? get(theme, 'blue') : get(theme, 'darkGrey')};
  min-height:${({ customProps }) => get(SIZE, get(customProps, 'size') ?? 'regular')}px;
  border-radius: 5px;

  &:focus {
    outline: none;
  }
`

const StyledText = styled(Text) <StyledBoxLabelProps>`
  color: ${({ theme, bordered, importance }) => isEqual(bordered, true) ? (isEqual(importance, 'primary') ? get(theme, 'blue') : get(theme, 'darkGrey')) : get(theme, 'white', '#fff')};
`

const Center = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Checkbox = ({
  name, value, label, bordered, importance, checkboxSize, onClick,
  ...props
}: CheckboxProps): JSX.Element => (
  <Box
    {...props}
    customProps={{
      size: checkboxSize,
      bordered,
      importance
    }}
    onClick={onClick}>
    <Center>
      <StyledText
        bordered={bordered}
        importance={importance}>
        {label}
      </StyledText>
    </Center>
  </Box>
)

export default Checkbox
