import List from '../../templates/List'
import DepartmentRow from '../../molecules/DepartmentRow'
import { useFetchInfiniteDepartmentsQuery } from '../../../queries/Departments'

const HEADER = [{
  label: 'COMMON.name',
  textLeft: true
}, {
  label: 'DEPARTMENTS.code'
}, {
  label: 'REGIONS.singleTitle'
}]

const BODY = [{
  key: 'name',
  title: 'name'
}, {
  key: 'code',
  title: 'code'
}, {
  key: 'region',
  title: 'region'
}]

const Departments = (): JSX.Element => {
  const options = {
    bodyColor: 'extraLightBlue',
    headerColor: 'transparent',
    columnAlignment: '1fr 1fr 1fr'
  }

  return (
    <List
      row={DepartmentRow}
      body={BODY}
      title='departments'
      header={HEADER}
      options={options}
      useFetch={useFetchInfiniteDepartmentsQuery}
    />
  )
}

export default Departments
