import styled from 'styled-components'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { get, map, isEqual, isEmpty, join, startsWith } from 'lodash'

import Row from '../../atoms/Row'
import Data from '../Data'
import Icon from '../../atoms/Icon'
import Text from '../../atoms/Text'
import Modal from '../../organisms/Modal'
import FlexBox from '../../atoms/FlexBox'
import ContainerRow from '../../atoms/ContainerRow'
import TooltipTable from '../../atoms/TooltipTable'
import AddFavoriteModal from '../../organisms/AddFavoriteModal'
import FavoriteCheckbox from '../FavoriteCheckbox'
import ProgramDocumentForm from '../../molecules/ProgramDocumentForm'
import Field, { Content } from '../../atoms/Field'
import { LotRowProps } from './LotRow.types'

const StyledRow = styled(Row)`
  border-radius: 15px;
  ${({ higlighted }) => isEqual(higlighted, true) ? 'background-color: #ffe8d8;' : ''}
  ${({ higlighted, theme }) => isEqual(higlighted, true) ? `border-top: 4px solid ${get(theme, 'white') as string};` : 'none'}
  ${({ higlighted, theme }) => isEqual(higlighted, true) ? `border-bottom: 4px solid ${get(theme, 'white') as string};` : 'none'}
`

const Div = styled(FlexBox)`
  position: relative;

  & > div {
    display: none;
  }

  &:hover > div {
    display: flex;
  }
`
const StyledTooltipTable = styled(TooltipTable)`
  z-index: 99999;
    position: absolute;
    top: 30px;
    left:50%;
    margin-left:-60px;
    width: 120px;

  &:before {
    top:-40%;
    left: 43%;
  }
`

const LotRow = ({ data, fields, options, meta }: LotRowProps): JSX.Element => {
  const [isOpenFavorite, updateIsOpenFavorite] = useState<boolean>(false)
  const [isOpenDocForm, updateIsOpenDocForm] = useState<boolean>(false)
  const { t } = useTranslation()

  return (
    <ContainerRow options={options}>
      <StyledRow
        options={options}
        higlighted={isEqual(get(data, 'higlighted', false), true)}>
        {map(fields, ({ key, title }) => {
          const name = isEmpty(title) ? key : title ?? ''
          const keyName = join([key, title], '-')

          if (isEqual(title, 'isPublic') && isEqual(get(data, 'prescriberCrawlerCount'), 0)) {
            return (
              <Data
                key={keyName} >
                <Content>
                  <Text>{'?'} </Text>
                </Content>
              </Data>
            )
          }

          if (startsWith(key, 'parking')) {
            if (isEqual(0, get(data, key))) {
              return (
                <Data key={key}>
                  <Text >-</Text>
                </Data>
              )
            }

            return (
              <Data key={key}>
                <Icon cursor stroke color='secondary' variant='valid' />
              </Data>
            )
          }

          if (isEqual(key, 'clientFavorites')) {
            return (
              <Data key={keyName}>
                <FavoriteCheckbox setFavorite={(val) => get(meta, 'lot.setFavorite')(
                  get(meta, 'programId'),
                  [get(data, 'id')],
                  val)} />
              </Data>
            )
          }

          if (isEqual(key, 'favorites')) {
            return (
              <Data
                key={keyName}
                onClick={() => updateIsOpenFavorite(true)}>
                <Icon
                  variant='favorite'
                  color='danger'
                  cursor
                  stroke />
                <Modal
                  gap={16}
                  isOpen={isOpenFavorite}
                  onRequestClose={() => updateIsOpenFavorite(false)}
                  backgroundBlur>
                  <AddFavoriteModal programId={get(meta, 'programId')} lotIds={[get(data, 'id')]} closeModal={() => updateIsOpenFavorite(false)} />
                </Modal>
              </Data>
            )
          }

          if (isEqual(key, 'plan')) {
            const plan = get(data, key)
            if (isEmpty(plan) || isEqual(plan, 'N/A')) {
              return (
                <Content>
                  <Div width100>
                    <Icon cursor stroke variant='plus-circle' onClick={() => updateIsOpenDocForm(true)} />
                    <StyledTooltipTable
                      color='mediumPrimary'>
                      {t('DOCUMENTS.addNewPlan')}
                    </StyledTooltipTable>
                  </Div>
                  <Modal
                    isOpen={isOpenDocForm}
                    backgroundBlur={true}
                    onRequestClose={() => updateIsOpenDocForm(false)}>
                    <ProgramDocumentForm
                      lotId={get(data, 'id').toString()}
                      programId={get(meta, 'programId')}
                      updateOpen={updateIsOpenDocForm}
                      programName={get(meta, 'programName', '')}
                      lotNumber={get(data, 'reference', '')} />
                  </Modal>
                </Content>

              )
            }

            return (
              <Content>
                <a target='_blank' href={plan} rel='noreferrer' onClick={evt => { evt.stopPropagation() }}>
                  <Icon stroke cursor variant='surface' />
                </a>
              </Content>
            )
          }

          return (
            <Data
              key={keyName}>
              <Field
                name={name}
                value={get(data, key)} />
            </Data>
          )
        })}
      </StyledRow>
    </ContainerRow>
  )
}

export default LotRow
