import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Lot from './components/pages/Lot'
import Home from './components/pages/Home'
import City from './components/pages/City'
import Users from './components/pages/Users'
import Alert from './components/pages/Alert'
import Login from './components/pages/Login'
import Client from './components/pages/Client'
import Alerts from './components/pages/Alerts'
import Source from './components/pages/Source'
import Region from './components/pages/Region'
import Calculs from './components/pages/Calculs'
import Clients from './components/pages/Clients'
import Sources from './components/pages/Sources'
import Program from './components/pages/Program'
import Settings from './components/pages/Settings'
import Register from './components/pages/Register'
import NotFound from './components/pages/NotFound'
import AuthPage from './components/atoms/AuthPage'
import UserData from './components/pages/UserData'
import Programs from './components/pages/Programs'
import Documents from './components/pages/Documents'
import Locations from './components/pages/Locations'
import Department from './components/pages/Department'

const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/connexion' element={
          <AuthPage loginRequired={false}>
            <Login />
          </AuthPage>
        } />
        <Route path='/inscription' element={
          <AuthPage loginRequired={false}>
            <Register />
          </AuthPage>
        } />
        <Route path='/' element={
          <AuthPage loginRequired={true}>
            <Home />
          </AuthPage>
        } />
        <Route path='/programs' element={
          <AuthPage loginRequired={true}>
            <Programs />
          </AuthPage>
        } />
        <Route path='/programs/:id' element={
          <AuthPage loginRequired={true}>
            <Program />
          </AuthPage>
        } />
        <Route path='/lots/:id' element={
          <AuthPage loginRequired={true}>
            <Lot />
          </AuthPage>
        } />
        <Route path='/alerts' element={
          <AuthPage loginRequired={true}>
            <Alerts />
          </AuthPage>
        } />
        <Route path='/alerts/:id' element={
          <AuthPage loginRequired={true}>
            <Alert />
          </AuthPage>
        } />
        <Route path='/clients' element={
          <AuthPage loginRequired={true}>
            <Clients />
          </AuthPage>
        } />
        <Route path='/clients/:id' element={
          <AuthPage loginRequired={true}>
            <Client />
          </AuthPage>
        } />
        <Route path='/users' element={
          <AuthPage loginRequired={true}>
            <Users />
          </AuthPage>
        } />
        <Route path='/users/:id' element={
          <AuthPage loginRequired={true}>
            <UserData />
          </AuthPage>
        } />
        <Route path='/promoters' element={
          <AuthPage loginRequired={true}>
            <Sources />
          </AuthPage>
        } />
        <Route path='/documents' element={
          <AuthPage loginRequired={true}>
            <Documents />
          </AuthPage>
        } />
        <Route path='/locations' element={
          <AuthPage loginRequired={true}>
            <Locations />
          </AuthPage>
        } />
        <Route path='/cities/:id' element={
          <AuthPage loginRequired={true}>
            <City />
          </AuthPage>
        } />
        <Route path='/regions/:id' element={
          <AuthPage loginRequired={true}>
            <Region />
          </AuthPage>
        } />
        <Route path='/departments/:id' element={
          <AuthPage loginRequired={true}>
            <Department />
          </AuthPage>
        } />

        <Route path='/promoters/:id' element={
          <AuthPage loginRequired={true}>
            <Source />
          </AuthPage>
        } />
        <Route path='/calculs' element={
          <AuthPage loginRequired={true}>
            <Calculs />
          </AuthPage>
        } />
        <Route path='/settings' element={
          <AuthPage loginRequired={true}>
            <Settings />
          </AuthPage>
        } />
        <Route path='*' element={
          <AuthPage loginRequired={true}>
            <NotFound />
          </AuthPage>
        } />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
