import styled from 'styled-components'
import { offColor } from 'off-color'
import { InputProps, StyledInputProps } from './Input.types'
import { Icon, Badge, spacings } from '@folhomee/front-library'
import { get, map, isEqual, isUndefined, isEmpty } from 'lodash'

const SELECTION_PADDINGS = {
  small: '8px 12px',
  large: '12px 14px',
  regular: '9px 14px'
}

const HEIGHT = {
  small: 30,
  large: 40,
  regular: 35
}

const FONT_SIZES = {
  small: 12,
  large: 18,
  regular: 15
}

const StyledInput = styled.input<StyledInputProps>`
  width: 100%;
  color: ${({ theme, customProps }) => get(theme, isEqual(get(customProps, 'importance'), 'primary') ? 'blue' : 'darkGrey')};
  border: 1px solid ${({ theme, customProps }) => get(theme, get(customProps, 'borderColor', 'white'))};
  height: ${({ customProps }) => get(HEIGHT, get(customProps, 'size') ?? 'regular', 35)}px;
  padding: 0;
  font-size: ${({ customProps }) => get(FONT_SIZES, get(customProps, 'size') ?? 'regular', 15)}px;
  max-width: ${({ maxWidth }) => isUndefined(maxWidth) ? '' : maxWidth}px;
  min-width: ${({ minWidth }) => isUndefined(minWidth) ? '' : minWidth}px;
  border-top: ${({ customProps }) => isEqual(get(customProps, 'borderless'), false) ? '' : 'none'};
  line-height: normal;
  padding-left: ${get(spacings, 'xxs')}px;
  border-radius: ${({ customProps }) => get(customProps, 'squared') ?? false ? 5 : 53}px;
  border-radius: ${({ customProps }) => isEqual(get(customProps, 'borderless'), false) ? '' : 0};

  &::placeholder {
    color: ${({ theme, customProps }) => offColor(get(theme, isEqual(get(customProps, 'importance'), 'primary') ? 'blue' : 'darkGrey')).rgba(0.5)};
    font-size: ${({ customProps }) => get(FONT_SIZES, get(customProps, 'size') ?? 'regular', 15)}px !important;
    font-family: SourceSansPro;
    line-height: normal;
  }
`

const Inline = styled.div`
  display: flex;
  align-items: center;
`

const Close = styled(Icon)`
  transform: scale(0.6);
`

const Selection = styled.div<StyledInputProps>`
  gap: 8px;
  color: ${({ theme, customProps }) => get(theme, isEqual(get(customProps, 'importance'), 'primary') ? 'blue' : 'darkGrey')};
  width: 100%;
  border: 1px solid ${({ theme, customProps }) => get(theme, get(customProps, 'borderColor', 'white'))};
  display: flex;
  padding: ${({ customProps }) => get(SELECTION_PADDINGS, get(customProps, 'size') ?? 'regular', '8px 12px')};
  flex-flow: wrap;
  font-size: ${({ customProps }) => get(FONT_SIZES, get(customProps, 'size') ?? 'regular', 15)}px;
  max-height: 120px;
  overflow-y: scroll;
  line-height: normal;
  border-radius: ${({ customProps }) => get(customProps, 'squared') ?? false ? 5 : 53}px;
  border-bottom: ${({ customProps }) => isEqual(get(customProps, 'borderless'), false) ? '' : 'none'};
  border-bottom-left-radius: ${({ customProps }) => isEqual(get(customProps, 'borderless'), false) ? '' : 0};
  border-bottom-right-radius: ${({ customProps }) => isEqual(get(customProps, 'borderless'), false) ? '' : 0};
`

const StyledBadge = styled(Badge)`
  color: ${({ theme }) => get(theme, 'blue')};
`

export const getBorderColor = (error: boolean, bordered: boolean, importance: string): string => {
  if (error) {
    return 'orange'
  }

  if (!bordered) {
    return 'white'
  }

  return isEqual(importance, 'primary') ? 'blue' : 'darkGrey'
}

const Input = ({
  inputSize = 'regular', importance = 'primary',
  name, value, error, selection, multiple, placeholder,
  bordered, squared, onChange, onRemove, onBlur, updateSelection, minWidth, maxWidth,
  ...props
}: InputProps): JSX.Element => {
  if (isEqual(multiple, true) && !isUndefined(updateSelection) && !isUndefined(onRemove) && !isEmpty(selection)) {
    return (
      <div>
        <Selection
          {...props}
          customProps={{
            size: inputSize,
            squared,
            bordered,
            importance,
            borderless: true,
            borderColor: getBorderColor(error ?? false, bordered, importance)
          }}>
          {map(selection, ({ label, value: currValue }) => {
            return (
              <StyledBadge
                variant='selection'
                outline={false}
                importance='regular'>
                <Inline>
                  <p>{label}</p>
                  <Close variant='close' onClick={async () => await onRemove(label, currValue)} />
                </Inline>
              </StyledBadge>
            )
          })}
        </Selection>
        <StyledInput
          {...props}
          customProps={{
            size: inputSize,
            squared,
            bordered,
            importance,
            borderless: true,
            borderColor: getBorderColor(error ?? false, bordered, importance)
          }}
          name={name}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder}
          minWidth={minWidth}
          maxWidth={maxWidth}>
        </StyledInput>
      </div >
    )
  }

  return (
    <StyledInput
      {...props}
      customProps={{
        size: inputSize,
        squared,
        bordered,
        importance,
        borderless: false,
        borderColor: getBorderColor(error ?? false, bordered, importance)
      }}
      name={name}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={placeholder}
      minWidth={minWidth}
      maxWidth={maxWidth}/>
  )
}

export default Input
