import useClient from '../../hooks/useClient'
import { AxiosError } from 'axios'
import { get, map, range, size } from 'lodash'
import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult, useMutation, UseMutationResult, useQueryClient } from 'react-query'

import { PageContacts } from './Contacts.types'
import { getFieldById, getRelationships } from '../utils'

const transformPage = (page: any): PageContacts => ({
  meta: get(page, 'data.meta'),
  data: map(get(page, 'data.data'), ({ id, attributes, relationships }) => {
    const ships = getRelationships(relationships, ['source'], [])
    const included = get(page, 'data.included')

    return ({
      id,
      job: get(attributes, 'job'),
      email: get(attributes, 'email'),
      phone: get(attributes, 'phone'),
      regionId: get(attributes, 'region_id'),
      lastName: get(attributes, 'last_name'),
      firstName: get(attributes, 'first_name'),
      sourceName: getFieldById(included, get(ships, 'source', -1), 'source', 'attributes.name')
    })
  })
})

export const useCreateContactQuery = (id: number): UseMutationResult => {
  const client = useClient()
  const queryClient = useQueryClient()

  return useMutation(async values => await client.post(`/api/sources/${id}/contacts`, values), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['fetchContacts', id])
    }
  })
}

export const useFetchContactsQuery = (sources: any): UseInfiniteQueryResult<PageContacts, AxiosError> => {
  const client = useClient()

  return useInfiniteQuery(['fetchContacts', sources], async ({ pageParam = 1 }) => {
    return await client.get('/api/contacts', {
      params: {
        'page[number]': pageParam,
        'page[size]': 10,
        sources: sources
      }
    })
  }, {
    select: ({ pages }): InfiniteData<PageContacts> => ({
      pages: map(pages, page => transformPage(page)),
      pageParams: range(size(pages) + 1)
    }),
    getNextPageParam: (_, allPages) => size(allPages) + 1,
    staleTime: 1000 * 60,
    refetchOnWindowFocus: false
  })
}

export const useDeleteContactQuery = (sourceId: number): UseMutationResult => {
  const client = useClient()
  const queryClient = useQueryClient()

  return useMutation(['deleteContact', sourceId], async (contactId) => await client.delete(`/api/sources/${sourceId}/contacts/${String(contactId)}`), {
    onSuccess: async () => {
      await queryClient.refetchQueries(['fetchContacts'])
    }
  })
}

export const useUpdateContactQuery = (sourceId: number, contactId: number): UseMutationResult => {
  const client = useClient()
  const queryClient = useQueryClient()

  return useMutation(['updateContact', sourceId], async (values) => await client.patch(`/api/sources/${sourceId}/contacts/${String(contactId)}`, values), {
    onSuccess: async () => {
      await queryClient.refetchQueries(['fetchContacts'])
    }
  })
}
