import styled from 'styled-components'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, Icon } from '@folhomee/front-library'
import { isEqual, get, isUndefined } from 'lodash'

import Card from '../../atoms/Card'
import { formatCommentDate } from '../../../queries/utils/date'
import { useDeleteCommentQuery } from '../../../queries/Comments'
import { CommentCardProps, StyledCardProps } from './CommentCard.types'

const Inline = styled.div`
  gap: 4px;
  display: flex;
  align-items: center;
`

const CommentCardContainer = styled(Card)<StyledCardProps>`
  gap: 8px;
  width: 100%;
  border: solid 4px ${({ isPublic }) => isEqual(isPublic, true) ? '#fff6d8' : '#f4f4f4'};
  padding: 16px;
  box-shadow: 0 3px 6px 0 rgba(51, 102, 204, 0.15);
  border-radius: 20px;
`

const CommentHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const CommentContent = styled(Text)`
  width: 100%;
  font-size: 14px;
`

const TrashIcon = styled(Icon)`
  cursor: pointer;
  overflow: overlay;
  transform: scale(0.8);

  & g {
    transform: scale(0.6) translate(7px, 6px);
  }

  & > path {
    fill: ${({ theme }) => get(theme, 'orange')} !important;
  }
`

const Name = styled(Text)`
  padding: 4px 12px;
  font-size: 14px;
  border-radius: 16px;
  background-color: #fff6d8; 
`

const Status = styled(Text)`
  font-style: italic;
  text-transform: capitalize;
`

const Label = styled(Text)`
  padding: 4px 12px;
  font-size: 14px;
  border-radius: 16px;
  background-color: #fff6d8;
`

const CommentCard = ({ comment, id, route }: CommentCardProps): JSX.Element => {
  const { t } = useTranslation()
  const source: string = get(comment, 'source')
  const isAuthor: boolean = get(comment, 'isAuthor', false)
  const { mutateAsync } = useDeleteCommentQuery(id, route, get(comment, 'id'))
  const handleDelete = useCallback(async () => {
    await mutateAsync({})
  }, [])
  return (
    <CommentCardContainer isPublic={get(comment, 'public')}>
      <CommentHeader>
        {!isAuthor && <Name
          type='table'
          strong={true}
          variant='secondary'>{get(comment, 'author')}</Name>}
        {isAuthor && <Inline>
          <Name
            type='table'
            strong={true}
            variant='secondary'>{t('COMMON.me')}</Name>
          <Status
            type='small'
            strong={false}
            variant='secondary'>{isEqual(get(comment, 'public'), true) ? t('COMMON.publicVisibility') : t('COMMON.private')}</Status>
        </Inline>}
        {!isUndefined(source) && <Label
          type='table'
          strong={true}
          variant='secondary'>{t('COMMENTS.source', { source })}</Label>}
        <Inline>
          <Text
            type='small'
            strong={false}
            variant='secondary'>{formatCommentDate(get(comment, 'createdAt'))}</Text>
          {isAuthor && <TrashIcon variant='trash-solid' onClick={handleDelete} />}
        </Inline>
      </CommentHeader>
      <CommentContent
        type='table'
        variant='secondary'>{get(comment, 'content')}</CommentContent>
    </CommentCardContainer>
  )
}

export default CommentCard
