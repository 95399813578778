import styled from 'styled-components'
import { get, isEmpty, reduce } from 'lodash'

import Table from '../../organisms/Table'
import DetailList from '../../templates/DetailList'
import MaxContent from '../../atoms/MaxContent'
import ProgramRow from '../../molecules/ProgramRow'
import TableBottom from '../../molecules/TableBottom'
import { useFetchDepartmentQuery } from '../../../queries/Departments'
import { useFetchInfiniteProgramsQuery } from '../../../queries/Programs'
import { BODY_PROGRAMS, HEADER_PROGRAMS } from '../../../utils/tables/programs'
import { DepartmentData } from '../../molecules/DepartmentData'
import Page from '../../atoms/Page'

const Container = styled.div`
  margin-top: 48px;
`

const ProgramDepartment = ({ data }: any): JSX.Element => {
  const { data: programs, isFetching, fetchNextPage } = useFetchInfiniteProgramsQuery({
    departments: [get(data, 'id')]
  })

  const options = {
    bodyColor: 'extraLightBlue',
    headerColor: 'transparent',
    columnAlignment: '8em 9.5em 0.6fr 0.8fr 0.8fr 0.8fr 0.4fr 0.6fr 0.5fr 1fr'
  }

  const displayData = reduce(get(programs, 'pages', []), (acc: any[], items: any[]): any[] =>
    !isEmpty(get(items, 'data')) ? [...acc, ...get(items, 'data', [])] : acc, [])

  if (isEmpty(displayData)) {
    return (
      <></>
    )
  }

  return (
    <Container>
      <Table
        row={ProgramRow}
        data={displayData}
        body={BODY_PROGRAMS}
        header={HEADER_PROGRAMS}
        options={options}/>
      <TableBottom
        loading={isFetching}
        fetchNextPage={fetchNextPage}/>
    </Container>
  )
}

const TABS = [{
  key: 'source',
  label: 'Données',
  component: DepartmentData
}, {
  key: 'programs',
  label: 'Programmes',
  component: ProgramDepartment
}]

const Actions = (): JSX.Element => (
  <></>
)

const Department = (): JSX.Element => (
  <Page>
    <MaxContent>
      <DetailList
        tabs={TABS}
        type='department'
        actions={Actions}
        useFetch={useFetchDepartmentQuery} />
    </MaxContent>
  </Page>
)

export default Department
