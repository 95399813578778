/* eslint-disable @typescript-eslint/no-misused-promises */
import styled from 'styled-components'
import { Icon, Input, spacings } from '@folhomee/front-library'
import { useCallback, useEffect, useState } from 'react'
import { get, map, isUndefined, isEqual, filter, forEach, isEmpty, includes } from 'lodash'

import Text from '../Text'
import { SearchOptionsProps } from '../../molecules/SearchForm/SearchForm.types'
import { DropdownProps, StyledDropdownProps } from './Dropdown.types'

const HEIGHT = {
  small: 30,
  large: 40,
  regular: 35
}

const ARROW_BOTTOM = {
  small: 5,
  large: 10,
  regular: 7
}

const FONT_SIZES = {
  small: 12,
  large: 22,
  regular: 15
}

const StyledDropdown = styled.div<StyledDropdownProps>`
  width: 100%;
  position: relative;
  min-height: ${({ customProps }) => get(HEIGHT, get(customProps, 'size') ?? 'regular', 35)}px;
`

const StyledInput = styled(Input) <StyledDropdownProps>`
  height: fit-content !important;
  padding: 8px;
  font-size: ${({ customProps }) => get(FONT_SIZES, get(customProps, 'size') ?? 'regular', 15)}px;
  min-width: ${({ minWidth }) => isUndefined(minWidth) ? '' : minWidth}px;
  min-height: ${({ customProps }) => get(HEIGHT, get(customProps, 'size') ?? 'regular', 35)}px;
`

const ArrowIcon = styled(Icon) <StyledDropdownProps>`
  right: 5px;
  bottom: ${({ customProps }) => get(ARROW_BOTTOM, get(customProps, 'size') ?? 'regular', 21)}px;
  position: absolute;
  transform: scale(0.7);

  & path {
    stroke: ${({ theme }) => get(theme, 'darkGrey', '#FFF')} !important;
    stroke-width: 3 !important;
  }
`

const Selector = styled.div<StyledDropdownProps>`
  display: flex;

  & > div {
    width: 100%;
    background: ${({ theme }) => get(theme, 'white', '#FFF')};
  }

  & input {
    cursor: pointer !important;
    height: ${({ customProps }) => get(HEIGHT, get(customProps, 'size') ?? 'regular', 35)}px;
    padding: 0;
    line-height: normal;
    padding-left: ${get(spacings, 'xxs')}px;

    &::placeholder { 
      line-height: normal;
      font-size: ${({ customProps }) => get(FONT_SIZES, get(customProps, 'size') ?? 'regular', 15)}px;
    }
  }
`

const Options = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => get(theme, 'darkGrey')};
  z-index: 9999;
  position: absolute;
  max-height: 239px;
  overflow-y: scroll;
  border-radius: 5px;
  background-color: ${({ theme }) => get(theme, 'white', '#FFF')};
`

const Option = styled.div`
  margin: 4px;
  cursor: pointer;
  padding: 6px 12px;
  font-size: 15px;
  background-color: ${({ theme }) => get(theme, 'white', '#FFF')};

  &:hover {
    background: ${({ theme }) => get(theme, 'lightBlue')};
    border-radius: 5px;
  }
`

const Dropdown = ({ dropdownSize, name, value, options, multiple, selection, placeholder, onChange, minWidth, ...props }: DropdownProps): JSX.Element => {
  const [currOptions, updateCurrOptions] = useState(filter(options, opt => !includes(selection, opt)))
  const [showOptions, updateShowOptions] = useState(false)
  const [userSelection, updateUserSelection] = useState<SearchOptionsProps[]>([])

  useEffect(() => {
    updateCurrOptions(filter(options, opt => !includes(selection, opt)))
  }, [options, updateCurrOptions])

  useEffect(() => {
    if (!isEqual(userSelection, selection) && !isUndefined(selection)) {
      updateUserSelection(isUndefined(selection) ? [] : filter(selection, item => !isEmpty(item)))

      forEach(selection ?? [], select => {
        if (isEqual(multiple, true)) {
          updateCurrOptions(filter(options, opt => !isEqual(get(opt, 'value'), get(select, 'value'))))
        } else {
          updateCurrOptions(filter(options, opt => !isEqual(get(opt, 'value'), get(select, 'value'))))
        }
      })
    }
  }, [selection])

  const handleSelector = useCallback(() => {
    updateShowOptions(!showOptions)
  }, [showOptions])

  const handleOption = useCallback(async (label, value) => {
    updateUserSelection([...userSelection, { label, value }])

    if (isEqual(multiple, true)) {
      updateCurrOptions(filter(currOptions, opt => !isEqual(get(opt, 'value'), value)))
    } else {
      updateCurrOptions(filter(options, opt => !isEqual(get(opt, 'value'), value)))
    }

    await onChange(name, { label, value }, true)
    updateShowOptions(false)
  }, [currOptions, showOptions])

  const handleRemoveOptions = useCallback(async (label, value) => {
    updateCurrOptions([...currOptions, { label, value }].sort((first, second) => get(first, 'label').localeCompare(get(second, 'label'))))
    updateUserSelection(filter(userSelection, select => !isEqual(get(select, 'value'), value)))
    await onChange(name, { label, value }, false)
  }, [currOptions, userSelection])

  return (
    <StyledDropdown
      customProps={{
        size: dropdownSize
      }}
      dropdownSize={dropdownSize}>
      <Selector onClick={handleSelector}
        dropdownSize={dropdownSize}>
        <StyledInput
          {...props}
          customProps={{
            size: dropdownSize
          }}
          name='dropdown'
          value={get(value, 'label', '')}
          squared={true}
          inputSize='regular'
          dropdownSize={dropdownSize}
          multiple={multiple}
          bordered={true}
          readOnly={true}
          onChange={() => { }}
          onRemove={handleRemoveOptions}
          selection={userSelection}
          importance='secondary'
          placeholder={placeholder}
          updateSelection={updateUserSelection}
          minWidth={minWidth} />
        <ArrowIcon variant='arrow-down'
          dropdownSize={dropdownSize}
          customProps={{
            size: dropdownSize
          }} />
      </Selector>
      {showOptions &&
        <Options>
          {map(currOptions, (option) => (
            <Option
              key={`${name}-${get(option, 'label')}`}
              onClick={async () => await handleOption(get(option, 'label'), get(option, 'value'))}>
              <Text>
                {get(option, 'label')}
              </Text>
            </Option>
          ))}
        </Options>}
    </StyledDropdown>
  )
}

export default Dropdown
