import { useParams } from 'react-router-dom'
import { useCallback, useState } from 'react'
import { get, reduce, isEqual, toNumber } from 'lodash'

import Icon from '../../atoms/Icon'
import Page from '../../atoms/Page'
import Modal from '../../organisms/Modal'
import Table from '../../organisms/Table'
import MaxContent from '../../atoms/MaxContent'
import DetailList from '../../templates/DetailList'
import ProgramRow from '../../molecules/ProgramRow'
import SourceData from '../../molecules/SourceData'
import SourceMerge from '../../molecules/SourceMerge'
import CrawlerList from '../../organisms/CrawlerList'
import LoadingDots from '../../atoms/LoadingDots'
import TableBottom from '../../molecules/TableBottom'
import SourceDetails from '../../molecules/SourceDetails'
import { TabProps } from '../../atoms/Tabs/Tabs.types'
import { useFetchMeQuery } from '../../../queries/Users'
import { ProgramListProps } from './Source.types'
import { useFetchSourceQuery } from '../../../queries/Sources'
import { useFetchInfiniteProgramsQuery } from '../../../queries/Programs'
import { PagePrograms, ProgramPreview } from '../../../queries/Programs/Programs.types'
import { BODY_PROGRAMS, HEADER_PROGRAMS } from '../../../utils/tables/programs'

const ProgramSource = ({ data }: ProgramListProps): JSX.Element => {
  const { data: programs, isFetching, fetchNextPage } = useFetchInfiniteProgramsQuery({
    sources: [get(data, 'id')]
  })

  const options = {
    bodyColor: 'extraLightBlue',
    headerColor: 'transparent',
    columnAlignment: '8em 9.5em 0.6fr 0.8fr 0.8fr 0.8fr 0.4fr 0.6fr 0.5fr 1fr'
  }

  return (
    <MaxContent>
      <Table
        row={ProgramRow}
        data={reduce(get(programs, 'pages', []), (acc: ProgramPreview[], page: PagePrograms): ProgramPreview[] => [...acc, ...get(page, 'data', [])], [])}
        body={BODY_PROGRAMS}
        header={HEADER_PROGRAMS}
        options={options}
        noPadding={true} />
      <TableBottom
        loading={isFetching}
        fetchNextPage={fetchNextPage} />
    </MaxContent>
  )
}

const TABS = [{
  key: 'details',
  label: 'SOURCES.informations',
  component: SourceDetails,
  admin: false
}, {
  key: 'programs',
  count: true,
  label: 'COMMON.programs',
  admin: false,
  component: ProgramSource
}] as TabProps[]

const ADMIN_TABS = [{
  key: 'source',
  label: 'COMMON.data',
  component: SourceData,
  admin: true
}, {
  key: 'crawlers',
  count: true,
  label: 'SOURCES.crawlers',
  component: CrawlerList,
  admin: true
}] as TabProps[]

const Actions = (): JSX.Element => {
  const [isOpen, updateIsOpen] = useState<boolean>(false)
  const params = useParams()
  const sourceId = get(params, 'id', -1)

  const handleDelete = useCallback(() => {
    updateIsOpen(true)
  }, [])

  return (
    <>
      <Modal
        backgroundBlur={true}
        isOpen={isOpen}
        onRequestClose={() => updateIsOpen(false)}>
        <SourceMerge
          update={updateIsOpen}
          id={toNumber(sourceId)}/>
      </Modal>
      <Icon cursor stroke color='danger' variant='trash' onClick={handleDelete}/>
    </>
  )
}

const Source = (): JSX.Element => {
  const { data, isFetching } = useFetchMeQuery()
  return (
    <Page>
      {isEqual(isFetching, true) && <LoadingDots />}
      {isEqual(isFetching, false) &&
        <MaxContent>
          <DetailList
            tabs={isEqual(get(data, 'role'), 'admin') ? [...TABS, ...ADMIN_TABS] : TABS}
            type='source'
            actions={isEqual(get(data, 'role'), 'admin') ? Actions : (): JSX.Element => (<></>)}
            useFetch={useFetchSourceQuery} />
        </MaxContent>}
    </Page>
  )
}

export default Source
