import styled from 'styled-components'
import { get, map, isEqual } from 'lodash'

import Data from '../Data'
import Text from '../../atoms/Text'
import Field from '../../atoms/Field'
import RowLink from '../../atoms/RowLink'
import ContainerRow from '../../atoms/ContainerRow'
import { ClientRowProps } from './ClientRow.types'

const Link = styled.a`
  text-decoration: none;
`

const ClientRow = ({ data, fields, options }: ClientRowProps): JSX.Element => (
  <ContainerRow options={options}>
    <RowLink options={options} to={`/clients/${get(data, 'id', '') as string}`}>
      {map(fields, ({ key }) => {
        if (isEqual(key, 'firstName') || isEqual(key, 'lastName')) {
          return (
            <Data key={key} left={true}>
              <Text color='primary' strong>
                {get(data, key)}
              </Text>
            </Data>
          )
        }

        if (isEqual(key, 'email')) {
          return (
            <Data key={key} left={true}>
              <Text capitalize='none'>
                {get(data, key)}
              </Text>
            </Data>
          )
        }

        if (isEqual(key, 'phone')) {
          return (
            <Data
              key={key} left={true}
              onClick={(e) => e.stopPropagation()}>
              <Link href={`tel:${get(data, key, '') as string}`}>
                <Text>
                  {get(data, key)}
                </Text>
              </Link>
            </Data>
          )
        }

        return (
          <Data key={key}>
            <Field
              key={key}
              name={key}
              value={get(data, key)} />
          </Data>
        )
      })}
    </RowLink>
  </ContainerRow>
)

export default ClientRow
