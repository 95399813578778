import { useTranslation } from 'react-i18next'

import Icon from '../Icon'
import ButtonRound from '../ButtonRound'
import TooltipContent from '../TooltipContent'
import { FavoriteButtonProps } from './FavoriteButton.types'

const FavoriteButton = ({ onClick }: FavoriteButtonProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <>
      <ButtonRound
        color='lightDanger'
        onClick={onClick}
        buttonSize='regular'
        data-tooltip-id='FavoriteTooltip'>
        <Icon
          variant='favorite'
          color='danger'
          stroke
          cursor />
      </ButtonRound>
      <TooltipContent
        id="FavoriteTooltip"
        color='lightDanger'>
        {t('PROGRAM.addFavorite')}
      </TooltipContent>
    </>
  )
}

export default FavoriteButton
