import styled from 'styled-components'
import { get, isEqual, isUndefined } from 'lodash'

import Text from '../Text'
import { HighlightedTextProps, TextProps } from './HighlightedText.types'

const StyledText = styled(Text) <TextProps>`
  color: ${({ theme, color }) => get(theme, color)};
  flex-grow: ${({ flexGrow }) => isEqual(flexGrow, true) ? '1' : 0};
  text-align: ${({ center, textAlign }) => isEqual(center, true) ? 'center' : isUndefined(textAlign) ? 'start' : textAlign};
`

const HighlightedText = ({ text, color, strong, flexGrow, textAlign }: HighlightedTextProps): JSX.Element => {
  return (
    <StyledText
      strong={strong}
      color='primary'
      flexGrow={flexGrow}
      textAlign={textAlign}>
      {text}
    </StyledText>
  )
}

export default HighlightedText
