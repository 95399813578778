import styled, { keyframes } from 'styled-components'
import { get } from 'lodash'

import { LoaderProps } from './Loader.types'

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    opacity: .8;
    transform: rotate(360deg);
  }
`

const positionDot1 = keyframes`
  0% {
    top: 45%;
    left: 45%;
  }

  50% {
    top:38%;
    left: 45%;
  }

  100% {
    top: 45%;
    left: 45%;
  }
`

const positionDot2 = keyframes`
  0% {
    top: 45%;
    left: 45%;
  }

  50% {
    top: 50%;
    left: 38%;
  }

  100% {
    top: 45%;
    left: 45%;
  }
`

const positionDot3 = keyframes`
  0% {
    top: 45%;
    left: 45%;
  }

  50% {
    top: 50%;
    left: 52%;
  }

  100% {
    top: 45%;
    left: 45%;
  }
`

const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Content = styled.div`
  width: 200px;
  height: 200px;
  position: relative;
  animation: ${rotate} 0.5s infinite;
`

const Dot = styled.div`
  width: 10%;
  height: 10%;
  position: absolute;
  border-radius: 50%;
`

const Dot1 = styled(Dot)`
  animation: ${positionDot1} 1s infinite;
  background: ${({ theme }) => get(theme, 'lightOrange', '#fff')};
`

const Dot2 = styled(Dot)`
  animation: ${positionDot2} 1s infinite;
  background: ${({ theme }) => get(theme, 'orange', '#fff')};
`

const Dot3 = styled(Dot)`
  animation: ${positionDot3} 1s infinite;
  background: ${({ theme }) => get(theme, 'blue', '#fff')};
`

const Loader = ({ loading }: LoaderProps): JSX.Element | null => {
  const shouldDisplay: boolean = loading ?? false

  if (!shouldDisplay) {
    return null
  }

  return (
    <LoaderContainer data-testid='loader' >
      <Content>
        <Dot1 />
        <Dot2 />
        <Dot3 />
      </Content>
    </LoaderContainer>
  )
}

export default Loader
