import { get, map, isEqual } from 'lodash'

import Data from '../Data'
import Link from '../../atoms/Link'
import Text from '../../atoms/Text'
import Field from '../../atoms/Field'
import RowLink from '../../atoms/RowLink'
import ContainerRow from '../../atoms/ContainerRow'
import { CityRowProps } from './CityRow.types'

const CityRow = ({ data, fields, options }: CityRowProps): JSX.Element => {
  return (
    <ContainerRow options={options}>
      <RowLink options={options} to={`/cities/${get(data, 'id', '') as string}`}>
        {map(fields, ({ key }) => {
          if (isEqual(key, 'name')) {
            return (
              <Data key={key} left>
                <Text color='primary' strong>
                  {get(data, key)}
                </Text>
              </Data>
            )
          }

          if (isEqual(key, 'department')) {
            return (
              <Data key={key}>
                <Link to={`/departments/${get(data, 'department.id', '') as string}`}>
                  <Text color='primary'>
                    {get(data, 'department.code')}
                  </Text>
                </Link>
              </Data>
            )
          }

          return (
            <Data key={key}>
              <Field
                key={key}
                name={key}
                value={get(data, key)} />
            </Data>
          )
        })}
      </RowLink>
    </ContainerRow>
  )
}
export default CityRow
