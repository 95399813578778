/* eslint-disable @typescript-eslint/no-misused-promises */
import styled from 'styled-components'
import { offColor } from 'off-color'
import { useTranslation } from 'react-i18next'
import { useCallback, useState, useEffect } from 'react'
import { get, map, isUndefined, isEqual, flatten, includes, range, isEmpty, size, filter, toLower, isNull, isNaN } from 'lodash'

import Text from '../../atoms/Text'
import Input from '../../atoms/Input'
import Toggler from '../Toggler'
import Dropdown from '../../atoms/Dropdown'
import Checkbox from '../../atoms/Checkbox'
import useClient from '../../../hooks/useClient'
import Autocomplete from '../../molecules/Autocomplete'
import LocationInput from '../../molecules/LocationInput'
import { SearchInputProps } from './SearchInput.type'
import { SearchOptionsProps } from '../../molecules/SearchForm/SearchForm.types'
import { getMin, getMax } from '../../../utils/search'

const Inline = styled.div`
  gap: 8px;
  align-items: center;
  display: flex;
`

const TextArea = styled.textarea`
  color: ${({ theme }) => get(theme, 'darkGrey')};
  width: 100%;
  border: 1px solid ${({ theme }) => get(theme, 'darkGrey')};
  resize: none;
  padding: 8px;
  font-size: 16px;
  min-height: 120px;
  overflow-y: scroll;
  font-family: SourceSansPro; 
  border-radius: 5px;

  &::placeholder {
    font-size: 15px;
    font-family: SourceSansPro;
    color: ${({ theme }) => offColor(get(theme, 'darkGrey')).rgba(0.5)};
  }
`

const getInput = (name: string, values: object, multiple: boolean | undefined): any => {
  if (isEqual(multiple, true)) {
    return get(values, name, [])
  }

  return get(values, name, '')
}

const SearchInput = ({ type, name, values, options, multiple, handleBlur, handleChange, placeholder, setFieldValue, minWidth }: SearchInputProps): JSX.Element => {
  const [input, updateInput] = useState(getInput(name, values, multiple))
  const { t } = useTranslation()
  const client = useClient()
  const elements = isUndefined(options) ? [] : options
  const placeholderText = isUndefined(placeholder) ? '' : t(placeholder)

  useEffect(() => {
    updateInput(getInput(name, values, multiple))
  }, [values])

  const boxClicked = useCallback(async (value: any, min: number, max: number) => {
    if (size(input) < 2) {
      if (isEqual(value, min)) {
        return await setFieldValue(name, [])
      }

      return await setFieldValue(name, [...input, value])
    }

    if (isEqual(value, min)) {
      if (isEqual(max, min + 1)) {
        return await setFieldValue(name, [min + 1])
      }

      return await setFieldValue(name, [min + 1, max])
    }

    if (isEqual(value, max)) {
      if (isEqual(min, max - 1)) {
        return await setFieldValue(name, [min])
      }

      return await setFieldValue(name, [min, max - 1])
    }

    if (value < min) {
      return await setFieldValue(name, [value, max])
    }

    if (value > max) {
      return await setFieldValue(name, [min, value])
    }

    return await setFieldValue(name, [value])
  }, [input, values])

  const checkboxClicked = useCallback(async (value: any) => {
    if (isEqual(value, true) || isEqual(value, 'true')) {
      return await setFieldValue(name, false)
    }

    return await setFieldValue(name, true)
  }, [values])

  const fetchSuggestions = useCallback(async (search: string) => {
    const subKeys = ['regions', 'departments', 'agglomerations', 'cities']
    const suggestions = await Promise.all(map(subKeys, async (subKey) => {
      const { data } = await client.get(`/api/${subKey}/search`, {
        params: { search }
      })

      return data
    }))

    return flatten(map(subKeys, (key, idx) => {
      return map(get(suggestions[idx], 'data'), ({ attributes }) => ({
        type: key,
        label: get(attributes, 'full_name', get(attributes, 'name')),
        value: get(attributes, 'id')
      }))
    }))
  }, [name, client])

  const fetchAutocompleteSuggestions = useCallback(async (search: string) =>
    filter(options, ({ label }) => !isNull(toLower(label).match(toLower(search)))), [options])

  if (isEqual(type, 'location')) {
    return (
      <LocationInput
        name={name}
        distance={parseInt(get(values, 'distance', '0'), 10)}
        onChange={setFieldValue}
        selection={input}
        placeholder={placeholderText}
        fetchSuggestions={fetchSuggestions} />
    )
  }

  if (isEqual(type, 'autocomplete')) {
    return (
      <Autocomplete
        name={name}
        value=''
        onBlur={handleBlur}
        squared={true}
        bordered={true}
        multiple={multiple}
        onChange={setFieldValue}
        inputSize='regular'
        selection={input}
        importance='secondary'
        data-testid='input-email'
        placeholder={placeholderText}
        fetchSuggestions={fetchAutocompleteSuggestions} />
    )
  }

  if (isEqual(type, 'checkbox')) {
    const isChecked = isEqual(input, 'true') || isEqual(input, true)

    return (
      <Inline>
        <Checkbox
          label=''
          onClick={async () => await checkboxClicked(input)}
          bordered={!isChecked}
          importance='secondary'
          checkboxSize='small' />
        <Text>{t(placeholderText)}</Text>
      </Inline>
    )
  }

  if (isEqual(type, 'box')) {
    const empty = isEmpty(filter(input, it => !isNaN(it)))
    const min = empty ? -1 : getMin(input)
    const max = empty ? -1 : getMax(input, min)

    return (
      <Inline>
        {map(elements, ({ value, label }: SearchOptionsProps) => (
          <Checkbox
            key={label}
            label={label}
            onClick={async () => await boxClicked(value, min, max)}
            bordered={!includes(range(min, parseInt(max) + 1), value)}
            importance='secondary'
            checkboxSize='large' />
        ))}
      </Inline>
    )
  }

  if (isEqual(type, 'toggle')) {
    return (
      <Toggler
        name={name}
        input={input}
        options={elements}
        onChange={setFieldValue} />
    )
  }

  if (isEqual(type, 'select')) {
    return (
      <Dropdown
        name={name}
        value={input}
        options={elements}
        multiple={multiple}
        onChange={setFieldValue}
        selection={input}
        placeholder={placeholderText}
        dropdownSize='regular' />
    )
  }

  if (isEqual(type, 'area')) {
    return (
      <TextArea
        name={name}
        value={input}
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={placeholderText} />
    )
  }

  return (
    <Input
      name={name}
      value={input}
      onBlur={handleBlur}
      squared={true}
      bordered={true}
      onChange={handleChange}
      inputSize='regular'
      importance='secondary'
      placeholder={placeholderText}
      minWidth={minWidth} />
  )
}

export default SearchInput
