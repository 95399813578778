import qs from 'qs'
import styled from 'styled-components'
import { useAsyncEffect } from 'use-async-effect'
import { useTranslation } from 'react-i18next'
import { useCallback, useState } from 'react'
import { media, spacings, Badge, Icon } from '@folhomee/front-library'
import { get, map, isEmpty, isEqual, omit, isArray, filter, includes, values, isUndefined, find } from 'lodash'

import numeral from '../../../services/numeral'
import useClient from '../../../hooks/useClient'
import { buildFilters } from '../../../utils/search'
import { SearchFieldsProps } from '../SearchForm/SearchForm.types'
import { SearchManagerProps, FilterProps } from './SearchManager.types'

const Container = styled.div`
  gap: 5px ;
  flex: 1;
  display: flex;
  padding: ${get(spacings, 's')}px ${get(spacings, 's')}px 0;
  align-items: center;
  flex-wrap: wrap;

  ${media.between('md', 'xl')`
    padding: 0;
  `}

  ${media.lessThan('sm')`
    padding: ${get(spacings, 's')}px ${get(spacings, 's')}px 0 ${get(spacings, 'xs')}px;
  `}
`
const Inline = styled.div`
  gap: 2px;
  display: flex;
  align-items: center;
`

const StyledBadge = styled(Badge)`
  padding: 2px 1px 2px 4px;

  & span {
    letter-spacing: 0.03rem;
  }

  ${media.lessThan('md')`
    margin: 3px;
  `}
`

const StyledIcon = styled(Icon)`
  transform: scale(0.5);

  & path {
    stroke: ${({ theme }) => get(theme, 'white')} !important;
    stroke-width: 4 !important;
  }
`

const IconContainer = styled.div`
  width: 30px;
  cursor: pointer;
  height: 30px;
  display: flex;
  overflow: overlay;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  background-color: ${({ theme }) => get(theme, 'orange')} !important;

  ${media.lessThan('md')`
    width: 25px;
    height: 25px;
  `}
`
const TrashIcon = styled(Icon)`
  transform: scale(0.9);

  & path {
    fill: ${({ theme }) => get(theme, 'white')} !important;
  }
`

const getFilterLabel = (key: string, value: string, fields: SearchFieldsProps[], t: any): string => {
  const operator = /(min)/.test(key) ? '≥' : /(max)/.test(key) ? '≤' : ''

  if (/(price)/i.test(key)) {
    return `${t('PROGRAM_SEARCH.budgetValue', { operator: operator, budget: numeral(value).format('0,0') as string }) as string} ${t('COMMON.euroSign') as string}`
  }

  if (/(rooms)/i.test(key)) {
    return `${t('LOT.typology', { operator: operator }) as string} ${t('PROGRAM_SEARCH.quarterSign') as string}${value}`
  }

  if (/(floor)/i.test(key)) {
    if (isEqual(value, 0)) {
      return 'RDC'
    }
    return `${t('LOT.floorValue', { operator: operator, etage: value }) as string}`
  }

  if (/(surface)/i.test(key)) {
    return `${t('LOT.surfaceValue', { operator: operator, surface: value }) as string}`
  }

  if (isEqual(key, 'deliveryQuarter')) {
    const quarter = parseInt(value) + 1
    return `${t('PROGRAM.deliveryMax') as string} ${t('PROGRAM_SEARCH.quarterSign') as string}${quarter.toString()}`
  }

  if (isEqual(key, 'deliveryYear')) {
    return `${t('PROGRAM.deliveryMax') as string} ${value}`
  }

  if (isEqual(key, 'deliveryQuarterMinimum')) {
    const quarter = parseInt(value) + 1
    return `${t('PROGRAM.deliveryMin') as string} ${t('PROGRAM_SEARCH.quarterSign') as string}${quarter.toString()}`
  }

  if (isEqual(key, 'deliveryYearMinimum')) {
    return `${t('PROGRAM.deliveryMin') as string} ${value}`
  }

  if (includes(['regions', 'cities', 'departments', 'agglomerations', 'taxations', 'sources'], key)) {
    return get(value, 'label', '')
  }

  if (includes(['parking', 'terrace', 'balcony', 'garden'], key)) {
    return t(`COMMON.${key}`) as string
  }

  if (isEqual(key, 'lotType')) {
    return t(`LOT.${value}`) as string
  }

  if (isEqual(key, 'useType')) {
    return t(`PROGRAM_SEARCH.${value}`) as string
  }

  if (isEqual(key, 'distance')) {
    return `${t('PROGRAM_SEARCH.distance', { operator: operator, value: value }) as string}`
  }

  return `${operator}${value}`
}

const Filter = ({ name, value, search, multiple, fields, updateSearch }: FilterProps): JSX.Element | null => {
  const { t } = useTranslation()
  const removeSearchField = useCallback(() => {
    if (isEqual(multiple, true)) {
      return updateSearch(new URLSearchParams(qs.stringify({
        ...search,
        [name]: filter(get(search, name), elt => !isEqual(`${elt}`, `${get(value, 'value', '') as string}`))
      })))
    }

    updateSearch(new URLSearchParams(qs.stringify(omit(search, [name]))))
  }, [search, value])

  if (isEmpty(value)) {
    return null
  }

  const label = getFilterLabel(name, value, fields, t)

  return (
    <StyledBadge variant='primary' importance='regular'>
      <Inline>
        <span>{label}</span>
        <StyledIcon variant='close' onClick={removeSearchField} />
      </Inline>
    </StyledBadge>
  )
}

const SearchManager = ({ search, fields, updateSearch, updateSearchBuild }: SearchManagerProps): JSX.Element | null => {
  const client = useClient()
  const [filters, updateFilters] = useState<any>(omit(search, ['page', 'mute', 'client']))

  const resetSearch = useCallback(() => {
    updateSearch(new URLSearchParams(qs.stringify({})))
  }, [search])

  const isEmptyFilters = useCallback(() =>
    isUndefined(find(values(filters), filter => !isEmpty(filter))), [filters])

  useAsyncEffect(async () => {
    const updated = await buildFilters(omit(search, ['page', 'mute', 'client']), client, fields)
    updateFilters(updated)
    updateSearchBuild(false)
  }, [search, fields])

  if (isEmpty(search)) {
    return null
  }

  return (
    <Container>
      {map(filters, (value, name) => {
        if (isArray(value)) {
          return map(value, (elt, idx) => (
            <Filter
              key={`${name}-${idx}`}
              name={name}
              value={elt}
              search={search}
              fields={fields}
              multiple={true}
              updateSearch={updateSearch} />
          ))
        }
        return (
          <Filter
            key={name}
            name={name}
            value={value}
            search={search}
            fields={fields}
            multiple={false}
            updateSearch={updateSearch} />
        )
      })}
      {!isEmptyFilters() &&
        <IconContainer>
          <TrashIcon variant='trash-solid' onClick={resetSearch} />
        </IconContainer>}
    </Container>
  )
}

export default SearchManager
