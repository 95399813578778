import styled, { keyframes } from 'styled-components'
import { DotProps, LoadingDotsProps } from './LoadingDots.types'
import { map, range, get } from 'lodash'

const slide = keyframes`
  0% {
    transform: scale(0.9);
  }
  50% {
    opacity: .6;
    transform: scale(1.2);
  }
  100% {
    transform: scale(0.9)
  }
`

const PlaceholderContainer = styled.div`
  gap: 6px;
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  max-width: 90px;
  transition: all 300ms ease-in-out;
  align-items: center;
  border-radius: 25%;
  justify-content: center;
`

const Dot = styled.div<DotProps>`
  width: 8px;
  height: 8px;
  display: inline-block;
  background: ${({ theme, color }) => get(theme, color, '#fff')};
  animation: ${slide} 1s infinite;
  border-radius: 100%;

  &:nth-child(1) {
    animation-delay: .1s;
  }

  &:nth-child(2) {
    animation-delay: .2s;
  }

  &:nth-child(3) {
    animation-delay: .3s;
  }

  &:nth-child(4) {
    animation-delay: .4s;
  }
`

const LoadingDots = ({ color = 'blue', ...props }: LoadingDotsProps): JSX.Element => (
  <PlaceholderContainer {...props}>
    {map(range(4), (idx: number): JSX.Element => {
      const key: string = `loading-dot-${idx}`

      return (
        <Dot color={color} key={key} />
      )
    })}
  </PlaceholderContainer>
)

export default LoadingDots
