import qs from 'qs'
import styled from 'styled-components'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { get, map, isEqual, omit, join } from 'lodash'

import Data from '../Data'
import Icon from '../../atoms/Icon'
import Field from '../../atoms/Field'
import RowLink from '../../atoms/RowLink'
import useClient from '../../../hooks/useClient'
import ButtonRound from '../../atoms/ButtonRound'
import ContainerRow from '../../atoms/ContainerRow'
import ButtonContainer from '../../atoms/ButtonContainer'
import { AlertRowProps } from './AlertRow.types'

const StyledData = styled(Data)`
  gap: 4px;
`

const AlertRow = ({ data, fields, options }: AlertRowProps): JSX.Element => {
  const { t } = useTranslation()
  const client = useClient()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const deleteMutation = useMutation(async (id: number) => await client.delete(`/api/alerts/${id}`), {
    onSuccess: async () => {
      await queryClient.invalidateQueries('fetchAlerts')
    }
  })
  const updateMutation = useMutation(async (id: number) => await client.patch(`/api/alerts/${id}`, {
    alert: {
      status: isEqual(get(data, 'status'), false)
    }
  }), {
    onSuccess: async () => {
      await queryClient.invalidateQueries('fetchAlerts')
    }
  })
  const deleteAlert = useCallback(async () => {
    if (window.confirm(t('ALERTS.confirmDelete', { title: get(data, 'name') }))) {
      await deleteMutation.mutateAsync(get(data, 'id'))
    }
  }, [data, deleteMutation])
  const updateAlert = useCallback(async () => {
    if (window.confirm(t(isEqual(get(data, 'status'), true) ? 'ALERTS.confirmPause' : 'ALERTS.confirmUnpause', { title: get(data, 'name') }))) {
      await updateMutation.mutateAsync(get(data, 'id'))
    }
  }, [data, updateMutation])
  const navigateToPrograms = useCallback(() => {
    navigate(join(['/programs', qs.stringify({ ...omit(data, ['name', 'id', 'createdAt', 'status']), alert_id: get(data, 'id') })], '?'))
  }, [data])

  return (
    <ContainerRow options={options}>
      <RowLink options={options} to={`/alerts/${get(data, 'id', '') as string}`}>
        {map(fields, ({ key }) => {
          if (isEqual(key, 'action')) {
            return (
              <StyledData key={key}>
                <ButtonRound
                  color='danger'
                  onClick={async (evt) => {
                    evt.preventDefault()
                    return await deleteAlert()
                  }}
                  buttonSize='tiny'>
                  <Icon
                    color='white'
                    variant='trash-solid'
                    transform='scale(0.85)'
                    fill />
                </ButtonRound>
                <ButtonRound
                  color='warning'
                  onClick={async (evt) => {
                    evt.preventDefault()
                    return navigate(`/alerts/${get(data, 'id', '') as string}`)
                  }}
                  buttonSize='tiny'>
                  <Icon
                    color='white'
                    variant='edit-solid'
                    transform='scale(0.85)'
                    fill />
                </ButtonRound>
                <ButtonRound
                  color='white'
                  onClick={async (evt) => {
                    evt.preventDefault()
                    return await updateAlert()
                  }}
                  buttonSize='tiny'>
                  <Icon
                    variant={isEqual(get(data, 'status'), true) ? 'pause-solid' : 'play-solid'}
                    color='primary'
                    transform='scale(1.4)'
                    fill
                    cursor />
                </ButtonRound>
              </StyledData>
            )
          }

          if (isEqual(key, 'view')) {
            return (
              <Data key={key}>
                <ButtonContainer
                  color='primary'
                  label={t('ALERTS.viewAlert')}
                  onClick={async (evt) => {
                    evt.preventDefault()
                    return navigateToPrograms()
                  }}
                  buttonSize='regular' />
              </Data>
            )
          }

          if (isEqual(key, 'name')) {
            return (
              <Data key={key} left={true}>
                <Field
                  key={key}
                  name={key}
                  value={get(data, key)} />
              </Data>
            )
          }

          return (
            <Data key={key}>
              <Field
                key={key}
                name={key}
                value={get(data, key)} />
            </Data>
          )
        })}
      </RowLink>
    </ContainerRow>
  )
}

export default AlertRow
