import styled from 'styled-components'
import { useState, useCallback } from 'react'
import { get, map, isEqual } from 'lodash'

import Row from '../../atoms/Row'
import Data from '../Data'
import Text from '../../atoms/Text'
import Field from '../../atoms/Field'
import Modal from '../../organisms/Modal'
import AlertForm from '../../organisms/AlertForm'
import ContainerRow from '../../atoms/ContainerRow'
import { AdalertRowProps } from './AdalertRow.types'
import { ButtonContainerProps } from '../AnnonceRow/ProgramRow.types'
import { useMutateAlertStatusQuery, useDeleteAlertQuery } from '../../../queries/AdAlerts'

const ButtonContainer = styled.div<ButtonContainerProps>`
  border: 1px solid white;
  background-color: ${({ theme, status }) => get(theme, status)};
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
`
const ScrollContainer = styled.div`
  overflow-y: scroll;
  width: 100%;
  max-height: 400px;
  height: 400px;
`
const Inline = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const AdalertRow = ({ data, fields, options }: AdalertRowProps): JSX.Element => {
  const alertId: string = get(data, 'id', 0).toString()
  const status = parseInt(get(data, 'status.id', '1'))
  const [open, updateOpen] = useState(false)
  const [openUpdate, updateOpenUpdate] = useState(false)
  const [action, setAction] = useState('')

  const { mutateAsync } = useMutateAlertStatusQuery(alertId)
  const { mutateAsync: deleteAsync } = useDeleteAlertQuery(alertId)

  const getActionStatus = useCallback((status, action) => {
    if (isEqual(action, 'supprimer')) {
      return 'supprimer'
    }

    if (isEqual(status, 1)) {
      return 'désactiver'
    }

    return 'activer'
  }, [])

  const updateModalStatus = useCallback(() => {
    updateOpen(!open)
  }, [open])

  const updateModal = useCallback(() => {
    updateOpenUpdate(!openUpdate)
  }, [openUpdate])

  const updateProgramStatus = useCallback(async () => {
    if (isEqual(action, 'supprimer')) {
      await deleteAsync({})
    } else {
      await mutateAsync(isEqual(status, 1) ? 3 : 1)
    }
    updateOpen(!open)
  }, [status, open, action])

  return (
    <ContainerRow options={options}>
      <Row options={options}>
        {map(fields, ({ key }) => {
          if (isEqual(key, 'user.first_name')) {
            return (
              <Data key={key} left={true}>
                <Text color='primary'>
                  {get(data, key)} {get(data, 'user.last_name')}
                </Text>
              </Data>
            )
          }

          if (isEqual(key, 'user.first_name')) {
            return (
              <Data key={key} left={true}>
                <Text color='primary'>
                  {get(data, key)} {get(data, 'user.last_name')}
                </Text>
              </Data>
            )
          }

          if (isEqual(key, 'status')) {
            return (
              <Data key={key}>
                <Field
                  key={key}
                  name={key}
                  value={get(data, 'status.name')} />
              </Data>
            )
          }

          if (isEqual(key, 'actions')) {
            return (
              <Inline key={key}>
                <ButtonContainer
                  status='orange'
                  onClick={updateModal} >
                  <Text color='white'>
                    Modifier
                  </Text>
                </ButtonContainer>
                <Data onClick={updateModalStatus}>
                  <ButtonContainer
                    status={isEqual(status, 1) ? 'orange' : 'green'}
                    onClick={() => setAction('')} >
                    <Text color='white'>
                      {isEqual(status, 1) ? 'désactiver' : 'activer'}
                    </Text>
                  </ButtonContainer>
                  <ButtonContainer status='grey' onClick={() => setAction('supprimer')}>
                    <Text color='white'>
                      X
                    </Text>
                  </ButtonContainer>
                </Data>
              </Inline>
            )
          }

          return (
            <Data key={key}>
              <Field
                key={key}
                name={key}
                value={get(data, key)} />
            </Data>
          )
        })}
      </Row>
      <Modal isOpen={open} onRequestClose={updateModalStatus}>
        <>
          <Text>
            Êtes vous sûr de vouloir {getActionStatus(status, action)} l&apos;alerte {get(data, 'attributes.name')} ?
          </Text>
          <ButtonContainer status={isEqual(status, 1) ? 'orange' : 'green'} onClick={updateProgramStatus}>
            <Text color='white'>
              {getActionStatus(status, action)}
            </Text>
          </ButtonContainer>
        </>
      </Modal>
      <Modal isOpen={openUpdate} onRequestClose={updateModal}>
        <ScrollContainer>
          <AlertForm
            alert={data}
            updateOpen={updateOpenUpdate} />
        </ScrollContainer>
      </Modal>
    </ContainerRow >
  )
}

export default AdalertRow
