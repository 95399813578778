import styled from 'styled-components'
import { useThrottle } from '@react-hook/throttle'
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import { get, reduce, size, toUpper } from 'lodash'

import Text from '../../atoms/Text'
import Input from '../../atoms/Input'
import Table from '../../organisms/Table'
import FlexBox from '../../atoms/FlexBox'
import TableBottom from '../../molecules/TableBottom'
import DataContainer from '../../atoms/DataContainer'
import { ListProps } from './ListProps.types'
import { getMinMediaQuery } from '../../../utils/media'

const DivInput = styled.div`
  width: 100%;
  max-width: 250px;
`

const List = <T extends unknown, O extends unknown>({ row, body, header, title, options, useFetch }: ListProps<T, O>): JSX.Element => {
  const { t } = useTranslation()
  const [value, updateValue] = useState('')
  const [search, updateSearch] = useThrottle('')
  const [bigScreen, updatebigScreen] = useState<boolean>(getMinMediaQuery('xl').matches)
  const { data, isFetching, fetchNextPage } = useFetch({ search })

  useEffect(() => {
    const mql = getMinMediaQuery('xl')
    const resize = (event: MediaQueryListEvent): void => updatebigScreen(event.matches)

    mql.addEventListener('change', resize)
    return () => {
      mql.removeEventListener('change', resize)
    }
  }, [])

  useEffect(() => {
    updateSearch(value)
  }, [value])

  const items = reduce(get(data, 'pages', []), (acc: O[], page: T): O[] => [...acc, ...get(page, 'data', []) as O[]], [])

  return (
    <DataContainer>
      <FlexBox row justifyContent='space-between'>
        <Text textType='title' strong>
          {t(`${toUpper(title)}.${title}Title`, { count: get(data, 'pages.0.meta.total', 0) })}
        </Text>
        <DivInput>
          <Input
            type='text'
            name='search'
            value={value}
            squared={true}
            bordered={true}
            onChange={evt => updateValue(evt.currentTarget.value)}
            inputSize='regular'
            importance='secondary'
            placeholder={t('COMMON.search')}
            maxWidth={220} />
        </DivInput>
      </FlexBox>
      <Table
        row={row}
        data={items}
        body={body}
        header={header}
        options={options}
        bigScreen={bigScreen} />
      {size(items) > 20 && <TableBottom
        loading={isFetching}
        fetchNextPage={fetchNextPage} />}
    </DataContainer>
  )
}

export default List
