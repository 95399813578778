import { AxiosError } from 'axios'
import { get, omit, map, range, size } from 'lodash'
import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult, useQuery, UseQueryResult } from 'react-query'

import useClient from '../../hooks/useClient'
import { Department } from '../Departments/Departments.types'
import { PageCities, PageCity } from './Cities.types'
import { buildQueryParams, getFieldById, getObjectById, getRelationships } from '../utils'

const transformPage = ({ data }: any): PageCities => ({
  meta: get(data, 'meta'),
  data: map(get(data, 'data'), ({ id, attributes, relationships }) => {
    const ships = getRelationships(relationships, ['department', 'tax_area'], [])
    const included = get(data, 'included')

    return ({
      id,
      name: get(attributes, 'name'),
      insee: get(attributes, 'insee'),
      zipcode: get(attributes, 'zipcode'),
      taxArea: getFieldById(included, get(ships, 'tax_area', 0), 'tax_area', 'attributes.name'),
      department: getObjectById(included, get(ships, 'department', 0), 'department', { id: 'id', name: 'name', code: 'code', region: 'region' }) as Department,
      image_type: get(attributes, 'image_type', 0),
      acquisition: get(attributes, 'acquisition', false)
    })
  })
})

export const useFetchInfiniteCitiesQuery = (search: any): UseInfiniteQueryResult<PageCities, AxiosError> => {
  const client = useClient()

  return useInfiniteQuery(['fetchInfiniteCities', search], async ({ pageParam = 1 }) => {
    return await client.get('/api/cities', {
      params: {
        'page[number]': pageParam,
        'page[size]': 21,
        ...buildQueryParams(omit(search, ['page']))
      }
    })
  }, {
    select: ({ pages }): InfiniteData<PageCities> => ({
      pages: map(pages, transformPage),
      pageParams: range(size(pages) + 1)
    }),
    getNextPageParam: (_, allPages) => size(allPages) + 1,
    staleTime: 60 * 1000,
    refetchOnWindowFocus: false
  })
}

export const useFetchCityQuery = (id: string): UseQueryResult<PageCity, AxiosError> => {
  const client = useClient()

  return useQuery(['fetchCity', id], async () => {
    return await client.get(`/api/cities/${id}`)
  }, {
    select: ({ data }): PageCity => {
      const { id, attributes, relationships } = get(data, 'data')
      const ships = getRelationships(relationships, ['department', 'tax_area'], [])
      const included = get(data, 'included')

      return {
        meta: {},
        data: {
          id,
          slug: get(attributes, 'slug', ''),
          name: get(attributes, 'name', ''),
          insee: get(attributes, 'insee', ''),
          zipcode: get(attributes, 'zipcode', ''),
          taxArea: getFieldById(included, get(ships, 'tax_area', 0), 'tax_area', 'attributes.name'),
          min_price: get(attributes, 'min_price', 100000),
          department: getObjectById(included, get(ships, 'department', 0), 'department', { id: 'id', name: 'name', code: 'code', region: 'region' }) as Department,
          image_type: get(attributes, 'image_type', 0),
          acquisition: get(attributes, 'acquisition', false)
        }
      }
    },
    refetchOnWindowFocus: false
  })
}
