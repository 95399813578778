import styled from 'styled-components'
import { get, isEqual } from 'lodash'
import { useState, useEffect } from 'react'

import Body from '../../molecules/TableBody'
import Header from '../../molecules/TableHeader'
import { TableProps, StyledTableProps } from './Table.types'

const StyledTable = styled.div<StyledTableProps>`
  width: 100%;
  border: ${({ theme, outline }) => isEqual(outline, true) ? `0.5px solid ${get(theme, 'grey') as string}` : 0};
  margin: auto;
  display: flex;
  padding: ${({ noPadding }) => isEqual(noPadding, true) ? 0 : '8px'};
  box-shadow: 0 2px 3px 0 rgba(51, 102, 204, 0.15);
  border-radius: 15px;
  flex-direction: column;
  background-color: ${({ theme }) => get(theme, 'color', '#fff')};
`

const Table = <T extends unknown>({ row, meta, data, body, header, actions, options, bigScreen, ...props }: TableProps<T>): JSX.Element => {
  const [localData, updateLocalData] = useState<T[]>(data)

  useEffect(() => updateLocalData(data), [data])

  return (
    <StyledTable {...props}>
      <Header
        data={localData}
        fields={header}
        options={options}
        visible={bigScreen ?? true}
        updateData={updateLocalData} />
      <Body
        row={row}
        data={localData}
        meta={meta}
        fields={body}
        actions={actions}
        options={options} />
    </StyledTable>
  )
}

export default Table
