import { useTranslation } from 'react-i18next'
import { get, reduce } from 'lodash'
import { useCallback, useState } from 'react'

import Table from '../Table'
import Modal from '../../organisms/Modal'
import FlexBox from '../../atoms/FlexBox'
import CrawlerRow from '../../molecules/CrawlerRow'
import TableBottom from '../../molecules/TableBottom'
import ButtonContainer from '../../atoms/ButtonContainer'
import CreateCrawlerModal from '../../organisms/CreateCrawlerModal'
import { CrawlerListProps } from './CrawlerList.types'
import { useFetchInfiniteCrawlersQuery } from '../../../queries/Crawlers'
import { Crawler, PageCrawlers } from '../../../queries/Crawlers/Crawlers.types'
import { BODY_CRAWLERS, HEADER_CRAWLERS } from '../../../utils/tables/crawlers'

const CrawlerList = ({ data }: CrawlerListProps): JSX.Element => {
  const { t } = useTranslation()
  const [isOpenCreate, updateIsOpenCreate] = useState(false)
  const { data: crawlers, isFetching, fetchNextPage } = useFetchInfiniteCrawlersQuery({
    sources: [get(data, 'id')]
  })

  const options = {
    bodyColor: 'extraLightBlue',
    headerColor: 'transparent',
    columnAlignment: '1.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr'
  }

  const handleImport = useCallback(() => {
  }, [])

  const handleCreate = useCallback(() => {
    updateIsOpenCreate(true)
  }, [updateIsOpenCreate])

  return (
    <>
      <FlexBox row justifyContent='end' width100 gap={16}>
        <ButtonContainer
          label={t('COMMON.import')}
          color='primary'
          buttonSize='regular'
          onClick={handleImport} />
        <ButtonContainer
          label={t('COMMON.new')}
          color='success'
          buttonSize='regular'
          onClick={handleCreate} />
      </FlexBox>
      <Table
        row={CrawlerRow}
        data={reduce(get(crawlers, 'pages', []), (acc: Crawler[], page: PageCrawlers): Crawler[] => [...acc, ...get(page, 'data', [])], [])}
        body={BODY_CRAWLERS}
        header={HEADER_CRAWLERS}
        options={options} />
      <TableBottom
        loading={isFetching}
        fetchNextPage={fetchNextPage} />
      <Modal
        isOpen={isOpenCreate}
        onRequestClose={() => updateIsOpenCreate(false)}>
        <CreateCrawlerModal
          source={get(data, 'id', '')}
          closeModal={() => { updateIsOpenCreate(false) }} />
      </Modal>
    </>
  )
}

export default CrawlerList
