import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { get, isEqual, map, size, reduce } from 'lodash'
import { useState, useCallback, useEffect } from 'react'

import Page from '../../atoms/Page'
import Text from '../../atoms/Text'
import Table from '../../organisms/Table'
import Modal from '../../organisms/Modal'
import Loader from '../../molecules/LoaderRotating'
import FlexBox from '../../atoms/FlexBox'
import AlertForm from '../../organisms/AlertForm'
import AdalertRow from '../../molecules/AdalertRow'
import MaxContent from '../../atoms/MaxContent'
import AnnonceRow from '../../molecules/AnnonceRow'
import TableBottom from '../../molecules/TableBottom'
import ButtonContainer from '../../atoms/ButtonContainer'
import UpdateUserModal from '../../organisms/UpdateUserModal'
import { User } from '../../../queries/Users/Users.types'
import { useModal } from '../../../hooks/useModal'
import { getMinMediaQuery } from '../../../utils/media'
import { useFetchInfiniteAdAlertsQuery } from '../../../queries/AdAlerts'
import { BODY_ANNONCES, HEADER_ANNONCES } from '../../../utils/tables/annonces'
import { useFetchUserQuery, useFetchUserAdsQuery, useFetchUserRequestsQuery } from '../../../queries/Users'

const HEADER = [{
  label: 'COMMON.name',
  textLeft: true
}, {
  label: 'USER.user',
  textLeft: true
}, {
  label: 'COMMON.status',
  textLeft: true
}, {
  label: 'COMMON.actions',
  textLeft: true
}]

const BODY = [{
  key: 'name',
  title: 'name'
}, {
  key: 'user.first_name',
  title: 'user'
}, {
  key: 'status',
  title: 'status'
}, {
  key: 'actions',
  title: 'actions'
}]

const InlineAd = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`
const Display = styled.img`
  width: 100px;
  height: 100px;
`
const ScrollContainer = styled.div`
  overflow-y: scroll;
  width: 100%;
  max-height: 400px;
  height: 400px;
`

const UserData = (): JSX.Element => {
  const { t } = useTranslation()
  const { id } = useParams()

  const { data: user } = useFetchUserQuery(Number(id))

  const [isOpen, toggle] = useModal()
  const [openCreate, setOpenCreate] = useState(false)
  const [bigScreen, updatebigScreen] = useState<boolean>(getMinMediaQuery('xl').matches)
  const { data, isFetching, fetchNextPage } = useFetchInfiniteAdAlertsQuery({
    user_id: get(user, 'id')
  })
  const { data: annonces, isFetching: isFetchingAnnonces } = useFetchUserAdsQuery(Number(id))
  const { data: requests, isFetching: isFetchingRequests } = useFetchUserRequestsQuery(Number(id))

  const options = {
    bodyColor: 'extraLightBlue',
    headerColor: 'transparent',
    columnAlignment: '0.5fr 0.5fr 1.5fr 1.0fr'
  }
  const adsOptions = {
    bodyColor: 'extraLightBlue',
    headerColor: 'transparent',
    columnAlignment: '1fr 1.5fr 0.8fr 0.8fr 0.8fr 0.8fr 0.8fr 0.9fr'
  }

  const alerts = reduce(get(data, 'pages', []), (acc: any[], page: any): any[] => [...acc, ...get(page, 'data', [])], [])

  const date = new Date(get(user, 'createdAt', ''))
  const formattedDate = date.toLocaleDateString('fr-FR', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })

  const openAnnoncePage = useCallback((id: string) => {
    const url = `/annonces/${id}`

    const win = window.open(url, '_blank')

    if (win != null) {
      win.focus()
    }
  }, [])

  const handleOpenCreate = useCallback(() => {
    setOpenCreate(!openCreate)
  }, [openCreate])

  useEffect(() => {
    const mql = getMinMediaQuery('xl')
    const resize = (event: MediaQueryListEvent): void => updatebigScreen(event.matches)

    mql.addEventListener('change', resize)
    return () => {
      mql.removeEventListener('change', resize)
    }
  }, [])

  return (
    <Page>
      <Modal isOpen={isOpen} onRequestClose={() => toggle(false)}>
        <UpdateUserModal closeModal={() => toggle(false)} user={user as User} />
      </Modal>
      <MaxContent>
        <FlexBox top={24} bottom={24} gap={8} row width100 justifyContent='space-between' alignStart>
          <FlexBox gap={4} alignStart>
            <Text textType='subtitle' fontSize={30} top={0} bottom={0} strong>{get(user, 'name') as string}</Text>
            <Text >Crée le {formattedDate}</Text>
            <Text >{get(user, 'phone')}</Text>
            <Text >{get(user, 'email') as string}</Text>
          </FlexBox>
          <ButtonContainer
            color='warning'
            onClick={async (evt) => {
              evt.preventDefault()
              toggle()
            }}
            buttonSize='regular'>
            {t('COMMON.edit')}
          </ButtonContainer>
          <ButtonContainer
            color='warning'
            onClick={handleOpenCreate}
            buttonSize='regular'>
            Créer une alerte
          </ButtonContainer>
        </FlexBox>
        <Loader loading={isFetching} />
        <Text textType='subtitle' fontSize={30}>Annonces déposées ({get(annonces, 'total', 0)})</Text>
        {isEqual(isFetchingAnnonces, false) && <div>
          <Table
            row={AnnonceRow}
            data={get(annonces, 'data', [])}
            header={HEADER_ANNONCES}
            body={BODY_ANNONCES}
            options={adsOptions} />
        </div>}
        <Text textType='subtitle' fontSize={30}>Annonces contactées ({size(requests)})</Text>
        {isEqual(isFetchingRequests, false) && map(requests, request => {
          const date = new Date(get(request, 'created_at', ''))
          const formattedDate = date.toLocaleDateString('fr-FR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          })

          return (
            <InlineAd>
              <Display src={get(request, 'ad.display_img')} />
              <Text>{get(request, 'ad.name', '')}</Text>
              <Text>{get(request, 'ad.display_city', '')}</Text>
              <Text>Contacté le {formattedDate}</Text>
              <button onClick={() => openAnnoncePage(get(request, 'ad.id', ''))}>Voir l&apos;annonce</button>
            </InlineAd>
          )
        })}
        <Text textType='subtitle' fontSize={30}>Alertes</Text>
        <Table
          row={AdalertRow}
          data={alerts}
          body={BODY}
          header={HEADER}
          options={options}
          bigScreen={bigScreen} />
        {size(alerts) > 20 && <TableBottom
          loading={isFetching}
          fetchNextPage={fetchNextPage} />}
      </MaxContent>
      <Modal isOpen={openCreate} onRequestClose={handleOpenCreate}>
        <ScrollContainer>
          <AlertForm
            alert={{
              userId: get(user, 'id', '')
            }}
            updateOpen={setOpenCreate} />
        </ScrollContainer>
      </Modal>
    </Page>
  )
}

export default UserData
