import { get, map, isEqual } from 'lodash'

import Data from '../Data'
import Text from '../../atoms/Text'
import Field from '../../atoms/Field'
import RowLink from '../../atoms/RowLink'
import ContainerRow from '../../atoms/ContainerRow'
import { RegionRowProps } from './RegionRow.Types'

const RegionsRow = ({ data, fields, options }: RegionRowProps): JSX.Element => (
  <ContainerRow options={options}>
    <RowLink options={options} to={`/regions/${get(data, 'id', '')}`}>
      {map(fields, ({ key }) => {
        if (isEqual(key, 'name')) {
          return (
            <Data key={key} left={true}>
              <Text color='primary' strong>
                {get(data, key)}
              </Text>
            </Data>
          )
        }

        return (
          <Data key={key}>
            <Field
              key={key}
              name={key}
              value={get(data, key)} />
          </Data>
        )
      })}
    </RowLink>
  </ContainerRow>
)

export default RegionsRow
