import { get } from 'lodash'

const errors = {
  LOGIN: {
    'no user found with given credentials': 'LOGIN.notFound'
  },
  'Network Error': 'COMMON.networkError'
}

export const getErrors = (path: string): string => get(errors, path, '')
