import { AxiosError } from 'axios'
import { get, map, range, size, omit, first, isEqual } from 'lodash'
import { InfiniteData, useMutation, UseQueryResult, UseMutationResult, useQuery, useInfiniteQuery, UseInfiniteQueryResult, useQueryClient } from 'react-query'

import useClient from '../../hooks/useClient'
import { buildQueryParams } from '../utils'
import { PageClients, PageClient } from './Clients.types'

const transformPage = ({ data }: any): PageClients => ({
  meta: get(data, 'meta'),
  data: map(get(data, 'data'), ({ id, attributes }) => ({
    id,
    email: get(attributes, 'email'),
    phone: get(attributes, 'phone_number'),
    lastName: get(attributes, 'last_name'),
    firstName: get(attributes, 'first_name'),
    isFavorite: get(attributes, 'is_favorite')
  }))
})

export const useFetchRates = (): UseQueryResult<number[], AxiosError> => {
  const client = useClient()

  return useQuery('fetchRates', async () => {
    return await client.get('/api/clients/rate')
  }, {
    select: ({ data }): number[] => {
      const res = get(data, 'data', [])
      return res
    },
    staleTime: 60 * 1000 * 60 * 24,
    refetchOnWindowFocus: false
  })
}

export const useCreatePipedriveAlert = (id: number): UseMutationResult => {
  const client = useClient()
  const queryClient = useQueryClient()

  return useMutation(async () => await client.post(`/api/clients/${id}/pipedrive_alert`), {
    onSuccess: async () => {
      await queryClient.refetchQueries(['fetchAllAlerts'])
    }
  })
}

export const useFetchInfiniteClientQuery = (search: any, userIdToFilter: number): UseInfiniteQueryResult<PageClients, AxiosError> => {
  const client = useClient()

  return useInfiniteQuery(['fetchInfiniteClients', search, userIdToFilter], async ({ pageParam = 1 }) => {
    return await client.get('/api/clients', {
      params: {
        'page[number]': pageParam,
        'page[size]': 21,
        ...isEqual(userIdToFilter, -1) ? {} : { user_id: userIdToFilter },
        ...buildQueryParams(omit(search, ['page']))
      }
    })
  }, {
    select: ({ pages }): InfiniteData<PageClients> => ({
      pages: map(pages, transformPage),
      pageParams: range(size(pages) + 1)
    }),
    getNextPageParam: (_, allPages) => size(allPages) + 1,
    staleTime: 60 * 1000,
    refetchOnWindowFocus: false
  })
}

export const useFetchInfiniteClientFavoritesQuery = (lotIds: number[], programId: number, search: any, userIdToFilter: number): UseInfiniteQueryResult<PageClients, AxiosError> => {
  const client = useClient()

  return useInfiniteQuery(['fetchInfiniteProgramClients', programId, lotIds, search, userIdToFilter], async ({ pageParam = 1 }) => {
    return await client.get('/api/clients/favorites', {
      params: {
        'page[number]': pageParam,
        'page[size]': 21,
        program: programId,
        ...isEqual(userIdToFilter, -1) ? {} : { user_id: userIdToFilter },
        ...isEqual(size(lotIds), 1) ? { lot: first(lotIds) } : {},
        ...buildQueryParams(omit(search, ['page']))
      }
    })
  }, {
    select: ({ pages }): InfiniteData<PageClients> => ({
      pages: map(pages, transformPage),
      pageParams: range(size(pages) + 1)
    }),
    getNextPageParam: (lastPage, _) => {
      return get(lastPage, 'data.meta.pagination.next')
    },
    staleTime: 60 * 1000,
    refetchOnWindowFocus: false
  })
}

export const useFetchClientQuery = (id: string): UseQueryResult<PageClient, AxiosError> => {
  const client = useClient()

  return useQuery(['fetchClient', id], async () => {
    return await client.get(`/api/clients/${id}`)
  }, {
    select: ({ data }): PageClient => {
      const { id, attributes } = get(data, 'data')

      return {
        meta: 0,
        data: {
          id,
          email: get(attributes, 'email'),
          phone: get(attributes, 'phone_number'),
          lastName: get(attributes, 'last_name'),
          firstName: get(attributes, 'first_name'),
          idPipedrive: get(attributes, 'id_pipedrive')
        }
      }
    },
    staleTime: 60 * 1000,
    refetchOnWindowFocus: false
  })
}

export const useMutateClientProgramQuery = (id: number): UseMutationResult => {
  const client = useClient()
  const queryClient = useQueryClient()

  return useMutation(async (programClients) => await client.put(`/api/programs/${get(programClients, 'programId', '') as string}/favorites`, programClients), {
    onSuccess: async () => {
      await queryClient.refetchQueries(['fetchInfinitePrograms'])
    }
  })
}
