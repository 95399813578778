import styled from 'styled-components'
import { useState } from 'react'
import { isEmpty, isEqual, isUndefined } from 'lodash'

import LoadingDots from '../../atoms/LoadingDots'
import { DisplayImageProps } from './DisplayImage.types'

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  margin-bottom: 16px;
`

const ImagePlaceholder = ({ isLoaded }: any): JSX.Element | null => {
  if (isEqual(isLoaded, true)) {
    return null
  }

  return <LoadingDots />
}

const Image = ({ src, isError, isLoaded, onLoad, updateIsError }: any): JSX.Element => {
  if (isEqual(isError, true) || isEmpty(src)) {
    return (
      <StyledImage src='https://cdn.folhomee.fr/public/images/no-picture.png' />
    )
  }

  return (
    <>
      <ImagePlaceholder isLoaded={isLoaded} />
      <StyledImage
        src={src}
        onLoad={onLoad}
        onError={() => updateIsError(true)} />
    </>
  )
}

const DisplayImage = ({ isFetching, data }: DisplayImageProps): JSX.Element => {
  const [isError, updateIsError] = useState(false)
  const [isLoaded, updateIsLoaded] = useState(false)
  const onLoad = (): any => updateIsLoaded(true)

  if (isEqual(isFetching, true) || isUndefined(data)) {
    return (
      <LoadingDots />
    )
  }

  return (
    <Image
      src={data}
      onLoad={onLoad}
      isError={isError}
      isLoaded={isLoaded}
      updateIsError={updateIsError} />
  )
}

export default DisplayImage
