import styled from 'styled-components'
import { spacings } from '@folhomee/front-library'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useState, useCallback } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { get, isEmpty, isEqual, map, omit, toUpper } from 'lodash'

import Text from '../../atoms/Text'
import Icon from '../../atoms/Icon'
import Modal from '../../organisms/Modal'
import Input from '../../atoms/Input'
import FlexBox from '../../atoms/FlexBox'
import useClient from '../../../hooks/useClient'
import ButtonContainer from '../../atoms/ButtonContainer'
import ConfirmationHandler from '../ConfirmationHandler'
import { InputItem } from '../../templates/Form'
import { SourceDataProps } from './SourceData.types'

const FIELDS_INFOS = [{
  key: 'name',
  placeholder: 'COMMON.name'
}, {
  key: 'slug',
  placeholder: 'COMMON.slug'
}]

const FIELDS_LINKS = [{
  key: 'urlPrescriber',
  placeholder: 'COMMON.urlPrescriber'
}, {
  key: 'urlPublic',
  placeholder: 'COMMON.urlPublic'
}]

const Inline = styled.div`
  gap: ${get(spacings, 'xs')}px;
  display: flex;
  align-items: center;
`

const SourceData = ({ data }: SourceDataProps): JSX.Element => {
  const client = useClient()
  const queryClient = useQueryClient()
  const [urls, updateUrls] = useState<string[]>(get(data, 'urls', [] as string[]))
  const [confirmDelete, updateConfirmDelete] = useState<boolean>(false)
  const [index, updateIndex] = useState<number>(-1)
  const { t } = useTranslation()

  const updateMutation = useMutation(async (values) => {
    return await client.patch(`/api/sources/${get(data, 'id', '') as string}`, {
      name: get(values, 'name'),
      slug: get(values, 'slug'),
      urls: get(values, 'urls'),
      urlPrescriber: get(values, 'urlPrescriber'),
      urlPublic: get(values, 'urlPublic')
    })
  }, {
    onSuccess: async () => {
      await queryClient.refetchQueries(['fetchSource', get(data, 'id')])
      await queryClient.invalidateQueries(['fetchInfiniteSources'])
    }
  })

  const onSubmit = useCallback(async (values) => {
    await updateMutation.mutateAsync(values)
  }, [])

  const addPrescriberUrl = (): void => updateUrls([...urls, ''])

  const { values, handleChange, handleSubmit, setFieldTouched, setFieldValue, handleBlur } = useFormik({
    initialValues: data,
    onSubmit
  })

  const additionalLinksField = map(urls, (_, ind) => {
    return { key: `urls[${ind}]`, placeholder: 'COMMON.urlPrescriberIndex' }
  })

  const onClickRemove = (newIndex: number): void => {
    updateIndex(newIndex)
    updateConfirmDelete(true)
  }

  const onCancelDelete = (): void => updateConfirmDelete(false)
  const onConfirmDelete = useCallback(async (): Promise<void> => {
    if (isEqual(index, -1)) {
      updateConfirmDelete(false)
    }
    const newUrls = urls.filter((_, i) => !isEqual(i, index))
    setFieldValue('urls', newUrls)
    const newValues = { ...omit(values, ['urls']), urls: newUrls }
    await onSubmit(newValues)
    updateConfirmDelete(false)
  }, [values, index])

  return (
    <>
      <Modal isOpen={confirmDelete} onRequestClose={onCancelDelete}>
        <ConfirmationHandler onCancel={onCancelDelete} onConfirm={async () => await onConfirmDelete()}>
          <Text textType='subtitle'>
            {t<string>('SOURCES.removePrescriberUrl', { prescriberUrl: get(values, 'urls', [])[index] })}
          </Text>
        </ConfirmationHandler>
      </Modal>
      <FlexBox width100>
        <FlexBox row gap={48} width100 bottom={24} alignStart>
          <FlexBox alignStart justifyContent='start' gap={16}>
            <Text color='primary' textType='bigger' strong >
              {t('SOURCES.informations')}
            </Text>
            {map(FIELDS_INFOS, field => (
              <InputItem
                field={field}
                values={values}
                handleBlur={handleBlur}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched} />
            ))}
          </FlexBox>
          <FlexBox alignStart justifyContent='start' gap={16}>
            <FlexBox row gap={16}>
            <Text color='primary' textType='bigger' strong >
                {t('SOURCES.links')}
              </Text>
              {!isEmpty(get(data, 'urlPrescriber')) &&
                <Icon cursor stroke variant='plus-circle' onClick={addPrescriberUrl} />}
            </FlexBox>
            {map(FIELDS_LINKS, field => (
              <>
                <InputItem
                  field={field}
                  values={values}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched} />
              </>
            ))}
          </FlexBox>
          {map(additionalLinksField, (field, ind) => (
            <FlexBox alignStart justifyContent='start' top={41} gap={8}>
              <Inline>
                <Text textType='subtitle' strong >
                  {toUpper(t(get(field, 'placeholder', ''), { index: ind + 2 }))}
                </Text>
                <Icon
                  variant='close-circle'
                  stroke
                  onClick={() => onClickRemove(ind)} />
              </Inline>
              <Input
                name={get(field, 'key')}
                value={get(values, get(field, 'key'))}
                squared={true}
                bordered={true}
                onChange={handleChange}
                inputSize='regular'
                importance='secondary'
                placeholder={t(get(field, 'placeholder', ''), { index: ind + 2 })} />
            </FlexBox>
          ))}
        </FlexBox>
        <ButtonContainer
          label={t<string>('COMMON.validate')}
          outline={false}
          color='success'
          onClick={() => handleSubmit()}
          buttonSize='large' />
      </FlexBox>
    </>
  )
}

export default SourceData
