import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { useCallback, useEffect, useRef, useState } from 'react'
import { get, includes, isEqual, map, filter, size } from 'lodash'

import Row from '../../atoms/Row'
import Text from '../../atoms/Text'
import Table from '../../organisms/Table'
import Checkbox from '../../atoms/Checkbox'
import useClient from '../../../hooks/useClient'
import ButtonContainer from '../../atoms/ButtonContainer'
import { StyledTableProps } from '../../organisms/Table/Table.types'
import { BODY_DOUBLE, HEADER_DOUBLE } from '../../../utils/tables/programs'
import { ProgramDoubleProps, ProgramDoubleRowProps } from './ProgramDouble.types'

const StyledTable = styled(Table) <StyledTableProps>`
  max-height: 330px;
`

const StyledCheckbox = styled(Checkbox)`
  justify-self: center;
`

const ProgramDoubleRow = ({ data, meta, fields, actions, options }: ProgramDoubleRowProps): JSX.Element => (
  <Row top={8} options={options}>
    {map(fields, ({ key }) => {
      if (isEqual(key, 'action')) {
        return (
          <StyledCheckbox
            label=''
            onClick={() => actions(get(data, 'id'))}
            bordered={!includes(meta, get(data, 'id'))}
            importance='secondary'
            checkboxSize='small' />
        )
      }

      return (
        <Text left={8}>
          {get(data, key)}
        </Text>
      )
    })}
  </Row >
)

const ProgramDouble = ({ id, double, update }: ProgramDoubleProps): JSX.Element => {
  const { t } = useTranslation()
  const [select, updateSelect] = useState<number[]>([])
  const client = useClient()
  const queryClient = useQueryClient()

  const options = useRef({
    bodyColor: 'extraLightBlue',
    headerColor: 'lightGrey',
    columnAlignment: '0.3fr 0.3fr 1fr'
  })

  const handleSelection = useCallback((id: number) => {
    const remove = filter(select, elt => !isEqual(id, elt))

    updateSelect(isEqual(size(remove), size(select)) ? [...select, id] : remove)
  }, [select, updateSelect])

  const updateMutation = useMutation(async (double: number[]) => await client.post(`/api/programs/${id.toString()}/merge`, {
    double
  }), {
    onSuccess: async () => {
      await queryClient.invalidateQueries('fetchProgramDouble')
      await queryClient.invalidateQueries(['fetchProgram', id])
      update(false)
    }
  })

  const handleMerge = useCallback(async () => {
    await updateMutation.mutateAsync(select)
  }, [select, updateMutation])

  useEffect(() => {
  }, [select])

  return (
    <>
      <Text textType='bigger' color='primary' strong>
        {t('PROGRAM.double')}
      </Text>
      <StyledTable
        row={ProgramDoubleRow}
        data={double}
        meta={select}
        body={BODY_DOUBLE}
        header={HEADER_DOUBLE}
        actions={handleSelection}
        options={options.current}
        outline={true} />
      <ButtonContainer
        top={16}
        label={t<string>('COMMON.validate')}
        outline={false}
        color='success'
        onClick={handleMerge}
        buttonSize='large'
        center />
    </>
  )
}

export default ProgramDouble
