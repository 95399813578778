import styled from 'styled-components'
import { get, isUndefined } from 'lodash'
import { media } from '@folhomee/front-library'

import { OptionsProps } from '../../organisms/Table/Table.types'

const Row = styled.div<OptionsProps>`
  gap: 8px;
  display: grid;
  margin-top: ${({ top }) => isUndefined(top) ? 0 : top}px;
  align-items: center;
  margin-left: ${({ left }) => isUndefined(left) ? 0 : left}px;
  margin-bottom: ${({ bottom }) => isUndefined(bottom) ? 0 : bottom}px;
  grid-template-columns: ${({ options }) => get(options, 'columnAlignment')};
  
  ${media.lessThan('lg')`
    grid-template-columns: repeat(3, 1fr);
  `}
`
export default Row
