import qs from 'qs'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useState, useCallback } from 'react'
import { media, ButtonLots, Badge } from '@folhomee/front-library'
import { get, map, isEmpty, isEqual, includes, isNull, size, sortBy, capitalize } from 'lodash'

import Row from '../../atoms/Row'
import Data from '../Data'
import Icon from '../../atoms/Icon'
import Text from '../../atoms/Text'
import Field from '../../atoms/Field'
import Table from '../../organisms/Table'
import Modal from '../../organisms/Modal'
import LotRow from '../LotRow'
import FlexBox from '../../atoms/FlexBox'
import LoadingDots from '../../atoms/LoadingDots'
import ContainerRow from '../../atoms/ContainerRow'
import DisplayImage from '../../molecules/DisplayImage'
import TooltipTable from '../../atoms/TooltipTable'
import FavoriteCheckbox from '../FavoriteCheckbox'
import AddFavoriteModal from '../../organisms/AddFavoriteModal'
import ProgramDocumentForm from '../ProgramDocumentForm'
import { sortLots } from '../LotsTable'
import { BodyFieldType } from '../TableBody/TableBody.types'
import { HeaderFieldType } from '../../molecules/TableHeader/TableHeader.types'
import { formatTaxations } from '../../../utils/taxations'
import { TableOptionsType } from './../../organisms/Table/Table.types'
import { getDeliveryDate, isActable } from '../../../queries/utils/programFormat'
import { BODY_LOTS, BODY_LOT_FAVORITE, HEADER_LOTS } from '../../../utils/tables/lots'
import { ProgramRowProps, ButtonProps, ProgramLotsProps } from './ProgramRow.types'
import { useFetchProgramQuery, useFetchProgramImageQuery, useFetchProgramDocumentsQuery } from '../../../queries/Programs'

const StyledContainerRow = styled(ContainerRow)`
  margin-bottom: 1px;
`
const LargeData = styled(Data)`
  width: 152px;
`

const InlineStart = styled(FlexBox)`
  & div {
    border-bottom: 1px solid ${({ theme }) => get(theme, 'blue')};
    padding-bottom: 4px;
  }

  & div:last-of-type {
    border-bottom: none;
  }

  & p:hover {
    color: ${({ theme }) => get(theme, 'orange')};
  }
`

const ImagePreview = styled.div`
  width: 7.6em;
  height: 5.5em;
  margin: 0;
  
  & > img {
    border-radius: 5px;
  }
`

const StyledData = styled(Data)`
  cursor: pointer;
`

const DataDoc = styled(Data)`
  position relative;
  width: 100%;

  & > div {
    display: none;
  }

  &:hover > div {
    display: flex;
  }
`

const LineText = styled(Text)`
  overflow: hidden;
  max-width: 152px;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    overflow: visible;
  }
  
  & > span:hover {
    z-index: 9999;
    background-color: #e7eefdd4;
  }
`

const FieldDoc = styled.div`
  width: fit-content;
  margin: auto;
  position: relative;
`

const StyledButtonLots = styled(ButtonLots) <ButtonProps>`
  min-width: 100px;
  background-color: ${({ showLots }) => isEqual(showLots, true) ? '#0049EE' : 'transparent'};

  & > div {
    margin-left:2px;
    justify-content: space-between;

  & svg {
    width: 20px;
    height: 20px;
    margin: 0;
    transform: ${({ showLots }) => isEqual(showLots, true) ? 'rotate(180deg)' : ''};
    transition: transform .2s ease;
  }

  & span {
    font-size: 18px;
  }
`

const DivLots = styled.div<ButtonProps>`
  height: ${({ showLots }) => isEqual(showLots, true) ? 'auto' : 0};
  opacity: ${({ showLots }) => isEqual(showLots, true) ? 1 : 0};
  padding: ${({ showLots }) => isEqual(showLots, true) ? 1 : 0}px 0;
  transition: all 0.5s ease;
`

const DocumentList = styled.div`
  gap: 8px;
  top: 40px;
  left: -70px;
  width: auto;
  cursor: auto;
  height: fit-content;
  z-index: 100;
  padding: 8px;
  display: flex;
  position: absolute;
  min-width: 157px;
  flex-wrap: wrap;
  background: ${({ theme }) => get(theme, 'lightBlue')};
  box-shadow: 0 1px 4px 0 rgba(51, 102, 204, 0.30);
  border-radius: 8px;
      
  &::after {
    top: -12px;
    left: 68px;
    content: ""; 
    position: absolute;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid ${({ theme }) => get(theme, 'lightBlue')};
  }

  & > svg {
    cursor: pointer;
  }
`

const Documents = styled.div`
  color: ${({ theme }) => get(theme, 'blue')};
  width: 80%;
  cursor: pointer;
  flex-grow: 1;
`

const StyledBadge = styled(Badge)`
  cursor: pointer;
  transform: scale(0.75);
  background: transparent;
  margin-left: -16px;
  border-color: ${({ theme }) => get(theme, 'blue')};
  padding-left: 2px;
  border-radius: 25px;

  & svg {
    transform: scale(0.75);
  }
`

const StyledModal = styled(Modal)`
  width: 1000px;
  min-width: 1000px;

  ${media.greaterThan('xl')`
    width: 1000px;
    min-width: 1000px;
  `}
`

const StyledTooltipTable = styled(TooltipTable)`
  width: 160px;
  top: 40px;
  left:50%;
  margin-left:-78px;
  width: 160px;

  &:before {
    top:-40%;
    left: 43%;
  }
`
const getProgramLotsBody = (lotOptions: any): BodyFieldType[] => {
  if (isEqual(get(lotOptions, 'clientFavorites'), true)) {
    return BODY_LOT_FAVORITE
  }

  return BODY_LOTS
}

const getProgramLotsHeader = (lotOptions: any): Array<HeaderFieldType<unknown>> => {
  return HEADER_LOTS
}

const getProgramLotsOptions = (lotOptions: any): TableOptionsType => {
  return {
    bodyColor: 'extraLightBlue',
    headerColor: 'lightGrey',
    columnAlignment: '0.15fr 0.15fr 0.25fr 0.25fr 0.35fr 0.3fr 0.2fr 0.35fr 0.2fr 0.6fr 0.4fr 0.15fr 0.2fr 0.15fr'
  }
}

const ProgramLots = ({ data, meta, showLots }: ProgramLotsProps): JSX.Element => {
  const [searchParams] = useSearchParams()
  const querySearch = qs.parse(searchParams.toString())
  const programId = get(data, 'id', '')
  const { data: program, isFetching } = useFetchProgramQuery(programId as string, querySearch)

  const body = getProgramLotsBody(get(meta, 'lot'))
  const header = getProgramLotsHeader(get(meta, 'lot'))
  const options = getProgramLotsOptions(get(meta, 'lot'))

  const lots = sortLots(get(program, 'data.lots'))

  if (isEqual(showLots, false)) {
    return (
      <></>
    )
  }

  if (isEqual(isFetching, true)) {
    return (
      <ContainerRow options={options}>
        <Row options={{ ...options, columnAlignment: '1fr' }}>
          <LoadingDots />
        </Row>
      </ContainerRow>
    )
  }

  return (
    <Table
      row={LotRow}
      data={lots}
      meta={{ ...meta, programId: get(data, 'id'), programName: get(data, 'attributes.name') }}
      body={body}
      header={header}
      options={options}
      noPadding />
  )
}

const ProgramRowLoaded = ({ data, fields, options, meta }: ProgramRowProps): JSX.Element => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const [showLots, updateShowLots] = useState(false)
  const [isOpenDocs, updateIsOpenDocs] = useState(false)
  const [isOpenDocForm, updateIsOpenDocForm] = useState(false)
  const [isOpenFavorite, updateIsOpenFavorite] = useState(false)
  const programId: string = get(data, 'id', 0).toString()
  const { data: item, isFetching } = useFetchProgramImageQuery(programId, true)
  const { data: fetchDocuments, isFetching: isFetchingDocuments } = useFetchProgramDocumentsQuery(programId)
  const documents = fetchDocuments ?? []

  const openProgramPage = useCallback(() => {
    const url = `/programs/${programId}`

    if (!isEqual(isNull(searchParams.get('alert_id')), true)) {
      const win = window.open(`${url}?alert_id=${searchParams.get('alert_id') as string}`, '_blank')

      if (win != null) {
        win.focus()
      }
    }

    const win = window.open(url, '_blank')

    if (win != null) {
      win.focus()
    }
  }, [data])
  const toggleShowLots = useCallback(evt => {
    evt.stopPropagation()

    return updateShowLots(!showLots)
  }, [updateShowLots, showLots])

  const openDocument = useCallback((evt, url) => {
    evt.stopPropagation()

    if (!isEmpty(url)) {
      const win = window.open(url, '_blank')

      if (win != null) {
        win.focus()
      }
    }
  }, [])
  const openFavorites = useCallback(evt => {
    evt.stopPropagation()

    return updateIsOpenFavorite(!isOpenFavorite)
  }, [updateIsOpenFavorite, isOpenFavorite])

  return (
    <StyledContainerRow options={options}>
      <Row options={options}>
        {map(fields, ({ key, values }) => {
          if (isEqual(key, 'images')) {
            return (
              <StyledData key={key} onClick={openProgramPage} left column noPadding>
                <ImagePreview>
                  <DisplayImage data={item} isFetching={isFetching} />
                </ImagePreview>
              </StyledData>
            )
          }

          if (isEqual(key, 'name')) {
            return (
              <LargeData key={key} column onClick={openProgramPage} gap={4}>
                <LineText color='primary' strong>
                  {capitalize(get(data, `attributes.${key}`, ''))}
                </LineText>
                <LineText textType='small' color='primary' capitalize='none'>
                  {get(data, 'attributes.sources_display', '')}
                </LineText>
                <FlexBox row>
                  <Icon variant='pin-solid' transform='scale(0.8) translateX(-4px)' fill />
                  <LineText color='primary'>
                    {get(data, 'attributes.display_city_list', '')}
                  </LineText>
                </FlexBox>
              </LargeData>
            )
          }

          if (includes(['firstDetails', 'secondDetails'], key)) {
            return (
              <Data key={key} onClick={openProgramPage}>
                {map(values, ({ key, title }) => (
                  <Field
                    key={key}
                    name={key}
                    value={get(data, key)}
                    title={title} />
                ))}
              </Data>
            )
          }

          if (isEqual(key, 'lots')) {
            return (
              <Data key={key}>
                <StyledButtonLots
                  label={t('PROGRAM.lotsCount', { count: size(get(data, 'relationships.lots.data', [])) })}
                  outline={isEqual(showLots, false)}
                  onClick={toggleShowLots}
                  showLots={showLots}
                  importance='regular' />
              </Data>
            )
          }

          if (isEqual(key, 'priceRange.minPrice')) {
            return (
              <Data key={key} onClick={openProgramPage}>
                <Field
                  key={key}
                  name={key}
                  value={sortBy(get(data, 'attributes.min_price'))} />
              </Data>
            )
          }

          if (isEqual(key, 'brochureLink')) {
            if (isEqual(isFetchingDocuments, true)) {
              return (
                <Data key={key}>
                  <div>...</div>
                </Data>
              )
            }

            if (isEmpty(documents)) {
              return (
                <DataDoc key={key}>
                  <StyledTooltipTable
                    color='mediumPrimary'>
                    {t('DOCUMENTS.addNewDocument')}
                  </StyledTooltipTable>
                  <Icon variant='plus-circle' onClick={() => updateIsOpenDocForm(true)} stroke />
                  <Modal
                    isOpen={isOpenDocForm}
                    backgroundBlur
                    onRequestClose={() => updateIsOpenDocForm(false)}>
                    <ProgramDocumentForm
                      programId={programId}
                      updateOpen={updateIsOpenDocForm}
                      programName={get(data, 'attributes.name', '')} />
                  </Modal>
                </DataDoc>
              )
            }

            return (
              <Data key={key}>
                <FieldDoc>
                  <Icon variant='download-file-solid' transform='scale(1.3)' onClick={() => updateIsOpenDocs(true)} fill />
                  {isEqual(isOpenDocs, true) &&
                    <DocumentList>
                      <Documents>
                        <InlineStart gap={4} alignStart>
                          {map(documents, doc => {
                            const key = `DOCUMENTS.${get(doc, 'fileType', '')}`
                            return (
                              <div onClick={(evt) => openDocument(evt, get(doc, 'url', ''))}>
                                <Text color='primary'>{t<string>(key)}</Text>
                              </div>
                            )
                          })}
                        </InlineStart>
                      </Documents>
                      <Icon variant='close' onClick={() => updateIsOpenDocs(false)} stroke />
                      <StyledBadge
                        variant='selection'
                        importance='regular'
                        outline >
                        <FlexBox
                          row
                          onClick={() => updateIsOpenDocForm(true)} >
                          <Icon variant='plus' stroke />
                          <Text strong color='primary'>{t('DOCUMENTS.addDocumentShort')}</Text>
                        </FlexBox>
                      </StyledBadge>
                      <StyledModal
                        isOpen={isOpenDocForm}
                        backgroundBlur
                        onRequestClose={() => updateIsOpenDocForm(false)}>
                        <ProgramDocumentForm
                          programId={programId}
                          updateOpen={updateIsOpenDocForm}
                          programName={get(data, 'attributes.name', '')} />
                      </StyledModal>
                    </DocumentList>
                  }
                </FieldDoc>
              </Data>
            )
          }

          if (isEqual(key, 'taxations')) {
            return (
              <Data key={key} onClick={openProgramPage}>
                <Field
                  key={key}
                  name={key}
                  value={formatTaxations(get(data, key))} />
              </Data>
            )
          }

          if (isEqual(key, 'deliveryDate')) {
            return (
              <Data key={key} onClick={openProgramPage}>
                <Field
                  key={key}
                  name={key}
                  value={getDeliveryDate(new Date(get(data, 'attributes.delivery_date', '')))} />
              </Data>
            )
          }

          if (isEqual(key, 'actability')) {
            return (
              <Data key={key} onClick={openProgramPage}>
                <Field
                  key={key}
                  name={key}
                  value={isActable(new Date(get(data, 'attributes.actability_date', '')))} />
              </Data>
            )
          }

          if (isEqual(key, 'typeLots')) {
            return (
              <Data key={key} onClick={openProgramPage}>
                <Field
                  key={key}
                  name={key}
                  value={get(data, 'attributes.type_lots', '')} />
              </Data>
            )
          }

          if (isEqual(key, 'city.taxArea')) {
            return (
              <Data key={key} onClick={openProgramPage}>
                <Field
                  key={key}
                  name={key}
                  value={get(data, 'attributes.pinel_zone', '')} />
              </Data>
            )
          }

          if (isEqual(key, 'clientFavorites')) {
            return (
              <FavoriteCheckbox setFavorite={(val) => get(meta, 'lot.setFavorite')(
                get(data, 'id'),
                map(get(data, 'relationships.lots.data', []), elt => get(elt, 'id')),
                val)} />
            )
          }

          if (isEqual(key, 'favorites')) {
            return (
              <Data key={key}>
                <Icon variant='favorite' color='danger' transform='scale(1.2)' onClick={openFavorites} cursor stroke />
                <Modal
                  isOpen={isOpenFavorite}
                  onRequestClose={() => updateIsOpenFavorite(false)}
                  backgroundBlur>
                  <AddFavoriteModal programId={get(data, 'id', 0)} lotIds={map(get(data, 'relationships.lots.data'), 'id')} closeModal={() => updateIsOpenFavorite(false)} />
                </Modal>
              </Data>
            )
          }

          return (
            <Data key={key} onClick={openProgramPage}>
              <Field
                key={key}
                name={key}
                value={get(data, key)} />
            </Data>
          )
        })}
      </Row>
      <DivLots showLots={showLots}>
        {showLots && <ProgramLots
          data={data}
          meta={meta}
          showLots={showLots} />}
      </DivLots>
    </StyledContainerRow >
  )
}

const ProgramRow = ({ data, fields, options, meta }: ProgramRowProps): JSX.Element => (
  <ProgramRowLoaded
    data={data}
    fields={fields}
    options={options}
    meta={meta} />
)

export default ProgramRow
