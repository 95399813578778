import styled from 'styled-components'
import { Text as PlainText } from '@folhomee/front-library'
import { get, isUndefined, isEqual } from 'lodash'

import { TextProps, StyledTextProps } from './Text.types'

const FONT_SIZES = {
  small: 13,
  title: 30,
  bigger: 18,
  regular: 15,
  subtitle: 20,
  big: 30,
  smallest: 11
}

const MARGIN = {
  title: 24,
  big: 8
}

const COLOR = {
  white: '#FFFFFF',
  info: '#cedeff',
  danger: '#FF6600',
  primary: '#0049EE',
  success: '#5fc34b',
  warning: '#FF9900',
  secondary: '#707070',
  lightDanger: '#ffe8d8',
  lightWarning: '#fff6d8',
  lightPrimary: '#fff6d8',
  lightSecondary: '#f4f4f4',
  mediumSecondary: '#b5b5b5'
}

const StyledText = styled(PlainText) <StyledTextProps>`
  color: ${({ customProps, theme }) => get(COLOR, get(customProps, 'color') ?? 'danger', `${get(theme, 'darkGrey', '#FFF') as string}`)};
  font-size: ${({ customProps, fontSize }) => isUndefined(fontSize) ? get(FONT_SIZES, get(customProps, 'type') ?? 'regular', 15) : fontSize}px;
  margin-top: ${({ customProps, top }) => isEqual(get(customProps, 'type'), 'title') ? get(MARGIN, get(customProps, 'type') ?? 'title', 0) : isUndefined(top) ? '0' : top}px !important;
  text-align: ${({ center, textAlign }) => isEqual(center, true) ? 'center' : isUndefined(textAlign) ? 'start' : textAlign};
  margin-left: ${({ left, center }) => isEqual(center, true) ? 'auto' : isUndefined(left) ? '0' : left}px;
  line-height: normal;
  margin-right: ${({ right, center }) => isEqual(center, true) ? 'auto' : isUndefined(right) ? '0' : right}px;
  margin-bottom: ${({ customProps, bottom }) => isEqual(get(customProps, 'type'), 'title') ? get(MARGIN, get(customProps, 'type') ?? 'title', 0) : isUndefined(bottom) ? '0' : bottom}px !important;
  text-transform: ${({ capitalize }) => isUndefined(capitalize) ? 'inherit' : capitalize};
`

const Text = ({
  textType = 'regular', color = 'secondary',
  children, fontSize, top, bottom, left, center, textAlign, capitalize,
  ...props
}: TextProps): JSX.Element => {
  return (
    <StyledText
      {...props}
      customProps={{
        type: textType,
        color: color
      }}
      type='regular'
      color={color}
      variant='secondary'
      fontSize={fontSize}
    >
      {children}
    </StyledText>
  )
}

export default Text
