import styled from 'styled-components'
import { get } from 'lodash'
import { spacings } from '@folhomee/front-library'

import Background from '../../../assets/images/bg-home.png'

const AuthContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  padding: ${get(spacings, 's')}px;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-image: url(${Background});
  background-position: center;
`

export default AuthContainer
