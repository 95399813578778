import styled from 'styled-components'
import { get, isEqual, map, omit, toUpper, capitalize, isUndefined } from 'lodash'

import Text from '../../atoms/Text'
import { useTranslation } from 'react-i18next'
import { ProgramListProps } from './AlertClient.types'

const Container = styled.div`
  gap: 8px;
  display: flex;
  padding-top: 32px;
  flex-direction: column;
`

const Inline = styled.a`
  gap: 4px;
  cursor: pointer;
  display: flex;
  text-decoration: none;
`

const AlertClient = ({ data }: ProgramListProps): JSX.Element => {
  const { t } = useTranslation()

  const client = {
    name: {
      last: toUpper(get(data, 'client.lastname')),
      first: capitalize(get(data, 'client.firstname'))
    },
    ...omit(get(data, 'client'), ['lastname', 'firstname', 'pipedrive'])
  }

  if (isUndefined(data)) {
    return (
      <Container>
        <Text
          color='primary'
          strong >
          {t('ALERTS.noClient')}
        </Text>
      </Container>
    )
  }

  return (
    <Container>
      {map(client, (value, key) => {
        if (isEqual(key, 'name')) {
          return (
            <Inline href={`https://contactfolhomeefr.pipedrive.com/person/${get(data, 'data.client.pipedrive', '') as string}`} target='_blank'>
              <Text
                color='primary'
                strong >
                {get(value, 'first')}
              </Text>
              <Text
                color='primary'
                strong >
                {get(value, 'last')}
              </Text>
            </Inline>
          )
        }

        return (
          <Text
            color='primary'>
            {get(client, key)}
          </Text>
        )
      })}
    </Container>
  )
}

export default AlertClient
