import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { get, size, join, map, reduce, isEqual, first, last, capitalize } from 'lodash'

import Box from '../../atoms/Box'
import Text from '../../atoms/Text'
import Icon from '../../atoms/Icon'
import FlexBox from '../../atoms/FlexBox'
import numeral from '../../../services/numeral'
import HighlightedText from '../../atoms/HighlightedText'
import { formatTaxations } from '../../../utils/taxations'
import { ProgramSummaryProps } from './ProgramSummary.types'

const StyledFlexBox = styled(FlexBox)`
  background-color: ${({ theme }) => get(theme, 'lightBlue')};
  border-radius: 10px;
  display: inline-flex;
`

const LastSection = styled.div`
  width: 100%;
`

const TitleBox = styled.div`
  gap: 4px;
  width: 50%;
  display: flex;
  align-items: center;
`

const TopSection = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
`

const LinkToSource = styled(Link)`
  gap: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
`

const StyledText = styled(Text)`
  overflow: hidden;
  max-width: 411px;
  white-space: nowrap;
  text-overflow: ellipsis;
  
  &:hover {
    z-index: 9999;
    overflow: visible;
  }

  & > span:hover {
    z-index: 9999;
    background-color: #FFFFFF;
  }
`

const ProgramSummary = ({ program }: ProgramSummaryProps): JSX.Element => {
  const { t } = useTranslation()

  const actable = isEqual(get(program, 'actability', false), true) ? 'PROGRAM.actable' : 'PROGRAM.nonActable'
  const availableLots = reduce(get(program, 'lots'), (acc, lot) => {
    if (isEqual(get(lot, 'status'), 'available')) {
      return acc + 1
    }
    return acc
  }, 0)

  return (
    <FlexBox gap={8} justifyContent='space-around' alignStart width100>
      <TopSection>
        <StyledText
          textType='subtitle'
          top={0}
          strong>
          <span>
            {get(program, 'name')}
          </span>
        </StyledText>
        <FlexBox row gap={4} width100 alignStart>
          <Icon variant='pin-solid' fill color='secondary' transform='translateY(1.5px)' />
          <FlexBox alignStart width100>
            <Text
              textType='bigger'
              strong>
              {capitalize(get(program, 'address.text'))}
            </Text>
            <Text strong>
              {get(program, 'city.name')} ({get(program, 'city.department.code')} · {get(program, 'city.department.name')})
            </Text>
          </FlexBox>
        </FlexBox>
        {map(get(program, 'sources'), source => (
          <FlexBox row gap={4}>
            <Icon variant='build' transform='scale(0.9)' stroke />
            <LinkToSource to={`/promoters/${get(source, 'id')}`}>
              <Text color='primary'>
                {get(source, 'name')}
              </Text>
            </LinkToSource>
          </FlexBox>
        ))}
      </TopSection>
      <StyledFlexBox padding='8px 16px' alignStart gap={8} row wrap>
        <FlexBox alignStart justifyContent='space-between' width100 row>
          <HighlightedText
            text={`${t<string>('PROGRAM.delivery')} ${get(program, 'deliveryDate')}`}
            color='blue'
            strong />
          <HighlightedText
            text={t(actable)}
            color={isEqual(get(program, 'actability', false), true) ? 'success' : 'danger'}
            strong={true} />
        </FlexBox>
        <HighlightedText
          text={t<string>('PROGRAM.priceRange', { minPrice: numeral(first(get(program, 'priceRange.minPrice'))).format('0,0$'), maxPrice: numeral(last(get(program, 'priceRange.maxPrice'))).format('0,0$') })}
          color='blue'
          strong
          flexGrow />
      </StyledFlexBox>
      <LastSection>
        <Box top={true}>
          <TitleBox>
            <Icon stroke variant='calculator' color='secondary' />
            <Text>
              {t('COMMON.taxations')}
            </Text>
          </TitleBox>
          <Text strong>
            {join(formatTaxations(get(program, 'taxations', [])), ', ')}
          </Text>
        </Box>
        <Box top={true}>
          <TitleBox>
            <Icon
              variant='pin'
              color='secondary'
              stroke />
            <Text>
              {t('COMMON.fiscalZone')}
            </Text>
          </TitleBox>
          <Text strong>
            {get(program, 'city.taxArea')}
          </Text>
        </Box>
        <Box top={true}>
          <TitleBox>
            <Icon
              color='secondary'
              variant='program'
              stroke />
            <Text>
              {t('PROGRAM.availableLots')}
            </Text>
          </TitleBox>
          <Text strong >
            {availableLots} / {size(get(program, 'lots'))}
          </Text>
        </Box>
        <Box top={true} bottom={true}>
          <TitleBox>
            <Icon
              variant='typologie'
              color='secondary'
              stroke />
            <Text>
              {t('LOT.typology')}
            </Text>
          </TitleBox>
          <Text strong>
            {get(program, 'typeLots')}
          </Text>
        </Box>
      </LastSection>
    </FlexBox>
  )
}

export default ProgramSummary
