import styled from 'styled-components'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { get, reduce, isEmpty, filter } from 'lodash'

import Table from '../../organisms/Table'
import Loader from '../LoaderDot'
import ProgramRow from '../../molecules/ProgramRow'
import TableBottom from '../../molecules/TableBottom'
import { ClientFavoriteProps } from './ClientFavorite.types'
import { useMutateClientProgramQuery } from '../../../queries/Clients'
import { useFetchInfiniteProgramsQuery } from '../../../queries/Programs'
import { BODY_FAVORITE, HEADER_FAVORITE } from '../../../utils/tables/programs'

const Container = styled.div`
  width: 100%;
  height: 100%;
`

const ClientFavorite = ({ data: client }: ClientFavoriteProps): JSX.Element => {
  const [, setSearchParams] = useSearchParams()
  const { data: programs, isFetching, fetchNextPage } = useFetchInfiniteProgramsQuery({ client: get(client, 'id'), favorite: 'true' })
  const { mutateAsync } = useMutateClientProgramQuery(get(client, 'id'))

  const setFavorite = async (programId: number, lots: number[], isFavorite: boolean): Promise<unknown> =>
    await mutateAsync({ favorites: [{ client_id: get(client, 'id'), is_favorite: isFavorite }], lots, programId })

  const displayData = reduce(get(programs, 'pages', []), (acc: any[], items: any[]): any[] =>
    !isEmpty(get(items, 'data')) ? [...acc, ...filter(get(items, 'data', []), val => !isEmpty(val))] : acc, [])

  const options = {
    bodyColor: 'lightBlue',
    headerColor: 'transparent',
    columnAlignment: '1fr 0.9fr 0.5fr 0.7fr 0.5fr 0.8fr 0.5fr 0.5fr 0.5fr 0.8fr'
  }

  useEffect(() => {
    setSearchParams({
      client: get(client, 'id').toString(),
      favorite: 'true'
    })
  }, [])

  return (
    <Container>
      <Loader loading={isFetching} />
      <Table
        row={ProgramRow}
        data={displayData}
        body={BODY_FAVORITE}
        header={HEADER_FAVORITE}
        meta={{ lot: { setFavorite, clientFavorites: true } }}
        options={options} />
      <TableBottom
        loading={isFetching}
        fetchNextPage={fetchNextPage} />
    </Container>
  )
}

export default ClientFavorite
