import styled from 'styled-components'
import { get, isEqual } from 'lodash'
import { spacings, media } from '@folhomee/front-library'

import Loader from '../../molecules/LoaderDot'
import { LoginFormProps } from './LoginForm.types'
import { ReactComponent as Mascot } from '../../../assets/svgs/mascot.svg'

const Container = styled.div`
  gap: ${get(spacings, 'm')}px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ImageContainer = styled.div`
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: end;

  ${media.lessThan('md')`
    display: none;
  `}
`

const FormContainer = styled.form`
  gap: ${get(spacings, 'xs')}px;
  flex: 2;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: ${get(spacings, 'm')}px;
  padding-bottom: ${get(spacings, 'm')}px;
`

const ImageColumn = (): JSX.Element => (
  <ImageContainer>
    <Mascot />
  </ImageContainer>
)

const LoginForm = ({ loading, withoutImage, children }: LoginFormProps): JSX.Element => (
  <Container>
    {!isEqual(withoutImage, true) && <ImageColumn />}
    <FormContainer>
      <Loader loading={loading} />
      {children}
    </FormContainer>
  </Container>
)

export default LoginForm
