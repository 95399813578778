import List from '../../templates/List'
import RegionRow from '../../molecules/RegionRow'
import { useFetchInfiniteRegionsQuery } from '../../../queries/Regions'

const HEADER = [{
  label: 'COMMON.name',
  textLeft: true
}, {
  label: 'REGIONS.code'
}]

const BODY = [{
  key: 'name',
  title: 'name'
}, {
  key: 'code',
  title: 'code'
}]

const Regions = (): JSX.Element => {
  const options = {
    bodyColor: 'extraLightBlue',
    headerColor: 'transparent',
    columnAlignment: '1fr 1fr'
  }

  return (
    <List
      row={RegionRow}
      body={BODY}
      title='regions'
      header={HEADER}
      options={options}
      useFetch={useFetchInfiniteRegionsQuery}
    />
  )
}

export default Regions
