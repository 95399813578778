import styled from 'styled-components'
import { Icon as PlainIcon } from '@folhomee/front-library'
import { get, isEqual, isUndefined } from 'lodash'

import { IconProps, StyledIconProps } from './Icon.types'

const COLOR = {
  info: '#cedeff',
  white: 'white',
  danger: '#FF6600',
  primary: '#0049EE',
  success: '#5fc34b',
  warning: '#FF9900',
  secondary: '#707070',
  lightDanger: '#ffe8d8',
  lightWarning: '#fff6d8',
  lightSecondary: '#f4f4f4',
  mediumSecondary: '#b5b5b5'
}

const StyledIcon = styled(PlainIcon) <StyledIconProps>`
  cursor: ${({ cursor }) => isEqual(cursor, true) ? 'pointer' : 'default'};
  overflow: visible;
  transform: ${({ transform }) => isUndefined(transform) ? 'none' : transform};

  & > path {
    fill: ${({ customProps, fill }) => isEqual(fill, true) ? get(COLOR, get(customProps, 'color') ?? 'primary', 'none') : 'none'} !important;
    stroke: ${({ customProps, stroke }) => isEqual(stroke, true) ? get(COLOR, get(customProps, 'color') ?? 'primary', 'none') : 'none'} !important;
  }

  & > circle {
    fill: ${({ customProps, fill }) => isEqual(fill, true) ? get(COLOR, get(customProps, 'color') ?? 'primary', 'none') : 'none'} !important;
    stroke: ${({ customProps, stroke }) => isEqual(stroke, true) ? get(COLOR, get(customProps, 'color') ?? 'primary', 'none') : 'none'} !important;
  }

  & > rect {
    fill: ${({ customProps, fill }) => isEqual(fill, true) ? get(COLOR, get(customProps, 'color') ?? 'primary', 'none') : 'none'} !important;
    stroke: ${({ customProps, stroke }) => isEqual(stroke, true) ? get(COLOR, get(customProps, 'color') ?? 'primary', 'none') : 'none'} !important;
  }
`

const Icon = ({
  color = 'primary',
  fill, stroke, variant, transform,
  ...props
}: IconProps): JSX.Element => {
  return (
    <StyledIcon
      {...props}
      customProps={{
        color: color
      }}
      fill={fill}
      stroke={stroke}
      variant={variant}
      transform={transform}
    />
  )
}
export default Icon
