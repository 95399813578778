import styled from 'styled-components'
import { Icon } from '@folhomee/front-library'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { get, isEqual, isUndefined, map } from 'lodash'

import Text from '../../atoms/Text'
import { OptionsProps } from '../../organisms/Table/Table.types'
import { OptionIconProps } from '../../molecules/SearchForm/SearchForm.types'
import { Order, OrderInfos, HeaderFieldType, TableHeaderProps, InlineHeaderProps } from './TableHeader.types'

const ArrowDown = styled.div`
  width: 0;
  height: 0;
  cursor: pointer;
  margin-left: 2px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid ${({ theme }) => get(theme, 'darkGrey')};
`

const getOrder = (field: string, orderInfos: OrderInfos): Order => {
  if (isEqual(field, get(orderInfos, 'field'))) {
    return isEqual(get(orderInfos, 'order'), 'asc') ? 'desc' : 'asc'
  }
  return 'asc'
}

const Thead = styled.div<OptionsProps>`
  gap: 8px;
  display: inline-grid;
  text-align: center;
  align-items: center;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid ${({ theme }) => get(theme, 'lightGrey', '#fff')};
  background-color: ${({ theme, options }) => get(theme, get(options, 'headerColor'))};
  grid-template-columns: ${({ options }) => get(options, 'columnAlignment')};
`

const HeaderIcon = styled(Icon) <OptionIconProps>`
  & circle, path {
    fill: ${({ theme, isFilled }) => isEqual(isFilled, true) ? `${get(theme, 'darkGrey', '#FFF') as string} !important` : ''}; 
    stroke: ${({ theme }) => get(theme, 'darkGrey', '#FFF')} !important;
  }
`

const StyledText = styled(Text) <InlineHeaderProps>`
  padding: 2px 0;
  font-size: 14px;
  text-align: ${({ textLeft }) => isEqual(textLeft, true) ? 'start' : 'center'};
  padding-left: ${({ textLeft }) => isEqual(textLeft, true) ? '8px' : 0};
`

const HeaderTitle = <T extends unknown>({ label, icon, OptionIconProps }: HeaderFieldType<T>): JSX.Element => {
  const { t } = useTranslation()
  if (!isUndefined(icon)) {
    return (
      <HeaderIcon variant={icon} isFilled={get(OptionIconProps, 'isFilled', false)} />
    )
  }
  return (
    <>
      {t<string>(label)}
    </>
  )
}

const Header = <T extends unknown>({ data, fields, options, visible, updateData }: TableHeaderProps<T>): JSX.Element => {
  const [orderInfos, updateOrderInfos] = useState<OrderInfos>({ field: '', order: 'asc' })
  return (
    <Thead options={options}>
      {visible && map(fields, ({ label, onClick, icon, textLeft, OptionIconProps }: HeaderFieldType<T>, key) => (
        <div key={key}>
          <StyledText textLeft={isEqual(textLeft, true)} strong={true}>
            <HeaderTitle icon={icon} label={label} OptionIconProps={OptionIconProps} />
          </StyledText>
          {!isUndefined(onClick) && <ArrowDown onClick={(event) => {
            const order = getOrder(label, orderInfos)
            updateOrderInfos({
              field: label,
              order: order
            })
            onClick(event, data, order, updateData)
          }} />
          }
        </div>
      ))}
    </Thead>
  )
}

export default Header
