import styled from 'styled-components'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useCallback, useMemo } from 'react'
import { Button, Text, spacings } from '@folhomee/front-library'
import { find, get, isEqual, isObject, isUndefined, map, reduce, snakeCase } from 'lodash'

import SearchInput from '../../molecules/SearchInput'
import { Region } from '../../../queries/Regions/Regions.types'
import { UpdateMeProps } from './UpdateMe.types'
import { useMutateUserQuery } from '../../../queries/Users'
import { useFetchInfiniteRegionsQuery } from '../../../queries/Regions'

const Container = styled.div`
  width: 320px;
  display: flex;
  padding: ${get(spacings, 's')}px;
  flex-direction: column;
`

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  padding: ${get(spacings, 'xs')}px;
  flex-direction: column;
`

const UpdateMe = ({ user }: UpdateMeProps): JSX.Element => {
  const { t } = useTranslation()
  const { mutateAsync, status } = useMutateUserQuery(get(user, 'id', 'me'))
  const { data: regionsPage } = useFetchInfiniteRegionsQuery({})

  const regions = reduce(get(regionsPage, 'pages', []), (acc: Region[], page) => [...acc, ...get(page, 'data', [])], [])
  const userRegion = useMemo(() => {
    return get(find(regions, region => get(region, 'id') - get(user, 'region') === 0), 'name')
  }, [regions])

  const onSubmit = useCallback(async (values: any) => {
    const toSendValues = reduce(values, (acc: any, value: any, name: string): any => ({
      ...acc,
      [snakeCase(name)]: isObject(value) ? get(value, 'value') : value
    }), {})

    await mutateAsync(toSendValues)
  }, [mutateAsync])

  const { handleChange, handleBlur, values, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      lastName: get(user, 'lastName'),
      firstName: get(user, 'firstName'),
      regionId: get(user, 'region'),
      devise: get(user, 'devise')
    },
    onSubmit
  })

  const setFieldValueHandler = useCallback(async (field: string, value: any, add?: boolean): Promise<void> => {
    if (!isUndefined(add) && isEqual(add, false)) {
      await setFieldValue(field, '')
      return
    }

    await setFieldValue(field, value)
  }, [])

  const onClick = useCallback(() => {
    handleSubmit()
  }, [handleSubmit])

  const fields = [{
    name: 'lastName',
    type: 'text',
    title: 'COMMON.name',
    options: [],
    placeholder: get(user, 'lastName')
  }, {
    name: 'firstName',
    type: 'text',
    title: 'COMMON.firstName',
    options: [],
    placeholder: get(user, 'firstName')
  }, {
    name: 'regionId',
    type: 'select',
    title: 'REGIONS.singleTitle',
    options: map(regions, (label) => ({
      value: get(label, 'id'), label: get(label, 'name')
    })),
    placeholder: userRegion
  }, {
    name: 'devise',
    type: 'area',
    title: 'USER.devise',
    options: [],
    placeholder: get(user, 'devise')
  }]

  return (
    <Container>
    {map(fields, ({ name, title, type, options, placeholder }) => (
      <InputContainer>
        <Text
          type='regular'
          strong={true}
          variant='secondary'>
          {t<string>(title)}
        </Text>
        <SearchInput
          key={name}
          name={name}
          type={type}
          values={values}
          options={options}
          multiple={false}
          handleBlur={handleBlur}
          placeholder={String(placeholder)}
          handleChange={handleChange}
          setFieldValue={setFieldValueHandler} />
      </InputContainer>
    ))}
    <Button
      label={t('COMMON.validate')}
      variant='primary'
      type='submit'
      onClick={onClick}
      disabled={isEqual(status, 'loading')}
      importance='small' />
    </Container>
  )
}

export default UpdateMe
