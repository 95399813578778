import styled from 'styled-components'

import Icon from '../Icon'
import ButtonRound from '../ButtonRound'
import { ContactButtonProps } from './ContactButton.types'

// const StyledTooltipContent = styled(TooltipContent)`
//   color: ${({ theme }) => get(theme, 'green', '#fff')};
//   background: #e3ffd8d4;
// `

const Container = styled.div`
  position: relative;
`
const ContactButton = ({ onClick }: ContactButtonProps): JSX.Element => {
  return (
    <Container onClick={onClick}>
      <ButtonRound
        color='lightSuccess'
        buttonSize='regular'
        data-tooltip-id="ContactTooltip"
        onClick={onClick}>
        <Icon
          variant='phone-solid'
          color='success'
          transform='scale(1.2)'
          fill
          cursor />
      </ButtonRound>
      {/* <StyledTooltipContent
        id="ContactTooltip">
        {t('PROGRAM.seeContacts')}
      </StyledTooltipContent> */}
    </Container>
  )
}

export default ContactButton
