import styled from 'styled-components'

import LoadingDots from '../../atoms/LoadingDots'
import { LoaderProps } from './Loader.types'

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
  opacity: 0.5;
  max-width: 60px;
  margin-top: 8px;
  border-radius: 25px;
`

const Loader = ({ loading }: LoaderProps): JSX.Element | null => {
  const shouldDisplay: boolean = loading ?? false

  if (!shouldDisplay) {
    return null
  }

  return (
    <LoaderContainer data-testid='loader' >
      <LoadingDots />
    </LoaderContainer>
  )
}

export default Loader
