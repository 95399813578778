import { get, find, isEqual } from 'lodash'

import { City } from '../Cities/Cities.types'
import { Department } from '../Departments/Departments.types'
import { IncludeData } from './jsonapi.types'
import { getFieldById, getObjectById, getRelationships } from '.'

export const getCity = (data: IncludeData[], cityId: number): City => {
  const { attributes, relationships } = find(data, ({ id, type }) => isEqual(type, 'city') && isEqual(cityId, id)) ?? { attributes: [], relationships: [] }
  const ships = getRelationships(relationships, ['tax_area', 'department'], [])

  return {
    id: get(attributes, 'id', 0),
    name: get(attributes, 'name', 'N/A'),
    insee: get(attributes, 'insee', 'N/A'),
    zipcode: get(attributes, 'zipcode', 'N/A'),
    taxArea: getFieldById(data, get(ships, 'tax_area', 0), 'tax_area', 'attributes.name'),
    department: getObjectById(data, get(ships, 'department', 0), 'department', { id: 'id', name: 'name', code: 'code', region: 'region' }) as Department,
    image_type: get(attributes, 'image_type', 0),
    acquisition: get(attributes, 'acquisition', false)
  }
}
