/* eslint-disable @typescript-eslint/restrict-template-expressions */
import styled from 'styled-components'
import { spacings } from '@folhomee/front-library'
import { useTranslation } from 'react-i18next'
import { find, get, isEmpty, isEqual, isUndefined, join, lowerCase, map, reduce, startCase, upperCase } from 'lodash'

import Text from '../../atoms/Text'
import { Region } from '../../../queries/Regions/Regions.types'
import { ContactProgramModalProps } from '../ContactProgramModal/ContactProgramModal.types'
import { useFetchInfiniteRegionsQuery } from '../../../queries/Regions'

const StyledContactLink = styled.a`
  text-decoration: none;

  & > p {
    text-transform: lowercase;
  }
`

const Line = styled.div`
  width: 95%;
  border: 1px solid;
  margin: 0 0 ${get(spacings, 'xxs')}px;
  border-color: ${({ theme }) => get(theme, 'blue')};
  margin-right: ${get(spacings, 'm')}px;
`

const ContactProgramModal = ({ contacts }: ContactProgramModalProps): JSX.Element => {
  const { t } = useTranslation()

  const { data: regionsPage } = useFetchInfiniteRegionsQuery({})

  const regions = reduce(get(regionsPage, 'pages', []), (acc: Region[], page) => [...acc, ...get(page, 'data', [])], [])

  const getFullName = (firstName: string, lastName: string): string => join([startCase(lowerCase(firstName)), upperCase(lastName)], ' ')
  const getRegion = (regionId: number): string | undefined => get(find(regions, region => get(region, 'id') - regionId === 0), 'name')
  const getJobRegion = (job: string, regionId: number | undefined): string => {
    const info = []
    const region = getRegion(regionId as number)

    if (!isUndefined(job) && !isEmpty(job)) {
      info.push(job)
    }
    if (!isUndefined(region)) {
      info.push(region)
    }

    return join(info, ' - ')
  }

  return (
    <>
      <Text textType='subtitle' strong>
        {upperCase(t<string>('SOURCES.contacts'))}
      </Text>
      {map(contacts, ({ id, firstName, lastName, email, phone, job, regionId, sourceName }, index) => (
        <div key={id}>
          <div>
            {!isEqual(index, 0) && <Line />}
          </div>
          <Text color='primary' strong>
            {getFullName(firstName, lastName)}
          </Text>
          <StyledContactLink href={`mailto:${email}`}>
            <Text color='primary'>
              {email}
            </Text>
          </StyledContactLink>
          <StyledContactLink href={`tel:${phone}`}>
            <Text color='primary'>
              {phone}
            </Text>
          </StyledContactLink>
          <Text>
            {getJobRegion(job, regionId)}
          </Text>
          <Text color='primary'>
            {sourceName}
          </Text>
        </div>
      ))}
    </>
  )
}

export default ContactProgramModal
