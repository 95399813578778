import styled from 'styled-components'
import { get } from 'lodash'
import { spacings } from '@folhomee/front-library'

const MaxContent = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 ${get(spacings, 'l')}px;
  max-width: 1200px;
`

export default MaxContent
