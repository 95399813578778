import styled from 'styled-components'
import { get } from 'lodash'
import { Card } from '@folhomee/front-library'

import Page from '../../atoms/Page'
import Text from '../../atoms/Text'
import UpdateMe from '../../organisms/UpdateMe'
import UserInfo from '../../organisms/UserInfo'
import MaxContent from '../../atoms/MaxContent'
import { User } from '../../../queries/Users/Users.types'
import { useFetchMeQuery } from '../../../queries/Users'

const SettingsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`

const Settings = (): JSX.Element => {
  const { data } = useFetchMeQuery()
  return (
    <Page>
      <MaxContent>
        <Text textType='title' strong>
          {get(data, 'name')}
        </Text>
        <Card>
          <SettingsContainer>
            <UpdateMe user={data as User} />
            <UserInfo user={data as User} />
          </SettingsContainer>
        </Card>
      </MaxContent>
    </Page>
  )
}

export default Settings
