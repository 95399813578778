import { get } from 'lodash'
import { AxiosError } from 'axios'
import { useQuery, UseQueryResult } from 'react-query'

import useClient from '../../hooks/useClient'
import { getLot } from '../utils/lotFormat'
import { PageLot } from './lots.types'

export const useFetchLotQuery = (id: string): UseQueryResult<PageLot, AxiosError> => {
  const client = useClient()

  return useQuery(['fetchLot', id], async () => {
    return await client.get(`/api/lots/${id}`)
  }, {
    select: ({ data }): PageLot => {
      const included = get(data, 'included')
      const { id, attributes, relationships } = get(data, 'data')

      return {
        meta: get(data, 'meta'),
        data: getLot(id, attributes, included, relationships)
      }
    },
    refetchOnWindowFocus: false
  })
}
