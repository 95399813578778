import styled from 'styled-components'
import { get } from 'lodash'
import { spacings, media } from '@folhomee/front-library'

const AuthCard = styled.div`
  display: flex;
  padding: ${get(spacings, 'l')}px ${get(spacings, 'm')}px ${get(spacings, 'xs')}px;
  max-width: 550px;
  box-shadow: 0 3px 6px 0 rgba(51, 102, 204, 0.15);
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
  background-color: ${({ theme }) => get(theme, 'white')};

  ${media.lessThan('md')`
    padding: ${get(spacings, 'l')}px ${get(spacings, 's')}px ${get(spacings, 'm')}px;
  `}
`

export default AuthCard
