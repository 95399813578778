import styled from 'styled-components'
import { spacings } from '@folhomee/front-library'
import { useFormik } from 'formik'
import { useThrottle } from '@react-hook/throttle'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useRef, useState } from 'react'
import { get, isEqual, isNull, map, reduce, isUndefined } from 'lodash'

import Card from '../../atoms/Card'
import Text from '../../atoms/Text'
import Input from '../../atoms/Input'
import Loader from '../../molecules/LoaderDot'
import FlexBox from '../../atoms/FlexBox'
import SearchInput from '../../molecules/SearchInput'
import ClientCheckbox from '../../molecules/ClientCheckbox'
import ButtonContainer from '../../atoms/ButtonContainer'
import { PageUsers, User } from '../../../queries/Users/Users.types'
import { Client, PageClients } from '../../../queries/Clients/Clients.types'
import { AddFavoriteModalProps } from './AddFavoriteModal.types'
import { useMutateProgramFavoriteQuery } from '../../../queries/Programs'
import { useFetchInfiniteClientFavoritesQuery } from '../../../queries/Clients'
import { useFetchMeQuery, useFetchInfiniteUsersQuery } from '../../../queries/Users'

const StyledCard = styled(Card)`
  border: 0.5px solid ${({ theme }) => get(theme, 'darkGrey')};
  resize: none;
  box-shadow: none;
  border-radius: 10px;
  margin-top: ${get(spacings, 'xxs')}px;
`
const StyledFlexBox = styled(FlexBox)`
  height: 330px;
  overflow-y: auto;
`

const AddFavoriteModal = ({ lotIds, programId, closeModal }: AddFavoriteModalProps): JSX.Element => {
  const { t } = useTranslation()
  const scrollableRef = useRef<HTMLDivElement>(null)
  const [value, updateValue] = useState('')
  const [userIdToFilter, updateUserIdToFilter] = useState<number>(-1)
  const [search, updateSearch] = useThrottle('')
  const { data: users } = useFetchInfiniteUsersQuery({})
  const { data: userData } = useFetchMeQuery()
  const { data, isFetching, fetchNextPage, hasNextPage } = useFetchInfiniteClientFavoritesQuery(lotIds, programId, { search }, userIdToFilter)
  const { mutateAsync } = useMutateProgramFavoriteQuery(programId, lotIds)

  const userOptions = reduce(get(users, 'pages', []), (acc: User[], page: PageUsers): User[] => [...acc, ...get(page, 'data', [])], [])

  const onSubmitUser = useCallback(async (values) => {
    updateUserIdToFilter(get(values, 'users.value', -1))
  }, [])

  const {
    handleChange: handleChangeUser,
    handleBlur: handleBlurUser,
    values: valuesUser,
    setFieldValue: setFieldValueUser,
    handleSubmit: handleSubmitUser
  } = useFormik({
    initialValues: {
      filteredUserId: ''
    },
    onSubmit: onSubmitUser
  })

  const setFieldValueHandlerUser = useCallback(async (field: string, value: any, add?: boolean): Promise<void> => {
    if (!isUndefined(add) && isEqual(add, false)) {
      await setFieldValueUser(field, '')
      return
    }
    await setFieldValueUser(field, value)
  }, [])

  useEffect(() => {
    handleSubmitUser()
  }, [valuesUser])

  const onScroll = (): void => {
    if (isNull(scrollableRef.current)) {
      return
    }
    const { scrollTop, scrollHeight, clientHeight } = scrollableRef.current
    if (isEqual(scrollTop + clientHeight, scrollHeight) && !isFetching && (hasNextPage ?? true)) {
      fetchNextPage()
    }
  }

  const onSubmit = useCallback(async (values) => {
    const toSendValues = reduce(values, (acc: any[], val, key) => [...acc, { client_id: key, is_favorite: val }], [])

    await mutateAsync({ favorites: toSendValues, lots: lotIds })

    closeModal()
  }, [])

  const clients = reduce(get(data, 'pages', []), (acc: Client[], page: PageClients): Client[] => [...acc, ...get(page, 'data', []) as Client[]], [])

  const {
    setFieldValue: setFieldValueClient,
    handleSubmit: handleSubmitClient
  } = useFormik({
    initialValues: reduce(clients, (prev: any, curr: Client) => ({ ...prev, [get(curr, 'id')]: get(curr, 'isFavorite') }), {}),
    onSubmit
  })

  const setFieldValueHandlerClient = useCallback(async (client, value) => {
    if (isEqual(value, true) || isEqual(value, 'true')) {
      await setFieldValueClient(client, true)
      return
    }

    await setFieldValueClient(client, false)
  }, [])

  useEffect(() => {
    updateSearch(value)
  }, [value])

  const createFavorites = useCallback(() => {
    handleSubmitClient()
  }, [handleSubmitClient])

  return (
    <>
      <Text
        textType='bigger'
        color='primary'
        strong >
        {t<string>('CLIENTS.favorite')}
      </Text>
      <FlexBox
        gap={8}
        width100
        alignStart>
        <Text strong>{t('CLIENTS.searchClients')}</Text>
        <FlexBox key={'name'}
          row
          gap={8}
          width100
          alignStart>
          <Input
            type='text'
            name='search'
            value={value}
            squared
            bordered
            onChange={evt => updateValue(evt.currentTarget.value)}
            inputSize='regular'
            importance='secondary'
            placeholder={t('COMMON.name')} />
          {isEqual(get(userData, 'role', ''), 'admin') &&
            <SearchInput
              key='filteredUserId'
              name='users'
              type='select'
              values={valuesUser}
              options={[...[{ value: -1, label: 'Tous' }], ...map(userOptions, ({ id, name }: User) => ({
                value: id, label: name
              }))]}
              multiple={false}
              minWidth={200}
              handleBlur={handleBlurUser}
              placeholder='USER.sortedByUser'
              handleChange={handleChangeUser}
              setFieldValue={setFieldValueHandlerUser} />}
        </FlexBox>
        <Loader loading={isFetching} />
        <StyledCard padding='16px 24px' width100>
          <StyledFlexBox ref={scrollableRef} onScroll={onScroll} gap={8} justifyContent='start' alignStart width100 >
            {map(clients, (client) => (
              <ClientCheckbox key={get(client, 'id')} client={client} setFieldValue={setFieldValueHandlerClient} />
            ))}
          </StyledFlexBox>
        </StyledCard>
      </FlexBox>
      <ButtonContainer
        type='submit'
        buttonSize='large'
        color='primary'
        onClick={createFavorites}
        center>{t<string>('COMMON.register')}</ButtonContainer>
    </>
  )
}

export default AddFavoriteModal
