import styled from 'styled-components'
import { get, isEqual, isUndefined } from 'lodash'

import { offColor } from 'off-color'
import { OptionsProps } from '../../organisms/Table/Table.types'

const ContainerRow = styled.div<OptionsProps>`
  border-radius: 13px;
  background-color: ${({ theme, status, options }) => offColor(isEqual(status, 1) || isUndefined(status) ? get(theme, get(options, 'bodyColor')) : get(theme, 'lightOrange')).rgba(0.3)};
`

export default ContainerRow
