import styled from 'styled-components'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer'
import { media } from '@folhomee/front-library'
import { useTranslation } from 'react-i18next'
import { LatLngExpression } from 'leaflet'
import { get, isNaN } from 'lodash'
import { useState, useRef } from 'react'
import { MapContainer, Marker } from 'react-leaflet'

import Background from '../../../assets/images/google-maps-logo.png'
import TooltipContent from '../../atoms/TooltipContent'
import { MapProps } from './Map.types'

const containerStyle = {
  width: '100%',
  height: '100%',
  borderRadius: '10px'
}

const FolhomeeContainer = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  border: 10px solid ${({ theme }) => get(theme, 'white')};
  position: relative;
  max-width: 476px;
  box-shadow: 0 3px 6px 0 rgba(51, 102, 204, 0.15);
  border-radius: 20px;

  ${media.lessThan('lg')`
    flex: auto;
    height: auto;
  `}

  ${media.lessThan('sm')`
    flex: auto;
    height: 200px;
  `}

  & > .leaflet-bottom, .leaflet-right {
    display: none;
  }
`

const ShortcutGoogleMap = styled.a`
  right: 10px;
  width: 40px;
  bottom: 10px;
  height: 40px;
  z-index: 1000;
  position: absolute;
  box-shadow: 0 3px 6px 0 rgba(51, 102, 204, 0.15);
  border-radius: 50%;
  background-size: 50%;
  background-color: ${({ theme }) => get(theme, 'white')};
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-position: center;
`

const StyledTooltip = styled(TooltipContent)`
  z-index: 1010;
`

const Map = ({ position }: MapProps): JSX.Element => {
  const { t } = useTranslation()
  const center = useRef<LatLngExpression>([get(position, 'lat', 0), get(position, 'lng', 0)])
  const [readyToRender, updateReadyToRender] = useState(false)

  setTimeout(() => {
    updateReadyToRender(true)
  }, 0)
  if (isNaN(get(position, 'lat')) || isNaN(get(position, 'lng'))) {
    return (
      <></>
    )
  }

  const urlMap = `https://www.google.com/maps/place/${get(position, 'lat', 0)},${get(position, 'lng', 0)}`
  return (
    <FolhomeeContainer>
      {readyToRender && <MapContainer
        zoom={17}
        style={containerStyle}
        center={center.current}
        scrollWheelZoom={true}>
        <ShortcutGoogleMap data-tooltip-id="MapTooltip" target='_blank' href={urlMap} />
        <ReactLeafletGoogleLayer apiKey='AIzaSyAUqQHGO5orNQYmzKNTZE3cKiZeGp6kDOc' type='roadmap' />
        <Marker position={center.current} />
      </MapContainer>}
      <StyledTooltip
        id="MapTooltip"
        color='lightSuccess'>
        {t('PROGRAM.seeOnGoogleMaps')}
      </StyledTooltip>
    </FolhomeeContainer>
  )
}

export default Map
