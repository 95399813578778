import styled from 'styled-components'
import { Tooltip } from 'react-tooltip'
import { spacings } from '@folhomee/front-library'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { isEqual, size, get, first, map } from 'lodash'

import Icon from '../../atoms/Icon'
import FlexBox from '../../atoms/FlexBox'
import ButtonRound from '../../atoms/ButtonRound'
import { ExternalReference } from '../../../queries/utils/query.types'
import { ItemLinkProps, LinksModalProps } from './ItemLink.types'

const LinksContainer = styled(FlexBox)`
  position: relative;
`

const Link = styled.a`
  width: 44px;
  height: 40px;
  display: flex;
  padding: 12px;
  background: ${({ theme }) => get(theme, 'lightBlue')};
  align-items: center;
  border-radius: 20px;
  text-decoration: none;

  :hover {
    background: #b4ccfc;
    transition: all 0.3s ease-out;
  }

  &:active {
    transform: translateY(2px);
  }
`

const ModalContainer = styled(Tooltip)`
  gap: ${get(spacings, 'xxs')}px;
  color: ${({ theme }) => get(theme, 'darkGrey', '#fff')};
  width: 160px;
  display: flex;
  padding: ${get(spacings, 'xs')}px;
  background: ${({ theme }) => get(theme, 'lightBlue', '#fff')};
  font-family: SourceSansPro;
  font-weight: 600;
  line-height: normal;
  border-radius: 25px;
  flex-direction: column;

  & > a {
    font-size: 15px;
    text-align: left;
    font-family: 'SourceSansPro';
    border-bottom: 1px solid #0048EE80;
    padding-bottom: 8px;
    text-decoration: none;
    text-transform: capitalize;
  }
  
  & a:last-of-type {
    border-bottom: 0px;
  }
`

const LinksModal = ({ urls }: LinksModalProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <ModalContainer
      id="LinkTooltip"
      place='bottom'
      clickable >
      {map(urls, (item: ExternalReference) => (
        <a
          rel='noreferrer'
          key={`${get(item, 'url', '')}-${get(item, 'crawlType', '')}`}
          href={get(item, 'url', '')}
          target='_blank'>
          {t<string>(`COMMON.${get(item, 'crawlType', '')}`, { source: get(item, 'sourceName') })}
        </a>
      ))}
    </ModalContainer>
  )
}

const ItemLink = ({ urls, onClick }: ItemLinkProps): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null)

  if (isEqual(size(urls), 1)) {
    return (
      <ButtonRound
        onClick={() => get(first(urls), 'url')}
        color='info'
        buttonSize='regular'>
        <Link target='_blank' href={get(first(urls), 'url')}>
          <Icon
            variant='extern-link'
            color='primary'
            stroke
            cursor />
        </Link>
      </ButtonRound>
    )
  }
  return (
    <ButtonRound
      onClick={onClick}
      color='info'
      buttonSize='regular'
      data-tooltip-id="LinkTooltip">
      <LinksContainer
        ref={containerRef}>
        <Icon
          variant='extern-link'
          color='primary'
          stroke />
        <LinksModal urls={urls} />
      </LinksContainer>
    </ButtonRound>
  )
}

export default ItemLink
