import styled from 'styled-components'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { media, spacings, Text, Icon } from '@folhomee/front-library'

import FlexBox from '../FlexBox'
import ButtonContainer from '../ButtonContainer'
import { getMaxMediaQuery } from '../../../utils/media'
import { DisplayProps, SearchButtonProps } from './SearchButton.types'

const Container = styled.div <DisplayProps>`
  cursor: pointer;
  display: ${({ visible }) => visible ?? true ? 'block' : 'none'};
  padding: ${get(spacings, 'xs')}px;
  box-shadow: 0 3px 4px 0 rgba(50, 73, 144, 0.2);
  padding-top: ${get(spacings, 's')}px;
  border-radius: 15px;  

  ${media.lessThan('md')`
    width: 100%;
  `}
`

const StyledButton = styled(ButtonContainer) <DisplayProps>`
  display: ${({ visible }) => visible ?? true ? 'block' : 'none'};
  left: 16px;

  ${media.greaterThan('xl')`
    display: none;
  `}
`

const SearchButton = ({ showSearch, updateShowSearch }: SearchButtonProps): JSX.Element => {
  const { t } = useTranslation()
  const [mobileScreen, updateMobileScreen] = useState(getMaxMediaQuery('md').matches)

  useEffect(() => {
    const mql = getMaxMediaQuery('md')
    const resize = (event: MediaQueryListEvent): void => updateMobileScreen(event.matches)

    mql.addEventListener('change', resize)
    return () => {
      mql.removeEventListener('change', resize)
    }
  }, [])

  if (mobileScreen) {
    return (
      <Container onClick={() => updateShowSearch(true)}>
        <FlexBox gap={4} row>
          <Icon variant='search' />
          <Text variant='primary' type='regular' strong={true}>{t<string>('COMMON.search')}</Text>
          <Icon variant='arrow-down' />
        </FlexBox>
      </Container>
    )
  }

  return (
    <StyledButton
      top={16}
      color='primary'
      visible={!showSearch}
      onClick={() => updateShowSearch(true)}
      buttonSize='regular'
      minWidth={100}
      outline>
      <FlexBox gap={4} row >
        <Icon variant='search' />
        <Text variant='primary' type='regular' strong={true}>{t<string>('COMMON.search')}</Text>
      </FlexBox>
    </StyledButton>
  )
}

export default SearchButton
