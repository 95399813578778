import styled from 'styled-components'
import { Button } from '@folhomee/front-library'
import { get, isUndefined, isEqual } from 'lodash'

import { offColor } from 'off-color'
import { ButtonContainerProps, StyledButtonContainerProps } from './ButtonContainer.types'

const FONT_SIZES = {
  small: 13,
  large: 17,
  regular: 15
}

const PADDING = {
  small: '4px 8px',
  large: '10px 24px',
  regular: '8px 16px'
}

const COLOR = {
  white: '#FFFFFF',
  info: '#cedeff',
  danger: '#FF6600',
  primary: '#0049EE',
  success: '#5fc34b',
  warning: '#FF9900',
  secondary: '#707070',
  lightDanger: '#ffe8d8',
  transparent: '#FFFFFF00',
  lightSuccess: '#e3ffd8d4',
  lightWarning: '#fff6d8',
  lightSecondary: '#f4f4f4',
  mediumSecondary: '#b5b5b5'
}

const COLOR_HOVER = {
  info: '#b4ccfc',
  danger: '#ff4500',
  primary: '#023fc7',
  success: '#4dbb37',
  warning: '#eb8d01',
  secondary: '#4f4f4f',
  lightDanger: '#ffd9bf',
  transparent: '#cedeff',
  lightWarning: '#ffefb8',
  lightSuccess: '#cef2c0d4',
  lightSecondary: '#e1e1e1',
  mediumSecondary: '#909090'
}

const COLOR_FONT = {
  info: '#0049EE',
  danger: 'white',
  primary: 'white',
  success: 'white',
  warning: 'white',
  secondary: 'white',
  mediumSecondary: 'white'
}

const StyledButtonContainer = styled(Button) <StyledButtonContainerProps>`
  gap: 4px;
  color: ${({ theme, customProps }) => get(COLOR_FONT, get(customProps, 'color') ?? 'primary', `${get(theme, 'darkGrey', '#FFF') as string}`)};
  width: auto;
  border: ${({ outline }) => isEqual(outline, true) ? '' : 0};
  cursor: pointer;
  display: flex;
  padding: ${({ customProps }) => get(PADDING, get(customProps, 'size') ?? 'regular', '9px 16px')};
  position: relative;
  font-size: ${({ customProps }) => get(FONT_SIZES, get(customProps, 'size') ?? 'regular', 15)}px;
  min-width: ${({ minWidth }) => isUndefined(minWidth) ? '170' : minWidth}px;
  margin-top: ${({ top }) => isUndefined(top) ? 0 : top}px;
  align-items: center;
  margin-left: ${({ center }) => isEqual(center, true) ? 'auto' : ''};
  border-color: ${({ outline, theme, customProps }) => isEqual(outline, true) ? get(COLOR, get(customProps, 'color') ?? 'primary', `${get(theme, 'darkGrey', '#FFF') as string}`) : 'none'};
  margin-right: ${({ center }) => isEqual(center, true) ? 'auto' : ''};
  border-radius: 20px;
  margin-bottom: ${({ bottom }) => isUndefined(bottom) ? 0 : bottom}px;
  justify-content: center;
  background-color: ${({ theme, outline, customProps }) => isEqual(outline, true) ? 'transparent' : get(COLOR, get(customProps, 'color') ?? 'primary', `${get(theme, 'darkGrey', '#FFF') as string}`)};

  &:hover {
    transition: all 0.3s ease-out;
    background-color: ${({ theme, outline, customProps }) => isEqual(outline, true) ? offColor(get(COLOR_HOVER, get(customProps, 'color') ?? 'primary', 'transparent')).rgba(0.15) : get(COLOR_HOVER, get(customProps, 'color') ?? 'primary', 'transparent')};
  }

  &:active {
    transform: translateY(2px);
  }
`

const ButtonContainer = ({
  buttonSize = 'regular', importanceBase = 'primary',
  outline, label, color, onClick, children,
  ...props
}: ButtonContainerProps): JSX.Element => {
  return (
    <StyledButtonContainer
      {...props}
      customProps={{
        size: buttonSize,
        color: color,
        importance: importanceBase
      }}
      label={label}
      color={color}
      variant='primary'
      onClick={onClick}
      outline={outline}
      importance='regular'
    >
      {children}
    </StyledButtonContainer>
  )
}
export default ButtonContainer
