import styled from 'styled-components'
import { useState } from 'react'
import { spacings } from '@folhomee/front-library'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import { get, reduce, size, map, isEqual, isUndefined } from 'lodash'

import Page from '../../atoms/Page'
import Text from '../../atoms/Text'
import Loader from '../../molecules/LoaderRotating'
import LotsTable from '../../molecules/LotsTable'
import MaxContent from '../../atoms/MaxContent'
import ProgramDetails from '../../organisms/ProgramDetails'
import { useTabTitle } from '../../../hooks/useTabTitle'
import { useFetchMeQuery } from '../../../queries/Users'
import { useDecorateParams } from '../../../hooks/useDecorateParams'
import { useFetchProgramQuery } from '../../../queries/Programs'
import { ALERT_ID_QUERY_PARAM, LOT_ID_QUERY_PARAM } from '../../../config'

const Container = styled(MaxContent)`
  width: 100%;
  margin: 0 auto;
  max-width: 1300px;
  
  & > p {
    max-width: 100%;
    margin-bottom: ${get(spacings, 'xs')}px;
  }
`

const Program = (): JSX.Element => {
  const { t } = useTranslation()
  const params = useParams()
  const [searchParams] = useSearchParams()

  const alertId = searchParams.get(ALERT_ID_QUERY_PARAM)
  const higlightedLots = searchParams.getAll(LOT_ID_QUERY_PARAM)

  const { data, isFetching, isError } = useFetchProgramQuery(get(params, 'id', ''), {})
  const { data: userData, isFetching: isFetchingUser } = useFetchMeQuery()

  const program = get(data, 'data')
  const programName = get(program, 'name', '')
  const [, updateIsOpenFavorite] = useState(false)

  const availableLots = reduce(get(program, 'lots'), (acc, lot) => {
    if (isEqual(get(lot, 'status'), 'available')) {
      return acc + 1
    }

    return acc
  }, 0)

  useTabTitle(programName)
  useDecorateParams(programName)
  if (isEqual(isError, true)) {
    return (
      <Page>
        {t('ERRORS.programError')}
      </Page>
    )
  }
  if (isEqual(isFetching, true) || isEqual(isFetchingUser, true) || isUndefined(program) || isUndefined(userData)) {
    return (
      <Page>
        <Loader loading={isFetching} />
      </Page>
    )
  }
  return (
    <Page>
      <ProgramDetails
        user={userData}
        lots={map(get(program, 'lots'), lot => get(lot, 'id'))}
        openFavorite={() => updateIsOpenFavorite(true)}
        program={program} />
      <Container>
        <Text textType='title' strong>
          {t<string>('LOT.availability')} ({availableLots.toString()}/{size(get(program, 'lots', [])).toString()})
        </Text>
        <LotsTable
          lots={get(program, 'lots', [])}
          alertId={alertId ?? ''}
          programId={get(program, 'id')}
          higlightedLots={higlightedLots ?? []} />
      </Container>
    </Page>
  )
}

export default Program
