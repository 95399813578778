import styled from 'styled-components'
import { get } from 'lodash'
import { spacings, media } from '@folhomee/front-library'

const DataContainer = styled.div`
  ${media.greaterThan('sm')`
    padding: 0 ${get(spacings, 's')}px ${get(spacings, 's')}px ${get(spacings, 's')}px;
  `}
`
export default DataContainer
