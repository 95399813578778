import { Variant } from '@folhomee/front-library'
import { reduce, range } from 'lodash'

const FIRST_BODY = [{
  key: 'brochureLink',
  title: 'PROGRAM.brochure'
}, {
  key: 'city.taxArea',
  title: 'PROGRAM.priorityZoneShort'
}, {
  key: 'actability',
  title: 'PROGRAM.actable'
}]

const SECOND_BODY = [{
  key: 'typeLots',
  title: 'COMMON.type'
}, {
  key: 'priceRange.minPrice',
  title: 'COMMON.minPrice'
}, {
  key: 'deliveryDate',
  title: 'PROGRAM.delivery'
}]

export const BODY_PROGRAMS = [...reduce(range(3), (acc, idx) => [...acc, FIRST_BODY[idx], SECOND_BODY[idx]], [{
  key: 'images',
  title: 'images'
}, {
  key: 'name',
  title: 'name'
}]), {
  key: 'favorites',
  title: 'favorites'
}, {
  key: 'lots',
  title: 'lots'
}]

export const SMALL_BODY_PROGRAMS = [{
  key: 'images',
  title: 'images'
}, {
  key: 'name',
  title: 'name'
}, {
  key: 'firstDetails',
  values: FIRST_BODY
}, {
  key: 'secondDetails',
  values: SECOND_BODY
}, {
  key: 'favorites',
  title: 'favorites'
}, {
  key: 'lots',
  title: 'lots'
}]

export const HEADER_PROGRAMS = [{
  label: 'PROGRAM.program'
}, {
  label: ''
}, {
  label: 'PROGRAM.brochure'
}, {
  label: 'COMMON.type'
}, {
  label: 'PROGRAM.priorityZone'
}, {
  label: 'COMMON.minPrice'
}, {
  label: 'PROGRAM.actable'
}, {
  label: 'PROGRAM.delivery'
}, {
  label: 'favorites',
  icon: 'favorite' as Variant
}, {
  label: 'LOT.lots'
}]

export const SEARCH_FIELDS = [{
  icon: 'eye' as Variant,
  title: 'PROGRAM_SEARCH.use',
  fields: [{
    key: 'useType',
    type: 'toggle',
    options: [{
      value: 'commonRight',
      label: 'PROGRAM_SEARCH.commonRight'
    }, {
      value: 'investment',
      label: 'PROGRAM_SEARCH.investment'
    }]
  }]
}, {
  icon: 'target' as Variant,
  title: 'PROGRAM.location',
  borderTop: true,
  fields: [{
    key: 'location',
    type: 'location',
    subkeys: ['cities', 'departments', 'agglomerations'],
    multiple: true,
    placeholder: 'PROGRAM_SEARCH.locationPlaceholder'
  }]
}, {
  type: 'inline',
  icon: 'price' as Variant,
  title: 'PROGRAM_SEARCH.budget',
  fields: [{
    key: 'minPrice',
    type: 'input',
    placeholder: 'COMMON.shortMin'
  }, {
    key: 'maxPrice',
    type: 'input',
    placeholder: 'COMMON.shortMax'
  }]
}, {
  icon: 'typologie' as Variant,
  title: 'LOT.typology',
  fields: [{
    key: 'rooms',
    type: 'box',
    options: [{
      value: 1,
      label: 'T1'
    }, {
      value: 2,
      label: 'T2'
    }, {
      value: 3,
      label: 'T3'
    }, {
      value: 4,
      label: 'T4'
    }, {
      value: 5,
      label: 'T5+'
    }]
  }]
}, {
  type: 'inline',
  icon: 'surface' as Variant,
  title: 'LOT.surface',
  fields: [{
    key: 'minSurface',
    type: 'input',
    placeholder: 'COMMON.shortMin'
  }, {
    key: 'maxSurface',
    type: 'input',
    placeholder: 'COMMON.shortMax'
  }]
}, {
  type: 'inlineTop',
  icon: 'calendar' as Variant,
  title: 'PROGRAM_SEARCH.delivery',
  fields: [{
    key: 'deliveryQuarter',
    type: 'select',
    options: [{
      value: '0',
      label: 'T1'
    }, {
      value: '1',
      label: 'T2'
    }, {
      value: '2',
      label: 'T3'
    }, {
      value: '3',
      label: 'T4'
    }],
    placeholder: 'PROGRAM_SEARCH.deliveryQuarterPlaceholder'
  }, {
    key: 'deliveryYear',
    type: 'select',
    placeholder: 'PROGRAM_SEARCH.deliveryYearPlaceholder'
  }]
}, {
  icon: 'program' as Variant,
  title: 'PROGRAM_SEARCH.program',
  borderTop: true,
  fields: [{
    key: 'program',
    placeholder: 'PROGRAM.program'
  }]
}, {
  icon: 'build' as Variant,
  title: 'PROGRAM_SEARCH.source',
  valueType: 'sources',
  fields: [{
    key: 'sources',
    type: 'autocomplete',
    multiple: true,
    placeholder: 'PROGRAM_SEARCH.sourcePlaceholder'
  }]
}]

export const MORE_SEARCH_FIELDS = [{
  title: 'PROGRAM_SEARCH.taxations',
  valueType: 'taxations',
  moreSearch: true,
  borderTop: true,
  fields: [{
    key: 'taxations',
    type: 'select',
    multiple: true,
    placeholder: 'PROGRAM_SEARCH.taxationsPlaceholder'
  }]
}, {
  title: 'LOT.floor',
  moreSearch: true,
  fields: [{
    key: 'floor',
    type: 'box',
    options: [{
      value: 0,
      label: 'RDC'
    }, {
      value: 1,
      label: '1'
    }, {
      value: 2,
      label: '2'
    }, {
      value: 3,
      label: '3'
    }, {
      value: 4,
      label: '4+'
    }]
  }]
}, {
  type: 'inlineTop',
  icon: 'calendar' as Variant,
  title: 'PROGRAM_SEARCH.deliveryMinimum',
  moreSearch: true,
  fields: [{
    key: 'deliveryQuarterMinimum',
    type: 'select',
    options: [{
      value: '0',
      label: 'T1'
    }, {
      value: '1',
      label: 'T2'
    }, {
      value: '2',
      label: 'T3'
    }, {
      value: '3',
      label: 'T4'
    }],
    placeholder: 'PROGRAM_SEARCH.deliveryQuarterPlaceholder'
  }, {
    key: 'deliveryYearMinimum',
    type: 'select',
    placeholder: 'PROGRAM_SEARCH.deliveryYearPlaceholder'
  }]
}, {
  type: 'grid',
  title: 'PROGRAM_SEARCH.extras',
  moreSearch: true,
  fields: [{
    key: 'parking',
    type: 'checkbox',
    placeholder: 'COMMON.parking'
  }, {
    key: 'terrace',
    type: 'checkbox',
    placeholder: 'COMMON.terrace'
  }, {
    key: 'balcony',
    type: 'checkbox',
    placeholder: 'COMMON.balcony'
  }, {
    key: 'garden',
    type: 'checkbox',
    placeholder: 'COMMON.garden'
  }]
}, {
  icon: 'program' as Variant,
  title: 'COMMON.type',
  moreSearch: true,
  fields: [{
    key: 'lotType',
    type: 'toggle',
    options: [{
      value: 'apartment',
      label: 'LOT.apartment'
    }, {
      value: 'house',
      label: 'LOT.house'
    }]
  }]
}]

export const BODY_MUTE = [{
  key: 'action',
  title: 'common.select'
}, {
  key: 'name',
  title: 'COMMON.name'
}]

export const HEADER_MUTE = [{
  label: 'COMMON.select',
  textLeft: true
}, {
  label: 'COMMON.name',
  textLeft: true
}]

export const BODY_DOUBLE = [{
  key: 'action',
  title: 'common.select'
}, {
  key: 'id',
  title: 'COMMON.id'
}, {
  key: 'name',
  title: 'COMMON.name'
}]

export const HEADER_DOUBLE = [{
  label: 'COMMON.select',
  textLeft: true
}, {
  label: 'COMMON.id',
  textLeft: true
}, {
  label: 'COMMON.name',
  textLeft: true
}]

export const HEADER_FAVORITE = HEADER_PROGRAMS

export const BODY_FAVORITE = [...reduce(range(3), (acc, idx) => [...acc, FIRST_BODY[idx], SECOND_BODY[idx]], [{
  key: 'images',
  title: 'images'
}, {
  key: 'name',
  title: 'name'
}]), {
  key: 'clientFavorites',
  title: 'favorites'
}, {
  key: 'lots',
  title: 'lots'
}]
