import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { get, truncate } from 'lodash'
import { useCallback, useState } from 'react'
import { media, spacings, Text, Icon } from '@folhomee/front-library'

import { ProgramDescriptionProps } from './ProgramDescription.types'

const Description = styled.div`
  width: 100%;
  bottom: 0px;
  display: none;
  padding: ${get(spacings, 's')}px ${get(spacings, 'm')}px;
  position: absolute;
  box-shadow: 0 3px 6px 0 rgba(51, 102, 204, 0.15);
  min-height: 100px;
  border-radius: 15px;
  background-color: ${({ theme }) => get(theme, 'white', '#FFF')};

  ${media.lessThan('md')`
    height: 130px;
    padding: ${get(spacings, 'xs')}px;
  `}
`

const OpenContainer = styled.div`
  gap: ${get(spacings, 'xxs')}px;
  right: 10px;
  bottom: 0;
  cursor: pointer;
  display: flex;
  position: absolute;
  transform: scale(0.8);
  padding-bottom: 16px;
`

const DescriptionTitle = styled(Text)`
  margin-bottom: 8px;
`

const ProgramDescription = ({ program, ...props }: ProgramDescriptionProps): JSX.Element => {
  const { t } = useTranslation()
  const [isOpen, updateIsOpen] = useState<boolean>(false)

  const toggleOpen = useCallback(() => updateIsOpen(!isOpen), [isOpen, updateIsOpen])

  return (
    <Description>
      <DescriptionTitle
        type='regular'
        strong={true}
        variant='secondary'>
        {t('PROGRAM.descriptionTitle', { description: get(program, 'name') })}
      </DescriptionTitle>
      <Text
        type='table'
        strong={false}
        variant='secondary'>
        <span>{isOpen ? get(program, 'description', '') : truncate(get(program, 'description', ''), { length: 315 }) }</span>
      </Text>
      <OpenContainer onClick={toggleOpen}>
        <Icon variant={isOpen ? 'minus-circle' : 'plus-circle'} />
        <Text
          type='regular'
          strong={true}
          variant='primary'>
          {t(isOpen ? 'PROGRAM.showLess' : 'PROGRAM.showMore')}
        </Text>
      </OpenContainer>
    </Description>
  )
}

export default ProgramDescription
