import styled from 'styled-components'

import { ReactComponent as ImpulseLogo } from '../../../assets/svgs/logo-impulse.svg'

const Container = styled.div`
  width: 50px;
  align-self: stretch;
  margin-left: 8px;
  margin-right: 24px;
`

const Logo = (): JSX.Element => {
  return (
    <Container>
      <ImpulseLogo />
    </Container>
  )
}

export default Logo
