import { AxiosError } from 'axios'
import { get, omit, map, range, size } from 'lodash'
import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult } from 'react-query'

import useClient from '../../hooks/useClient'
import { City } from '../Cities/Cities.types'
import { PageAgglomerations } from './Agglomerations.types'
import { buildQueryParams, getObjectByIds, getRelationships } from '../utils'

const transformPage = ({ data }: any): PageAgglomerations => ({
  meta: get(data, 'meta'),
  data: map(get(data, 'data'), ({ id, attributes, relationships }) => {
    const ships = getRelationships(relationships, [], ['cities'])
    const included = get(data, 'included')

    return ({
      id,
      name: get(attributes, 'name'),
      cities: getObjectByIds(included, get(ships, 'cities', []), 'city', { id: 'id', name: 'name', insee: 'insee', zipcode: 'zipcode', department: 'department' }) as City[]
    })
  })
})

export const useFetchInfiniteAgglomerationsQuery = (search: any): UseInfiniteQueryResult<PageAgglomerations, AxiosError> => {
  const client = useClient()

  return useInfiniteQuery(['fetchInfiniteAgglomerations', search], async ({ pageParam = 1 }) => {
    return await client.get('/api/agglomerations', {
      params: {
        'page[number]': pageParam,
        'page[size]': 21,
        ...buildQueryParams(omit(search, ['page']))
      }
    })
  }, {
    select: ({ pages }): InfiniteData<PageAgglomerations> => ({
      pages: map(pages, transformPage),
      pageParams: range(size(pages) + 1)
    }),
    getNextPageParam: (_, allPages) => size(allPages) + 1,
    staleTime: 60 * 1000,
    refetchOnWindowFocus: false
  })
}
