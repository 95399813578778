import { get, isUndefined, isNull } from 'lodash'
import { useState, useLayoutEffect, RefObject } from 'react'

const useIntersection = (element: RefObject<any>, rootMargin: string = '0px'): boolean => {
  const [isVisible, updateIsVisible] = useState<boolean>(false)

  useLayoutEffect(() => {
    if (!isUndefined(get(element, 'current')) && !isNull(get(element, 'current'))) {
      const observer = new IntersectionObserver(([entry]) => updateIsVisible(get(entry, 'isIntersecting', false)), {
        rootMargin
      })

      observer.observe(get(element, 'current'))

      return () => observer.unobserve(get(element, 'current'))
    }
  }, [element])

  return isVisible
}

export default useIntersection
