import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { spacings, Text } from '@folhomee/front-library'
import { useCallback, useState } from 'react'
import { find, get, isEqual, isUndefined, map } from 'lodash'

import Cities from '../Cities'
import Regions from '../Regions'
import Departments from '../Departments'
import Agglomerations from '../Agglomerations'
import RightContainer from '../../atoms/RightContainer'
import { LocationProps, TitleProps } from './LocationsProps'

const PAGES = [{
  name: 'cities',
  title: 'COMMON.cities',
  component: Cities
}, {
  name: 'agglomerations',
  title: 'AGGLOMERATIONS.pluralTitle',
  component: Agglomerations
}, {
  name: 'departments',
  title: 'PROGRAM_SEARCH.department',
  component: Departments
}, {
  name: 'regions',
  title: 'REGIONS.pluralTitle',
  component: Regions
}]

const Inline = styled.div`
  display: flex;
  height: 100%;
`

const Links = styled.div`
  gap: 16px;
  width: 24%;
  height: 100vh;
  display: flex;
  z-index: 999;
  overflow: auto;
  max-width: 320px;
  box-shadow: 0 3px 6px 0 rgba(51, 102, 204, 0.15);
  padding-top: 72px;
  flex-direction: column;
`

const MarginLinks = styled.div`
  padding: 0 ${get(spacings, 's')}px;
`

const Title = styled(Text) <TitleProps>`
  color: ${({ theme, select }) => isEqual(select, true) ? get(theme, 'orange') : get(theme, 'blue')};
  cursor: pointer;
`

const Location = ({ name }: LocationProps): JSX.Element => {
  const Component = get(find(PAGES, page => isEqual(get(page, 'name'), name)), 'component')
  if (!isUndefined(Component)) {
    return <Component />
  }
  return <></>
}

const Locations = (): JSX.Element => {
  const { t } = useTranslation()
  const [select, updateSelect] = useState<string>(get(PAGES[0], 'name'))
  const handleLocation = useCallback((name: string) => {
    updateSelect(name)
  }, [updateSelect])

  return (
    <Inline>
      <Links>
        {map(PAGES, ({ title, name }) => (
          <MarginLinks key={name} onClick={() => handleLocation(name)}>
            <Title
              type='table'
              select={isEqual(select, name)}
              strong={true}
              variant='primary'>
              {t<string>(title)}
            </Title>
          </MarginLinks>
        ))}
      </Links>
      <RightContainer>
          <Location name={select} />
      </RightContainer>
    </Inline>
  )
}

export default Locations
