import styled from 'styled-components'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useCallback, useMemo } from 'react'
import { Button, spacings, Text } from '@folhomee/front-library'
import { get, map, reduce, find, snakeCase, isUndefined, isEqual, isObject, upperCase, chunk, join } from 'lodash'

import SearchInput from '../../molecules/SearchInput'
import { Region } from '../../../queries/Regions/Regions.types'
import { UpdateContactProps } from './UpdateContact.types'
import { useUpdateContactQuery } from '../../../queries/Contacts'
import { useFetchInfiniteRegionsQuery } from '../../../queries/Regions'

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  overflow-y: scroll;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  & > div {
    width: 100%;
  }
`

const InputContainer = styled.div`
  display: flex;
  padding-top: ${get(spacings, 'xs')}px;
  flex-direction: column;
  padding-bottom: ${get(spacings, 'xs')}px;
`

const SubmitContainer = styled.div`
  gap: ${get(spacings, 'xs')}px;
  display: flex;
  flex-direction: column;
`

const FieldsLine = styled.div`
  gap: 13px;
  display:flex;
  width: 100%;

  & > div {
    flex: 1
  }
`

const UpdateContact = ({ id, contact, closeModal }: UpdateContactProps): JSX.Element => {
  const { t } = useTranslation()
  const { mutateAsync } = useUpdateContactQuery(id, get(contact, 'id'))
  const { data: regionsPage } = useFetchInfiniteRegionsQuery({})

  const regions = reduce(get(regionsPage, 'pages', []), (acc: Region[], page) => [...acc, ...get(page, 'data', [])], [])
  const contactRegion = useMemo(() => {
    return get(find(regions, region => get(region, 'id') - get(contact, 'regionId') === 0), 'name')
  }, [regions])

  const onSubmit = useCallback(async (values) => {
    const toSendValues = reduce(values, (acc: any, value: any, name: string): any => ({
      ...acc,
      [snakeCase(name)]: isObject(value) ? get(value, 'value') : value
    }), [])
    await mutateAsync(toSendValues)
    closeModal()
  }, [mutateAsync, closeModal])

  const { values, handleChange, handleSubmit, setFieldValue, handleBlur } = useFormik({
    initialValues: {
      job: get(contact, 'job'),
      email: get(contact, 'email'),
      phone: get(contact, 'phone'),
      regionId: get(contact, 'regionId'),
      lastName: get(contact, 'lastName'),
      firstName: get(contact, 'firstName')
    },
    onSubmit
  })

  const fields = [{
    name: 'firstName',
    type: 'text',
    title: 'COMMON.firstName',
    options: [],
    placeholder: get(contact, 'firstName')
  }, {
    name: 'lastName',
    type: 'text',
    title: 'COMMON.name',
    options: [],
    placeholder: get(contact, 'lastName')
  }, {
    name: 'email',
    type: 'text',
    title: 'COMMON.email',
    options: [],
    placeholder: get(contact, 'email')
  }, {
    name: 'phone',
    type: 'text',
    title: 'COMMON.phone',
    options: [],
    placeholder: get(contact, 'phone')
  }, {
    name: 'job',
    type: 'text',
    title: 'COMMON.job',
    options: [],
    placeholder: get(contact, 'job')
  }, {
    name: 'regionId',
    type: 'select',
    title: 'REGIONS.singleTitle',
    options: map(regions, (label) => ({
      value: get(label, 'id'), label: get(label, 'name')
    })),
    placeholder: contactRegion
  }]

  const setFieldValueHandler = useCallback(async (field: string, value: any, add?: boolean): Promise<void> => {
    if (!isUndefined(add) && isEqual(add, false)) {
      await setFieldValue(field, '')
      return
    }

    await setFieldValue(field, value)
  }, [])

  return (
    <div>
      <FormContainer>
        <Text
          type='regular'
          strong={true}
          variant='primary'
        >
          {t('SOURCES.editContact')}
        </Text>
        {map(chunk(fields, 2), (lineFields) => (
          <FieldsLine key={join(map(lineFields, 'key'), '-')}>
            {map(lineFields, ({ name, placeholder, type, options, title }) => (
              <InputContainer key={name}>
                <Text
                  type='regular'
                  strong={true}
                  variant='secondary'>
                  {upperCase(t<string>(title))}
                </Text>
                <SearchInput
                  name={name}
                  type={type}
                  values={values}
                  options={options}
                  multiple={false}
                  handleBlur={handleBlur}
                  placeholder={placeholder}
                  handleChange={handleChange}
                  setFieldValue={setFieldValueHandler} />
              </InputContainer>
            ))}
          </FieldsLine>
        ))}
      </FormContainer>
      <SubmitContainer>
        <Button
          label={t('COMMON.save')}
          variant='primary'
          importance='small'
          type='submit'
          onClick={() => handleSubmit()} />
        <Button
          label={t('COMMON.cancel')}
          variant='secondary'
          importance='small'
          onClick={closeModal} />
      </SubmitContainer>
    </div>
  )
}

export default UpdateContact
