import styled from 'styled-components'
import { get } from 'lodash'

import { TooltipTableProps, StyledTooltipTableProps } from './TooltipTable.types'

const FONT_SIZES = {
  small: 13,
  large: 17,
  regular: 15
}

const PADDING = {
  small: '4px 8px',
  large: '10px 24px',
  regular: '8px 16px'
}

const COLOR = {
  white: '#FFFFFF',
  info: '#cedeff',
  danger: '#FF6600',
  primary: '#0049EE',
  success: '#5fc34b',
  warning: '#FF9900',
  secondary: '#707070',
  lightDanger: '#ffe8d8',
  lightSuccess: '#e3ffd8',
  lightWarning: '#fff6d8',
  lightPrimary: '#e7eefd',
  mediumPrimary: '#cedeff',
  lightSecondary: '#f4f4f4',
  mediumSecondary: '#b5b5b5'
}

const COLOR_FONT = {
  info: '#0049EE',
  danger: 'white',
  primary: 'white',
  success: 'white',
  warning: 'white',
  secondary: 'white',
  lightDanger: '#FF6600',
  lightSuccess: '#5fc34b',
  mediumPrimary: '#0049EE',
  mediumSecondary: 'white'
}

const StyledTooltipTable = styled.div<StyledTooltipTableProps>`
  color: ${({ theme, customProps }) => get(COLOR_FONT, get(customProps, 'color') ?? 'primary', `${get(theme, 'darkGrey', '#FFF') as string}`)};
  padding: ${({ customProps }) => get(PADDING, get(customProps, 'size') ?? 'regular', '9px 16px')};
  font-size: ${({ customProps }) => get(FONT_SIZES, get(customProps, 'size') ?? 'regular', 15)}px;
  background-color: ${({ theme, customProps }) => get(COLOR, get(customProps, 'color') ?? 'primary', `${get(theme, 'darkGrey', '#FFF') as string}`)};
  text-align: center;
  box-shadow: 0 3px 6px 0 rgba(51,102,204,0.15);
  font-family: SourceSansPro;
  font-weight: 600;
  border-radius: 25px;
  z-index: 999;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 10px;
  width: fit-content;

  :before {
    content:'';
    position:absolute;
    width: 0;
    height: 0;
    border:10px solid transparent;
    border-bottom:10px solid ${({ theme, customProps }) => get(COLOR, get(customProps, 'color') ?? 'danger', `${get(theme, 'darkGrey', '#FFF') as string}`)};;
  }
`

const TooltipTable = ({
  buttonSize = 'regular',
  label, color, children,
  ...props
}: TooltipTableProps): JSX.Element => {
  return (
    <StyledTooltipTable
      {...props}
      customProps={{
        size: buttonSize,
        color: color
      }}
      buttonSize={buttonSize}
      color={color}
    >
      {children}
    </StyledTooltipTable>
  )
}
export default TooltipTable
