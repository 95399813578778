import styled from 'styled-components'
import { get } from 'lodash'
import { media, spacings, Icon, Text } from '@folhomee/front-library'

import { PaginationProps } from './Pagination.types'

const Container = styled.div`
  cursor: pointer;
  margin: ${get(spacings, 'xs')}px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.lessThan('sm')`
    margin: ${get(spacings, 'xxs')}px;
  `}
`

const StyledIcon = styled(Icon)`
  transform: scale(0.6);
  
  & path {
    stroke-width: 4 !important;
  }
`

const Pagination = ({ end, page, updatePage }: PaginationProps): JSX.Element => {
  return (
    <Container>
      {page >= 2 && <StyledIcon
        variant='arrow-left'
        onClick={() => updatePage(page - 1)} />}
      <Text type='regular' variant='primary' strong={true}>{page}</Text>
      {!end && <StyledIcon
        variant='arrow-right'
        onClick={() => updatePage(page + 1)} />}
    </Container>
  )
}
export default Pagination
