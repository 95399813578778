import styled from 'styled-components'
import { spacings } from '@folhomee/front-library'
import { get, isEqual } from 'lodash'

import { BoxProps } from './Box.types'

const StyledBox = styled.div<BoxProps>`
  border: 1px solid ${({ theme }) => get(theme, 'grey')};
  display: flex;
  padding: ${get(spacings, 'xxs')}px;
  align-items: center;
  
  border-top: ${({ top }) => isEqual(top, true) ? '' : 0};
  border-left: ${({ left }) => isEqual(left, true) ? '' : 0};
  border-right: ${({ right }) => isEqual(right, true) ? '' : 0};
  border-bottom: ${({ bottom }) => isEqual(bottom, true) ? '' : 0};
`

const Box = ({ top, left, right, bottom, children, ...props }: BoxProps): JSX.Element => {
  return (
    <StyledBox
      {...props}
      top={top}
      left={left}
      right={right}
      bottom={bottom}>
      {children}
    </StyledBox>
  )
}

export default Box
