
import styled from 'styled-components'
import { get } from 'lodash'
import { spacings } from '@folhomee/front-library'

const InlineTitle = styled.div`
  display: flex;
  justify-content: start;
  gap: ${get(spacings, 'xxs')}px;
  width: 100%;
  height: fit-content;
  padding-top: 48px;
`

export default InlineTitle
