import styled from 'styled-components'
// @ts-expect-error
import MarkerClusterGroup from '@christopherpickering/react-leaflet-markercluster'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer'
import { useTranslation } from 'react-i18next'
import { LatLngExpression } from 'leaflet'
import { MapContainer, Marker, Popup } from 'react-leaflet'
import { useState, useRef, useCallback } from 'react'
import { get, map, random, capitalize } from 'lodash'

import Icon from '../../atoms/Icon'
import Text from '../../atoms/Text'
import numeral from '../../../services/numeral'
import DisplayImage from '../../molecules/DisplayImage'
import LoaderRotating from '../LoaderRotating'
import ButtonContainer from '../../atoms/ButtonContainer'
import { getDeliveryDate } from '../../../queries/utils/programFormat'
import { MarkerItemProps, ProgramsMapProps } from './ProgramsMap.types'
import { useFetchProgramImageQuery, useFetchProgramsMapQuery } from '../../../queries/Programs'
import FlexBox from '../../atoms/FlexBox'

const containerStyle = {
  width: '100%',
  height: '586px',
  borderRadius: '10px'
}

const FolhomeeContainer = styled.div`
  width: 100%;
  height: 588px;
  margin-top: 16px;
  border-radius: 10px;

  & .markercluster-map {
    height: 90vh;
  }
`

const BlockImg = styled.div`
  height: 120px;
  cursor: pointer;
  margin-bottom: 8px;
`

const StyledPopup = styled(Popup)`
  .leaflet-popup-content {
    margin: 4px;
  }

  .leaflet-popup-content-wrapper{
    width: 250px;
    border-radius: 20px;
  }
`

const addRandomMeter = (meters: number): number =>
  meters * (1 / ((2 * Math.PI / 360) * 6378.137)) / 1000

const MarkerItem = ({ item, openProgramPage }: MarkerItemProps): JSX.Element => {
  const { t } = useTranslation()
  const latitude = useRef(parseFloat(get(item, 'attributes.display_position.lat', 0)) + addRandomMeter(random(10, 30)))
  const { data, isFetching, refetch } = useFetchProgramImageQuery(get(item, 'id'))

  const onPopupOpen = useCallback(() => {
    refetch()
  }, [refetch])

  return (
    <Marker
      position={[latitude.current, get(item, 'attributes.display_position.lng', 0)]}
      eventHandlers={{
        click: onPopupOpen
      }}>
      <StyledPopup>
        <BlockImg onClick={() => openProgramPage(get(item, 'id'))}>
          <DisplayImage data={data} isFetching={isFetching} />
        </BlockImg>
        <FlexBox alignStart padding='8px' gap={4}>
          <FlexBox alignStart>
            <Text color='primary' textType='bigger' strong>
              {capitalize(get(item, 'attributes.name', ''))} • {capitalize(get(item, 'attributes.promoter', ''))}
            </Text>
            <Text color='primary' textType='small'>
              {capitalize(get(item, 'attributes.city', ''))}
            </Text>
          </FlexBox>
          <FlexBox row top={8} bottom={8}>
            <Icon variant='building-solid' transform='scale(0.9) translateX(-2px)' fill />
            <Text color='primary' strong>
              {t('PROGRAM.lotsAvailable', { count: get(item, 'attributes.total_lots', 0) })}
            </Text>
          </FlexBox>
          <Text color='primary'>
            {t('PROGRAM.deliveryValue', { operator: getDeliveryDate(new Date(get(item, 'attributes.delivery_date', 0))) })}
          </Text>
          <Text color='primary'>
            {t('PROGRAM.typologies', { count: get(item, 'attributes.type_s', 0) })}
          </Text>
          <Text color='danger' strong>
            {t('PROGRAM.priceFrom', { price: numeral(get(item, 'attributes.min_price', 0)).format('0,0 $') })}
          </Text>
          <ButtonContainer
            top={8}
            color='primary'
            buttonSize='regular'
            onClick={() => openProgramPage(get(item, 'id'))}
            outline
            center>
            <Text color='primary' strong>
              {t('PROGRAM.showProgram')}
            </Text>
          </ButtonContainer>
        </FlexBox>
      </StyledPopup>
    </Marker>
  )
}

const ProgramsMap = ({ search }: ProgramsMapProps): JSX.Element => {
  const center = useRef<LatLngExpression>([46.2153165, 3.5193059])
  const [readyToRender, updateReadyToRender] = useState(false)
  const { data, isFetching } = useFetchProgramsMapQuery(search)

  setTimeout(() => {
    updateReadyToRender(true)
  }, 0)

  const openProgramPage = useCallback((id: string): void => {
    const url = `/programs/${id}`

    const win = window.open(url, '_blank')

    if (win != null) {
      win.focus()
    }
  }, [])

  return (
    <FolhomeeContainer>
      {isFetching && <LoaderRotating loading={isFetching} />}
      {readyToRender && <MapContainer
        zoom={6}
        style={containerStyle}
        center={center.current}
        scrollWheelZoom={true}>
        <ReactLeafletGoogleLayer apiKey='AIzaSyAUqQHGO5orNQYmzKNTZE3cKiZeGp6kDOc' type='roadmap' />
        <MarkerClusterGroup>
          {map(data, (item: any) => (
            <MarkerItem item={item} openProgramPage={openProgramPage} />
          ))}
        </MarkerClusterGroup>
      </MapContainer>}
    </FolhomeeContainer>
  )
}

export default ProgramsMap
