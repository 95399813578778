import styled from 'styled-components'
import { get } from 'lodash'
import { spacings } from '@folhomee/front-library'

const RightContainer = styled.div`
  width: 100%;
  height: 100vh;
  overflow: auto;
  padding-top: ${get(spacings, 'xl')}px;
  background-image: linear-gradient(to bottom, #fcfdff 9%, ${({ theme }) => get(theme, 'lightBlue', '#FFF')} 99%);
`

export default RightContainer
