import { useState, useRef, useEffect } from 'react'
import { get, map, includes, isEmpty, isEqual, orderBy } from 'lodash'

import Table from '../../organisms/Table'
import LotRow from '../../molecules/LotRow'
import { LotsTableProps } from './LotsTable.types'
import { useFetchAlertQuery } from '../../../queries/Alerts'
import { isHighlightedFromAlert } from '../../../utils/highlights'
import { BODY_LOTS, HEADER_LOTS } from '../../../utils/tables/lots'

export const sortLots = (lots: any): any => {
  return orderBy(map(lots, elt => {
    elt.lowerPrice = get(elt, 'prices')[0]
    return elt
  }), 'lowerPrice')
}

const LotsTable = ({ lots, alertId, programId, higlightedLots }: LotsTableProps): JSX.Element => {
  const optionsLot = useRef({
    bodyColor: 'extraLightBlue',
    headerColor: 'lightGrey',
    columnAlignment: '0.2fr 0.2fr 0.3fr 0.2fr 0.35fr 0.35fr 0.2fr 0.4fr 0.25fr 0.55fr 0.45fr 0.2fr 0.2fr 0.2fr'
  })

  const [displayedLots, updatedDisplayedLots] = useState(map(sortLots(lots), (lot) => ({
    ...lot,
    higlighted: includes(higlightedLots, `${get(lot, 'id', '') as string}`)
  })))

  const { data } = useFetchAlertQuery(alertId, !isEmpty(alertId))

  useEffect(() => {
    updatedDisplayedLots(map(displayedLots, lot => ({
      ...lot,
      higlighted: isEqual(get(lot, 'higlighted', false), true) || isHighlightedFromAlert(lot, get(data, 'data'))
    })))
  }, [data])

  return (
    <Table
      row={LotRow}
      meta={{ programId }}
      data={displayedLots}
      body={BODY_LOTS}
      header={HEADER_LOTS}
      options={optionsLot.current}/>
  )
}

export default LotsTable
