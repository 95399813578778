import styled from 'styled-components'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { get, isEqual } from 'lodash'

import Icon from '../../atoms/Icon'
import Text from '../../atoms/Text'
import Modal from '../../organisms/Modal'
import FlexBox from '../../atoms/FlexBox'
import ConfirmationHandler from '../ConfirmationHandler'
import { FavoriteButtonProps, FavoriteCheckboxProps } from './FavoriteCheckbox.types'

const StyledIcon = styled(Icon) <FavoriteButtonProps>`
  & > path {
    fill: ${({ theme, checked }) => isEqual(checked, true) ? get(theme, 'orange') : 'none'} !important;
  }
`

const FavoriteCheckbox = ({ setFavorite }: FavoriteCheckboxProps): JSX.Element => {
  const { t } = useTranslation()
  const [isOpen, updateIsOpen] = useState(false)
  const [isChecked, updateIsChecked] = useState(true)

  const onClickHandler = (evt: any): void => {
    evt.stopPropagation()
    updateIsOpen(!isOpen)
  }

  const setFavoriteHandler = (): void => {
    updateIsChecked(!isChecked)
    setFavorite(!isChecked)
    updateIsOpen(false)
  }

  return (
    <FlexBox>
      <Modal
        gap={24}
        isOpen={isOpen}
        onRequestClose={() => updateIsOpen(false)}
        backgroundBlur>
        <ConfirmationHandler onCancel={() => updateIsOpen(false)} onConfirm={setFavoriteHandler}>
          <Text textType='bigger' color='primary'>{t<string>('CLIENTS.removeFavorite')}</Text>
        </ConfirmationHandler>
      </Modal>
      <StyledIcon
        color='danger'
        checked={isChecked}
        onClick={onClickHandler}
        variant='favorite'
        stroke />
    </FlexBox>
  )
}

export default FavoriteCheckbox
