import List from '../../templates/List'
import CityRow from '../../molecules/CityRow'
import { useFetchInfiniteCitiesQuery } from '../../../queries/Cities'

const HEADER = [{
  label: 'COMMON.name',
  textLeft: true
}, {
  label: 'CITIES.insee'
}, {
  label: 'CITIES.zipcode'
}, {
  label: 'COMMON.fiscalZone'
}, {
  label: 'CITIES.department'
}]

const BODY = [{
  key: 'name',
  title: 'name'
}, {
  key: 'insee',
  title: 'Insee'
}, {
  key: 'zipcode',
  title: 'zipcode'
}, {
  key: 'taxArea',
  title: 'taxArea'
}, {
  key: 'department',
  title: 'department'
}]

const Cities = (): JSX.Element => {
  const options = {
    bodyColor: 'extraLightBlue',
    headerColor: 'transparent',
    columnAlignment: '2fr 1fr 1fr 1fr 1fr'
  }

  return (
    <List
      row={CityRow}
      body={BODY}
      title='cities'
      header={HEADER}
      options={options}
      useFetch={useFetchInfiniteCitiesQuery} />
  )
}

export default Cities
