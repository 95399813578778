import styled from 'styled-components'
import { spacings } from '@folhomee/front-library'
import { useTranslation } from 'react-i18next'
import { get, isEqual } from 'lodash'
import { ChangeEvent, useCallback, useState } from 'react'

import Text from '../../atoms/Text'
import FlexBox from '../../atoms/FlexBox'
import Checkbox from '../../atoms/Checkbox'
import ButtonContainer from '../../atoms/ButtonContainer'
import { CommentFormProps } from './CommentForm.types'
import { useCreateCommentQuery } from '../../../queries/Comments'

const Body = styled.textarea`
  width: 100%;
  border: 0.5px solid ${({ theme }) => get(theme, 'darkGrey')};
  padding: ${get(spacings, 'xxs')}px;
  font-size: 16px;
  min-height: 220px;
  font-family: SourceSansPro;
  border-radius: 4px;
`

const Choice = styled(Checkbox)`
  border-radius: 50%;
`

const StyledText = styled(Text)`
  margin-right: auto;
`

const CommentForm = ({ id, route, updateOpen }: CommentFormProps): JSX.Element => {
  const { t } = useTranslation()
  const [isPublic, updateIsPublic] = useState<boolean>(isEqual(route, 'sources'))
  const [comment, updateComment] = useState<string>('')

  const { mutateAsync } = useCreateCommentQuery(id, route)

  const handleVisibility = useCallback((isPublic: boolean) => {
    updateIsPublic(isPublic)
  }, [updateIsPublic])

  const handleWriting = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    updateComment(get(e, 'target.value', ''))
  }, [updateComment])

  const handleCommentPush = useCallback(async () => {
    await mutateAsync({ comment, public: isPublic })
    updateOpen(false)
  }, [comment, isPublic])

  return (
    <FlexBox width100>
      <FlexBox width100 row justifyContent='space-between' bottom={16}>
        <StyledText
          color='primary'
          strong>
          {t('COMMENTS.titleNew')}
        </StyledText>
        {!isEqual(route, 'sources') &&
          <>
            <FlexBox row gap={4} right={16}>
              <Choice
                label=''
                bordered={isEqual(isPublic, true)}
                importance='secondary'
                checkboxSize='small'
                onClick={() => handleVisibility(false)} />
              <Text>{t('COMMON.private')}</Text>
            </FlexBox>
            <FlexBox row gap={4} right={24}>
              <Choice
                label=''
                bordered={isEqual(isPublic, false)}
                importance='secondary'
                checkboxSize='small'
                onClick={() => handleVisibility(true)} />
              <Text>{t('COMMON.publicVisibility')}</Text>
            </FlexBox>
          </>}
      </FlexBox>
      <FlexBox width100 gap={8}>
        <Body
          name='comment'
          value={comment}
          onChange={(e) => handleWriting(e)}
          placeholder={`${t('COMMENTS.write')}${t('COMMON.suspens')}`} />
        <ButtonContainer
          top={8}
          label={t('COMMON.save')}
          color='primary'
          buttonSize='large'
          onClick={handleCommentPush} />
        <ButtonContainer
          label={t('COMMON.cancel')}
          color='secondary'
          buttonSize='large'
          onClick={() => updateOpen(false)} />
      </FlexBox>
    </FlexBox>
  )
}

export default CommentForm
