import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { media, spacings } from '@folhomee/front-library'
import { useState, useEffect } from 'react'
import { get, isEqual, isEmpty, isUndefined, reduce, concat, map, camelCase, first } from 'lodash'

import Map from '../../molecules/Map'
import Card from '../../atoms/Card'
import Text from '../../atoms/Text'
import Icon from '../../atoms/Icon'
import Page from '../../atoms/Page'
import Modal from '../../organisms/Modal'
import Loader from '../../molecules/LoaderRotating'
import FlexBox from '../../atoms/FlexBox'
import Carousel from '../Carousel'
import Comments from '../../organisms/Comments'
import MaxContent from '../../atoms/MaxContent'
import MuteButton from '../../atoms/MuteButton'
import ProgramLink from '../../molecules/ItemLink'
import ButtonRound from '../../atoms/ButtonRound'
import ProgramMute from '../../molecules/ProgramMute'
import DoubleButton from '../../atoms/DoubleButton'
import ContactButton from '../../atoms/ContactButton'
import ProgramDouble from '../../molecules/ProgramDouble'
import ProgramSummary from '../../molecules/ProgramSummary'
import FavoriteButton from '../../atoms/FavoriteButton'
import AddFavoriteModal from '../AddFavoriteModal'
import ProgramDescription from '../../molecules/ProgramDescription'
import ContactProgramModal from '../ContactProgramModal'
import ProgramDocumentForm from '../../molecules/ProgramDocumentForm'
import { ProgramPreview } from '../../../queries/Programs/Programs.types'
import { useFetchMeQuery } from '../../../queries/Users'
import { ExternalReference } from '../../../queries/utils/query.types'
import { ProgramDetailsProps } from './ProgramDetails.types'
import { useFetchContactsQuery } from '../../../queries/Contacts'
import { useFetchProgramDouble, useFetchProgramDocumentsQuery } from '../../../queries/Programs'

const Container = styled(MaxContent)`
  gap: ${get(spacings, 'xs')}px;
  width: 100%;
  margin: ${get(spacings, 'xl')}px auto;
  display: flex;
  max-width: 1300px;

  ${media.lessThan('lg')`
    max-width: 100%;
    flex-direction: column;
  `}
`

const First = styled(FlexBox)`
  width: 60%;
`

const BlockAction = styled(Card)`
  gap: ${get(spacings, 'xs')}px;
  padding:  ${get(spacings, 's')}px ${get(spacings, 'l')}px;
  
  ${media.lessThan('md')`
    flex-direction: column-reverse;
  `}
`

const ModalContact = styled(Modal)`
  & div {
    background: red;
  }
`

const BlockDoc = styled(Card)`
  padding:  ${get(spacings, 's')}px;
  flex-grow: 1;
  max-height: 400px;
`

const ListDoc = styled.div`
  gap: 10px;
  display: flex;
  overflow: auto;
  flex-direction: column;
`

const DocumentLink = styled.a`
  gap: 7px;
  display: flex;
  align-items: center;
  text-decoration: none;
`

const LineText = styled(Text)`
  overflow: hidden;
  max-width: 152px;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    overflow: visible;
  }
  
  & > span:hover {
    z-index: 9999;
    background-color: #e7eefdd4;
  }
`

const StyledComments = styled(Comments)`
  max-width: 100% !important;
`

const getDocumentName = (fileType: string, fileName: string): string => {
  if (isEqual(fileType, 'other')) {
    return fileName
  }

  return 'PROGRAM.' + camelCase(fileType)
}

const ProgramDetails = ({ openFavorite, lots, user, program, ...props }: ProgramDetailsProps): JSX.Element => {
  const { t } = useTranslation()
  const [isOpenMute, updateIsOpenMute] = useState(false)
  const [isOpenDouble, updateIsOpenDouble] = useState(false)
  const [isOpenDocForm, updateIsOpenDocForm] = useState(false)
  const [isOpenFavorite, updateIsOpenFavorite] = useState(false)
  const [isOpenContact, updateIsOpenContact] = useState(false)

  const mute: boolean = get(program, 'mute', false)

  const programId = get(program, 'id')
  const sources = map(get(program, 'sources', []), 'id')

  const { data, isFetching } = useFetchProgramDouble(programId)
  const { data: userData, isFetching: isFetchingUser } = useFetchMeQuery()
  const { data: documentsData } = useFetchProgramDocumentsQuery(programId.toString())
  const { data: contactsData } = useFetchContactsQuery(sources)

  const contacts = get(first(get(contactsData, 'pages')), 'data', [])

  const sourceLinks = reduce(get(program, 'sources'),
    (acc: ExternalReference[], source): ExternalReference[] => acc.concat(map(get(source, 'urls', []), url => ({ url, crawlType: 'prescriber', sourceName: get(source, 'name') }))), [])

  const double: ProgramPreview[] = get(data, 'data', [])

  useEffect(() => {
  }, [isFetching])

  if (isUndefined(program)) {
    return (
      <></>
    )
  }

  if (isEqual(isFetching, true) || isEqual(isFetchingUser, true) || isUndefined(program) || isUndefined(userData)) {
    return (
      <Page>
        <Loader loading={isFetching} />
      </Page>
    )
  }

  return (
    <Container {...props}>
      <First gap={16} >
        <Carousel images={get(program, 'images')} />
        <ProgramDescription program={program} />
        <Map position={{ lat: parseFloat(get(program, 'address.lat', 0).toString()), lng: parseFloat(get(program, 'address.lng', 0).toString()) }} />
      </First>
      <FlexBox gap={16} width100 flexGrow>
        <FlexBox row gap={16} alignStart>
          <Card>
            <ProgramSummary program={program} />
          </Card>
          <FlexBox gap={16} height100>
            <BlockAction row>
              <MuteButton
                onClick={() => updateIsOpenMute(true)} />
              {isEqual(isFetching, false) && isEqual(get(user, 'role'), 'admin') && !isEmpty(double) && <DoubleButton
                onClick={() => updateIsOpenDouble(true)} />}
              <Modal
                gap={16}
                isOpen={isOpenDouble}
                onRequestClose={() => updateIsOpenDouble(false)}
                alignStart
                justifyStart
                backgroundBlur>
                <ProgramDouble update={updateIsOpenDouble} id={programId} double={double} />
              </Modal>
              <Modal
                gap={16}
                isOpen={isOpenMute}
                onRequestClose={() => updateIsOpenMute(false)}
                alignStart
                justifyStart
                backgroundBlur>
                <ProgramMute updateModal={updateIsOpenMute} id={programId} mute={mute} />
              </Modal>
              <ContactButton onClick={() => updateIsOpenContact(true)} programId={programId} />
              <ModalContact
                gap={8}
                isOpen={isOpenContact}
                onRequestClose={() => updateIsOpenContact(false)}
                alignStart
                justifyStart>
                <ContactProgramModal update={updateIsOpenContact} id={programId} contacts={contacts} />
              </ModalContact>
              <FavoriteButton openFavorite={() => updateIsOpenFavorite(true)} onClick={() => updateIsOpenFavorite(true)} programId={programId} />
              <Modal
                gap={16}
                isOpen={isOpenFavorite}
                onRequestClose={() => updateIsOpenFavorite(false)}
                alignStart
                justifyStart
                backgroundBlur>
                <AddFavoriteModal programId={programId} lotIds={lots} closeModal={() => updateIsOpenFavorite(false)} />
              </Modal>
              <ProgramLink urls={concat(get(program, 'url'), sourceLinks)} onClick={concat(get(program, 'url'), sourceLinks)} />
            </BlockAction>
            <Modal
              gap={16}
              isOpen={isOpenDocForm}
              backgroundBlur
              onRequestClose={() => updateIsOpenDocForm(false)}>
              <ProgramDocumentForm
                programId={get(program, 'id', '').toString()}
                updateOpen={updateIsOpenDocForm}
                programName={get(program, 'name', '')} />
            </Modal>
            <BlockDoc width100 justifyStart alignStart>
              <FlexBox row justifyContent='space-between' width100>
                <Text textType='subtitle' strong>
                  {t('COMMON.documents')}
                </Text>
                <Icon cursor stroke variant='plus-circle' onClick={() => updateIsOpenDocForm(true)} />
              </FlexBox>
              <ListDoc>
                {map(documentsData, doc => {
                  return (
                    <DocumentLink href={get(doc, 'url', '')} target='_blank'>
                      <ButtonRound
                        buttonSize='small'
                        color='info'
                        onClick={() => get(doc, 'url', '')}>
                        <Icon variant='download' stroke transform='scale(0.8)' />
                      </ButtonRound>
                      <LineText strong color='primary'>
                        {t(getDocumentName(get(doc, 'fileType', 'other'), get(doc, 'name', '')))}
                      </LineText>
                    </DocumentLink>
                  )
                })}
              </ListDoc>
            </BlockDoc>
          </FlexBox>
        </FlexBox>
        <StyledComments
          id={get(program, 'id')}
          userId={get(userData, 'id')}
          route='programs'
          programSources={map(get(program, 'sources', []), 'id')} />
      </FlexBox>
    </Container>
  )
}

export default ProgramDetails
