import styled from 'styled-components'
import { media, spacings } from '@folhomee/front-library'
import { get, isEqual, isUndefined } from 'lodash'

import { DataProps } from './Data.types'

const Data = styled.div <DataProps>`
  gap: ${({ gap }) => isUndefined(gap) ? 0 : gap}px;
  padding: ${get(spacings, 'xxs')}px ${({ left }) => isEqual(left, true) ? '8px' : 0};
  display: flex;
  padding:  ${({ noPadding }) => isEqual(noPadding, true) ? 0 : ''};
  align-items:  ${({ column }) => isEqual(column, true) ? 'start' : 'center'};
  flex-direction:  ${({ column }) => isEqual(column, true) ? 'column' : 'row'};
  justify-content:  ${({ left }) => isEqual(left, true) ? 'start' : 'center'};

  ${media.lessThan('md')`
    padding: 20px;
  `}
`

export default Data
