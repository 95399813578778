import styled from 'styled-components'
import { useSearchParams } from 'react-router-dom'
import { useCallback, useState } from 'react'
import { get, map, isEqual, isNull, isEmpty, capitalize } from 'lodash'

import Row from '../../atoms/Row'
import Data from '../Data'
import Icon from '../../atoms/Icon'
import Text from '../../atoms/Text'
import Modal from '../../organisms/Modal'
import Field from '../../atoms/Field'
import FlexBox from '../../atoms/FlexBox'
import numeral from '../../../services/numeral'
import ContainerRow from '../../atoms/ContainerRow'
import DisplayImage from '../../molecules/DisplayImage'
import { useMutateAnnonceQuery } from '../../../queries/Annonces'
import { ButtonContainerProps, ProgramRowProps } from './ProgramRow.types'

const StyledContainerRow = styled(ContainerRow)`
  margin-bottom: 1px;
`
const LargeData = styled(Data)`
  width: 152px;
`

const ImagePreview = styled.div`
  position: relative;
  width: 7.6em;
  height: 5.5em;
  margin: 0;
  
  & > img {
    border-radius: 5px;
  }
`

const StyledData = styled(Data)`
  cursor: pointer;
`

const LineText = styled(Text)`
  overflow: hidden;
  max-width: 152px;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    overflow: visible;
  }
  
  & > span:hover {
    z-index: 9999;
    background-color: #e7eefdd4;
  }
`

const ButtonContainer = styled.div<ButtonContainerProps>`
  border: 1px solid white;
  background-color: ${({ theme, status }) => get(theme, status)};
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
`

const Deleted = styled(Text)`
  position: absolute;
  top: 35%;
  left: 24px;
  font-weight: 1000;
  background-color: white;
  padding: 2px;
  z-index: 1000000;
`

const ProgramRowLoaded = ({ data, fields, options, meta }: ProgramRowProps): JSX.Element => {
  const [searchParams] = useSearchParams()
  const programId: string = get(data, 'id', 0).toString()
  const status = get(data, 'attributes.status', 1)
  const [open, updateOpen] = useState(false)
  const [action, setAction] = useState('')

  const { mutateAsync } = useMutateAnnonceQuery(programId)

  const getActionStatus = useCallback((status, action) => {
    if (isEqual(action, 'supprimer')) {
      return 'supprimer'
    }

    if (isEqual(status, 1)) {
      return 'désactiver'
    }

    return 'activer'
  }, [])

  const openProgramPage = useCallback(() => {
    const url = `/annonces/${programId}`

    if (!isEqual(isNull(searchParams.get('alert_id')), true)) {
      const win = window.open(`${url}?alert_id=${searchParams.get('alert_id') as string}`, '_blank')

      if (win != null) {
        win.focus()
      }
    }

    const win = window.open(url, '_blank')

    if (win != null) {
      win.focus()
    }
  }, [data])

  const updateModalStatus = useCallback(() => {
    updateOpen(!open)
  }, [open])

  const updateProgramStatus = useCallback(async () => {
    const newStatus = isEqual(action, 'supprimer') ? 4 : isEqual(status, 1) ? 3 : 1
    await mutateAsync({ ad: { status_id: newStatus } })
    updateOpen(!open)
  }, [open, action])

  return (
    <>
      <StyledContainerRow options={options} status={status}>
        <Row options={options}>
          {map(fields, ({ key, values }) => {
            if (isEqual(key, 'images')) {
              return (
                <StyledData key={key} onClick={openProgramPage} left column noPadding>
                  <ImagePreview>
                    <DisplayImage data={get(data, 'attributes.display_img')} isFetching={false} />
                    {isEqual(status, 4) && <Deleted color='danger'>SUPPRIMÉE</Deleted>}
                  </ImagePreview>
                </StyledData>
              )
            }

            if (isEqual(key, 'name')) {
              return (
                <LargeData key={key} column onClick={openProgramPage} gap={4}>
                  <LineText color='primary' strong>
                    {capitalize(get(data, `attributes.${key}`, ''))}
                  </LineText>
                  <FlexBox row>
                    <Icon variant='pin-solid' transform='scale(0.8) translateX(-4px)' fill />
                    <LineText color='primary'>
                      {get(data, 'attributes.display_city', '')} ({get(data, 'attributes.zipcode')})
                    </LineText>
                  </FlexBox>
                  <FlexBox row>
                    <Icon variant='pin-solid' transform='scale(0.8) translateX(-4px)' fill />
                    <LineText color='primary'>
                      {get(data, 'attributes.address', '')}
                    </LineText>
                  </FlexBox>
                </LargeData>
              )
            }

            if (isEqual(key, 'price')) {
              const price = numeral(get(data, 'attributes.min_price')).format('0,0$')
              const type = get(data, 'attributes.adType')
              const label = isEqual(type, 'sale') ? price : `${price as string} / mois`
              return (
                <Data key={key} onClick={openProgramPage}>
                  <Field
                    key={key}
                    name={key}
                    value={label} />
                </Data>
              )
            }

            if (isEqual(key, 'adType')) {
              const label = {
                sale: 'Vente',
                rental: 'Location',
                sublet: 'Sous-location'
              }
              return (
                <Data key={key} onClick={openProgramPage} column={true}>
                  <Field
                    key={`${key}-adType`}
                    name={`${key}-adType`}
                    value={get(label, get(data, 'attributes.adType', ''))} />
                  <Field
                    key={`${key}-whole`}
                    name={`${key}-whole`}
                    value={isEqual(get(data, 'attributes.whole'), false) ? 'Salle privative' : 'Local entier'} />
                  <Field
                    key={`${key}-fulltime`}
                    name={`${key}-fulltime`}
                    value={isEmpty(get(data, 'attributes.days')) ? 'Temps plein' : 'Temps partiel'} />
                </Data>
              )
            }

            if (isEqual(key, 'max_floor')) {
              return (
                <Data key={key} onClick={openProgramPage}>
                  <Field
                    key={key}
                    name={key}
                    value={isEqual(get(data, 'attributes.max_floor'), 0) ? 'RDC' : get(data, 'attributes.max_floor')} />
                </Data>
              )
            }

            if (isEqual(key, 'user')) {
              return (
                <Data key={key} onClick={openProgramPage} column={true}>
                  <Field
                    key={`${key}-user-name`}
                    name={`${key}-user-name`}
                    value={capitalize(get(data, 'attributes.user.name'))} />
                  <Field
                    key={`${key}-user-lastname`}
                    name={`${key}-user-lastname`}
                    value={capitalize(get(data, 'attributes.user.lastname'))} />
                </Data>
              )
            }

            if (isEqual(key, 'action')) {
              if (isEqual(status, 4)) {
                return null
              }

              return (
                <Data key={key} onClick={updateModalStatus}>
                  <ButtonContainer
                    status={isEqual(status, 1) ? 'orange' : 'green'}
                    onClick={() => setAction('')} >
                    <Text color='white'>
                      {isEqual(status, 1) ? 'désactiver' : 'activer'}
                    </Text>
                  </ButtonContainer>
                  <ButtonContainer status='grey' onClick={() => setAction('supprimer')}>
                    <Text color='white'>
                      X
                    </Text>
                  </ButtonContainer>
                </Data>
              )
            }

            if (isEqual(key, 'payments')) {
              const payments = get(data, 'attributes.payments', [])
              return (
                <Data key={key} onClick={openProgramPage} column={true}>
                  {map(payments, ({ name, subscription, amount }, idx) => {
                    return (
                      <Field
                        key={`key-${name as string}-${idx}`}
                        name={`key-${name as string}`}
                        value={`${isEmpty(subscription) ? '' : '[abonnement] '}${name as string} (${amount as string})`} />
                    )
                  })}
                  {isEmpty(payments) &&
                    <Text color='danger'>
                      Non payée
                    </Text>}
                </Data>
              )
            }

            return (
              <Data key={key} onClick={openProgramPage}>
                <Field
                  key={key}
                  name={key}
                  value={get(data, `attributes.${key}`)} />
              </Data>
            )
          })}
        </Row>
      </StyledContainerRow >
      <Modal isOpen={open} onRequestClose={updateModalStatus}>
        <>
          <Text>
            Êtes vous sûr de vouloir {getActionStatus(status, action)} l&apos;annonce {get(data, 'attributes.name')} ?
          </Text>
          <ButtonContainer status={isEqual(status, 1) ? 'orange' : 'green'} onClick={updateProgramStatus}>
            <Text color='white'>
              {getActionStatus(status, action)}
            </Text>
          </ButtonContainer>
        </>
      </Modal>
    </>
  )
}

const AnnonceRow = ({ data, fields, options, meta }: ProgramRowProps): JSX.Element => (
  <ProgramRowLoaded
    data={data}
    fields={fields}
    options={options}
    meta={meta} />
)

export default AnnonceRow
