/* eslint-disable @typescript-eslint/strict-boolean-expressions */

// import styled from 'styled-components'
import { get, map, pickBy, identity, isUndefined, isEqual, toString, isEmpty, isNull, filter } from 'lodash'
import { Button } from '@folhomee/front-library'
import { useFormik } from 'formik'
import { useState, useCallback } from 'react'

import Text from '../../atoms/Text'
import SearchInput from '../../molecules/SearchInput'
import { InputItem } from '../../templates/Form'
import { AlertFormProps } from './AlertForm.types'
import { useCreateAlertQuery, useMutateAlertQuery } from '../../../queries/AdAlerts'

const allowedEmptyKeys = ['whole', 'fulltime', 'ad_type']

const AlertForm = ({ alert, updateOpen }: AlertFormProps): JSX.Element => {
  const [error, updateError] = useState('')
  const [loading, setLoading] = useState(false)
  const { mutateAsync } = useMutateAlertQuery(get(alert, 'id'))
  const { mutateAsync: createAsync } = useCreateAlertQuery()

  const getFulltimeValue = (action: any, value: any): any => {
    if (isEqual(action, 'acheter')) {
      return true
    }

    return value
  }

  const getWholeValue = (action: any, value: any): any => {
    if (isEqual(action, 'acheter')) {
      return true
    }

    return value
  }

  const getProfession = (value: any): any => {
    return isEqual(value, 'Toutes catégories') ? '' : value
  }

  const onSubmit = useCallback(async (values) => {
    if (loading) {
      return
    }

    const dpts = filter(get(values, 'location'), elt => isEqual(get(elt, 'type'), 'departments'))
    const cities = filter(get(values, 'location'), elt => isEqual(get(elt, 'type'), 'cities'))
    const regions = filter(get(values, 'location'), elt => isEqual(get(elt, 'type'), 'regions'))
    const agglomerations = filter(get(values, 'location'), elt => isEqual(get(elt, 'type'), 'agglomerations'))

    const submitValues = {
      name: get(values, 'name', ''),
      cities: map(cities, city => get(city, 'value')),
      regions: map(regions, region => get(region, 'value')),
      departments: map(dpts, dpt => get(dpt, 'value')),
      agglomerations: map(agglomerations, agglomeration => get(agglomeration, 'value')),
      ad_type: get(values, 'action'),
      min_surface: get(values, 'surface', ''),
      max_price: get(values, 'budget', ''),
      min_rooms: isEqual(get(values, 'whole'), 'false') ? 1 : get(values, 'minRooms', ''),
      max_rooms: isEqual(get(values, 'whole'), 'false') ? 1 : get(values, 'maxRooms', ''),
      professioncategory: getProfession(get(values, 'professions', '')),
      whole: getWholeValue(get(values, 'action'), get(values, 'whole', '')),
      fulltime: getFulltimeValue(get(values, 'action'), get(values, 'availability', ''))
    }

    const filteredValues = pickBy(submitValues, (value, key) => {
      if (allowedEmptyKeys.includes(key)) {
        return true
      }

      if (Array.isArray(value)) {
        return value.length > 0
      }

      if (typeof value === 'boolean') {
        return true
      }

      return identity(value)
    })

    try {
      if (!isEmpty(get(alert, 'userId'))) {
        await createAsync({
          ...filteredValues,
          user_id: get(alert, 'userId')
        })
      } else {
        await mutateAsync(filteredValues)
      }
      updateOpen(false)
    } catch (error) {
      console.log('error ', error)
      updateError('Une erreur est survenue durant la modification des données')
    } finally {
      setLoading(false)
    }
  }, [loading])

  const { values, handleChange, setFieldTouched, setFieldValue, handleBlur } = useFormik({
    initialValues: {
      name: get(alert, 'name'),
      whole: toString(get(alert, 'whole')),
      rooms: filter([get(alert, 'min_rooms'), get(alert, 'max_rooms')], val => !isNull(val)),
      availability: toString(get(alert, 'fulltime')),
      minRooms: get(alert, 'min_rooms'),
      maxRooms: get(alert, 'max_rooms'),
      action: get(alert, 'ad_type'),
      budget: get(alert, 'max_price'),
      professions: get(alert, 'professioncategory.name', 'Toutes catégories'),
      surface: get(alert, 'min_surface'),
      location: [
        ...map(get(alert, 'cities'), city => ({
          value: toString(get(city, 'id')),
          type: 'cities',
          label: get(city, 'name')
        })),
        ...map(get(alert, 'departments'), dpt => ({
          value: toString(get(dpt, 'id')),
          type: 'departments',
          label: get(dpt, 'name')
        })),
        ...map(get(alert, 'regions'), region => ({
          value: toString(get(region, 'id')),
          type: 'regions',
          label: get(region, 'name')
        })),
        ...map(get(alert, 'agglomerations'), agglo => ({
          value: toString(get(agglo, 'id')),
          type: 'agglomerations',
          label: get(agglo, 'name')
        }))
      ]
    },
    onSubmit
  })

  const fields = [{
    key: 'name',
    type: 'text',
    options: [],
    placeholder: 'ANNONCE.name'
  }, {
    key: 'action',
    type: 'options',
    options: [{
      value: 'acheter',
      label: 'Vendre'
    }, {
      value: 'louer',
      label: 'Location'
    }],
    placeholder: 'ANNONCE.adType'
  }, {
    key: 'location',
    type: 'location',
    options: [],
    placeholder: 'ANNONCE.price'
  }, {
    key: 'budget',
    type: 'number',
    options: [],
    placeholder: 'ANNONCE.price'
  }, {
    key: 'surface',
    type: 'number',
    options: [],
    placeholder: 'ANNONCE.surface'
  }, {
    key: 'whole',
    type: 'options',
    options: [{
      value: 'true',
      label: 'Local entier'
    }, {
      value: 'false',
      label: 'Salle privative'
    }],
    placeholder: 'ANNONCE.whole'
  }, {
    key: 'minRooms',
    type: 'number',
    options: [],
    placeholder: 'ANNONCE.minRooms'
  }, {
    key: 'maxRooms',
    type: 'number',
    options: [],
    placeholder: 'ANNONCE.maxRooms'
  }, {
    key: 'availability',
    type: 'options',
    options: [{
      value: 'true',
      label: 'Temps plein'
    }, {
      value: 'false',
      label: 'Temps partiel'
    }],
    placeholder: 'ANNONCE.fulltime'
  }, {
    key: 'professions',
    type: 'options',
    options: [{
      value: 'Toutes catégories',
      label: 'Toutes catégories'
    }, {
      value: 'Médical',
      label: 'Médical'
    }, {
      value: 'Paramédical',
      label: 'Paramédical'
    }, {
      value: 'Juridique',
      label: 'Juridique'
    }, {
      value: 'Conseil/Finance',
      label: 'Conseil/Finance'
    }, {
      value: 'Autre',
      label: 'Autre'
    }],
    placeholder: 'ANNONCE.professions'
  }]

  const setFieldValuesHandler = useCallback(async (field: string, value: any, add?: boolean): Promise<any> => {
    const currValues = get(values, field, [])
    if (!isUndefined(add) && isEqual(add, false)) {
      return await setFieldValue(field, filter(currValues, elt => !isEqual(`${get(elt, 'value') as string}`, `${get(value, 'value') as string}`)))
    }

    await setFieldValue(field, [...currValues, value])
  }, [values])

  return (
    <div>
      {map(fields, ({ key, placeholder, type, options }, index) => {
        if (isEqual(key, 'whole') && isEqual(get(values, 'ad_type'), 'sale')) {
          return null
        }

        if (isEqual(key, 'location')) {
          return (
            <div key={key}>
              <p>Localisation</p>
              <SearchInput
                key={key}
                name={key}
                type={type}
                values={values}
                options={options}
                multiple={true}
                handleBlur={handleBlur}
                placeholder={placeholder}
                handleChange={handleChange}
                setFieldValue={setFieldValuesHandler} />
            </div>
          )
        }

        return (
          <InputItem
            key={index}
            field={{ key, placeholder, type, options }}
            values={values}
            optionNull={isEqual(type, 'options')}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched} />
        )
      })}
      {!isEmpty(error) && <Text color='danger'>{error}</Text>}
      <Button
        label={loading ? 'En attente...' : 'Valider'}
        onClick={async () => await onSubmit(values)}
        variant='success'
        importance='regular' />
    </div >
  )
}

export default AlertForm
