import { Variant } from '@folhomee/front-library'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { get, isEqual } from 'lodash'

import Page from '../../atoms/Page'
import Text from '../../atoms/Text'
import Table from '../../organisms/Table'
import Loader from '../../molecules/LoaderRotating'
import AlertRow from '../../molecules/AlertRow'
import MaxContent from '../../atoms/MaxContent'
import Pagination from '../../atoms/Pagination'
import { useFetchPaginatedAlertsQuery } from '../../../queries/Alerts'

const HEADER = [{
  label: 'COMMON.status'
}, {
  label: 'COMMON.createdDate'
}, {
  textLeft: true,
  label: 'ALERTS.alertName'
}, {
  label: 'ALERTS.view',
  icon: 'eye' as Variant
}, {
  label: 'COMMON.actions'
}]

const BODY_FIELDS = [{
  key: 'status',
  title: 'status'
}, {
  key: 'createdAt',
  title: 'date'
}, {
  key: 'name',
  title: 'name'
}, {
  key: 'view',
  title: 'view'
}, {
  key: 'action',
  title: 'action'
}]

const Alerts = (): JSX.Element => {
  const { t } = useTranslation()
  const [page, updatePage] = useState<number>(1)
  const { data, isFetching } = useFetchPaginatedAlertsQuery(page)

  const options = {
    bodyColor: 'extraLightBlue',
    headerColor: 'transparent',
    columnAlignment: '0.5fr 0.5fr 1.5fr 1.0fr 0.5fr'
  }

  return (
    <Page>
      <MaxContent>
        <Text textType='title' strong>
          {t('ALERTS.alertsTitle', { count: get(data, 'meta.total', 0) })}
        </Text>
        <Loader loading={isFetching} />
        {isEqual(isFetching, false) && <div>
          <Table
            row={AlertRow}
            data={get(data, 'data', [])}
            body={BODY_FIELDS}
            header={HEADER}
            options={options} />
          <Pagination
            end={false}
            page={page}
            updatePage={updatePage} />
        </div>}
      </MaxContent>
    </Page>
  )
}

export default Alerts
