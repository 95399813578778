import { AxiosError } from 'axios'
// @ts-expect-error
import { deserialize } from 'deserialize-json-api'
import { map, get, capitalize, omit, range, size, isEmpty, isUndefined, isEqual, reduce, filter } from 'lodash'
import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult, useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from 'react-query'

import useClient from '../../hooks/useClient'
import { getLots } from '../utils/lotFormat'
import { getCity } from '../utils/cityFormat'
import { DataSource } from '../Sources/Sources.types'
import { PagePrograms, PageProgram, Program, Address, ProgramCount, ProgramDocument } from './Programs.types'
import { getRelationships, buildQueryParams, getFieldByIds, getObjectById, getObjectByIds, getFieldById } from '../utils'
import {
  getMinPriceProgram, getTypologies, isActable, getDeliveryDate, getMaxPriceProgram, filterUrls, getUrls
} from '../utils/programFormat'

const buildProgram = (id: number, attributes: any, included: any, relationships: any, meta: object): Program => {
  const ships = getRelationships(relationships, ['city', 'address'], ['lots', 'taxations', 'external_references', 'images', 'sources', 'documents'])
  const lots = getLots(included, get(ships, 'lots', []))

  return {
    id,
    url: filterUrls(getUrls(included, ships),
      filter(map(getFieldByIds(included, get(ships, 'sources', []), 'source', 'attributes'), (source) => ({
        url: get(source, 'url_prescriber'), crawlType: 'sourcePrescriber', sourceName: get(source, 'name')
      })), ({ url }) => !isEmpty(url))
    ),
    city: getCity(included, get(ships, 'city', 0)),
    mute: get(meta, 'mute', false),
    name: capitalize(get(attributes, 'name')),
    lots,
    images: getFieldByIds(included, get(ships, 'images', []), 'image', 'attributes.url'),
    sources: getObjectByIds(included, get(ships, 'sources', []), 'source', { id: 'id', name: 'name', urlPrescriber: 'url_prescriber', urls: 'urls' }) as DataSource[],
    address: getObjectById(included, get(ships, 'address', 0), 'address', { text: 'text', lat: 'lat', lng: 'lng' }) as Address,
    typeLots: getTypologies(lots),
    taxations: getFieldByIds(included, get(ships, 'taxations', []), 'taxation', 'attributes.name'),
    priceRange: {
      maxPrice: getMaxPriceProgram(lots),
      minPrice: getMinPriceProgram(lots)
    },
    isFavorite: get(attributes, 'is_favorite', false),
    actability: isActable(new Date(get(attributes, 'actability_date'))),
    description: get(attributes, 'description'),
    deliveryDate: getDeliveryDate(new Date(get(attributes, 'delivery_date'))),
    brochureLink: getFieldById(included, get(ships, 'documents', [])[0], 'document', 'attributes.folhomee_url')
  }
}

const transformPage = ({ data }: any): PagePrograms => {
  const meta = get(data, 'meta')

  return {
    meta,
    data: map(get(data, 'data'), ({ id, attributes }) => {
      return {
        id,
        name: get(attributes, 'name')
      }
    })
  }
}

const transformMapPage = ({ data }: any): any => {
  const page = deserialize(get(data, 'data'))

  return reduce(page, (acc: any, item: any): any => [...acc, item], [])
}

const transformListPage = ({ data }: any): any => {
  const page = deserialize(get(data, 'data'))

  return {
    meta: get(data, 'meta'),
    data: reduce(page, (acc: any, item: any): any => {
      if (!isEmpty(item)) {
        return [...acc, item]
      }
      return acc
    }, [])
  }
}

export const useFetchProgramsCountQuery = (search: any): UseQueryResult<ProgramCount, AxiosError> => {
  const client = useClient()

  return useQuery(['fetchProgramsCount', search], async () => {
    return await client.get('/api/programs/count', {
      params: {
        ...buildQueryParams(omit(search, ['page']))
      }
    })
  }, {
    select: ({ data }): ProgramCount => {
      return {
        ...data
      }
    },
    staleTime: 1000 * 60,
    refetchOnWindowFocus: false
  })
}

export const useFetchInfiniteProgramsQuery = (search: any): UseInfiniteQueryResult<any, AxiosError> => {
  const client = useClient()

  return useInfiniteQuery(['fetchInfinitePrograms', search], async ({ pageParam = 1 }) => {
    return await client.get('/api/programs', {
      params: {
        'page[number]': pageParam,
        'page[size]': 11,
        ...buildQueryParams(omit(search, ['page']))
      }
    })
  }, {
    select: ({ pages }): InfiniteData<any> => ({
      pages: map(pages, transformListPage),
      pageParams: range(size(pages) + 1)
    }),
    getNextPageParam: (_, allPages) => size(allPages) + 1,
    staleTime: 1000 * 60,
    refetchOnWindowFocus: false
  })
}

export const useFetchProgramsMapQuery = (search: any): UseQueryResult<any, AxiosError> => {
  const client = useClient()

  return useQuery(['fetchProgramsMap', search], async () => {
    return await client.get('/api/programs/map', {
      params: {
        ...buildQueryParams(omit(search, ['page']))
      }
    })
  }, {
    select: transformMapPage,
    staleTime: 1000 * 60,
    refetchOnWindowFocus: false
  })
}

export const useFetchProgramQuery = (id: string, search: any): UseQueryResult<PageProgram, AxiosError> => {
  const client = useClient()

  return useQuery(['fetchProgram', id], async () => {
    return await client.get(`/api/programs/${id}`, {
      params: {
        ...buildQueryParams(omit(search, ['page']))
      }
    })
  }, {
    select: ({ data }): PageProgram => {
      const meta = get(data, 'meta')
      const included = get(data, 'included')
      const { id, attributes, relationships } = get(data, 'data')

      return {
        meta,
        data: buildProgram(id, attributes, included, relationships, meta)
      }
    },
    enabled: !isEmpty(id) && !isUndefined(id) && !isEqual(id, '0'),
    refetchOnWindowFocus: false
  })
}

export const useFetchProgramImageQuery = (id: string, enabled: boolean = false): UseQueryResult<string, AxiosError> => {
  const client = useClient()

  return useQuery(['fetchProgramImage', id], async () => {
    if (isUndefined(id)) {
      return { data: {} }
    }

    return await client.get(`/api/programs/${id}/image`)
  }, {
    select: ({ data }) =>
      get(data, 'image', 'https://cdn.folhomee.fr/public/images/no-picture.png'),
    enabled,
    staleTime: 1000 * 60,
    refetchOnWindowFocus: false
  })
}

export const useFetchProgramDouble = (id: number): UseQueryResult<PagePrograms, AxiosError> => {
  const client = useClient()

  return useQuery(['fetchProgramDouble', id], async () => {
    return await client.get(`/api/programs/${id}/double`)
  }, {
    select: ({ data }): PagePrograms => transformPage({ data }),
    getNextPageParam: (_, allPages) => size(allPages) + 1,
    staleTime: 1000 * 60,
    refetchOnWindowFocus: false
  })
}

export const useMutateProgramFavoriteQuery = (id: number, lotIds: number[]): UseMutationResult => {
  const client = useClient()
  const queryClient = useQueryClient()

  return useMutation(async (programClients) => await client.put(`/api/programs/${id}/favorites`, programClients), {
    onSuccess: async () => {
      await queryClient.refetchQueries(['fetchInfiniteProgramClients', id, lotIds])
    }
  })
}

export const useFetchProgramDocumentsQuery = (id: string): UseQueryResult<ProgramDocument[], AxiosError> => {
  const client = useClient()

  return useQuery(['fetchDocuments', id], async () => {
    return await client.get(`/api/programs/${id}/documents`)
  }, {
    select: ({ data }): ProgramDocument[] => {
      return map(data, doc => ({
        id: get(doc, 'id'),
        url: get(doc, 'folhomee_url'),
        name: get(doc, 'name'),
        fileType: get(doc, 'file_type')
      }))
    },
    staleTime: 1000 * 60,
    refetchOnWindowFocus: false
  })
}
