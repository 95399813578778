import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useSetRecoilState } from 'recoil'
import { Button, Error } from '@folhomee/front-library'
import { useCallback, useState } from 'react'
import { get, isEmpty, isUndefined, split, last, omit } from 'lodash'
import * as Yup from 'yup'

import AuthTop from '../../molecules/AuthTop'
import AuthCard from '../../atoms/AuthCard'
import AuthQuote from '../../atoms/AuthQuote'
import FormInput from '../../atoms/FormInput'
import useClient from '../../../hooks/useClient'
import LoginForm from '../../organisms/LoginForm'
import AuthContainer from '../../atoms/AuthContainer'
import { getErrors } from '../../../services/errors'
import { userTokenState } from '../../../recoil/atoms'

const showError = (error: string, touched: boolean): boolean => !isEmpty(error) && touched

const Register = (): JSX.Element => {
  const { t } = useTranslation()
  const client = useClient()
  const setUserToken = useSetRecoilState(userTokenState)
  const [loading, updateLoading] = useState<boolean>(false)
  const [requestError, updateRequestError] = useState('')

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('ERRORS.emailError'))
      .trim()
      .test('domain-validation', t('ERRORS.emailFolhomee'), value => /@folhomee.fr\s*$/.test(value ?? ''))
      .required(t('ERRORS.emailRequired')),
    password: Yup.string()
      .min(8, t('ERRORS.passwordTooShort'))
      .max(30, t('ERRORS.passwordTooLong'))
      .matches(/[A-Z]{1,}/, { excludeEmptyString: true, message: t('ERRORS.passwordNoUpperCase') })
      .matches(/[a-z]{1,}/, { excludeEmptyString: true, message: t('ERRORS.passwordNoLowerCase') })
      .matches(/[0-9]{1,}/, { excludeEmptyString: true, message: t('ERRORS.passwordNoNumber') })
      .trim()
      .required(t('ERRORS.passwordRequired')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], t('ERRORS.differentPassword'))
      .trim()
      .required(t('ERRORS.noPasswordConfirm'))
  })

  const onSubmit = useCallback(async (values, { resetForm }) => {
    updateLoading(true)

    try {
      const { headers } = await client.post('/auth/register', {
        user: omit(values, ['confirmPassword'])
      })

      setUserToken(last(split(get(headers, 'authorization', ''), ' ')) ?? '')
    } catch (err) {
      const message = get(err, 'response.data.message')
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const registerError = isUndefined(message) ? getErrors(get(err, 'message', 'Network Error')) : getErrors(`REGISTER.${message}`)
      updateRequestError(registerError)
    } finally {
      updateLoading(false)
      resetForm()
    }
  }, [])

  const { handleSubmit, handleChange, handleBlur, touched, values, errors } = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: ''
    },
    validationSchema: loginSchema,
    onSubmit
  })

  const emailError = showError(get(errors, 'email', ''), get(touched, 'email', false))
  const passwordError = showError(get(errors, 'password', ''), get(touched, 'password', false))
  const confirmPasswordError = showError(get(errors, 'confirmPassword', ''), get(touched, 'confirmPassword', false))

  return (
    <AuthContainer>
      <AuthCard>
        <AuthTop title='REGISTER.title' />
        <LoginForm loading={loading} withoutImage={true}>
          <Error
            show={!isEmpty(requestError)}
            label={t(requestError)} />
          <FormInput
            type='email'
            name='email'
            error={emailError}
            errors={errors}
            values={values}
            testId='input-email'
            handleBlur={handleBlur}
            placeholder={t('COMMON.email')}
            handleChange={handleChange} />
          <FormInput
            type='password'
            name='password'
            error={passwordError}
            errors={errors}
            values={values}
            testId='input-password'
            handleBlur={handleBlur}
            placeholder={t('COMMON.password')}
            handleChange={handleChange} />
          <FormInput
            type='password'
            name='confirmPassword'
            error={confirmPasswordError}
            errors={errors}
            values={values}
            testId='input-confirm-password'
            handleBlur={handleBlur}
            placeholder={t('COMMON.confirmPassword')}
            handleChange={handleChange} />
          <Button
            type='submit'
            label={t('REGISTER.button')}
            variant='danger'
            onClick={(evt) => {
              evt.preventDefault()

              return handleSubmit()
            }}
            importance='small' />
        </LoginForm>
        <AuthQuote />
      </AuthCard>
    </AuthContainer>
  )
}

export default Register
