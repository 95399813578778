import { AxiosError } from 'axios'
import { get, omit, map, range, size, isEqual } from 'lodash'
import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult, useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from 'react-query'

import useClient from '../../hooks/useClient'
import { Data } from '../utils/jsonapi.types'
import { Contact } from '../Contacts/Contacts.types'
import { PageSources, PageSource } from './Sources.types'
import { buildQueryParams, getObjectByIds, getRelationships } from '../utils'

export const useFetchSourcesQuery = (): UseQueryResult<Data[], AxiosError> => {
  const client = useClient()

  return useQuery(['fetchSources'], async () => await client.get('/api/sources/all'), {
    select: ({ data }): Data[] => {
      return map(get(data, 'data'), ({ attributes }) => ({
        label: get(attributes, 'name'),
        value: get(attributes, 'id')
      }))
    },
    staleTime: 1000 * 60 * 60 * 24,
    refetchOnWindowFocus: false
  })
}

const transformPage = ({ data }: any): PageSources => ({
  meta: get(data, 'meta'),
  data: map(get(data, 'data'), ({ id, attributes }) => ({
    id,
    name: get(attributes, 'name'),
    slug: get(attributes, 'slug'),
    logo: get(attributes, 'logo'),
    broken: get(attributes, 'broken', false),
    urlPublic: get(attributes, 'url_public', ''),
    urlPrescriber: get(attributes, 'url_prescriber', ''),
    crawlersPublic: get(attributes, 'crawlers_public', 0),
    crawlersOtaree: get(attributes, 'crawlers_otaree', 0),
    crawlersPrescriber: get(attributes, 'crawlers_prescriber', 0)
  }))
})

export const useFetchInfiniteSourceQuery = (search: any): UseInfiniteQueryResult<PageSources, AxiosError> => {
  const client = useClient()

  return useInfiniteQuery(['fetchInfiniteSources', search], async ({ pageParam = 1 }) => {
    return await client.get('/api/sources', {
      params: {
        'page[number]': pageParam,
        'page[size]': 21,
        ...buildQueryParams(omit(search, ['page']))
      }
    })
  }, {
    select: ({ pages }): InfiniteData<PageSources> => ({
      pages: map(pages, transformPage),
      pageParams: range(size(pages) + 1)
    }),
    getNextPageParam: (_, allPages) => size(allPages) + 1,
    staleTime: 60 * 1000,
    refetchOnWindowFocus: false
  })
}

export const useFetchSourceQuery = (id: string): UseQueryResult<PageSource, AxiosError> => {
  const client = useClient()

  return useQuery(['fetchSource', id], async () => {
    return await client.get(`/api/sources/${id}`)
  }, {
    select: ({ data }): PageSource => {
      const { id, attributes, relationships } = get(data, 'data')
      const ships = getRelationships(relationships, [], ['contacts'])
      const included = get(data, 'included')

      return {
        meta: 0,
        data: {
          id,
          urls: get(attributes, 'urls', []),
          slug: get(attributes, 'slug', ''),
          name: get(attributes, 'name', ''),
          logo: get(attributes, 'logo'),
          crawlers: get(data, 'meta.crawlers'),
          contacts: getObjectByIds(included, get(ships, 'contacts', []), 'contact', { id: 'id', firstName: 'first_name', lastName: 'last_name', email: 'email', phone: 'phone' }) as Contact[],
          programs: get(data, 'meta.programs'),
          urlPublic: get(attributes, 'url_public', ''),
          urlPrescriber: get(attributes, 'url_prescriber', '')
        }
      }
    },
    refetchOnWindowFocus: false
  })
}

export const useDeleteSourceQuery = (sourceId: number, newSource: number): UseMutationResult => {
  const client = useClient()
  const queryClient = useQueryClient()

  return useMutation(['deleteSource', sourceId], async () => {
    await client.delete(`/api/sources/${sourceId}`, {
      params: isEqual(newSource, -1) ? {} : { newSource }
    })
  }, {
    onSuccess: async () => {
      await queryClient.refetchQueries(['fetchInfiniteSources'])
    }
  })
}
