import qs from 'qs'
import styled from 'styled-components'
import useAsyncEffect from 'use-async-effect'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { spacings, media } from '@folhomee/front-library'
import { useState, useEffect, useCallback } from 'react'
import { get, reduce, isEmpty, camelCase, isEqual, omit, filter } from 'lodash'

import Text from '../../atoms/Text'
import Icon from '../../atoms/Icon'
import Table from '../../organisms/Table'
import FlexBox from '../../atoms/FlexBox'
import Container from '../../atoms/Container'
import ProgramRow from '../../molecules/ProgramRow'
import SearchForm from '../../molecules/SearchForm'
import TableBottom from '../../molecules/TableBottom'
import ProgramsMap from '../../molecules/ProgramsMap'
import SearchButton from '../../atoms/SearchButton'
import AlertsHandler from '../../organisms/AlertsHandler'
import SearchManager from '../../molecules/SearchManager'
import RightContainer from '../../atoms/RightContainer'
import ButtonContainer from '../../atoms/ButtonContainer'
import { getMinMediaQuery } from '../../../utils/media'
import { SearchFieldsProps } from '../../molecules/SearchForm/SearchForm.types'
import { useFetchSourcesQuery } from '../../../queries/Sources'
import { useFetchTaxationsQuery } from '../../../queries/Taxations'
import { DisplayProgramDataProps } from './Programs.types'
import { buildSimpleQuery, fetchOptions } from '../../../utils/search'
import { useFetchInfiniteProgramsQuery, useFetchProgramsCountQuery } from '../../../queries/Programs'
import { HEADER_PROGRAMS, BODY_PROGRAMS, SMALL_BODY_PROGRAMS, SEARCH_FIELDS, MORE_SEARCH_FIELDS } from '../../../utils/tables/programs'

const PROGRAM_SEARCH_FIELDS = [
  'useType', 'rooms', 'floor', 'taxations', 'sources', 'program', 'minPrice', 'maxPrice', 'minSurface', 'maxSurface',
  'deliveryQuarter', 'deliveryYear', 'deliveryQuarterMinimum', 'deliveryYearMinimum', 'parking', 'terrace',
  'garden', 'parking', 'lotType', 'location', 'distance'
]

const SearchHeader = styled.div`
  display: flex;
  align-items: center;
`

const StyledTable = styled(Table)`
  max-width: 100%;
`

const DataContainer = styled.div`
  ${media.greaterThan('sm')`
    padding: 0 ${get(spacings, 's')}px ${get(spacings, 's')}px ${get(spacings, 's')}px;
  `}
`

const StyledSearchManager = styled(SearchManager)`
  display: ${({ search }) => isEmpty(search) ? 'none' : 'block'};
`

const isSearchFormVisible = (showSearch: boolean, bigScreen: boolean): boolean => showSearch || (bigScreen && getMinMediaQuery('xl').matches)

const DisplayProgramData = ({ showMap, isFetching, fetchNextPage, bigScreen, data, search }: DisplayProgramDataProps): JSX.Element => {
  if (isEqual(showMap, true)) {
    return (
      <ProgramsMap search={search} />
    )
  }

  const options = {
    bodyColor: 'extraLightBlue',
    headerColor: 'transparent',
    columnAlignment: '8em 9.5em 0.6fr 0.8fr 0.8fr 0.8fr 0.4fr 0.6fr 0.5fr 1fr'
  }

  const optionsMobile = {
    bodyColor: 'extraLightBlue',
    headerColor: 'transparent',
    columnAlignment: '8.5em 1.3fr 0.8fr 0.8fr 0.5fr 0.8fr'
  }

  return (
    <>
      <StyledTable
        row={ProgramRow}
        data={data}
        body={isEqual(bigScreen, true) ? BODY_PROGRAMS : SMALL_BODY_PROGRAMS}
        header={HEADER_PROGRAMS}
        options={bigScreen ? options : optionsMobile}
        bigScreen={bigScreen}
        noPadding={true} />
      <TableBottom
        loading={isFetching}
        fetchNextPage={fetchNextPage} />
    </>
  )
}

const Programs = (): JSX.Element => {
  const { t } = useTranslation()
  const [search, updateSearch] = useState<any>({})
  const [showMap, updateShowMap] = useState<boolean>(false)
  const [loading, updateLoading] = useState<boolean>(true)
  const [bigScreen, updatebigScreen] = useState<boolean>(getMinMediaQuery('xl').matches)
  const [showSearch, updateShowSearch] = useState<boolean>(false)
  const [searchBuild, updateSearchBuild] = useState(true)
  const [searchParams, updateSearchParams] = useSearchParams()
  const [searchFields, updateSearchFields] = useState<SearchFieldsProps[]>([...SEARCH_FIELDS, ...MORE_SEARCH_FIELDS])
  const { data, isFetching, fetchNextPage } = useFetchInfiniteProgramsQuery(search)
  const { data: count, isFetching: isFetchingCount } = useFetchProgramsCountQuery(search)
  const { data: sources, isFetching: isFetchingSources } = useFetchSourcesQuery()
  const { data: taxations, isFetching: isFetchingTaxations } = useFetchTaxationsQuery()

  const toggleMap = useCallback(() => updateShowMap(!showMap), [showMap, updateShowMap])

  const formatProgramSearch = useCallback((search) => buildSimpleQuery(search), [])

  const addOptionsToFields = async (searchFields: SearchFieldsProps[]): Promise<void> => {
    const fields = await fetchOptions(searchFields, [...taxations ?? [], { label: t('PROGRAM_SEARCH.withoutTaxation'), value: -1 }], sources)

    updateSearchFields(fields)
    updateLoading(false)
  }

  useAsyncEffect(async () => {
    if ((!isFetchingTaxations && !isEmpty(taxations)) || (!isFetchingSources && !isEmpty(sources))) {
      await addOptionsToFields(searchFields)
    }

    const mql = getMinMediaQuery('xl')
    const resize = (event: MediaQueryListEvent): void => updatebigScreen(event.matches)

    mql.addEventListener('change', resize)
    return () => {
      mql.removeEventListener('change', resize)
    }
  }, [sources, taxations, isFetchingTaxations, isFetchingSources])

  useEffect(() => {
    const querySearch = qs.parse(searchParams.toString())

    updateSearch(reduce(omit(querySearch, ['page', 'alert_id']), (acc, value, key) => ({
      ...acc,
      [key]: value
    }), {}))
  }, [searchParams])

  if (isEqual(loading, true)) {
    return (
      <></>
    )
  }

  const displayData = filter(reduce(get(data, 'pages', []), (acc: any[], items: any[]): any[] =>
    [...acc, ...get(items, 'data', [])], []), item => !isEmpty(item))

  return (
    <Container>
      <SearchForm
        build={searchBuild}
        search={search}
        fields={searchFields}
        sources={sources}
        visible={isSearchFormVisible(showSearch, bigScreen)}
        taxations={taxations}
        searchFields={PROGRAM_SEARCH_FIELDS}
        formatSearch={formatProgramSearch}
        updateSearch={updateSearchParams}
        updateShowSearch={updateShowSearch}
        updateSearchBuild={updateSearchBuild} />
      <RightContainer>
        <SearchHeader>
          <SearchButton
            showSearch={showSearch}
            updateShowSearch={updateShowSearch} />
          <StyledSearchManager
            search={search}
            fields={searchFields}
            updateSearch={updateSearchParams}
            updateSearchBuild={updateSearchBuild} />
          <AlertsHandler search={reduce(search, (acc, value, key) => ({
            ...acc,
            ...(isEmpty(value) ? {} : { [camelCase(key)]: value })
          }), {})} />
        </SearchHeader>
        {isEqual(isFetchingCount, false) && <DataContainer>
          <FlexBox row justifyContent='space-between'>
            <Text textType='title' strong>
              {t('PROGRAM.programs', { count: get(count, 'programs', 0), total: get(count, 'lots', 0) })}
            </Text>
            <ButtonContainer
              color='info'
              buttonSize='regular'
              type='button'
              onClick={toggleMap}>
              <>
                <Icon variant={isEqual(showMap, true) ? 'list' : 'map-solid'} color='primary' fill />
                {t(isEqual(showMap, true) ? 'PROGRAM.seeOnList' : 'PROGRAM.seeOnMap')}
              </>
            </ButtonContainer>
          </FlexBox>
          <DisplayProgramData
            data={displayData}
            search={search}
            showMap={showMap}
            bigScreen={bigScreen}
            isFetching={isFetching}
            fetchNextPage={fetchNextPage} />
        </DataContainer>}
      </RightContainer>
    </Container >
  )
}

export default Programs
