import styled from 'styled-components'
import { spacings } from '@folhomee/front-library'
import { useEffect, useCallback, useState, KeyboardEvent, useRef, ChangeEvent } from 'react'
import { get, isEqual, size, isEmpty, map, filter, find, isUndefined } from 'lodash'

import Input from '../../atoms/Input'
import LoadingDots from '../../atoms/LoadingDots'
import { useOnClickOutside } from '../../../hooks/useOnClickOutside'
import { AutocompleteProps, SuggestionsDisplayProps } from './Autocomplete.types'
import {
  Suggestion, SuggestionContainerProps, SuggestionsListProps,
  SuggestionItemProps
} from '../../../utils/types/Suggestions.types'
import { offColor } from 'off-color'

const getBorderColor = (error: boolean, bordered: boolean, importance: string): string => {
  if (error) {
    return 'orange'
  }

  if (!bordered) {
    return 'white'
  }

  return isEqual(importance, 'primary') ? 'blue' : 'darkGrey'
}

const InputContainer = styled.div`
  width: 100%;
`

const StyledInput = styled(Input)`
  color: ${({ theme }) => offColor(get(theme, 'darkGrey')).rgba(0.5)};
  height: 35px;
  padding-left: 8px;
  font-family: SourceSansPro;

  &::placeholder {
    font-size: 15px;
    font-family: SourceSansPro;
  }
`
const SuggestionsContainer = styled.div<SuggestionContainerProps>`
  border: 1px solid ${({ theme, borderColor }) => get(theme, borderColor, '#fff')};
  padding:  ${get(spacings, 'xxs')}px;
  overflow: overlay;
  max-height: 220px;
  border-radius: ${({ squared }) => isEqual(squared, true) ? 5 : 53}px;
`

const SuggestionsList = styled.ul<SuggestionsListProps>`
  & > li {
    cursor: pointer;
    padding: ${get(spacings, 'xxs')}px;
  }

  & > li:last-of-type {
    border: none;
  }
`

const SuggestionItem = styled.li<SuggestionItemProps>`
  color: ${({ theme }) => get(theme, 'darkGrey', '#fff')};
  font-size: 15px;
  background: ${({ isActive, theme }) => isEqual(isActive, true) ? get(theme, 'lightBlue') : 'transparent'};
  border-radius: 5px;
  margin-bottom: 4px;
`

const Inline = styled.div`
  gap: 4px;
  display: flex;
`

const SuggestionsDisplay = ({
  show, suggestions, noSuggestionTitle, loading, squared, borderColor, onClick, activeSuggestion,
  updateActiveSuggestion
}: SuggestionsDisplayProps): JSX.Element | null => {
  const onHover = useCallback((idx: number): void => updateActiveSuggestion(idx), [updateActiveSuggestion])

  if (isEqual(show, false)) {
    return null
  }

  if (isEqual(loading, true)) {
    return (
      <SuggestionsContainer
        squared={squared}
        borderColor={borderColor}>
        <LoadingDots />
      </SuggestionsContainer>
    )
  }

  if (isEmpty(suggestions)) {
    return (
      <SuggestionsContainer
        squared={squared}
        borderColor={borderColor}>
        <p>{noSuggestionTitle}</p>
      </SuggestionsContainer>
    )
  }

  return (
    <SuggestionsContainer
      squared={squared}
      borderColor={borderColor}>
      <SuggestionsList borderColor={borderColor}>
        {map(suggestions, ({ type, label, value }: Suggestion, index): JSX.Element => (
          <SuggestionItem
            key={value}
            onClick={async () => await onClick({ type, label, value })}
            isActive={isEqual(index, activeSuggestion)}
            onMouseEnter={() => onHover(index)}>
            <Inline>
              {label}
            </Inline>
          </SuggestionItem>
        ))}
      </SuggestionsList>
    </SuggestionsContainer>
  )
}

const Autocomplete = ({
  inputSize = 'regular', importance = 'primary',
  name, value, error, subKeys, multiple, placeholder, bordered, squared, onChange, onBlur, selection,
  fetchSuggestions, noSuggestionTitle = 'No suggestion found', minimumCharacters = 0,
  ...props
}: AutocompleteProps): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [loading, updateLoading] = useState<boolean>(false)
  const [userInput, updateUserInput] = useState<string>('')
  const [userSelection, updateUserSelection] = useState<Suggestion[]>(isUndefined(selection) ? [] : selection)
  const [showSuggestions, updateShowSuggestions] = useState<boolean>(false)
  const [activeSuggestion, updateActiveSuggestion] = useState<number>(0)
  const [filteredSuggestions, updateFilteredSuggestions] = useState<Suggestion[]>([])

  useEffect(() => {
    if (!isEqual(userSelection, selection) && !isUndefined(selection)) {
      updateUserSelection(isUndefined(selection) ? [] : filter(selection, item => !isEmpty(item)))
    }
  }, [selection])

  const outsideClick = useCallback(() => {
    updateShowSuggestions(false)
    updateActiveSuggestion(0)
    return updateFilteredSuggestions([])
  }, [updateShowSuggestions, updateActiveSuggestion, updateFilteredSuggestions])

  useOnClickOutside(containerRef, outsideClick)

  const getSuggestions = useCallback(async (value: string): Promise<void> => {
    updateLoading(true)

    try {
      const suggestions = await fetchSuggestions(value)

      updateShowSuggestions(true)
      updateActiveSuggestion(0)
      updateFilteredSuggestions(filter(suggestions, sugg =>
        isUndefined(find(userSelection, select => isEqual(get(select, 'value'), get(sugg, 'value'))))))
    } catch (err) {
      updateShowSuggestions(false)
      updateActiveSuggestion(0)
      updateFilteredSuggestions([])
    } finally {
      updateLoading(false)
    }
  }, [userSelection, updateLoading, fetchSuggestions, updateShowSuggestions, updateActiveSuggestion, updateFilteredSuggestions])

  const onFocus = useCallback(async (): Promise<void> => {
    if (size(userInput) < minimumCharacters) {
      updateShowSuggestions(false)
      updateActiveSuggestion(0)
      return updateFilteredSuggestions([])
    }

    return await getSuggestions(userInput)
  }, [userInput, updateShowSuggestions, updateActiveSuggestion, updateFilteredSuggestions, minimumCharacters, getSuggestions])

  const onChangeHandler = useCallback(async (evt: string | ChangeEvent<any>): Promise<void> => {
    const evtValue = get(evt, 'currentTarget.value', '')

    updateUserInput(evtValue)

    if (isEmpty(evtValue) || size(evtValue) < minimumCharacters) {
      updateShowSuggestions(false)
      updateActiveSuggestion(0)
      return updateFilteredSuggestions([])
    }

    return await getSuggestions(evtValue)
  }, [fetchSuggestions, updateUserInput, updateShowSuggestions, updateActiveSuggestion, updateFilteredSuggestions, minimumCharacters, getSuggestions])

  const onRemoveHandler = useCallback(async (label: string, value: any): Promise<void> => {
    updateUserSelection(filter(userSelection, select => !isEqual(get(select, 'value'), value)))
    await onChange(name, { label, value }, false)
  }, [userSelection])

  const onClick = useCallback(async (suggestion: Suggestion) => {
    updateUserInput('')
    updateUserSelection([...userSelection, suggestion])
    await onChange(name, suggestion, true)
    updateShowSuggestions(false)
    updateActiveSuggestion(0)
    updateFilteredSuggestions([])
  }, [userSelection, updateUserInput, updateUserSelection, updateShowSuggestions, updateActiveSuggestion, updateFilteredSuggestions])

  const onKeyDown = useCallback(async (evt: KeyboardEvent): Promise<void> => {
    const keyCode = get(evt, 'key', get(evt, 'keyIdentifier', get(evt, 'keyCode')))

    if (isEqual(keyCode, 'Enter')) {
      evt.preventDefault()

      if (isEqual(activeSuggestion, -1)) {
        return
      }
      const suggestion = filteredSuggestions[activeSuggestion]

      return await onClick(suggestion)
    }

    if (isEqual(keyCode, 'Up') || isEqual(keyCode, 'ArrowUp')) {
      evt.preventDefault()

      if (isEqual(activeSuggestion, 0)) {
        return
      }

      return updateActiveSuggestion(activeSuggestion - 1)
    }

    if (isEqual(keyCode, 'Down') || isEqual(keyCode, 'ArrowDown')) {
      evt.preventDefault()

      if (isEqual(activeSuggestion, size(filteredSuggestions) - 1)) {
        return
      }

      return updateActiveSuggestion(activeSuggestion + 1)
    }
  }, [activeSuggestion, filteredSuggestions, updateUserInput, updateShowSuggestions, updateActiveSuggestion, updateFilteredSuggestions, onClick])

  return (
    <InputContainer ref={containerRef}>
      <StyledInput
        {...props}
        name={name}
        value={userInput}
        error={error}
        onBlur={onBlur}
        squared={squared}
        onFocus={onFocus}
        bordered={bordered}
        multiple={multiple}
        onChange={onChangeHandler}
        onRemove={onRemoveHandler}
        onKeyDown={onKeyDown}
        inputSize={inputSize}
        selection={userSelection}
        importance={importance}
        placeholder={placeholder}
        updateSelection={updateUserSelection} />
      <SuggestionsDisplay
        show={showSuggestions}
        squared={squared}
        onClick={onClick}
        loading={loading}
        borderColor={getBorderColor(error ?? false, bordered, importance)}
        suggestions={filteredSuggestions}
        activeSuggestion={activeSuggestion}
        noSuggestionTitle={noSuggestionTitle}
        updateActiveSuggestion={updateActiveSuggestion} />
    </InputContainer>
  )
}

export default Autocomplete
