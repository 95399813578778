import { map, filter, get, isEqual, reduce, includes, round, isEmpty, sortBy } from 'lodash'

import { Lot } from '../Lots/lots.types'
import { IncludeData } from './jsonapi.types'
import { Price, Extra } from '../Programs/Programs.types'
import { getUrls, filterUrls } from './programFormat'
import { getFieldById, getFieldByIds, getObjectByIds, getRelationships } from '.'

export const getLot = (id: number, attributes: any, included: any, relationships: any): Lot => {
  const ships = getRelationships(relationships, ['status'], ['prices', 'taxations', 'external_references', 'exposures', 'extras', 'documents', 'sources'])

  const vatPrices = getObjectByIds(included, get(ships, 'prices', []), 'price', { total: 'total', percent: 'vat_percent' }) as Price[]
  const prices = sortBy(vatPrices.map(elt => elt.total))
  const extras = getObjectByIds(included, get(ships, 'extras', []), 'extra', { type: 'type', surface: 'surface' }) as Extra[]
  const surface = get(attributes, 'surface')

  return {
    id,
    url: filterUrls(getUrls(included, ships), filter(map(getFieldByIds(included, get(ships, 'sources', []), 'source', 'attributes'), (source) => ({
      url: get(source, 'url_prescriber'), crawlType: 'sourcePrescriber', sourceName: get(source, 'name')
    })), ({ url }) => !isEmpty(url))),
    type: getTypology(get(attributes, 'type'), get(attributes, 'rooms')),
    plan: getFieldById(included, get(ships, 'documents', [])[0], 'document', 'attributes.folhomee_url'),
    floor: get(attributes, 'floor'),
    rooms: get(attributes, 'rooms'),
    status: getFieldById(included, get(ships, 'status', 0), 'status', 'attributes.name'),
    prices,
    surface,
    parking: getParkingCount(extras) > 0 ? '✔' : '-',
    typeTitle: get(attributes, 'type'),
    exposures: getFieldByIds(included, get(ships, 'exposures', []), 'exposure', 'attributes.name'),
    taxations: getFieldByIds(included, get(ships, 'taxations', []), 'taxation', 'attributes.name'),
    reference: get(attributes, 'reference'),
    programId: get(attributes, 'program_id'),
    meterPrice: getMeterPrice(prices, surface),
    surfaceExt: round(getSurfaceExt(extras), 2),
    prescriberCrawlerCount: get(attributes, 'prescriber_crawler_count')
  }
}

export const getLots = (data: IncludeData[], lotIds: number[]): Lot[] => {
  const lots = filter(data, ({ id, type }) => isEqual(type, 'lot') && includes(lotIds, id))

  return map(lots, ({ id, attributes, relationships }) => getLot(id, attributes, data, relationships))
}

export const getParkingCount = (extras: Extra[]): number =>
  round(reduce(extras, (acc: number, { type, surface }: Extra) => {
    if (isEqual(type, 'parking')) {
      return acc + parseFloat(surface ?? '0')
    }

    return acc
  }, 0))

export const getSurfaceExt = (extras: Extra[]): number =>
  reduce(extras, (acc: number, { type, surface }: Extra) => {
    if (includes(['terrace', 'garden'], type)) {
      return acc + parseFloat(surface ?? '0')
    }

    return acc
  }, 0)

export const getTypology = (type: string, rooms: number): string => {
  const lotType = isEqual(type, 'house') ? 'M' : 'T'
  return `${lotType}${rooms}`
}

const getMeterPrice = (prices: number[], surface: number): number[] =>
  map(prices, elt => round(elt / surface))
