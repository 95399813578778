export const BASE_URL: string = process.env.REACT_APP_BASE_URL ?? 'http://localhost:3670'

export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY ?? 'AIzaSyBQvsE56gDNCZZ7gw_RT_D3RY1R0q3fei4'

export const LOT_ID_QUERY_PARAM = process.env.REACT_APP_LOT_ID_QUERY_PARAM ?? 'lot_id[]'

export const ALERT_ID_QUERY_PARAM = process.env.REACT_APP_ALERT_ID_QUERY_PARAM ?? 'alert_id'

/**
 * APP RELATED
*/

export const EXTERIORS_KEYS = ['parking']
