import styled from 'styled-components'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useCallback, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { media, spacings, Input, Text } from '@folhomee/front-library'
import { isEmpty, get, isEqual, first, snakeCase, reduce } from 'lodash'

import Icon from '../../atoms/Icon'
import Modal from '../../organisms/Modal'
import Loading from '../../atoms/LoadingDots'
import useClient from '../../../hooks/useClient'
import ButtonContainer from '../../atoms/ButtonContainer'
import { useSearchAlertsQuery } from '../../../queries/Alerts'
import { AlertsHandlerProps, ModalContentProps } from './AlertsHandler.types'

const ExistingContainer = styled.div`
  gap: ${get(spacings, 'xs')}px;
  flex: 1;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
`

const FormContainer = styled.div`
  gap: ${get(spacings, 'xs')}px;
  display: flex;
  padding: ${get(spacings, 'xs')}px ${get(spacings, 'm')}px;
  flex-direction: column;

  ${media.lessThan('md')`
    padding: 0;
  `}
`

const AlertIcon = styled(Icon)`
  transform: scale(0.8);
`

const ButtonsContainer = styled.div`
  gap: ${get(spacings, 'xxs')}px;
  margin: ${get(spacings, 'xs')}px auto 0;
  display: flex;
  max-width: 100%;
  flex-direction: column;
`

const StyledInput = styled(Input)`
  font-family: Source Sans Pro;
`

const ModalContent = ({ search, updateIsOpen, data }: ModalContentProps): JSX.Element => {
  const { t } = useTranslation()
  const client = useClient()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [isSaved, updateIsSaved] = useState(false)

  const { mutateAsync, isLoading } = useMutation(async alert => await client.post('/api/alerts', { alert }), {
    onSuccess: async () => {
      await queryClient.invalidateQueries('fetchAlerts')
    }
  })

  const onSubmit = useCallback(async (values) => {
    try {
      await mutateAsync({
        ...values,
        ...reduce(search, (acc, value, key) => ({
          ...acc,
          [snakeCase(key)]: value
        }), {})
      })

      return updateIsSaved(true)
    } catch (err) {
      console.log(err)
    }
  }, [])
  const { handleSubmit, handleChange, handleBlur, values } = useFormik({
    initialValues: {
      name: ''
    },
    onSubmit
  })

  if (isEqual(isLoading, true)) {
    return (
      <Loading />
    )
  }

  if (!isEmpty(get(data, 'data', []))) {
    return (
      <ExistingContainer>
        <Text strong variant='primary' type='regular'>
          {t<string>('ALERTS.alertExisting', { name: get(first(get(data, 'data', [])), 'name', '') })}
        </Text>
      </ExistingContainer>
    )
  }

  if (isEqual(isSaved, true)) {
    return (
      <ExistingContainer>
        <Text strong variant='primary' type='regular'>
          {t<string>('ALERTS.alertSaved')}
        </Text>
        <ButtonsContainer>
          <ButtonContainer
            type='submit'
            color='primary'
            buttonSize='large'
            label={t<string>('ALERTS.returnToSearch')}
            onClick={() => updateIsOpen(false)}/>
          <ButtonContainer
            label={t<string>('ALERTS.seeAlerts')}
            onClick={() => navigate('/alerts')}
            buttonSize='large'
            color='secondary'/>
        </ButtonsContainer>
      </ExistingContainer>
    )
  }

  return (
    <FormContainer>
      <Text strong variant='primary' type='regular'>
        {t<string>('ALERTS.createAlertTitle')}
      </Text>
      <StyledInput
        name='name'
        value={get(values, 'name')}
        onBlur={handleBlur}
        squared={true}
        bordered={true}
        onChange={handleChange}
        inputSize='small'
        importance='secondary'
        data-testid='input-name'
        placeholder={t<string>('ALERTS.namePlaceholder')} />
      <ButtonsContainer>
        <ButtonContainer
          type='submit'
          label={t<string>('COMMON.save')}
          buttonSize='small'
          color='secondary'
          onClick={() => handleSubmit()}/>
        <ButtonContainer
          label={t<string>('COMMON.cancel')}
          color='secondary'
          onClick={() => updateIsOpen(false)}
          buttonSize='small'/>
      </ButtonsContainer>
    </FormContainer>
  )
}

const AlertsHandler = ({ search }: AlertsHandlerProps): JSX.Element | null => {
  const { t } = useTranslation()
  const [isOpen, updateIsOpen] = useState<boolean>(false)
  const { data, isFetching } = useSearchAlertsQuery(1, search)

  if (isEmpty(search) || isEqual(isFetching, true)) {
    return null
  }

  return (
    <>
      <ButtonContainer
        color='success'
        buttonSize='regular'
        onClick={() => updateIsOpen(true)}>
          <>
            <AlertIcon variant='alert-solid' fill color='white'/>
            {t<string>('ALERTS.addTitle')}
          </>
      </ButtonContainer>
      <Modal
        isOpen={isOpen}
        contentLabel={t<string>('ALERTS.createTitle')}
        onRequestClose={() => updateIsOpen(false)}>
        <ModalContent data={data} search={search} updateIsOpen={updateIsOpen} />
      </Modal>
    </>
  )
}

export default AlertsHandler
