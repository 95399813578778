import { Variant } from '@folhomee/front-library'
import { size, slice } from 'lodash'

export const BODY_LOTS = [{
  key: 'status',
  textLeft: true,
  title: ''
}, {
  key: 'plan',
  title: ''
}, {
  key: 'reference',
  title: ''
}, {
  key: 'type',
  title: ''
}, {
  key: 'surface',
  title: ''
}, {
  key: 'surfaceExt',
  title: ''
}, {
  key: 'floor',
  title: ''
}, {
  key: 'exposures',
  title: ''
}, {
  key: 'parking',
  title: ''
}, {
  key: 'prices',
  title: ''
}, {
  key: 'meterPrice',
  title: ''
}, {
  key: 'url',
  title: 'isPublic'
}, {
  key: 'url',
  title: ''
}, {
  key: 'favorites',
  title: ''
}]

export const HEADER_LOTS = [{
  label: 'COMMON.status'
}, {
  label: 'LOT.plan'
}, {
  label: 'COMMON.number'
}, {
  label: 'COMMON.type'
}, {
  label: 'LOT.surface'
}, {
  label: 'LOT.outside'
}, {
  label: 'LOT.floor'
}, {
  label: 'LOT.exposure'
}, {
  label: 'COMMON.parking'
}, {
  label: 'LOT.price'
}, {
  label: 'COMMON.meterPrice'
}, {
  label: '',
  icon: 'user-lock-solid' as Variant,
  OptionIconProps: {
    isFilled: true
  }
}, {
  label: 'COMMON.link'
}, {
  label: '',
  icon: 'favorite' as Variant
}]

export const BODY_LOT_FAVORITE = [...slice(BODY_LOTS, 0, size(BODY_LOTS) - 1), {
  key: 'clientFavorites',
  title: ''
}]
