import styled from 'styled-components'
import useAsyncEffect from 'use-async-effect'
import { useTranslation } from 'react-i18next'
import { useState, useCallback } from 'react'
import { FormikValues, useFormik } from 'formik'
import { Icon, Button, spacings } from '@folhomee/front-library'
import { get, omit, size, map, isEqual, isNull, filter, isUndefined, isEmpty, chunk, reduce, includes } from 'lodash'

import Text from '../../atoms/Text'
import FlexBox from '../../atoms/FlexBox'
import useClient from '../../../hooks/useClient'
import LoadingDots from '../../atoms/LoadingDots'
import SearchInput from '../../molecules/SearchInput'
import { useUpdateAlert } from '../../../queries/Alerts'
import { ProgramListProps } from './AlertSearch.types'
import { useFetchSourcesQuery } from '../../../queries/Sources'
import { useFetchTaxationsQuery } from '../../../queries/Taxations'
import { MORE_SEARCH_FIELDS, SEARCH_FIELDS } from '../../../utils/tables/programs'
import { SearchFieldProps, SearchFieldsProps, OptionIconProps } from '../../molecules/SearchForm/SearchForm.types'
import { buildForm, buildSimpleQuery, fetchOptions, snakeCaseSearch } from '../../../utils/search'

const Field = styled.div`
  gap: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const StyledIcon = styled(Icon) <OptionIconProps>`
  margin-right: ${get(spacings, 'xxs')}px;

  & path, rect, circle {
    stroke: ${({ theme }) => get(theme, 'darkGrey')} !important;
  }
  
  & path.programme-neuf_svg__cls-1 {
    stroke: #707070 !important;
  }
`

const Dots = styled(LoadingDots)`
  min-height: 24px;
  padding: 0 14px;
`

const PROGRAM_SEARCH_FIELDS = [
  'rooms', 'floor', 'taxations', 'sources', 'program', 'minPrice', 'maxPrice', 'minSurface', 'maxSurface',
  'deliveryQuarter', 'deliveryYear', 'deliveryQuarterMinimum', 'deliveryYearMinimum', 'parking', 'terrace',
  'garden', 'parking', 'lotType', 'location', 'distance'
]

const AlertSearch = ({ data }: ProgramListProps): JSX.Element => {
  const { t } = useTranslation()
  const search: FormikValues = reduce(data, (acc, value, key) => {
    if (includes(['deliveryYear', 'deliveryYearMinimum', 'deliveryQuarter',
      'deliveryQuarterMinimum', 'distance', 'minRooms', 'maxRooms', 'minFloor', 'maxFloor',
      'parking', 'garden', 'terrace', 'balcony', 'minPrice', 'maxPrice', 'minSurface', 'maxSurface'], key)) {
      return {
        ...acc,
        [key]: isNull(value) ? '' : value.toString()
      }
    }

    return {
      ...acc,
      [key]: value
    }
  }, {})
  const client = useClient()

  const [build, updateBuild] = useState<boolean>(false)
  const [fields, updateFields] = useState<SearchFieldsProps[]>([])
  const [loading, updateLoading] = useState<boolean>(false)
  const { data: sources, isFetching: isFetchingSources } = useFetchSourcesQuery()
  const { data: taxations, isFetching: isFetchingTaxations } = useFetchTaxationsQuery()

  const { mutateAsync } = useUpdateAlert(get(search, 'id', 0))

  const onSubmit = useCallback(async (values) => {
    try {
      updateLoading(true)
      await mutateAsync(omit(snakeCaseSearch(buildSimpleQuery(values)), ['client', 'created_at', 'id', 'mute', 'name', 'program', 'status']))
    } catch (err) {
      console.log(err)
    } finally {
      updateLoading(false)
    }
  }, [])

  const { values, handleBlur, handleChange, setFieldValue } = useFormik({
    initialValues: search,
    onSubmit: onSubmit
  })

  const onKeyPress = useCallback(async (evt) => {
    if (isEqual(get(evt, 'key'), 'Enter')) {
      return await onSubmit(values)
    }
  }, [values])

  const setFieldValueHandler = useCallback(async (field: string, value: any, add?: boolean): Promise<void> => {
    if (!isUndefined(add) && isEqual(add, false)) {
      await setFieldValue(field, '')
      return
    }

    await setFieldValue(field, value)
  }, [])

  const setFieldValuesHandler = useCallback(async (field: string, value: any, add?: boolean): Promise<any> => {
    const currValues = get(values, field, [])
    if (!isUndefined(add) && isEqual(add, false)) {
      return await setFieldValue(field, filter(currValues, elt => !isEqual(`${get(elt, 'value') as string}`, `${get(value, 'value') as string}`)))
    }

    await setFieldValue(field, [...currValues, value])
  }, [values])

  useAsyncEffect(async () => {
    if ((!isFetchingTaxations && !isEmpty(taxations)) || (!isFetchingSources && !isEmpty(sources))) {
      const fields = await fetchOptions([...SEARCH_FIELDS, ...MORE_SEARCH_FIELDS], taxations, sources)
      updateFields(fields)
      updateBuild(true)
    }
  }, [taxations, sources])

  useAsyncEffect(async () => {
    if (isEqual(build, true)) {
      await buildForm(PROGRAM_SEARCH_FIELDS, client, omit(search, ['page']), setFieldValue, fields)
      updateBuild(false)
    }
  }, [build])

  return (
    <>
      <FlexBox
        gap={32}
        row
        width100
        alignStart
        onKeyPress={onKeyPress}>
        {map(chunk(fields, (size(fields) / 2.5)), (formBlock, idx) => (
          <FlexBox
            gap={16}
            key={idx}
            width100>
            {map(formBlock, ({ icon, title, fields: innerFields, optionIcon }: SearchFieldsProps) => (
              <Field key={title}>
                <FlexBox width100 row justifyContent='start' alignStart>
                  {!isUndefined(icon) &&
                    <StyledIcon
                      {...optionIcon}
                      variant={icon} />}
                  <Text
                    color='secondary'
                    strong>
                    {t<string>(title)}
                  </Text>
                </FlexBox>
                <FlexBox row justifyContent='start' gap={8} width100 alignStart>
                  {map(innerFields, ({ key, type, options, multiple, placeholder }: SearchFieldProps) => (
                    <SearchInput
                      key={key}
                      name={key}
                      type={type}
                      values={values}
                      options={options}
                      multiple={multiple}
                      handleBlur={handleBlur}
                      placeholder={placeholder}
                      handleChange={handleChange}
                      setFieldValue={isEqual(multiple, true) ? setFieldValuesHandler : setFieldValueHandler} />
                  ))}
                </FlexBox>
              </Field>
            ))}
          </FlexBox>
        ))}
      </FlexBox>
      <Button
        type='submit'
        variant='danger'
        onClick={async () => await onSubmit(values)}
        importance='small'>
        <>
          {isEqual(loading, false) && t<string>('COMMON.save')}
          {isEqual(loading, true) && <Dots color='white' />}
        </>
      </Button>
    </>
  )
}

export default AlertSearch
