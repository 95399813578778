import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { spacings } from '@folhomee/front-library'
import { get, includes, isEqual, map, join, isEmpty, filter, size, first, last } from 'lodash'

import Icon from '../../atoms/Icon'
import Text from '../../atoms/Text'
import numeral from '../../../services/numeral'
import ItemLink from '../../molecules/ItemLink'
import { Content, StyledVat as VatField } from '../../atoms/Field'
import {
  DataTitleProps, LotDataProps, DataTextProps, DataItemProps, DataListProps, DataPriceProps, DataPriceItemProps, StyledVatProps,
  DocumentsListProps
} from './LotData.types'

const DATA_ITEMS = [{
  key: 'taxations',
  label: 'COMMON.taxations'
}, {
  key: 'taxArea',
  label: 'COMMON.fiscalZone'
}, {
  key: 'type',
  label: 'LOT.typology'
}, {
  key: 'floor',
  label: 'LOT.floor'
}, {
  key: 'surface',
  label: 'LOT.surface'
}, {
  key: 'surfaceExt',
  label: 'LOT.outside'
}, {
  key: 'exposures',
  label: 'LOT.exposure'
}, {
  key: 'parking',
  label: 'LOT.parking'
}]

const STATUS_COLOR = {
  available: 'green',
  reserved: 'lightOrange',
  unavailable: 'red'
}

const Container = styled.div`
  gap: ${get(spacings, 'm')}px;
  display: flex;
  padding: ${get(spacings, 'xs')}px;
  flex-direction: row;
  justify-content: center;
`

const Block = styled.div`
  gap: ${get(spacings, 'm')}px;
  width: 725px;
  display: flex;
  padding: ${get(spacings, 'l')}px;
  box-shadow: 0 3px 6px 0 rgba(51, 102, 204, 0.15);
  border-radius: 20px;
  flex-direction: column;
  background-color: ${({ theme }) => get(theme, 'white', '#FFF')}
`

const SmallBlock = styled(Block)`
  width: 235px;
  align-self: flex-start;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const TopData = styled.div`
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  gap: ${get(spacings, 'xxs')}px;
  display: flex;
  padding-bottom: ${get(spacings, 'xxs')}px;
`

const DataTile = styled.div<DataTitleProps>`
  flex: 1;
  padding: 8px 52px;
  border-radius: 5px;
  background-color: ${({ theme, color }) => get(theme, color, 'red')};
`

const DataText = styled(Text) <DataTextProps>`
  color: ${({ theme, isBlue }) => get(theme, isEqual(isBlue, true) ? 'blue' : 'white', '#FFF')};
`

const DataPriceItem = styled(Text) <DataPriceItemProps>`
  color: ${({ theme, isBlue }) => get(theme, isEqual(isBlue, true) ? 'blue' : 'white', '#FFF')};
  width: 90px;
  font-size: 18px;
  text-align: center;
`

const DataItemContainer = styled.div<{ index?: number }>`
  gap: ${get(spacings, 'xxs')}px;
  display: flex;
  padding: ${get(spacings, 'xs')}px 2px;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => get(theme, 'grey', '#fff')};

  & > div {
    flex: 1;

    &:first-of-type > p {
      font-weight: normal;
    }
  }

  ${({ index, theme }) => {
    if (isEqual(index, 0)) {
      return `border-top: 1px solid ${get(theme, 'grey', '#fff') as string};`
    }

    return ''
  }}
`

const StyledVat = styled(VatField) <StyledVatProps>`
  color: ${({ theme, isBlue }) => get(theme, isEqual(isBlue, true) ? 'blue' : 'red', '#FFF')};
  border: 1px solid ${({ theme, isBlue }) => get(theme, isEqual(isBlue, true) ? 'blue' : 'white', '#FFF')};
`

const DocumentLink = styled.a`
  gap: 7px;
  display: flex;
  align-items: center;
  text-decoration: none;
`

const IconLinkContainer = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  padding: 3px;
  background: ${({ theme }) => get(theme, 'lightBlue')};
  align-items: center;
  border-radius: 14px;
  text-decoration: none;
  
  & > svg {
    transform: scale(0.6);
  }
`

const DataItem = ({ lot, item }: DataItemProps): JSX.Element => {
  const { t } = useTranslation()
  const data = get(lot, item)

  if (includes(['surface', 'surfaceExt'], item)) {
    return (
      <Text strong>
        {t('LOT.meterSurface', { value: data })}
      </Text>
    )
  }

  if (includes(['exposures', 'taxations'], item)) {
    return (
      <Text strong>
        {join(data, ', ')}
      </Text>
    )
  }

  return (
    <Text strong>
      {data}
    </Text>
  )
}

const DataPrice = ({ prices, isBlue, isMeterPrice }: DataPriceProps): JSX.Element => {
  const { t } = useTranslation()

  const firstPrice = numeral(first(prices)).format('0,0$')
  const fullVatPrice = isEqual(isMeterPrice, true) ? t('LOT.meterPrice', { price: firstPrice }) : firstPrice

  if (isEqual(size(prices), 1)) {
    return (
      <DataText
        strong
        isBlue={isMeterPrice}>
        {fullVatPrice}
      </DataText>
    )
  }

  const lastPrice = numeral(last(prices)).format('0,0$')
  const reducedVatPrice = isEqual(isMeterPrice, true) ? t('LOT.meterPrice', { price: lastPrice }) : lastPrice

  return (
    <Content left={true}>
      <StyledVat
        isBlue={isBlue}>
        {t('PROGRAM.vatReduced')}
      </StyledVat>
      <DataPriceItem
        isBlue={isBlue}>
        {fullVatPrice}
      </DataPriceItem>
      <StyledVat
        isBlue={isBlue}>
        {t('PROGRAM.vatFull')}
      </StyledVat>
      <DataPriceItem
        isBlue={isBlue}>
        {reducedVatPrice}
      </DataPriceItem>
    </Content>
  )
}

const DataList = ({ lot }: DataListProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div>
      {map(DATA_ITEMS, (item: any, index: number) => (
        <DataItemContainer key={get(item, 'key', '')} index={index}>
          <div>
            <Text>
              {t(get(item, 'label'))}
            </Text>
          </div>
          <div>
            <DataItem lot={lot} item={get(item, 'key', '')} />
          </div>
        </DataItemContainer>
      ))}
    </div>
  )
}

const DocumentsList = ({ brochure, plan }: DocumentsListProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      {!isEmpty(brochure) && !isEqual(brochure, 'N/A') &&
        <DocumentLink href={brochure} target='_blank'>
          <IconLinkContainer>
            <Icon variant='extern-link' stroke />
          </IconLinkContainer>
          <Text color='primary' strong>
            {t('PROGRAM.brochure')}
          </Text>
        </DocumentLink>
      }
      {!isEmpty(plan) && !isEqual(plan, 'N/A') &&
        <DocumentLink href={plan} target='_blank'>
          <IconLinkContainer>
            <Icon variant='extern-link' stroke />
          </IconLinkContainer>
          <Text color='primary' strong>
            {t('LOT.floorPlan')}
          </Text>
        </DocumentLink >
      }
    </>
  )
}

const LotData = ({ lot, city, brochure }: LotDataProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Container>
      <Block>
        <TitleContainer>
          <Text fontSize={24} strong>
            {t('LOT.lot', { reference: get(lot, 'reference') })}
          </Text>
          <ItemLink urls={filter(get(lot, 'url', []), ({ url }) => !isEmpty(url))} />
        </TitleContainer>
        <TopData>
          <Row>
            <DataTile color='blue'>
              <DataText
                textType='bigger'
                strong>
                {t(`LOT.${get(lot, 'typeTitle', '')}`)}
              </DataText>
            </DataTile>
            <DataTile color='blue'>
              <DataPrice prices={get(lot, 'prices')} isMeterPrice={false} />
            </DataTile>
          </Row>
          <Row>
            <DataTile color={get(STATUS_COLOR, get(lot, 'status', 'available'), 'green')}>
              <DataText
                textType='bigger'
                strong>
                {t(`LOT.${get(lot, 'status', '')}`)}
              </DataText>
            </DataTile>
            <DataTile color='lightBlue'>
              <DataPrice prices={get(lot, 'meterPrice')} isBlue={true} isMeterPrice={true} />
            </DataTile>
          </Row>
        </TopData>
        <DataList lot={{ ...lot, taxArea: get(city, 'taxArea', '') }} />
      </Block>
      <SmallBlock>
        <Text fontSize={24} strong>
          {t('COMMON.documents')}
        </Text>
        <DocumentsList
          plan={get(lot, 'plan', '')}
          brochure={brochure} />
      </SmallBlock>
    </Container>
  )
}

export default LotData
