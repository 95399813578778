export const BODY_CRAWLERS = [{
  key: 'name',
  title: 'name'
}, {
  key: 'crawlType',
  title: 'type'
}, {
  key: 'type',
  title: 'otaree'
}, {
  key: 'type',
  title: 'prescriber'
}, {
  key: 'type',
  title: 'public'
}, {
  key: 'actions',
  title: 'actions'
}]

export const HEADER_CRAWLERS = [{
  label: 'Nom'
}, {
  label: 'Type'
}, {
  label: 'Autre'
}, {
  label: 'Prescription'
}, {
  label: 'Public'
}, {
  label: 'Actions'
}]
