import { useTranslation } from 'react-i18next'

import Icon from '../Icon'
import ButtonRound from '../ButtonRound'
import TooltipContent from '../TooltipContent'
import { CommentButtonProps } from './CommentButton.types'

const CommentButton = ({ onClick }: CommentButtonProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      <ButtonRound
        color='lightWarning'
        data-tooltip-id="CommentTooltip"
        onClick={onClick}
        buttonSize='regular'>
        <Icon
          variant='write'
          color='warning'
          stroke
          cursor />
      </ButtonRound>
      <TooltipContent
        id="CommentTooltip"
        color='lightDanger'>
        {t('COMMENTS.write')}
      </TooltipContent>
    </>
  )
}

export default CommentButton
