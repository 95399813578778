import { AxiosError } from 'axios'
// @ts-expect-error
import { deserialize } from 'deserialize-json-api'
import { get, reduce, isEmpty, isUndefined, isEqual, map, omit, range, size } from 'lodash'
import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult, useQuery, UseQueryResult, UseMutationResult, useMutation, useQueryClient } from 'react-query'

import useClient from '../../hooks/useClient'
import { buildQueryParams } from '../utils'

const transformListPage = ({ data }: any): any => {
  const page = deserialize(get(data, 'data'))

  return {
    meta: get(data, 'meta'),
    data: reduce(page, (acc: any, item: any): any => {
      if (!isEmpty(item)) {
        return [...acc, item]
      }
      return acc
    }, [])
  }
}

export const useFetchInfiniteAnnoncesQuery = (search: any): UseInfiniteQueryResult<any, AxiosError> => {
  const client = useClient()

  return useInfiniteQuery(['fetchInfiniteAnnonces', search], async ({ pageParam = 1 }) => {
    return await client.get('/api/annonces', {
      params: {
        'page[number]': pageParam,
        'page[size]': 11,
        status: [1, 2, 3, 4],
        impulse: true,
        ...buildQueryParams(omit(search, ['page']))
      }
    })
  }, {
    select: ({ pages }): InfiniteData<any> => ({
      pages: map(pages, transformListPage),
      pageParams: range(size(pages) + 1)
    }),
    getNextPageParam: (_, allPages) => size(allPages) + 1,
    staleTime: 1000 * 60,
    refetchOnWindowFocus: false
  })
}

export const useFetchAnnonceQuery = (id: string, search: any): UseQueryResult<any, AxiosError> => {
  const client = useClient()

  return useQuery(['fetchAnnonce', id], async () => {
    return await client.get(`/api/annonces/${id}`)
  }, {
    select: ({ data }): any => {
      const page = deserialize(get(data, 'data'))
      return page
    },
    enabled: !isEmpty(id) && !isUndefined(id) && !isEqual(id, '0'),
    refetchOnWindowFocus: false
  })
}

export const useFetchAnnoncesCountQuery = (search: any): UseQueryResult<any, AxiosError> => {
  const client = useClient()

  return useQuery(['fetchAnnonceCount', search], async () => {
    return await client.get('/api/annonces/count', {
      params: {
        impulse: true,
        status: [1, 2, 3, 4],
        // adType: ['sale', 'rental', 'sublet'],
        ...buildQueryParams(omit(search, ['page']))
      }
    })
  }, {
    select: ({ data }): any => {
      const page = deserialize(data)
      return page
    },
    refetchOnWindowFocus: false
  })
}

export const useSendAlertsQuery = (id: string): UseMutationResult => {
  const client = useClient()

  return useMutation(async () => {
    await client.post(`/api/annonces/${id}/send_alerts`)
  }, {
    onSuccess: async () => {
    }
  })
}

export const useMutateAnnonceQuery = (id: string): UseMutationResult => {
  const client = useClient()
  const queryClient = useQueryClient()

  return useMutation(async (toUpdate) => {
    const config = {
      timeout: 50000
    }

    await client.put(`/api/annonces/${id}`, toUpdate, config)
  }, {
    onSuccess: async () => {
      await queryClient.refetchQueries(['fetchInfiniteAnnonces'])
      await queryClient.refetchQueries(['fetchUserAds'])
      await queryClient.refetchQueries(['fetchAnnonce', id])
    }
  })
}
