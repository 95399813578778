import qs from 'qs'
import { Variant } from '@folhomee/front-library'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import { get, reduce, size, omit, identity } from 'lodash'

import Text from '../../atoms/Text'
import Table from '../../organisms/Table'
import UserRow from '../../molecules/UserRow'
import Container from '../../atoms/Container'
import SearchForm from '../../molecules/SearchForm'
import TableBottom from '../../molecules/TableBottom'
import DataContainer from '../../atoms/DataContainer'
import RightContainer from '../../atoms/RightContainer'
import { getMinMediaQuery } from '../../../utils/media'
import { SearchFieldsProps } from '../../molecules/SearchForm/SearchForm.types'
import { useFetchInfiniteUsersQuery } from '../../../queries/Users'
import { PageUsers, User } from '../../../queries/Users/Users.types'

const HEADER = [{
  label: 'COMMON.name',
  textLeft: true
}, {
  label: 'COMMON.email',
  textLeft: true
}, {
  label: 'NAVBAR.alerts'
}, {
  label: 'COMMON.admin'
}, {
  label: 'COMMON.status'
}]

const BODY_FIELDS = [{
  key: 'name',
  title: 'name'
}, {
  key: 'email',
  title: 'name'
}, {
  key: 'alertsTotal',
  title: 'alertsTotal'
}, {
  key: 'role',
  title: 'role'
}, {
  key: 'active',
  title: 'active'
}]

const SOURCES_SEARCH_FIELDS = ['name', 'email', 'role']

const SEARCH_FIELDS = [{
  icon: 'astronaut' as Variant,
  title: 'COMMON.name',
  fields: [{
    key: 'name',
    placeholder: 'COMMON.name'
  }]
}, {
  icon: 'archive' as Variant,
  title: 'COMMON.email',
  fields: [{
    key: 'email',
    placeholder: 'COMMON.email'
  }]
}, {
  title: 'USER.typeRole',
  fields: [{
    type: 'toggle',
    key: 'role',
    options: [{
      value: '0',
      label: 'USER.user'
    }, {
      value: '2',
      label: 'USER.admin'
    }],
    placeholder: 'USER.typeRole'
  }]
}]

const Users = (): JSX.Element => {
  const { t } = useTranslation()
  const [searchFields] = useState<SearchFieldsProps[]>(SEARCH_FIELDS)
  const [search, updateSearch] = useState<any>({
    role: 0
  })
  const [bigScreen, updatebigScreen] = useState<boolean>(getMinMediaQuery('xl').matches)
  const [searchBuild, updateSearchBuild] = useState(true)
  const [searchParams, updateSearchParams] = useSearchParams()
  const { data, isFetching, fetchNextPage } = useFetchInfiniteUsersQuery(search)
  const options = {
    bodyColor: 'extraLightBlue',
    headerColor: 'transparent',
    columnAlignment: '1fr 1fr 0.5fr 0.5fr 0.5fr'
  }

  useEffect(() => {
    const mql = getMinMediaQuery('xl')
    const resize = (event: MediaQueryListEvent): void => updatebigScreen(event.matches)

    mql.addEventListener('change', resize)
    return () => {
      mql.removeEventListener('change', resize)
    }
  }, [])

  useEffect(() => {
    const querySearch = qs.parse(searchParams.toString())

    updateSearch(reduce(omit(querySearch, ['page']), (acc, value, key) => ({
      ...acc,
      [key]: value
    }), {}))
    updateSearchBuild(false)
  }, [searchParams])

  const users = reduce(get(data, 'pages', []), (acc: User[], page: PageUsers): User[] => [...acc, ...get(page, 'data', [])], [])

  return (
    <Container>
      <SearchForm
        build={searchBuild}
        search={search}
        fields={searchFields}
        sources={users}
        visible={true}
        searchFields={SOURCES_SEARCH_FIELDS}
        formatSearch={identity}
        updateSearch={updateSearchParams}
        updateShowSearch={() => { }}
        updateSearchBuild={updateSearchBuild} />
      <RightContainer>
        <DataContainer>
          <Text textType='title' strong>
            {t('USER.usersTitle', { count: get(data, 'pages.0.meta.total', 0) })}
          </Text>
          <Table
            row={UserRow}
            data={users}
            body={BODY_FIELDS}
            header={HEADER}
            options={options}
            bigScreen={bigScreen} />
          {size(users) > 20 && <TableBottom
            loading={isFetching}
            fetchNextPage={fetchNextPage} />}
        </DataContainer>
      </RightContainer>
    </Container>
  )
}

export default Users
