/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import styled from 'styled-components'
import { get } from 'lodash'
import { useState } from 'react'

import Text from '../../atoms/Text'
import FlexBox from '../../atoms/FlexBox'
import { ClientCheckboxProps } from './ClientCheckbox.types'

const StyledCheckBox = styled.input`
  font: inherit;
  color: ${({ theme }) => get(theme, 'darkGrey')};
  width: 16px;
  border: 1px solid ${({ theme }) => get(theme, 'darkGrey')};
  height: 16px;
  margin: 0;
  margin-right: 4px;
  display: grid;
  transform: translateY(-0.075em);
  appearance: none;
  border-radius: 0.10em;
  place-content: center;
  background-color: #fff;
  -webkit-appearance: none;

  &::before {
    width: 16px;
    height: 16px;
    content: '';
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em ${({ theme }) => get(theme, 'darkGrey')};
    border-radius: 0.10em;
  }

  &:checked::before {
    transform: scale(1);
  }
`

const ClientCheckbox = ({ setFieldValue, client }: ClientCheckboxProps): JSX.Element => {
  const [isChecked, updateIsChecked] = useState(get(client, 'isFavorite'))

  const updateFieldValue = (): void => {
    setFieldValue(get(client, 'id', 0), !isChecked)
    updateIsChecked(!isChecked)
  }

  return (
    <FlexBox row={true} gap={4}>
      <StyledCheckBox
        onClick={updateFieldValue}
        type='checkbox'
        name='clients'
        value={get(client, 'id')}
        defaultChecked={get(client, 'isFavorite')} />
      <Text>{`${get(client, 'lastName')} ${get(client, 'firstName')}`}</Text>
    </FlexBox>
  )
}

export default ClientCheckbox
