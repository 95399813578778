import styled from 'styled-components'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'

import Icon from '../../atoms/Icon'
import Page from '../../atoms/Page'
import Text from '../../atoms/Text'
import FlexBox from '../../atoms/FlexBox'
import ClientData from '../../molecules/ClientData'
import DetailList from '../../templates/DetailList'
import MaxContent from '../../atoms/MaxContent'
import ClientAlert from '../../molecules/ClientAlert'
import ClientFavorite from '../../molecules/ClientFavorite'
import { TabProps } from '../../atoms/Tabs/Tabs.types'
import { useFetchClientQuery } from '../../../queries/Clients'

const TABS = [{
  key: 'data',
  label: 'COMMON.data',
  component: ClientData
}, {
  key: 'alert',
  label: 'ALERTS.alert',
  component: ClientAlert
}, {
  key: 'favorites',
  label: 'COMMON.favorites',
  component: ClientFavorite
}] as TabProps[]

const StyledLink = styled.a`
  align-self: center;
  text-align: right;
  margin-left: auto;
  text-decoration: none;
`

const Actions = ({ data }: any): JSX.Element => {
  const { t } = useTranslation()
  return (
    <FlexBox row gap={8}>
      <Text textType='title' strong>
        {t('USER.client')}
      </Text>
      <StyledLink
        rel='noreferrer'
        href={`https://contactfolhomeefr.pipedrive.com/person/${get(data, 'idPipedrive', '') as string}`}
        target='_blank'>
        <Icon stroke cursor variant='extern-link' />
      </StyledLink>
    </FlexBox>
  )
}

const Client = (): JSX.Element => {
  return (
    <Page>
      <MaxContent>
        <DetailList
          tabs={TABS}
          type='source'
          actions={Actions}
          useFetch={useFetchClientQuery} />
      </MaxContent>
    </Page>
  )
}

export default Client
