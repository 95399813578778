import { get } from 'lodash'
import { Error } from '@folhomee/front-library'

import Input from '../Input'
import { InputProps } from './FormInput.types'

const FormInput = ({ name, values, error, handleBlur, handleChange, errors, placeholder, type, testId }: InputProps): JSX.Element => (
  <>
    <Input
      type={type}
      name={name}
      value={get(values, name)}
      error={error}
      onBlur={handleBlur}
      squared={true}
      bordered={true}
      onChange={handleChange}
      inputSize='small'
      importance='secondary'
      data-testid={testId}
      placeholder={placeholder} />
    <Error
      show={error}
      label={get(errors, name, '')} />
  </>
)

export default FormInput
