import styled from 'styled-components'
import { get } from 'lodash'

import ButtonContainer from '../ButtonContainer'
import { StyledButtonRoundProps, ButtonRoundProps } from './ButtonRound.types'

const SIZE_WIDTH = {
  tiny: 30,
  small: 35,
  large: 50,
  regular: 44
}

const SIZE_HEIGHT = {
  tiny: 26,
  small: 31,
  large: 46,
  regular: 40
}

const StyledButtonRound = styled(ButtonContainer)<StyledButtonRoundProps>`
  width: ${({ customProps }) => get(SIZE_WIDTH, get(customProps, 'size') ?? 'regular', 44)}px;
  height: ${({ customProps }) => get(SIZE_HEIGHT, get(customProps, 'size') ?? 'regular', 40)}px;
  padding: 0;
  min-width: auto;
`

const ButtonRound = ({
  buttonSize = 'regular', importanceBase = 'primary',
  outline, label, color, onClick, children, target,
  ...props
}: ButtonRoundProps): JSX.Element => {
  return (
    <StyledButtonRound
      {...props}
      customProps={{
        size: buttonSize,
        color: color,
        importance: importanceBase
      }}
      label={label}
      color={color}
      onClick={onClick}
      outline={outline}
      buttonSize={buttonSize}
      target={target}
    >
      { children }
    </StyledButtonRound>
  )
}
export default ButtonRound
