import styled from 'styled-components'
import { get } from 'lodash'
import { useMutation, useQueryClient } from 'react-query'

import Form from '../../templates/Form'
import useClient from '../../../hooks/useClient'

const Container = styled.div`
  margin-top: 8px;
`

const FIELDS = [{
  key: 'name',
  placeholder: 'COMMON.name'
}, {
  key: 'slug',
  placeholder: 'COMMON.slug'
}, {
  key: 'code',
  placeholder: 'DEPARTMENTS.code'
}]

export const DepartmentData = ({ data }: any): JSX.Element => {
  const client = useClient()
  const queryClient = useQueryClient()
  const updateMutation = useMutation(async (values) => await client.patch(`/api/departments/${get(data, 'id') as string}`, {
    department: {
      code: get(values, 'code'),
      slug: get(values, 'slug'),
      name: get(values, 'name')
    }
  }), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['fetchDepartment', get(data, 'id')])
      await queryClient.invalidateQueries(['fetchInfiniteDepartments'])
    }
  })
  return (
    <Container>
      <Form
        data={data}
        fields={FIELDS}
        submit={updateMutation} />
    </Container>
  )
}
