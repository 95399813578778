import raf from 'rc-util/lib/raf'
import styled from 'styled-components'
import Slider from 'rc-slider'
import Tooltip from 'rc-tooltip'
import type { SliderProps } from 'rc-slider'
import { offColor } from 'off-color'
import { useTranslation } from 'react-i18next'
import { spacings, Badge } from '@folhomee/front-library'
import { useCallback, useState, ChangeEvent, useRef, useEffect } from 'react'
import { isEqual, get, isUndefined, isEmpty, size, find, filter, groupBy, map, first, isNull } from 'lodash'

import Icon from '../../atoms/Icon'
import Text from '../../atoms/Text'
import useClient from '../../../hooks/useClient'
import LoadingDots from '../../atoms/LoadingDots'
import useGeoPosition from '../../../hooks/useGeoPosition'
import ButtonContainer from '../../atoms/ButtonContainer'
import { Suggestion } from '../../../utils/types/Suggestions.types'
import { useOnClickOutside } from '../../../hooks/useOnClickOutside'
import {
  LocationInputProps, SuggestionsDisplayProps, SelectionDisplayProps, DataDisplayProps, SuggestionsListProps, SuggestionItemProps, SectionsProps,
  DisplaySelectionProps
} from './LocationInput.types'

import 'rc-slider/assets/index.css'
import 'rc-tooltip/assets/bootstrap.css'

const MINIMUM_CHARACTERS = 2

const StyledInput = styled.input`
  color: ${({ theme }) => get(theme, 'darkGrey', '#fff')};
  width: 100%;
  font-size: 15px;
  min-height: 34px;
  line-height: normal;
  padding-left: ${get(spacings, 'xxs')}px;
  border-radius: 5px;

  &::placeholder {
    color: ${({ theme }) => offColor(get(theme, 'darkGrey', '#fff')).rgba(0.5)};
    font-size: 15px;
    font-family: SourceSansPro;
  }
`

const BaseContainer = styled.div`
  width: 100%;
  border: solid 1px ${({ theme }) => get(theme, 'darkGrey', '#fff')};
  cursor: pointer;
  display: flex;
  min-height: 34px;
  align-items: center;
  padding-left: ${get(spacings, 'xxs')}px;
  border-radius: 5px;
  background-color: ${({ theme }) => get(theme, 'white', '#fff')};

  & > p {
    color: ${({ theme }) => offColor(get(theme, 'darkGrey', '#fff')).rgba(0.5)};
    display: block !important;
  }
`

const InputContainer = styled.div`
  width: 100%;
  position: relative;

  & > input {
    border: 1px solid ${({ theme }) => get(theme, 'darkGrey', '#fff')};
  }
`

const SuggestionsContainer = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => get(theme, 'darkGrey', '#fff')};
  padding: ${get(spacings, 'xxs')}px;
  z-index: 1000;
  position: absolute;
  overflow: overlay;
  max-height: 250px;
  background: ${({ theme }) => get(theme, 'white', '#fff')};
  box-shadow: rgb(26 26 26 / 8%) 0px -1px 4px 0px, rgb(26 26 26 / 12%) 0px 4px 8px 0px;
  border-radius: 5px;
`

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 4px;
  justify-content: space-around;
`

const SelectionContainer = styled.div`
  padding: ${get(spacings, 'xxs')}px;
`

const IconContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  
  & > svg {
    transform: scale(0.8) translateX(-5px);
  }
`

const ItemsList = styled.ul`
  margin-bottom: ${get(spacings, 'xs')}px;

  & > li {
    cursor: pointer;
    padding: 10px  ${get(spacings, 'xxs')}px;
  }
`

const SuggestionItem = styled.li<SuggestionItemProps>`
  color: ${({ theme }) => get(theme, 'blue', '#fff')};
  font-size: 15px;
  background: ${({ isActive, theme }) => isEqual(isActive, true) ? get(theme, 'lightBlue') : 'transparent'};
  border-radius: 10px;
`

const NoSuggestionContainer = styled.div`
  & > p {
    color: ${({ theme }) => get(theme, 'orange', '#fff')};
  }
`

const BadgesContainer = styled.div`
  gap: 4px;
  width: 100%;
  display: flex;
  flex-flow: wrap;
`

const StyledBadge = styled(Badge)`
  color: ${({ theme }) => get(theme, 'blue')};
  padding: 2px 4px;
`

const Inline = styled.div`
  display: flex;
  align-items: center;
`

const DistanceContainer = styled.div`
  gap: 4px;
  display: flex;
  padding: 4px 0;
  margin-top: 4px;
  flex-direction: column;
`

const StyledButton = styled(ButtonContainer)`
  padding: 4px;
  font-size: 14px;
`

const HandleTooltip = (props: {
  value: number
  children: React.ReactElement
  visible: boolean
  tipFormatter?: (value: number) => React.ReactNode
}): JSX.Element => {
  const { value, children, visible, tipFormatter = (val) => `${val} Km`, ...restProps } = props

  const tooltipRef = useRef<any>()
  const rafRef = useRef<number | null>(null)

  const cancelKeepAlign = (): void => {
    if (!isNull(rafRef.current)) {
      raf.cancel(rafRef.current)
    }
  }

  const keepAlign = (): void => {
    rafRef.current = raf(() => {
      tooltipRef.current?.forcePopupAlign()
    })
  }

  useEffect(() => {
    if (visible) {
      keepAlign()
    } else {
      cancelKeepAlign()
    }

    return cancelKeepAlign
  }, [value, visible])

  return (
    <Tooltip
      ref={tooltipRef}
      overlay={tipFormatter(value)}
      visible={visible}
      placement='top'
      overlayInnerStyle={{ minHeight: 'auto', width: 'auto' }}
      {...restProps}>
      {children}
    </Tooltip>
  )
}

const handleRender: SliderProps['handleRender'] = (node, props) => (
  <HandleTooltip value={props.value} visible={props.dragging}>
    {node}
  </HandleTooltip>
)

const SelectionDisplay = ({ selection, onRemove, onClick, updateDistance, distance }: SelectionDisplayProps): JSX.Element => {
  const { t } = useTranslation()
  const client = useClient()
  const position = useGeoPosition()
  const handlePosition = useCallback(async (): Promise<void> => {
    if (!isNull(position) && !isUndefined(position)) {
      const { data } = await client.get('/api/cities/search', {
        params: {
          coordinates: `${get(position, 'coords.longitude', '') as string}</>${get(position, 'coords.latitude', '') as string}`
        }
      })

      const city = first(get(data, 'data'))

      await onClick({ type: 'cities', value: get(city, 'attributes.id'), label: get(city, 'attributes.full_name', '') })
    }
  }, [position])

  if (isEmpty(selection)) {
    return (
      <IconContainer onClick={handlePosition}>
        <Icon variant='target' stroke cursor />
        <Text>
          {t<string>('COMMON.aroundMe')}
        </Text>
      </IconContainer>
    )
  }

  if (isEqual(size(selection), 1) && !isEqual(get(selection, 'type'), 'departments')) {
    return (
      <>
        <BadgesContainer>
          <StyledBadge
            variant='selection'
            outline={false}
            importance='regular'>
            <Inline>
              <p>{get(first(selection), 'label')}</p>
              <Icon
                variant='close'
                transform='scale(0.8)'
                onClick={async () => await onRemove(get(first(selection), 'label') as string, get(first(selection), 'value'))}
                cursor
                stroke />
            </Inline>
          </StyledBadge>
        </BadgesContainer>
        <DistanceContainer>
          <Text textType='small' color='primary'>
            {t<string>('COMMON.distanceTitle')}
          </Text>
          <div>
            <Slider
              min={0}
              max={100}
              step={5}
              value={distance}
              onChange={value => updateDistance(value as number)}
              trackStyle={{
                backgroundColor: '#0049ee'
              }}
              handleStyle={{
                borderColor: '#0049ee',
                backgroundColor: '#0049ee'
              }}
              handleRender={handleRender} />
          </div>
          <IconContainer >
            <Icon
              variant='warning'
              color='warning'
              stroke />
            <Text textType='small' color='warning' strong>
              {t<string>('COMMON.radiusWarning')}
            </Text>
          </IconContainer>
        </DistanceContainer>
      </>
    )
  }

  return (
    <BadgesContainer>
      {map(selection, ({ label, value }) => (
        <StyledBadge
          key={label}
          variant='selection'
          outline={false}
          importance='regular'>
          <Inline>
            <p>{label}</p>
            <Icon
              variant='close'
              onClick={async () => await onRemove(label, value)}
              stroke />
          </Inline>
        </StyledBadge>
      ))}
    </BadgesContainer>
  )
}

const Sections = ({ sections, activeSuggestion, updateActiveSuggestion, onClick }: SectionsProps): JSX.Element => {
  const { t } = useTranslation()
  const onHover = useCallback((idx: number): void => updateActiveSuggestion(idx), [updateActiveSuggestion])

  return (
    <>
      {map(sections, (suggestions: Suggestion[], title: string) => (
        <div key={title}>
          <Text bottom={8} strong >
            {t<string>(`COMMON.${title}`)}
          </Text>
          <ItemsList>
            {map(suggestions, ({ label, value }) => (
              <SuggestionItem
                key={`${value as string}-${label}`}
                onClick={async () => await onClick({ type: title, label, value })}
                isActive={isEqual(value, activeSuggestion)}
                onMouseEnter={() => onHover(value)}>
                {label}
              </SuggestionItem>
            ))}
          </ItemsList>
        </div>
      ))}
    </>
  )
}

const SuggestionsList = ({ suggestions, activeSuggestion, updateActiveSuggestion, onClick }: SuggestionsListProps): JSX.Element => {
  const sections = groupBy(suggestions, 'type')

  return (
    <Sections
      onClick={onClick}
      sections={sections}
      activeSuggestion={activeSuggestion}
      updateActiveSuggestion={updateActiveSuggestion} />
  )
}

const SuggestionsDisplay = ({ suggestions, loading, search, activeSuggestion, updateActiveSuggestion, onClick }: SuggestionsDisplayProps): JSX.Element => {
  const { t } = useTranslation()

  if (isEqual(loading, true)) {
    return (
      <NoSuggestionContainer>
        <LoadingDots />
      </NoSuggestionContainer>
    )
  }

  if (isEmpty(suggestions)) {
    return (
      <NoSuggestionContainer>
        <Text
          textType='small'>
          {t<string>('COMMON.noResultLocation', { search })}
        </Text>
      </NoSuggestionContainer>
    )
  }

  return (
    <SuggestionsList
      onClick={onClick}
      suggestions={suggestions}
      activeSuggestion={activeSuggestion}
      updateActiveSuggestion={updateActiveSuggestion} />
  )
}

const DataDisplay = ({
  suggestions, loading, showSuggestions, activeSuggestion, search, updateActiveSuggestion, onClick, selection, onRemove, updateDistance,
  validateLocation, distance
}: DataDisplayProps): JSX.Element => {
  const { t } = useTranslation()

  const handleRemoveAll = useCallback(async () => {
    await Promise.all(map(selection, async it => await onRemove(get(it, 'label'), get(it, 'value'))))

    updateDistance(0)
  }, [selection, onRemove])

  if (isEqual(showSuggestions, false)) {
    return (
      <>
        <SelectionContainer>
          <SelectionDisplay
            onClick={onClick}
            onRemove={onRemove}
            distance={distance}
            selection={selection}
            updateDistance={updateDistance} />
        </SelectionContainer>
        <hr />
        <ButtonsContainer>
          <Icon
            color='danger'
            variant='trash-solid'
            onClick={handleRemoveAll}
            fill />
          <StyledButton
            color='primary'
            onClick={validateLocation}
            buttonSize='small'>
            {t<string>('COMMON.validateLocation')}
          </StyledButton>
        </ButtonsContainer>
      </>
    )
  }

  return (
    <SuggestionsDisplay
      search={search}
      onClick={onClick}
      loading={loading}
      suggestions={suggestions}
      activeSuggestion={activeSuggestion}
      updateActiveSuggestion={updateActiveSuggestion} />
  )
}

const DisplaySelection = ({ selection, placeholder, onRemove }: DisplaySelectionProps): JSX.Element => {
  if (isEmpty(selection)) {
    return (
      <Text>
        {placeholder}
      </Text>
    )
  }

  return (
    <BadgesContainer>
      <StyledBadge
        variant='selection'
        outline={false}
        importance='regular'>
        <Inline>
          <p>{get(first(selection), 'label')}</p>
          <Icon
            variant='close'
            onClick={async () => await onRemove(get(first(selection), 'label') as string, get(first(selection), 'value'))}
            stroke />
        </Inline>
      </StyledBadge>
      {size(selection) > 1 &&
        <StyledBadge
          variant='selection'
          outline={false}
          importance='regular'>
          <Inline>
            <p>+ {size(selection) - 1}</p>
          </Inline>
        </StyledBadge>}
    </BadgesContainer>
  )
}

const LocationInput = ({ name, placeholder, onChange, selection, fetchSuggestions, distance }: LocationInputProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [loading, updateLoading] = useState<boolean>(false)
  const [isFocus, updateIsFocus] = useState<boolean>(false)
  const [userInput, updateUserInput] = useState<string>('')
  const [userDistance, updateUserDistance] = useState<number>(distance)
  const [userSelection, updateUserSelection] = useState<Suggestion[]>(isUndefined(selection) ? [] : selection)
  const [showSuggestions, updateShowSuggestions] = useState<boolean>(false)
  const [activeSuggestion, updateActiveSuggestion] = useState<number>(0)
  const [filteredSuggestions, updateFilteredSuggestions] = useState<Suggestion[]>([])

  useEffect(() => {
    if (!isEqual(distance, userDistance)) {
      updateUserDistance(distance)
    }
  }, [distance])

  useEffect(() => {
    if (!isEqual(userSelection, selection) && !isUndefined(selection)) {
      updateUserSelection(isUndefined(selection) ? [] : filter(selection, item => !isEmpty(item)))
    }
  }, [selection])

  const focusClick = useCallback(() => {
    updateIsFocus(true)
  }, [updateIsFocus])

  const outsideClick = useCallback(() => {
    updateUserInput('')
    updateShowSuggestions(false)
    updateIsFocus(false)
    updateActiveSuggestion(0)
    return updateFilteredSuggestions([])
  }, [updateIsFocus, updateActiveSuggestion, updateFilteredSuggestions, updateShowSuggestions])

  useOnClickOutside(containerRef, outsideClick)

  const getSuggestions = useCallback(async (value: string): Promise<void> => {
    updateLoading(true)

    try {
      const suggestions = await fetchSuggestions(value)

      updateActiveSuggestion(0)
      updateFilteredSuggestions(filter(suggestions, sugg =>
        isUndefined(find(userSelection, select => isEqual(get(select, 'value'), get(sugg, 'value'))))))
      updateShowSuggestions(true)
    } catch (err) {
      updateShowSuggestions(false)
      updateActiveSuggestion(0)
      updateFilteredSuggestions([])
    } finally {
      updateLoading(false)
    }
  }, [updateLoading, updateActiveSuggestion, updateFilteredSuggestions, updateShowSuggestions])

  const onChangeHandler = useCallback(async (evt: string | ChangeEvent<any>): Promise<void> => {
    const evtValue = get(evt, 'currentTarget.value', '')
    updateUserInput(evtValue)

    if (isEmpty(evtValue) || size(evtValue) < MINIMUM_CHARACTERS) {
      updateActiveSuggestion(0)
      return updateFilteredSuggestions([])
    }

    return await getSuggestions(evtValue)
  }, [updateUserInput, updateActiveSuggestion, updateFilteredSuggestions, getSuggestions])

  const onClick = useCallback(async (suggestion: Suggestion) => {
    updateUserInput('')
    updateUserSelection([...userSelection, suggestion])
    await onChange(name, suggestion, true)
    updateShowSuggestions(false)
    updateActiveSuggestion(0)
    updateFilteredSuggestions([])
  }, [userSelection, updateUserInput, updateUserSelection, updateShowSuggestions, updateActiveSuggestion, updateFilteredSuggestions])

  const onRemoveHandler = useCallback(async (label: string, value: any): Promise<void> => {
    updateUserSelection(filter(userSelection, select => !isEqual(get(select, 'value'), value)))
    await onChange(name, { label, value }, false)
  }, [userSelection])

  const validateLocation = useCallback((): void => {
    if (isEqual(size(userSelection), 1) && userDistance > 0) {
      onChange('distance', userDistance)
    }

    return updateIsFocus(false)
  }, [userDistance])

  if (isEqual(isFocus, true)) {
    return (
      <InputContainer ref={containerRef}>
        <StyledInput
          autoFocus
          ref={inputRef}
          name={name}
          value={userInput}
          onChange={onChangeHandler}
          placeholder={placeholder} />
        <SuggestionsContainer>
          <DataDisplay
            search={userInput}
            loading={loading}
            onClick={onClick}
            distance={userDistance}
            onRemove={onRemoveHandler}
            selection={userSelection}
            suggestions={filteredSuggestions}
            updateDistance={updateUserDistance}
            showSuggestions={showSuggestions}
            activeSuggestion={activeSuggestion}
            validateLocation={validateLocation}
            updateActiveSuggestion={updateActiveSuggestion} />
        </SuggestionsContainer>
      </InputContainer>
    )
  }

  return (
    <BaseContainer onClick={focusClick}>
      <DisplaySelection
        onRemove={onRemoveHandler}
        selection={userSelection ?? []}
        placeholder={placeholder ?? ''} />
    </BaseContainer>
  )
}

export default LocationInput
