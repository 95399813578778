import { AxiosError } from 'axios'
import { get, map, isEqual, capitalize, includes } from 'lodash'
import { useQuery, UseQueryResult, UseMutationResult, useMutation, useQueryClient } from 'react-query'

import useClient from '../../hooks/useClient'
import { Data } from '../utils/jsonapi.types'
import { PageDocument } from './Documents.types'

const transformPage = (data: any): PageDocument => ({
  meta: get(data, 'meta.total', 0),
  data: map(get(data, 'data'), ({ id, attributes }) => ({
    id,
    label: get(attributes, 'name'),
    userId: get(attributes, 'user_id', -1),
    value: get(attributes, 'folhomee_url')
  }))
})

export const useLoadDocument = (programId?: string): UseMutationResult => {
  const client = useClient()
  const queryClient = useQueryClient()

  return useMutation(async payload => await client.post('/api/documents/upload', payload), {
    onSuccess: async (res) => {
      const type = get(res, 'data.data.attributes.file_type')
      if (includes(['public', 'private'], type)) {
        return await queryClient.refetchQueries([`fetch${capitalize(type)}Documents`])
      }

      if (isEqual('plan', type)) {
        return await queryClient.refetchQueries(['fetchProgram', programId])
      }

      return await queryClient.refetchQueries(['fetchDocuments', programId])
    }
  })
}

export const useFetchDocumentsQuery = (type: string, params: any): UseQueryResult<PageDocument, AxiosError> => {
  const client = useClient()

  return useQuery([`fetch${capitalize(type)}Documents`], async () => await client.get('/api/documents', { params }), {
    select: ({ data }): PageDocument => transformPage(data),
    staleTime: 1000 * 60 * 60 * 24,
    refetchOnWindowFocus: false
  })
}

export const useDeleteDocumentQuery = (documentId: number): UseMutationResult => {
  const client = useClient()
  const queryClient = useQueryClient()

  return useMutation(async () => await client.delete(`/api/documents/${documentId}`), {
    onSuccess: async () => {
      await queryClient.refetchQueries(['fetchPublicDocuments'])
      await queryClient.refetchQueries(['fetchPrivateDocuments'])
    }
  })
}

export const useFetchDocumentTypesQuery = (): UseQueryResult<Data[], AxiosError> => {
  const client = useClient()
  return useQuery(['fetchDocumentTypes'], async () => await client.get('/api/documents/types'), {
    select: ({ data }): Data[] => {
      return map(get(data, 'data'), ({ attributes }) => ({
        label: get(attributes, 'slug'),
        value: get(attributes, 'id')
      }))
    },
    staleTime: 1000 * 60 * 60 * 24,
    refetchOnWindowFocus: false
  })
}
