import styled from 'styled-components'
import { isEqual, isUndefined } from 'lodash'

import { FlexBoxProps } from './FlexBox.types'

const FlexBox = styled.div<FlexBoxProps>`
  gap: ${({ gap }) => isUndefined(gap) ? 0 : gap}px;
  width: ${({ width100 }) => isEqual(width100, true) ? '100%' : ''};
  height: ${({ height100 }) => isEqual(height100, true) ? '100%' : ''};
  display: flex;
  padding: ${({ padding }) => isUndefined(padding) ? 0 : padding};
  max-width: ${({ maxWidth }) => isUndefined(maxWidth) ? '' : maxWidth}px;
  flex-wrap: ${({ wrap }) => isEqual(wrap, true) ? 'wrap' : 'nowrap'};
  flex-grow: ${({ flexGrow }) => isEqual(flexGrow, true) ? '1' : 0};
  margin-top: ${({ top }) => isUndefined(top) ? 0 : top}px;
  align-items: ${({ alignStart }) => isEqual(alignStart, true) ? 'start' : 'center'};
  margin-left: ${({ left }) => isUndefined(left) ? 0 : left}px;
  margin-right: ${({ right }) => isUndefined(right) ? 0 : right}px;
  margin-bottom: ${({ bottom }) => isUndefined(bottom) ? 0 : bottom}px;
  flex-direction: ${({ row }) => isEqual(row, true) ? 'row' : 'column'};
  justify-content: ${({ justifyContent }) => isUndefined(justifyContent) ? 'center' : justifyContent};
`

export default FlexBox
